import { green, red } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'

const useStyles = makeStyles((theme) => ({
  true: {
    color: green[600],
  },
  false: {
    color: red[800],
  },
}))

const Status = ({ children: value }) => {
  const classes = useStyles()

  return (
    <div>
      {value ? (
        <CheckCircleOutlineTwoToneIcon className={classes.true} />
      ) : (
        <HighlightOffOutlinedIcon className={classes.false} />
      )}
    </div>
  )
}

export default Status
