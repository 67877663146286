import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@mui/icons-material/Delete'
import { useError } from 'utils/hooks'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  root: {},
}))

const DeleteDialog = ({ singularDeleteEndpoint, row, setIsUpdated, setSelected }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { setError } = useError()

  const [open, setOpen] = useState(false)

  const handleSingularDelete = async (row) => {
    try {
      await axios.delete(`${singularDeleteEndpoint.url}/${row[singularDeleteEndpoint.deleteBy]}`)
      setSelected([])
      setIsUpdated()
    } catch (error) {
      setError(error)
    }
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDeleteItems = () => {
    handleSingularDelete(row)
    handleClose()
  }

  return (
    <div className={classes.root}>
      <IconButton size="small" aria-label="delete" onClick={handleOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog onClose={handleClose} aria-labelledby="delete items" open={open}>
        <DialogTitle>{t('Confirm')}</DialogTitle>
        <DialogContent>{t('AreYouSureToDeleteTheItem')}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <Button color="primary" onClick={handleDeleteItems}>
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DeleteDialog
