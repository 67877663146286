import axios from 'axios'
import { API_URL, WS_API_URL } from 'config'
import { getCookie } from 'helpers'
import { useReducer, useRef, useEffect } from 'react'
import { createContext, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { PageURLs } from 'Routes'
import { useError } from 'utils/hooks'
import { useAuth, useTenantInfo } from '..'

const chatReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'LOAD_CHAT_DATA':
      return {
        ...state,
        messages: [...payload.data, ...state.messages]
          .filter(
            (v, i, a) =>
              a.findIndex((t) => t.message === v.message && t.date === v.date && t.username === v.username) === i
          )
          .sort((a, b) => a.date - b.date),
        hasNext: payload.hasNext,
        loadingChat: false,
      }
    case 'SET_CONNECTED':
      return {
        ...state,
        establishedConnection: payload,
      }
    case 'SET_USER_STATUS':
      return {
        ...state,
        userStatuses: { ...state.userStatuses, [payload.username]: payload.status },
      }
    case 'ADD_NEW_MESSAGE':
      // mutating the object directly to change consecutivePosition respectively when a new message gets added
      if (
        state.messages.length > 0 &&
        state.messages[state.messages.length - 1].username === payload.username &&
        state.messages[state.messages.length - 1].isConsecutive &&
        state.messages[state.messages.length - 1].consecutivePosition === 'LAST' &&
        payload.date - state.messages[state.messages.length - 1].date < state.DEFAULT_TIME_TO_RESET
      ) {
        state.messages[state.messages.length - 1] = {
          ...state.messages[state.messages.length - 1],
          consecutivePosition: 'MIDDLE',
        }
      }
      if (
        state.messages.length > 0 &&
        state.messages[state.messages.length - 1].username === payload.username &&
        !state.messages[state.messages.length - 1].isConsecutive &&
        payload.date - state.messages[state.messages.length - 1].date < state.DEFAULT_TIME_TO_RESET
      ) {
        state.messages[state.messages.length - 1] = {
          ...state.messages[state.messages.length - 1],
          isConsecutive: true,
          consecutivePosition: 'FIRST',
        }
      }

      return {
        ...state,
        messages: [payload, ...state.messages]
          .filter(
            (v, i, a) =>
              a.findIndex((t) => t.message === v.message && t.date === v.date && t.username === v.username) === i
          )
          .sort((a, b) => a.date - b.date),
      }
    case 'SET_LOAD_PREV_MESSAGES':
      return {
        ...state,
        loadingPreviousMessages: payload,
      }
    case 'TOGGLE_PIN_CHAT':
      localStorage.setItem('pinChat', !state.pinChat)
      return {
        ...state,
        pinChat: !state.pinChat,
      }
    case 'SET_LATEST_SEEN':
      return {
        ...state,
        seen: { ...state.seen, [payload.username]: payload.date },
      }
    case 'SET_HAS_NEW_MESSAGES':
      return {
        ...state,
        hasNewMessages: payload,
      }
    case 'SET_CHAT_EXPAND':
      return {
        ...state,
        expanded: payload,
      }
    case 'REFRESH_CHAT':
      return {
        ...state,
        messages: [],
        seen: {},
        loadingChat: true,
        userStatuses: {},
      }
    default:
      return state
  }
}

const initialState = {
  messages: [],
  hasNext: false,
  userStatuses: {},
  expanded: false,
  pinChat: localStorage.pinChat === 'true' ? true : false,
  seen: {},
  hasNewMessages: false,
  establishedConnection: false,
  loadingPreviousMessages: false,
  loadingChat: true,
  DEFAULT_TIME_TO_RESET: 10800000, // 3 hours
}

const ChatContext = createContext(initialState)

const ChatProvider = ({ children }) => {
  const [state, dispatch] = useReducer(chatReducer, initialState)
  const { activeTenant } = useTenantInfo()
  const { token, user } = useAuth()
  const { setError } = useError()
  const location = useLocation()

  const ws = useRef(null)

  useEffect(() => {
    if (state.loadingChat || !state.establishedConnection) return

    const intervalId = setInterval(ping, 50000)
    return () => {
      if (!state.loadingChat || state.establishedConnection) {
        clearInterval(intervalId)
      }
    }
  }, [state.establishedConnection, state.loadingChat])

  useEffect(() => {
    if (state.loadingChat || !state.establishedConnection) return
    const { date } = state.messages.length >= 1 && state.messages[state.messages.length - 1]
    const currentUserLatestSeen = state.seen[user?.username]

    if (date > currentUserLatestSeen) {
      if (!state.expanded) {
        dispatch({
          type: 'SET_HAS_NEW_MESSAGES',
          payload: true,
        })
      }
    } else {
      dispatch({
        type: 'SET_HAS_NEW_MESSAGES',
        payload: false,
      })
    }
    // eslint-disable-next-line
  }, [state.messages, state.seen, state.loadingChat, state.establishedConnection, state.expanded])

  useEffect(() => {
    if (!state.establishedConnection || state.loadingChat) return
    if ((state.expanded || location.pathname === PageURLs.Chat) && state.messages.length) setLatestSeen()
    // eslint-disable-next-line
  }, [state.expanded, state.establishedConnection, location, state.messages, state.loadingChat])

  useEffect(() => {
    if (!activeTenant || !token) return
    loadChat({ deletePrevious: true })

    ws.current = new WebSocket(
      `ws${import.meta.env.MODE === 'production' ? 's' : ''}://${WS_API_URL}/chat-socket/${activeTenant}`
    )

    ws.current.onopen = () => {
      dispatch({ type: 'SET_CONNECTED', payload: true })
      authenticate()
    }

    ws.current.onclose = () => {
      dispatch({ type: 'SET_CONNECTED', payload: false })
    }

    ws.current.onerror = () => {
      dispatch({ type: 'SET_CONNECTED', payload: false })
      ws.current.close()
    }

    const wsCurrent = ws.current

    return () => wsCurrent.close()
    // eslint-disable-next-line
  }, [activeTenant])

  useEffect(() => {
    dispatch({
      type: 'SET_CHAT_EXPAND',
      payload: false,
    })
  }, [location])

  const loadChat = async ({
    from = 0,
    asc = false,
    pageSize = 50,
    previousMessagesContext = false,
    deletePrevious = false,
  }) => {
    try {
      deletePrevious && dispatch({ type: 'REFRESH_CHAT' })
      previousMessagesContext && dispatch({ type: 'SET_LOAD_PREV_MESSAGES', payload: true })
      const { data } = await axios(`${API_URL}/chat/${activeTenant}?asc=${asc}&pageSize=${pageSize}&from=${from}`)
      const newMessages = data.data.sort((a, b) => a.date - b.date)
      dispatch({
        type: 'LOAD_CHAT_DATA',
        payload: {
          ...data,
          data: newMessages.map((message, index) => {
            return {
              ...message,
              own: user.username === message.username,
              isConsecutive: isConsecutive(newMessages, index, message.username),
              consecutivePosition: consecutivePosition(newMessages, index, message.username),
            }
          }),
        },
      })
      previousMessagesContext && dispatch({ type: 'SET_LOAD_PREV_MESSAGES', payload: false })
    } catch (error) {
      setError(error)
    }
  }

  const isConsecutive = (allMessages, currentIndex, username) => {
    return (
      (currentIndex - 1 >= 0 &&
        allMessages[currentIndex - 1].username === username &&
        allMessages[currentIndex].date - allMessages[currentIndex - 1].date < state.DEFAULT_TIME_TO_RESET) ||
      (allMessages.length - 1 !== currentIndex &&
        allMessages[currentIndex + 1].username === username &&
        allMessages[currentIndex + 1].date - allMessages[currentIndex].date < state.DEFAULT_TIME_TO_RESET)
    )
  }

  const consecutivePosition = (allMessages, currentIndex, username) => {
    if (!isConsecutive(allMessages, currentIndex, username) && currentIndex === 0) {
      return null
    }

    if (isConsecutive(allMessages, currentIndex, username) && currentIndex === 0) {
      return 'FIRST'
    }

    if (isConsecutive(allMessages, currentIndex, username) && currentIndex + 1 >= allMessages.length) {
      return 'LAST'
    }

    if (!isConsecutive(allMessages, currentIndex, username) && currentIndex + 1 >= allMessages.length) {
      return null
    }

    if (
      allMessages[currentIndex - 1].username === username &&
      allMessages[currentIndex + 1].username === username &&
      allMessages[currentIndex].date - allMessages[currentIndex - 1].date < state.DEFAULT_TIME_TO_RESET &&
      allMessages[currentIndex + 1].date - allMessages[currentIndex].date < state.DEFAULT_TIME_TO_RESET
    ) {
      return 'MIDDLE'
    }
    if (
      allMessages[currentIndex - 1].username === username &&
      allMessages[currentIndex].username === username &&
      allMessages[currentIndex].date - allMessages[currentIndex - 1].date < state.DEFAULT_TIME_TO_RESET
    ) {
      return 'LAST'
    }
    if (
      (allMessages[currentIndex + 1].date - allMessages[currentIndex].date < state.DEFAULT_TIME_TO_RESET &&
        allMessages[currentIndex + 1].username === username) ||
      currentIndex === allMessages.length
    ) {
      return 'FIRST'
    }
  }

  if (!!ws.current) {
    ws.current.onmessage = (e) => {
      const result = JSON.parse(e.data).sort((a, b) => a.date - b.date)

      result.forEach((message, index) => {
        const username = message.username
        const type = message.chatDataType
        const data = message.data

        switch (type) {
          case 'USER_STATUS':
            dispatch({ type: 'SET_USER_STATUS', payload: { username: username, status: data } })
            break
          case 'MESSAGE':
            dispatch({
              type: 'ADD_NEW_MESSAGE',
              payload: {
                ...message,
                own: user.username === username,
                isConsecutive: isConsecutive(
                  [message, ...state.messages].sort((a, b) => a.date - b.date),
                  [message, ...state.messages].sort((a, b) => a.date - b.date).length - 1,
                  username
                ),
                consecutivePosition: consecutivePosition(
                  [message, ...state.messages].sort((a, b) => a.date - b.date),
                  [message, ...state.messages].sort((a, b) => a.date - b.date).length - 1,
                  username
                ),
              },
            })
            break
          case 'SEEN':
            dispatch({
              type: 'SET_LATEST_SEEN',
              payload: { username: username, date: message.date },
            })
            break
          default:
            return null
        }
      })
    }
  }

  const sendMessage = async (message) => {
    await ws.current.send(JSON.stringify({ chatDataType: 'MESSAGE', data: message, token: getCookie('auth_token') }))
  }

  const ping = async () => {
    await ws?.current?.send(1)
  }

  const authenticate = async () => {
    await ws.current.send(
      JSON.stringify({ chatDataType: 'AUTHENTICATE', data: 'AUTH ATTEMPT', token: getCookie('auth_token') })
    )
  }

  const togglePin = () => {
    dispatch({ type: 'TOGGLE_PIN_CHAT' })
  }

  const setLatestSeen = async () => {
    state.messages.length >= 1 &&
      state.seen[user.username] !== state.messages[state.messages.length - 1].date &&
      (await ws.current.send(
        JSON.stringify({
          chatDataType: 'SEEN',
          username: user?.username,
          date: state.messages[state.messages.length - 1].date,
          token: getCookie('auth_token'),
        })
      ))
    dispatch({
      type: 'SET_HAS_NEW_MESSAGES',
      payload: false,
    })
  }

  const setChatExpand = () => {
    if (!user.username) return
    dispatch({
      type: 'SET_CHAT_EXPAND',
      payload: !state.expanded,
    })
  }

  return (
    <ChatContext.Provider
      value={{
        messages: state.messages.sort((a, b) => a.date - b.date),
        userStatuses: state.userStatuses,
        seen: state.seen,
        expanded: state.expanded,
        establishedConnection: state.establishedConnection,
        hasNext: state.hasNext,
        pinChat: state.pinChat,
        hasNewMessages: state.hasNewMessages,
        loadingChat: state.loadingChat,
        DEFAULT_TIME_TO_RESET: state.DEFAULT_TIME_TO_RESET,
        loadingPreviousMessages: state.loadingPreviousMessages,
        ws,
        setChatExpand,
        togglePin,
        sendMessage,
        loadChat,
        ping,
      }}
      displayName="Chat"
    >
      {children}
    </ChatContext.Provider>
  )
}

const useChat = () => {
  const context = useContext(ChatContext)

  if (context === undefined) {
    throw new Error('useChat can only be used inside ChatProvider')
  }

  return context
}

export { ChatProvider, useChat }
