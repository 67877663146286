import { DateTime } from 'luxon'

export const convertMillisecondsToDateTime = (milliseconds) => {
  return DateTime.fromMillis(milliseconds, { zone: 'utc' }).set({
    year: 1970,
    month: 1,
    day: 1,
  })
}

export const convertDateTimeToMilliseconds = (dateTime) => {
  const hours = dateTime.hour
  const minutes = dateTime.minute
  const seconds = dateTime.second
  return hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000
}

export const convertMillisecondsToHumanReadableFormat = (milliseconds, showOnlyMinutesAndSeconds = false) => {
  // Convert milliseconds to a Luxon DateTime object
  const duration = DateTime.fromMillis(milliseconds, { zone: 'utc' }).toObject()

  const hours = duration.hour || 0
  const minutes = duration.minute || 0
  const seconds = duration.second || 0

  // If showOnlyMinutesAndSeconds is true, or if hours are 0, return only minutes and seconds
  if (showOnlyMinutesAndSeconds || hours === 0) {
    return `${String(minutes + hours * 60).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
  }

  // Otherwise, return hours, minutes, and seconds
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
}
