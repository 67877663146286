/* eslint-disable import/no-anonymous-default-export */
import { mergeDeep } from 'helpers'
import MuiTab from './MuiTab'
import MuiTabs from './MuiTabs'
import MuiPaper from './MuiPaper'
import MuiRadio from './MuiRadio'
import MuiButton from './MuiButton'
import MuiDialog from './MuiDialog'
import MuiMenuItem from './MuiMenuItem'
import MuiBackdrop from './MuiBackdrop'
import MuiCheckbox from './MuiCheckbox'
import MuiTableCell from './MuiTableCell'
import MuiTypography from './MuiTypography'
import MuiCssBaseline from './MuiCssBaseline'
import MuiListItemText from './MuiListItemText'
import MuiOutlinedInput from './MuiOutlinedInput'

export default function overrides(theme) {
  return mergeDeep(
    MuiTab(theme),
    MuiTabs(theme),
    MuiPaper(theme),
    MuiRadio(theme),
    MuiButton(theme),
    MuiDialog(theme),
    MuiMenuItem(theme),
    MuiBackdrop(theme),
    MuiCheckbox(theme),
    MuiTableCell(theme),
    MuiTypography(theme),
    MuiCssBaseline(theme),
    MuiListItemText(theme),
    MuiOutlinedInput(theme)
  )
}
