import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material'
import { ACCEPTED_ADMIN_GROUPS } from 'config'
import { useAuth, useEscapeRooms } from 'core'
import { useTranslation } from 'react-i18next'
import { PageURLs } from 'Routes'
import { Link as RouterLink } from 'react-router-dom'

const SelectRoomToModerate = () => {
  const { t } = useTranslation(['translation'])
  const { escapeRooms, setActiveRooms, activeEscapeRooms, activeEscapeRoomsIds } = useEscapeRooms()
  const { user } = useAuth()

  const handleChange = (event) => {
    const {
      target: { value },
    } = event

    const escapeRoomIds = value.map((escapeRoom) => escapeRoom.id)
    setActiveRooms(value.filter(({ id }, index) => !escapeRoomIds.includes(id, index + 1)))
  }

  return activeEscapeRooms.length ? (
    <>
      <FormControl sx={{ width: { xs: 180, sm: 240 } }}>
        <InputLabel size="small" id="selected-rooms">
          {t('SelectedRooms')}
        </InputLabel>
        <Select
          labelId="selected-rooms"
          multiple
          value={activeEscapeRooms.sort(function (a, b) {
            const nameA = a.name.toUpperCase()
            const nameB = b.name.toUpperCase()
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }

            return 0
          })}
          onChange={handleChange}
          input={<OutlinedInput size="small" label={t('SelectedRooms')} />}
          renderValue={(selected) =>
            selected.length === escapeRooms.length
              ? t('All')
              : escapeRooms
                  .filter((escapeRoom) => selected.map((selectedRoom) => selectedRoom.id).includes(escapeRoom.id))
                  .map((escapeRoom) => escapeRoom.name)
                  .join(', ')
          }
        >
          {escapeRooms.map((escapeRoom) => (
            <MenuItem
              key={escapeRoom.id}
              value={escapeRoom}
              disabled={activeEscapeRooms.length === 1 && activeEscapeRooms[0]?.id === escapeRoom.id}
            >
              <Checkbox checked={activeEscapeRoomsIds.indexOf(escapeRoom.id) > -1} />
              <ListItemText primary={escapeRoom.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  ) : !ACCEPTED_ADMIN_GROUPS.includes(user?.userGroup) ? (
    <CircularProgress />
  ) : (
    <Button
      size="small"
      color="primary"
      variant="contained"
      component={RouterLink}
      to={`${PageURLs.EscapeRooms}/create`}
    >
      {t('escaperooms:CreateEscapeRoom')}
    </Button>
  )
}

export default SelectRoomToModerate
