/* eslint-disable import/no-anonymous-default-export */
export default (theme) => ({
  MuiCssBaseLine: {
    styleOverrides: {
      '@global': {
        body: {
          letterSpacing: '0',
        },
      },
    },
  },
})
