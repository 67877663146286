import { green, red, yellow } from '@mui/material/colors'
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { SUBSCRIPTION_STATUSES } from 'config'

const SubscriptionStatus = ({ children: value }) => {
  if (value === SUBSCRIPTION_STATUSES.ACTIVE.key) {
    return (
      <div>
        <CheckCircleOutlineTwoToneIcon sx={{ color: green[600] }} />
      </div>
    )
  }
  if (value === SUBSCRIPTION_STATUSES.STALLED.key) {
    return (
      <div>
        <ErrorOutlineIcon sx={{ color: yellow[800] }} />
      </div>
    )
  }
  if (value === SUBSCRIPTION_STATUSES.INACTIVE.key) {
    return (
      <div>
        <HighlightOffOutlinedIcon sx={{ color: red[800] }} />
      </div>
    )
  }
  return null
}

export default SubscriptionStatus
