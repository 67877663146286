export const emojisCategories = [
  'Smileys & Emotion',
  'People & Body',
  'Animals & Nature',
  'Food & Drink',
  'Travel & Places',
  'Activities',
  'Objects',
  'Symbols',
  'Flags',
]
export const emojis = [
  {
    d: 'grinning face',
    e: '😀',
    c: 'Smileys & Emotion',
  },
  {
    d: 'grinning face with big eyes',
    e: '😃',
    c: 'Smileys & Emotion',
  },
  {
    d: 'grinning face with smiling eyes',
    e: '😄',
    c: 'Smileys & Emotion',
  },
  {
    d: 'beaming face with smiling eyes',
    e: '😁',
    c: 'Smileys & Emotion',
  },
  {
    d: 'grinning squinting face',
    e: '😆',
    c: 'Smileys & Emotion',
  },
  {
    d: 'grinning face with sweat',
    e: '😅',
    c: 'Smileys & Emotion',
  },
  {
    d: 'rolling on the floor laughing',
    e: '🤣',
    c: 'Smileys & Emotion',
  },
  {
    d: 'face with tears of joy',
    e: '😂',
    c: 'Smileys & Emotion',
  },
  {
    d: 'slightly smiling face',
    e: '🙂',
    c: 'Smileys & Emotion',
  },
  {
    d: 'upside-down face',
    e: '🙃',
    c: 'Smileys & Emotion',
  },
  {
    d: 'winking face',
    e: '😉',
    c: 'Smileys & Emotion',
  },
  {
    d: 'smiling face with smiling eyes',
    e: '😊',
    c: 'Smileys & Emotion',
  },
  {
    d: 'smiling face with halo',
    e: '😇',
    c: 'Smileys & Emotion',
  },
  {
    d: 'smiling face with hearts',
    e: '🥰',
    c: 'Smileys & Emotion',
  },
  {
    d: 'smiling face with heart-eyes',
    e: '😍',
    c: 'Smileys & Emotion',
  },
  {
    d: 'star-struck',
    e: '🤩',
    c: 'Smileys & Emotion',
  },
  {
    d: 'face blowing a kiss',
    e: '😘',
    c: 'Smileys & Emotion',
  },
  {
    d: 'kissing face',
    e: '😗',
    c: 'Smileys & Emotion',
  },
  {
    d: 'smiling face',
    e: '☺️',
    c: 'Smileys & Emotion',
  },
  {
    d: 'kissing face with closed eyes',
    e: '😚',
    c: 'Smileys & Emotion',
  },
  {
    d: 'kissing face with smiling eyes',
    e: '😙',
    c: 'Smileys & Emotion',
  },
  {
    d: 'smiling face with tear',
    e: '🥲',
    c: 'Smileys & Emotion',
  },
  {
    d: 'face savoring food',
    e: '😋',
    c: 'Smileys & Emotion',
  },
  {
    d: 'face with tongue',
    e: '😛',
    c: 'Smileys & Emotion',
  },
  {
    d: 'winking face with tongue',
    e: '😜',
    c: 'Smileys & Emotion',
  },
  {
    d: 'zany face',
    e: '🤪',
    c: 'Smileys & Emotion',
  },
  {
    d: 'squinting face with tongue',
    e: '😝',
    c: 'Smileys & Emotion',
  },
  {
    d: 'money-mouth face',
    e: '🤑',
    c: 'Smileys & Emotion',
  },
  {
    d: 'hugging face',
    e: '🤗',
    c: 'Smileys & Emotion',
  },
  {
    d: 'face with hand over mouth',
    e: '🤭',
    c: 'Smileys & Emotion',
  },
  {
    d: 'shushing face',
    e: '🤫',
    c: 'Smileys & Emotion',
  },
  {
    d: 'thinking face',
    e: '🤔',
    c: 'Smileys & Emotion',
  },
  {
    d: 'zipper-mouth face',
    e: '🤐',
    c: 'Smileys & Emotion',
  },
  {
    d: 'face with raised eyebrow',
    e: '🤨',
    c: 'Smileys & Emotion',
  },
  {
    d: 'neutral face',
    e: '😐',
    c: 'Smileys & Emotion',
  },
  {
    d: 'expressionless face',
    e: '😑',
    c: 'Smileys & Emotion',
  },
  {
    d: 'face without mouth',
    e: '😶',
    c: 'Smileys & Emotion',
  },
  {
    d: 'face in clouds',
    e: '😶‍🌫️',
    c: 'Smileys & Emotion',
  },
  {
    d: 'smirking face',
    e: '😏',
    c: 'Smileys & Emotion',
  },
  {
    d: 'unamused face',
    e: '😒',
    c: 'Smileys & Emotion',
  },
  {
    d: 'face with rolling eyes',
    e: '🙄',
    c: 'Smileys & Emotion',
  },
  {
    d: 'grimacing face',
    e: '😬',
    c: 'Smileys & Emotion',
  },
  {
    d: 'face exhaling',
    e: '😮‍💨',
    c: 'Smileys & Emotion',
  },
  {
    d: 'lying face',
    e: '🤥',
    c: 'Smileys & Emotion',
  },
  {
    d: 'relieved face',
    e: '😌',
    c: 'Smileys & Emotion',
  },
  {
    d: 'pensive face',
    e: '😔',
    c: 'Smileys & Emotion',
  },
  {
    d: 'sleepy face',
    e: '😪',
    c: 'Smileys & Emotion',
  },
  {
    d: 'drooling face',
    e: '🤤',
    c: 'Smileys & Emotion',
  },
  {
    d: 'sleeping face',
    e: '😴',
    c: 'Smileys & Emotion',
  },
  {
    d: 'face with medical mask',
    e: '😷',
    c: 'Smileys & Emotion',
  },
  {
    d: 'face with thermometer',
    e: '🤒',
    c: 'Smileys & Emotion',
  },
  {
    d: 'face with head-bandage',
    e: '🤕',
    c: 'Smileys & Emotion',
  },
  {
    d: 'nauseated face',
    e: '🤢',
    c: 'Smileys & Emotion',
  },
  {
    d: 'face vomiting',
    e: '🤮',
    c: 'Smileys & Emotion',
  },
  {
    d: 'sneezing face',
    e: '🤧',
    c: 'Smileys & Emotion',
  },
  {
    d: 'hot face',
    e: '🥵',
    c: 'Smileys & Emotion',
  },
  {
    d: 'cold face',
    e: '🥶',
    c: 'Smileys & Emotion',
  },
  {
    d: 'woozy face',
    e: '🥴',
    c: 'Smileys & Emotion',
  },
  {
    d: 'knocked-out face',
    e: '😵',
    c: 'Smileys & Emotion',
  },
  {
    d: 'face with spiral eyes',
    e: '😵‍💫',
    c: 'Smileys & Emotion',
  },
  {
    d: 'exploding head',
    e: '🤯',
    c: 'Smileys & Emotion',
  },
  {
    d: 'cowboy hat face',
    e: '🤠',
    c: 'Smileys & Emotion',
  },
  {
    d: 'partying face',
    e: '🥳',
    c: 'Smileys & Emotion',
  },
  {
    d: 'disguised face',
    e: '🥸',
    c: 'Smileys & Emotion',
  },
  {
    d: 'smiling face with sunglasses',
    e: '😎',
    c: 'Smileys & Emotion',
  },
  {
    d: 'nerd face',
    e: '🤓',
    c: 'Smileys & Emotion',
  },
  {
    d: 'face with monocle',
    e: '🧐',
    c: 'Smileys & Emotion',
  },
  {
    d: 'confused face',
    e: '😕',
    c: 'Smileys & Emotion',
  },
  {
    d: 'worried face',
    e: '😟',
    c: 'Smileys & Emotion',
  },
  {
    d: 'slightly frowning face',
    e: '🙁',
    c: 'Smileys & Emotion',
  },
  {
    d: 'frowning face',
    e: '☹️',
    c: 'Smileys & Emotion',
  },
  {
    d: 'face with open mouth',
    e: '😮',
    c: 'Smileys & Emotion',
  },
  {
    d: 'hushed face',
    e: '😯',
    c: 'Smileys & Emotion',
  },
  {
    d: 'astonished face',
    e: '😲',
    c: 'Smileys & Emotion',
  },
  {
    d: 'flushed face',
    e: '😳',
    c: 'Smileys & Emotion',
  },
  {
    d: 'pleading face',
    e: '🥺',
    c: 'Smileys & Emotion',
  },
  {
    d: 'frowning face with open mouth',
    e: '😦',
    c: 'Smileys & Emotion',
  },
  {
    d: 'anguished face',
    e: '😧',
    c: 'Smileys & Emotion',
  },
  {
    d: 'fearful face',
    e: '😨',
    c: 'Smileys & Emotion',
  },
  {
    d: 'anxious face with sweat',
    e: '😰',
    c: 'Smileys & Emotion',
  },
  {
    d: 'sad but relieved face',
    e: '😥',
    c: 'Smileys & Emotion',
  },
  {
    d: 'crying face',
    e: '😢',
    c: 'Smileys & Emotion',
  },
  {
    d: 'loudly crying face',
    e: '😭',
    c: 'Smileys & Emotion',
  },
  {
    d: 'face screaming in fear',
    e: '😱',
    c: 'Smileys & Emotion',
  },
  {
    d: 'confounded face',
    e: '😖',
    c: 'Smileys & Emotion',
  },
  {
    d: 'persevering face',
    e: '😣',
    c: 'Smileys & Emotion',
  },
  {
    d: 'disappointed face',
    e: '😞',
    c: 'Smileys & Emotion',
  },
  {
    d: 'downcast face with sweat',
    e: '😓',
    c: 'Smileys & Emotion',
  },
  {
    d: 'weary face',
    e: '😩',
    c: 'Smileys & Emotion',
  },
  {
    d: 'tired face',
    e: '😫',
    c: 'Smileys & Emotion',
  },
  {
    d: 'yawning face',
    e: '🥱',
    c: 'Smileys & Emotion',
  },
  {
    d: 'face with steam from nose',
    e: '😤',
    c: 'Smileys & Emotion',
  },
  {
    d: 'pouting face',
    e: '😡',
    c: 'Smileys & Emotion',
  },
  {
    d: 'angry face',
    e: '😠',
    c: 'Smileys & Emotion',
  },
  {
    d: 'face with symbols on mouth',
    e: '🤬',
    c: 'Smileys & Emotion',
  },
  {
    d: 'smiling face with horns',
    e: '😈',
    c: 'Smileys & Emotion',
  },
  {
    d: 'angry face with horns',
    e: '👿',
    c: 'Smileys & Emotion',
  },
  {
    d: 'skull',
    e: '💀',
    c: 'Smileys & Emotion',
  },
  {
    d: 'skull and crossbones',
    e: '☠️',
    c: 'Smileys & Emotion',
  },
  {
    d: 'pile of poo',
    e: '💩',
    c: 'Smileys & Emotion',
  },
  {
    d: 'clown face',
    e: '🤡',
    c: 'Smileys & Emotion',
  },
  {
    d: 'ogre',
    e: '👹',
    c: 'Smileys & Emotion',
  },
  {
    d: 'goblin',
    e: '👺',
    c: 'Smileys & Emotion',
  },
  {
    d: 'ghost',
    e: '👻',
    c: 'Smileys & Emotion',
  },
  {
    d: 'alien',
    e: '👽',
    c: 'Smileys & Emotion',
  },
  {
    d: 'alien monster',
    e: '👾',
    c: 'Smileys & Emotion',
  },
  {
    d: 'robot',
    e: '🤖',
    c: 'Smileys & Emotion',
  },
  {
    d: 'grinning cat',
    e: '😺',
    c: 'Smileys & Emotion',
  },
  {
    d: 'grinning cat with smiling eyes',
    e: '😸',
    c: 'Smileys & Emotion',
  },
  {
    d: 'cat with tears of joy',
    e: '😹',
    c: 'Smileys & Emotion',
  },
  {
    d: 'smiling cat with heart-eyes',
    e: '😻',
    c: 'Smileys & Emotion',
  },
  {
    d: 'cat with wry smile',
    e: '😼',
    c: 'Smileys & Emotion',
  },
  {
    d: 'kissing cat',
    e: '😽',
    c: 'Smileys & Emotion',
  },
  {
    d: 'weary cat',
    e: '🙀',
    c: 'Smileys & Emotion',
  },
  {
    d: 'crying cat',
    e: '😿',
    c: 'Smileys & Emotion',
  },
  {
    d: 'pouting cat',
    e: '😾',
    c: 'Smileys & Emotion',
  },
  {
    d: 'see-no-evil monkey',
    e: '🙈',
    c: 'Smileys & Emotion',
  },
  {
    d: 'hear-no-evil monkey',
    e: '🙉',
    c: 'Smileys & Emotion',
  },
  {
    d: 'speak-no-evil monkey',
    e: '🙊',
    c: 'Smileys & Emotion',
  },
  {
    d: 'kiss mark',
    e: '💋',
    c: 'Smileys & Emotion',
  },
  {
    d: 'love letter',
    e: '💌',
    c: 'Smileys & Emotion',
  },
  {
    d: 'heart with arrow',
    e: '💘',
    c: 'Smileys & Emotion',
  },
  {
    d: 'heart with ribbon',
    e: '💝',
    c: 'Smileys & Emotion',
  },
  {
    d: 'sparkling heart',
    e: '💖',
    c: 'Smileys & Emotion',
  },
  {
    d: 'growing heart',
    e: '💗',
    c: 'Smileys & Emotion',
  },
  {
    d: 'beating heart',
    e: '💓',
    c: 'Smileys & Emotion',
  },
  {
    d: 'revolving hearts',
    e: '💞',
    c: 'Smileys & Emotion',
  },
  {
    d: 'two hearts',
    e: '💕',
    c: 'Smileys & Emotion',
  },
  {
    d: 'heart decoration',
    e: '💟',
    c: 'Smileys & Emotion',
  },
  {
    d: 'heart exclamation',
    e: '❣️',
    c: 'Smileys & Emotion',
  },
  {
    d: 'broken heart',
    e: '💔',
    c: 'Smileys & Emotion',
  },
  {
    d: 'heart on fire',
    e: '❤️‍🔥',
    c: 'Smileys & Emotion',
  },
  {
    d: 'mending heart',
    e: '❤️‍🩹',
    c: 'Smileys & Emotion',
  },
  {
    d: 'red heart',
    e: '❤️',
    c: 'Smileys & Emotion',
  },
  {
    d: 'orange heart',
    e: '🧡',
    c: 'Smileys & Emotion',
  },
  {
    d: 'yellow heart',
    e: '💛',
    c: 'Smileys & Emotion',
  },
  {
    d: 'green heart',
    e: '💚',
    c: 'Smileys & Emotion',
  },
  {
    d: 'blue heart',
    e: '💙',
    c: 'Smileys & Emotion',
  },
  {
    d: 'purple heart',
    e: '💜',
    c: 'Smileys & Emotion',
  },
  {
    d: 'brown heart',
    e: '🤎',
    c: 'Smileys & Emotion',
  },
  {
    d: 'black heart',
    e: '🖤',
    c: 'Smileys & Emotion',
  },
  {
    d: 'white heart',
    e: '🤍',
    c: 'Smileys & Emotion',
  },
  {
    d: 'hundred points',
    e: '💯',
    c: 'Smileys & Emotion',
  },
  {
    d: 'anger symbol',
    e: '💢',
    c: 'Smileys & Emotion',
  },
  {
    d: 'collision',
    e: '💥',
    c: 'Smileys & Emotion',
  },
  {
    d: 'dizzy',
    e: '💫',
    c: 'Smileys & Emotion',
  },
  {
    d: 'sweat droplets',
    e: '💦',
    c: 'Smileys & Emotion',
  },
  {
    d: 'dashing away',
    e: '💨',
    c: 'Smileys & Emotion',
  },
  {
    d: 'hole',
    e: '🕳️',
    c: 'Smileys & Emotion',
  },
  {
    d: 'bomb',
    e: '💣',
    c: 'Smileys & Emotion',
  },
  {
    d: 'speech balloon',
    e: '💬',
    c: 'Smileys & Emotion',
  },
  {
    d: 'eye in speech bubble',
    e: '👁️‍🗨️',
    c: 'Smileys & Emotion',
  },
  {
    d: 'left speech bubble',
    e: '🗨️',
    c: 'Smileys & Emotion',
  },
  {
    d: 'right anger bubble',
    e: '🗯️',
    c: 'Smileys & Emotion',
  },
  {
    d: 'thought balloon',
    e: '💭',
    c: 'Smileys & Emotion',
  },
  {
    d: 'zzz',
    e: '💤',
    c: 'Smileys & Emotion',
  },
  {
    d: 'waving hand',
    e: '👋',
    c: 'People & Body',
  },
  {
    d: 'raised back of hand',
    e: '🤚',
    c: 'People & Body',
  },
  {
    d: 'hand with fingers splayed',
    e: '🖐️',
    c: 'People & Body',
  },
  {
    d: 'raised hand',
    e: '✋',
    c: 'People & Body',
  },
  {
    d: 'vulcan salute',
    e: '🖖',
    c: 'People & Body',
  },
  {
    d: 'OK hand',
    e: '👌',
    c: 'People & Body',
  },
  {
    d: 'pinched fingers',
    e: '🤌',
    c: 'People & Body',
  },
  {
    d: 'pinching hand',
    e: '🤏',
    c: 'People & Body',
  },
  {
    d: 'victory hand',
    e: '✌️',
    c: 'People & Body',
  },
  {
    d: 'crossed fingers',
    e: '🤞',
    c: 'People & Body',
  },
  {
    d: 'love-you gesture',
    e: '🤟',
    c: 'People & Body',
  },
  {
    d: 'sign of the horns',
    e: '🤘',
    c: 'People & Body',
  },
  {
    d: 'call me hand',
    e: '🤙',
    c: 'People & Body',
  },
  {
    d: 'backhand index pointing left',
    e: '👈',
    c: 'People & Body',
  },
  {
    d: 'backhand index pointing right',
    e: '👉',
    c: 'People & Body',
  },
  {
    d: 'backhand index pointing up',
    e: '👆',
    c: 'People & Body',
  },
  {
    d: 'middle finger',
    e: '🖕',
    c: 'People & Body',
  },
  {
    d: 'backhand index pointing down',
    e: '👇',
    c: 'People & Body',
  },
  {
    d: 'index pointing up',
    e: '☝️',
    c: 'People & Body',
  },
  {
    d: 'thumbs up',
    e: '👍',
    c: 'People & Body',
  },
  {
    d: 'thumbs down',
    e: '👎',
    c: 'People & Body',
  },
  {
    d: 'raised fist',
    e: '✊',
    c: 'People & Body',
  },
  {
    d: 'oncoming fist',
    e: '👊',
    c: 'People & Body',
  },
  {
    d: 'left-facing fist',
    e: '🤛',
    c: 'People & Body',
  },
  {
    d: 'right-facing fist',
    e: '🤜',
    c: 'People & Body',
  },
  {
    d: 'clapping hands',
    e: '👏',
    c: 'People & Body',
  },
  {
    d: 'raising hands',
    e: '🙌',
    c: 'People & Body',
  },
  {
    d: 'open hands',
    e: '👐',
    c: 'People & Body',
  },
  {
    d: 'palms up together',
    e: '🤲',
    c: 'People & Body',
  },
  {
    d: 'handshake',
    e: '🤝',
    c: 'People & Body',
  },
  {
    d: 'folded hands',
    e: '🙏',
    c: 'People & Body',
  },
  {
    d: 'writing hand',
    e: '✍️',
    c: 'People & Body',
  },
  {
    d: 'nail polish',
    e: '💅',
    c: 'People & Body',
  },
  {
    d: 'selfie',
    e: '🤳',
    c: 'People & Body',
  },
  {
    d: 'flexed biceps',
    e: '💪',
    c: 'People & Body',
  },
  {
    d: 'mechanical arm',
    e: '🦾',
    c: 'People & Body',
  },
  {
    d: 'mechanical leg',
    e: '🦿',
    c: 'People & Body',
  },
  {
    d: 'leg',
    e: '🦵',
    c: 'People & Body',
  },
  {
    d: 'foot',
    e: '🦶',
    c: 'People & Body',
  },
  {
    d: 'ear',
    e: '👂',
    c: 'People & Body',
  },
  {
    d: 'ear with hearing aid',
    e: '🦻',
    c: 'People & Body',
  },
  {
    d: 'nose',
    e: '👃',
    c: 'People & Body',
  },
  {
    d: 'brain',
    e: '🧠',
    c: 'People & Body',
  },
  {
    d: 'anatomical heart',
    e: '🫀',
    c: 'People & Body',
  },
  {
    d: 'lungs',
    e: '🫁',
    c: 'People & Body',
  },
  {
    d: 'tooth',
    e: '🦷',
    c: 'People & Body',
  },
  {
    d: 'bone',
    e: '🦴',
    c: 'People & Body',
  },
  {
    d: 'eyes',
    e: '👀',
    c: 'People & Body',
  },
  {
    d: 'eye',
    e: '👁️',
    c: 'People & Body',
  },
  {
    d: 'tongue',
    e: '👅',
    c: 'People & Body',
  },
  {
    d: 'mouth',
    e: '👄',
    c: 'People & Body',
  },
  {
    d: 'baby',
    e: '👶',
    c: 'People & Body',
  },
  {
    d: 'child',
    e: '🧒',
    c: 'People & Body',
  },
  {
    d: 'boy',
    e: '👦',
    c: 'People & Body',
  },
  {
    d: 'girl',
    e: '👧',
    c: 'People & Body',
  },
  {
    d: 'person',
    e: '🧑',
    c: 'People & Body',
  },
  {
    d: 'person: blond hair',
    e: '👱',
    c: 'People & Body',
  },
  {
    d: 'man',
    e: '👨',
    c: 'People & Body',
  },
  {
    d: 'person: beard',
    e: '🧔',
    c: 'People & Body',
  },
  {
    d: 'man: beard',
    e: '🧔‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman: beard',
    e: '🧔‍♀️',
    c: 'People & Body',
  },
  {
    d: 'man: red hair',
    e: '👨‍🦰',
    c: 'People & Body',
  },
  {
    d: 'man: curly hair',
    e: '👨‍🦱',
    c: 'People & Body',
  },
  {
    d: 'man: white hair',
    e: '👨‍🦳',
    c: 'People & Body',
  },
  {
    d: 'man: bald',
    e: '👨‍🦲',
    c: 'People & Body',
  },
  {
    d: 'woman',
    e: '👩',
    c: 'People & Body',
  },
  {
    d: 'woman: red hair',
    e: '👩‍🦰',
    c: 'People & Body',
  },
  {
    d: 'person: red hair',
    e: '🧑‍🦰',
    c: 'People & Body',
  },
  {
    d: 'woman: curly hair',
    e: '👩‍🦱',
    c: 'People & Body',
  },
  {
    d: 'person: curly hair',
    e: '🧑‍🦱',
    c: 'People & Body',
  },
  {
    d: 'woman: white hair',
    e: '👩‍🦳',
    c: 'People & Body',
  },
  {
    d: 'person: white hair',
    e: '🧑‍🦳',
    c: 'People & Body',
  },
  {
    d: 'woman: bald',
    e: '👩‍🦲',
    c: 'People & Body',
  },
  {
    d: 'person: bald',
    e: '🧑‍🦲',
    c: 'People & Body',
  },
  {
    d: 'woman: blond hair',
    e: '👱‍♀️',
    c: 'People & Body',
  },
  {
    d: 'man: blond hair',
    e: '👱‍♂️',
    c: 'People & Body',
  },
  {
    d: 'older person',
    e: '🧓',
    c: 'People & Body',
  },
  {
    d: 'old man',
    e: '👴',
    c: 'People & Body',
  },
  {
    d: 'old woman',
    e: '👵',
    c: 'People & Body',
  },
  {
    d: 'person frowning',
    e: '🙍',
    c: 'People & Body',
  },
  {
    d: 'man frowning',
    e: '🙍‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman frowning',
    e: '🙍‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person pouting',
    e: '🙎',
    c: 'People & Body',
  },
  {
    d: 'man pouting',
    e: '🙎‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman pouting',
    e: '🙎‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person gesturing NO',
    e: '🙅',
    c: 'People & Body',
  },
  {
    d: 'man gesturing NO',
    e: '🙅‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman gesturing NO',
    e: '🙅‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person gesturing OK',
    e: '🙆',
    c: 'People & Body',
  },
  {
    d: 'man gesturing OK',
    e: '🙆‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman gesturing OK',
    e: '🙆‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person tipping hand',
    e: '💁',
    c: 'People & Body',
  },
  {
    d: 'man tipping hand',
    e: '💁‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman tipping hand',
    e: '💁‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person raising hand',
    e: '🙋',
    c: 'People & Body',
  },
  {
    d: 'man raising hand',
    e: '🙋‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman raising hand',
    e: '🙋‍♀️',
    c: 'People & Body',
  },
  {
    d: 'deaf person',
    e: '🧏',
    c: 'People & Body',
  },
  {
    d: 'deaf man',
    e: '🧏‍♂️',
    c: 'People & Body',
  },
  {
    d: 'deaf woman',
    e: '🧏‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person bowing',
    e: '🙇',
    c: 'People & Body',
  },
  {
    d: 'man bowing',
    e: '🙇‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman bowing',
    e: '🙇‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person facepalming',
    e: '🤦',
    c: 'People & Body',
  },
  {
    d: 'man facepalming',
    e: '🤦‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman facepalming',
    e: '🤦‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person shrugging',
    e: '🤷',
    c: 'People & Body',
  },
  {
    d: 'man shrugging',
    e: '🤷‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman shrugging',
    e: '🤷‍♀️',
    c: 'People & Body',
  },
  {
    d: 'health worker',
    e: '🧑‍⚕️',
    c: 'People & Body',
  },
  {
    d: 'man health worker',
    e: '👨‍⚕️',
    c: 'People & Body',
  },
  {
    d: 'woman health worker',
    e: '👩‍⚕️',
    c: 'People & Body',
  },
  {
    d: 'student',
    e: '🧑‍🎓',
    c: 'People & Body',
  },
  {
    d: 'man student',
    e: '👨‍🎓',
    c: 'People & Body',
  },
  {
    d: 'woman student',
    e: '👩‍🎓',
    c: 'People & Body',
  },
  {
    d: 'teacher',
    e: '🧑‍🏫',
    c: 'People & Body',
  },
  {
    d: 'man teacher',
    e: '👨‍🏫',
    c: 'People & Body',
  },
  {
    d: 'woman teacher',
    e: '👩‍🏫',
    c: 'People & Body',
  },
  {
    d: 'judge',
    e: '🧑‍⚖️',
    c: 'People & Body',
  },
  {
    d: 'man judge',
    e: '👨‍⚖️',
    c: 'People & Body',
  },
  {
    d: 'woman judge',
    e: '👩‍⚖️',
    c: 'People & Body',
  },
  {
    d: 'farmer',
    e: '🧑‍🌾',
    c: 'People & Body',
  },
  {
    d: 'man farmer',
    e: '👨‍🌾',
    c: 'People & Body',
  },
  {
    d: 'woman farmer',
    e: '👩‍🌾',
    c: 'People & Body',
  },
  {
    d: 'cook',
    e: '🧑‍🍳',
    c: 'People & Body',
  },
  {
    d: 'man cook',
    e: '👨‍🍳',
    c: 'People & Body',
  },
  {
    d: 'woman cook',
    e: '👩‍🍳',
    c: 'People & Body',
  },
  {
    d: 'mechanic',
    e: '🧑‍🔧',
    c: 'People & Body',
  },
  {
    d: 'man mechanic',
    e: '👨‍🔧',
    c: 'People & Body',
  },
  {
    d: 'woman mechanic',
    e: '👩‍🔧',
    c: 'People & Body',
  },
  {
    d: 'factory worker',
    e: '🧑‍🏭',
    c: 'People & Body',
  },
  {
    d: 'man factory worker',
    e: '👨‍🏭',
    c: 'People & Body',
  },
  {
    d: 'woman factory worker',
    e: '👩‍🏭',
    c: 'People & Body',
  },
  {
    d: 'office worker',
    e: '🧑‍💼',
    c: 'People & Body',
  },
  {
    d: 'man office worker',
    e: '👨‍💼',
    c: 'People & Body',
  },
  {
    d: 'woman office worker',
    e: '👩‍💼',
    c: 'People & Body',
  },
  {
    d: 'scientist',
    e: '🧑‍🔬',
    c: 'People & Body',
  },
  {
    d: 'man scientist',
    e: '👨‍🔬',
    c: 'People & Body',
  },
  {
    d: 'woman scientist',
    e: '👩‍🔬',
    c: 'People & Body',
  },
  {
    d: 'technologist',
    e: '🧑‍💻',
    c: 'People & Body',
  },
  {
    d: 'man technologist',
    e: '👨‍💻',
    c: 'People & Body',
  },
  {
    d: 'woman technologist',
    e: '👩‍💻',
    c: 'People & Body',
  },
  {
    d: 'singer',
    e: '🧑‍🎤',
    c: 'People & Body',
  },
  {
    d: 'man singer',
    e: '👨‍🎤',
    c: 'People & Body',
  },
  {
    d: 'woman singer',
    e: '👩‍🎤',
    c: 'People & Body',
  },
  {
    d: 'artist',
    e: '🧑‍🎨',
    c: 'People & Body',
  },
  {
    d: 'man artist',
    e: '👨‍🎨',
    c: 'People & Body',
  },
  {
    d: 'woman artist',
    e: '👩‍🎨',
    c: 'People & Body',
  },
  {
    d: 'pilot',
    e: '🧑‍✈️',
    c: 'People & Body',
  },
  {
    d: 'man pilot',
    e: '👨‍✈️',
    c: 'People & Body',
  },
  {
    d: 'woman pilot',
    e: '👩‍✈️',
    c: 'People & Body',
  },
  {
    d: 'astronaut',
    e: '🧑‍🚀',
    c: 'People & Body',
  },
  {
    d: 'man astronaut',
    e: '👨‍🚀',
    c: 'People & Body',
  },
  {
    d: 'woman astronaut',
    e: '👩‍🚀',
    c: 'People & Body',
  },
  {
    d: 'firefighter',
    e: '🧑‍🚒',
    c: 'People & Body',
  },
  {
    d: 'man firefighter',
    e: '👨‍🚒',
    c: 'People & Body',
  },
  {
    d: 'woman firefighter',
    e: '👩‍🚒',
    c: 'People & Body',
  },
  {
    d: 'police officer',
    e: '👮',
    c: 'People & Body',
  },
  {
    d: 'man police officer',
    e: '👮‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman police officer',
    e: '👮‍♀️',
    c: 'People & Body',
  },
  {
    d: 'detective',
    e: '🕵️',
    c: 'People & Body',
  },
  {
    d: 'man detective',
    e: '🕵️‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman detective',
    e: '🕵️‍♀️',
    c: 'People & Body',
  },
  {
    d: 'guard',
    e: '💂',
    c: 'People & Body',
  },
  {
    d: 'man guard',
    e: '💂‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman guard',
    e: '💂‍♀️',
    c: 'People & Body',
  },
  {
    d: 'ninja',
    e: '🥷',
    c: 'People & Body',
  },
  {
    d: 'construction worker',
    e: '👷',
    c: 'People & Body',
  },
  {
    d: 'man construction worker',
    e: '👷‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman construction worker',
    e: '👷‍♀️',
    c: 'People & Body',
  },
  {
    d: 'prince',
    e: '🤴',
    c: 'People & Body',
  },
  {
    d: 'princess',
    e: '👸',
    c: 'People & Body',
  },
  {
    d: 'person wearing turban',
    e: '👳',
    c: 'People & Body',
  },
  {
    d: 'man wearing turban',
    e: '👳‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman wearing turban',
    e: '👳‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person with skullcap',
    e: '👲',
    c: 'People & Body',
  },
  {
    d: 'woman with headscarf',
    e: '🧕',
    c: 'People & Body',
  },
  {
    d: 'person in tuxedo',
    e: '🤵',
    c: 'People & Body',
  },
  {
    d: 'man in tuxedo',
    e: '🤵‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman in tuxedo',
    e: '🤵‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person with veil',
    e: '👰',
    c: 'People & Body',
  },
  {
    d: 'man with veil',
    e: '👰‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman with veil',
    e: '👰‍♀️',
    c: 'People & Body',
  },
  {
    d: 'pregnant woman',
    e: '🤰',
    c: 'People & Body',
  },
  {
    d: 'breast-feeding',
    e: '🤱',
    c: 'People & Body',
  },
  {
    d: 'woman feeding baby',
    e: '👩‍🍼',
    c: 'People & Body',
  },
  {
    d: 'man feeding baby',
    e: '👨‍🍼',
    c: 'People & Body',
  },
  {
    d: 'person feeding baby',
    e: '🧑‍🍼',
    c: 'People & Body',
  },
  {
    d: 'baby angel',
    e: '👼',
    c: 'People & Body',
  },
  {
    d: 'Santa Claus',
    e: '🎅',
    c: 'People & Body',
  },
  {
    d: 'Mrs. Claus',
    e: '🤶',
    c: 'People & Body',
  },
  {
    d: 'mx claus',
    e: '🧑‍🎄',
    c: 'People & Body',
  },
  {
    d: 'superhero',
    e: '🦸',
    c: 'People & Body',
  },
  {
    d: 'man superhero',
    e: '🦸‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman superhero',
    e: '🦸‍♀️',
    c: 'People & Body',
  },
  {
    d: 'supervillain',
    e: '🦹',
    c: 'People & Body',
  },
  {
    d: 'man supervillain',
    e: '🦹‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman supervillain',
    e: '🦹‍♀️',
    c: 'People & Body',
  },
  {
    d: 'mage',
    e: '🧙',
    c: 'People & Body',
  },
  {
    d: 'man mage',
    e: '🧙‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman mage',
    e: '🧙‍♀️',
    c: 'People & Body',
  },
  {
    d: 'fairy',
    e: '🧚',
    c: 'People & Body',
  },
  {
    d: 'man fairy',
    e: '🧚‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman fairy',
    e: '🧚‍♀️',
    c: 'People & Body',
  },
  {
    d: 'vampire',
    e: '🧛',
    c: 'People & Body',
  },
  {
    d: 'man vampire',
    e: '🧛‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman vampire',
    e: '🧛‍♀️',
    c: 'People & Body',
  },
  {
    d: 'merperson',
    e: '🧜',
    c: 'People & Body',
  },
  {
    d: 'merman',
    e: '🧜‍♂️',
    c: 'People & Body',
  },
  {
    d: 'mermaid',
    e: '🧜‍♀️',
    c: 'People & Body',
  },
  {
    d: 'elf',
    e: '🧝',
    c: 'People & Body',
  },
  {
    d: 'man elf',
    e: '🧝‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman elf',
    e: '🧝‍♀️',
    c: 'People & Body',
  },
  {
    d: 'genie',
    e: '🧞',
    c: 'People & Body',
  },
  {
    d: 'man genie',
    e: '🧞‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman genie',
    e: '🧞‍♀️',
    c: 'People & Body',
  },
  {
    d: 'zombie',
    e: '🧟',
    c: 'People & Body',
  },
  {
    d: 'man zombie',
    e: '🧟‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman zombie',
    e: '🧟‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person getting massage',
    e: '💆',
    c: 'People & Body',
  },
  {
    d: 'man getting massage',
    e: '💆‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman getting massage',
    e: '💆‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person getting haircut',
    e: '💇',
    c: 'People & Body',
  },
  {
    d: 'man getting haircut',
    e: '💇‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman getting haircut',
    e: '💇‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person walking',
    e: '🚶',
    c: 'People & Body',
  },
  {
    d: 'man walking',
    e: '🚶‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman walking',
    e: '🚶‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person standing',
    e: '🧍',
    c: 'People & Body',
  },
  {
    d: 'man standing',
    e: '🧍‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman standing',
    e: '🧍‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person kneeling',
    e: '🧎',
    c: 'People & Body',
  },
  {
    d: 'man kneeling',
    e: '🧎‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman kneeling',
    e: '🧎‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person with white cane',
    e: '🧑‍🦯',
    c: 'People & Body',
  },
  {
    d: 'man with white cane',
    e: '👨‍🦯',
    c: 'People & Body',
  },
  {
    d: 'woman with white cane',
    e: '👩‍🦯',
    c: 'People & Body',
  },
  {
    d: 'person in motorized wheelchair',
    e: '🧑‍🦼',
    c: 'People & Body',
  },
  {
    d: 'man in motorized wheelchair',
    e: '👨‍🦼',
    c: 'People & Body',
  },
  {
    d: 'woman in motorized wheelchair',
    e: '👩‍🦼',
    c: 'People & Body',
  },
  {
    d: 'person in manual wheelchair',
    e: '🧑‍🦽',
    c: 'People & Body',
  },
  {
    d: 'man in manual wheelchair',
    e: '👨‍🦽',
    c: 'People & Body',
  },
  {
    d: 'woman in manual wheelchair',
    e: '👩‍🦽',
    c: 'People & Body',
  },
  {
    d: 'person running',
    e: '🏃',
    c: 'People & Body',
  },
  {
    d: 'man running',
    e: '🏃‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman running',
    e: '🏃‍♀️',
    c: 'People & Body',
  },
  {
    d: 'woman dancing',
    e: '💃',
    c: 'People & Body',
  },
  {
    d: 'man dancing',
    e: '🕺',
    c: 'People & Body',
  },
  {
    d: 'person in suit levitating',
    e: '🕴️',
    c: 'People & Body',
  },
  {
    d: 'people with bunny ears',
    e: '👯',
    c: 'People & Body',
  },
  {
    d: 'men with bunny ears',
    e: '👯‍♂️',
    c: 'People & Body',
  },
  {
    d: 'women with bunny ears',
    e: '👯‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person in steamy room',
    e: '🧖',
    c: 'People & Body',
  },
  {
    d: 'man in steamy room',
    e: '🧖‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman in steamy room',
    e: '🧖‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person climbing',
    e: '🧗',
    c: 'People & Body',
  },
  {
    d: 'man climbing',
    e: '🧗‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman climbing',
    e: '🧗‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person fencing',
    e: '🤺',
    c: 'People & Body',
  },
  {
    d: 'horse racing',
    e: '🏇',
    c: 'People & Body',
  },
  {
    d: 'skier',
    e: '⛷️',
    c: 'People & Body',
  },
  {
    d: 'snowboarder',
    e: '🏂',
    c: 'People & Body',
  },
  {
    d: 'person golfing',
    e: '🏌️',
    c: 'People & Body',
  },
  {
    d: 'man golfing',
    e: '🏌️‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman golfing',
    e: '🏌️‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person surfing',
    e: '🏄',
    c: 'People & Body',
  },
  {
    d: 'man surfing',
    e: '🏄‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman surfing',
    e: '🏄‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person rowing boat',
    e: '🚣',
    c: 'People & Body',
  },
  {
    d: 'man rowing boat',
    e: '🚣‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman rowing boat',
    e: '🚣‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person swimming',
    e: '🏊',
    c: 'People & Body',
  },
  {
    d: 'man swimming',
    e: '🏊‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman swimming',
    e: '🏊‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person bouncing ball',
    e: '⛹️',
    c: 'People & Body',
  },
  {
    d: 'man bouncing ball',
    e: '⛹️‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman bouncing ball',
    e: '⛹️‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person lifting weights',
    e: '🏋️',
    c: 'People & Body',
  },
  {
    d: 'man lifting weights',
    e: '🏋️‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman lifting weights',
    e: '🏋️‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person biking',
    e: '🚴',
    c: 'People & Body',
  },
  {
    d: 'man biking',
    e: '🚴‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman biking',
    e: '🚴‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person mountain biking',
    e: '🚵',
    c: 'People & Body',
  },
  {
    d: 'man mountain biking',
    e: '🚵‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman mountain biking',
    e: '🚵‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person cartwheeling',
    e: '🤸',
    c: 'People & Body',
  },
  {
    d: 'man cartwheeling',
    e: '🤸‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman cartwheeling',
    e: '🤸‍♀️',
    c: 'People & Body',
  },
  {
    d: 'people wrestling',
    e: '🤼',
    c: 'People & Body',
  },
  {
    d: 'men wrestling',
    e: '🤼‍♂️',
    c: 'People & Body',
  },
  {
    d: 'women wrestling',
    e: '🤼‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person playing water polo',
    e: '🤽',
    c: 'People & Body',
  },
  {
    d: 'man playing water polo',
    e: '🤽‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman playing water polo',
    e: '🤽‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person playing handball',
    e: '🤾',
    c: 'People & Body',
  },
  {
    d: 'man playing handball',
    e: '🤾‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman playing handball',
    e: '🤾‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person juggling',
    e: '🤹',
    c: 'People & Body',
  },
  {
    d: 'man juggling',
    e: '🤹‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman juggling',
    e: '🤹‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person in lotus position',
    e: '🧘',
    c: 'People & Body',
  },
  {
    d: 'man in lotus position',
    e: '🧘‍♂️',
    c: 'People & Body',
  },
  {
    d: 'woman in lotus position',
    e: '🧘‍♀️',
    c: 'People & Body',
  },
  {
    d: 'person taking bath',
    e: '🛀',
    c: 'People & Body',
  },
  {
    d: 'person in bed',
    e: '🛌',
    c: 'People & Body',
  },
  {
    d: 'people holding hands',
    e: '🧑‍🤝‍🧑',
    c: 'People & Body',
  },
  {
    d: 'women holding hands',
    e: '👭',
    c: 'People & Body',
  },
  {
    d: 'woman and man holding hands',
    e: '👫',
    c: 'People & Body',
  },
  {
    d: 'men holding hands',
    e: '👬',
    c: 'People & Body',
  },
  {
    d: 'kiss',
    e: '💏',
    c: 'People & Body',
  },
  {
    d: 'kiss: woman, man',
    e: '👩‍❤️‍💋‍👨',
    c: 'People & Body',
  },
  {
    d: 'kiss: man, man',
    e: '👨‍❤️‍💋‍👨',
    c: 'People & Body',
  },
  {
    d: 'kiss: woman, woman',
    e: '👩‍❤️‍💋‍👩',
    c: 'People & Body',
  },
  {
    d: 'couple with heart',
    e: '💑',
    c: 'People & Body',
  },
  {
    d: 'couple with heart: woman, man',
    e: '👩‍❤️‍👨',
    c: 'People & Body',
  },
  {
    d: 'couple with heart: man, man',
    e: '👨‍❤️‍👨',
    c: 'People & Body',
  },
  {
    d: 'couple with heart: woman, woman',
    e: '👩‍❤️‍👩',
    c: 'People & Body',
  },
  {
    d: 'family',
    e: '👪',
    c: 'People & Body',
  },
  {
    d: 'family: man, woman, boy',
    e: '👨‍👩‍👦',
    c: 'People & Body',
  },
  {
    d: 'family: man, woman, girl',
    e: '👨‍👩‍👧',
    c: 'People & Body',
  },
  {
    d: 'family: man, woman, girl, boy',
    e: '👨‍👩‍👧‍👦',
    c: 'People & Body',
  },
  {
    d: 'family: man, woman, boy, boy',
    e: '👨‍👩‍👦‍👦',
    c: 'People & Body',
  },
  {
    d: 'family: man, woman, girl, girl',
    e: '👨‍👩‍👧‍👧',
    c: 'People & Body',
  },
  {
    d: 'family: man, man, boy',
    e: '👨‍👨‍👦',
    c: 'People & Body',
  },
  {
    d: 'family: man, man, girl',
    e: '👨‍👨‍👧',
    c: 'People & Body',
  },
  {
    d: 'family: man, man, girl, boy',
    e: '👨‍👨‍👧‍👦',
    c: 'People & Body',
  },
  {
    d: 'family: man, man, boy, boy',
    e: '👨‍👨‍👦‍👦',
    c: 'People & Body',
  },
  {
    d: 'family: man, man, girl, girl',
    e: '👨‍👨‍👧‍👧',
    c: 'People & Body',
  },
  {
    d: 'family: woman, woman, boy',
    e: '👩‍👩‍👦',
    c: 'People & Body',
  },
  {
    d: 'family: woman, woman, girl',
    e: '👩‍👩‍👧',
    c: 'People & Body',
  },
  {
    d: 'family: woman, woman, girl, boy',
    e: '👩‍👩‍👧‍👦',
    c: 'People & Body',
  },
  {
    d: 'family: woman, woman, boy, boy',
    e: '👩‍👩‍👦‍👦',
    c: 'People & Body',
  },
  {
    d: 'family: woman, woman, girl, girl',
    e: '👩‍👩‍👧‍👧',
    c: 'People & Body',
  },
  {
    d: 'family: man, boy',
    e: '👨‍👦',
    c: 'People & Body',
  },
  {
    d: 'family: man, boy, boy',
    e: '👨‍👦‍👦',
    c: 'People & Body',
  },
  {
    d: 'family: man, girl',
    e: '👨‍👧',
    c: 'People & Body',
  },
  {
    d: 'family: man, girl, boy',
    e: '👨‍👧‍👦',
    c: 'People & Body',
  },
  {
    d: 'family: man, girl, girl',
    e: '👨‍👧‍👧',
    c: 'People & Body',
  },
  {
    d: 'family: woman, boy',
    e: '👩‍👦',
    c: 'People & Body',
  },
  {
    d: 'family: woman, boy, boy',
    e: '👩‍👦‍👦',
    c: 'People & Body',
  },
  {
    d: 'family: woman, girl',
    e: '👩‍👧',
    c: 'People & Body',
  },
  {
    d: 'family: woman, girl, boy',
    e: '👩‍👧‍👦',
    c: 'People & Body',
  },
  {
    d: 'family: woman, girl, girl',
    e: '👩‍👧‍👧',
    c: 'People & Body',
  },
  {
    d: 'speaking head',
    e: '🗣️',
    c: 'People & Body',
  },
  {
    d: 'bust in silhouette',
    e: '👤',
    c: 'People & Body',
  },
  {
    d: 'busts in silhouette',
    e: '👥',
    c: 'People & Body',
  },
  {
    d: 'people hugging',
    e: '🫂',
    c: 'People & Body',
  },
  {
    d: 'footprints',
    e: '👣',
    c: 'People & Body',
  },
  {
    d: 'monkey face',
    e: '🐵',
    c: 'Animals & Nature',
  },
  {
    d: 'monkey',
    e: '🐒',
    c: 'Animals & Nature',
  },
  {
    d: 'gorilla',
    e: '🦍',
    c: 'Animals & Nature',
  },
  {
    d: 'orangutan',
    e: '🦧',
    c: 'Animals & Nature',
  },
  {
    d: 'dog face',
    e: '🐶',
    c: 'Animals & Nature',
  },
  {
    d: 'dog',
    e: '🐕',
    c: 'Animals & Nature',
  },
  {
    d: 'guide dog',
    e: '🦮',
    c: 'Animals & Nature',
  },
  {
    d: 'service dog',
    e: '🐕‍🦺',
    c: 'Animals & Nature',
  },
  {
    d: 'poodle',
    e: '🐩',
    c: 'Animals & Nature',
  },
  {
    d: 'wolf',
    e: '🐺',
    c: 'Animals & Nature',
  },
  {
    d: 'fox',
    e: '🦊',
    c: 'Animals & Nature',
  },
  {
    d: 'raccoon',
    e: '🦝',
    c: 'Animals & Nature',
  },
  {
    d: 'cat face',
    e: '🐱',
    c: 'Animals & Nature',
  },
  {
    d: 'cat',
    e: '🐈',
    c: 'Animals & Nature',
  },
  {
    d: 'black cat',
    e: '🐈‍⬛',
    c: 'Animals & Nature',
  },
  {
    d: 'lion',
    e: '🦁',
    c: 'Animals & Nature',
  },
  {
    d: 'tiger face',
    e: '🐯',
    c: 'Animals & Nature',
  },
  {
    d: 'tiger',
    e: '🐅',
    c: 'Animals & Nature',
  },
  {
    d: 'leopard',
    e: '🐆',
    c: 'Animals & Nature',
  },
  {
    d: 'horse face',
    e: '🐴',
    c: 'Animals & Nature',
  },
  {
    d: 'horse',
    e: '🐎',
    c: 'Animals & Nature',
  },
  {
    d: 'unicorn',
    e: '🦄',
    c: 'Animals & Nature',
  },
  {
    d: 'zebra',
    e: '🦓',
    c: 'Animals & Nature',
  },
  {
    d: 'deer',
    e: '🦌',
    c: 'Animals & Nature',
  },
  {
    d: 'bison',
    e: '🦬',
    c: 'Animals & Nature',
  },
  {
    d: 'cow face',
    e: '🐮',
    c: 'Animals & Nature',
  },
  {
    d: 'ox',
    e: '🐂',
    c: 'Animals & Nature',
  },
  {
    d: 'water buffalo',
    e: '🐃',
    c: 'Animals & Nature',
  },
  {
    d: 'cow',
    e: '🐄',
    c: 'Animals & Nature',
  },
  {
    d: 'pig face',
    e: '🐷',
    c: 'Animals & Nature',
  },
  {
    d: 'pig',
    e: '🐖',
    c: 'Animals & Nature',
  },
  {
    d: 'boar',
    e: '🐗',
    c: 'Animals & Nature',
  },
  {
    d: 'pig nose',
    e: '🐽',
    c: 'Animals & Nature',
  },
  {
    d: 'ram',
    e: '🐏',
    c: 'Animals & Nature',
  },
  {
    d: 'ewe',
    e: '🐑',
    c: 'Animals & Nature',
  },
  {
    d: 'goat',
    e: '🐐',
    c: 'Animals & Nature',
  },
  {
    d: 'camel',
    e: '🐪',
    c: 'Animals & Nature',
  },
  {
    d: 'two-hump camel',
    e: '🐫',
    c: 'Animals & Nature',
  },
  {
    d: 'llama',
    e: '🦙',
    c: 'Animals & Nature',
  },
  {
    d: 'giraffe',
    e: '🦒',
    c: 'Animals & Nature',
  },
  {
    d: 'elephant',
    e: '🐘',
    c: 'Animals & Nature',
  },
  {
    d: 'mammoth',
    e: '🦣',
    c: 'Animals & Nature',
  },
  {
    d: 'rhinoceros',
    e: '🦏',
    c: 'Animals & Nature',
  },
  {
    d: 'hippopotamus',
    e: '🦛',
    c: 'Animals & Nature',
  },
  {
    d: 'mouse face',
    e: '🐭',
    c: 'Animals & Nature',
  },
  {
    d: 'mouse',
    e: '🐁',
    c: 'Animals & Nature',
  },
  {
    d: 'rat',
    e: '🐀',
    c: 'Animals & Nature',
  },
  {
    d: 'hamster',
    e: '🐹',
    c: 'Animals & Nature',
  },
  {
    d: 'rabbit face',
    e: '🐰',
    c: 'Animals & Nature',
  },
  {
    d: 'rabbit',
    e: '🐇',
    c: 'Animals & Nature',
  },
  {
    d: 'chipmunk',
    e: '🐿️',
    c: 'Animals & Nature',
  },
  {
    d: 'beaver',
    e: '🦫',
    c: 'Animals & Nature',
  },
  {
    d: 'hedgehog',
    e: '🦔',
    c: 'Animals & Nature',
  },
  {
    d: 'bat',
    e: '🦇',
    c: 'Animals & Nature',
  },
  {
    d: 'bear',
    e: '🐻',
    c: 'Animals & Nature',
  },
  {
    d: 'polar bear',
    e: '🐻‍❄️',
    c: 'Animals & Nature',
  },
  {
    d: 'koala',
    e: '🐨',
    c: 'Animals & Nature',
  },
  {
    d: 'panda',
    e: '🐼',
    c: 'Animals & Nature',
  },
  {
    d: 'sloth',
    e: '🦥',
    c: 'Animals & Nature',
  },
  {
    d: 'otter',
    e: '🦦',
    c: 'Animals & Nature',
  },
  {
    d: 'skunk',
    e: '🦨',
    c: 'Animals & Nature',
  },
  {
    d: 'kangaroo',
    e: '🦘',
    c: 'Animals & Nature',
  },
  {
    d: 'badger',
    e: '🦡',
    c: 'Animals & Nature',
  },
  {
    d: 'paw prints',
    e: '🐾',
    c: 'Animals & Nature',
  },
  {
    d: 'turkey',
    e: '🦃',
    c: 'Animals & Nature',
  },
  {
    d: 'chicken',
    e: '🐔',
    c: 'Animals & Nature',
  },
  {
    d: 'rooster',
    e: '🐓',
    c: 'Animals & Nature',
  },
  {
    d: 'hatching chick',
    e: '🐣',
    c: 'Animals & Nature',
  },
  {
    d: 'baby chick',
    e: '🐤',
    c: 'Animals & Nature',
  },
  {
    d: 'front-facing baby chick',
    e: '🐥',
    c: 'Animals & Nature',
  },
  {
    d: 'bird',
    e: '🐦',
    c: 'Animals & Nature',
  },
  {
    d: 'penguin',
    e: '🐧',
    c: 'Animals & Nature',
  },
  {
    d: 'dove',
    e: '🕊️',
    c: 'Animals & Nature',
  },
  {
    d: 'eagle',
    e: '🦅',
    c: 'Animals & Nature',
  },
  {
    d: 'duck',
    e: '🦆',
    c: 'Animals & Nature',
  },
  {
    d: 'swan',
    e: '🦢',
    c: 'Animals & Nature',
  },
  {
    d: 'owl',
    e: '🦉',
    c: 'Animals & Nature',
  },
  {
    d: 'dodo',
    e: '🦤',
    c: 'Animals & Nature',
  },
  {
    d: 'feather',
    e: '🪶',
    c: 'Animals & Nature',
  },
  {
    d: 'flamingo',
    e: '🦩',
    c: 'Animals & Nature',
  },
  {
    d: 'peacock',
    e: '🦚',
    c: 'Animals & Nature',
  },
  {
    d: 'parrot',
    e: '🦜',
    c: 'Animals & Nature',
  },
  {
    d: 'frog',
    e: '🐸',
    c: 'Animals & Nature',
  },
  {
    d: 'crocodile',
    e: '🐊',
    c: 'Animals & Nature',
  },
  {
    d: 'turtle',
    e: '🐢',
    c: 'Animals & Nature',
  },
  {
    d: 'lizard',
    e: '🦎',
    c: 'Animals & Nature',
  },
  {
    d: 'snake',
    e: '🐍',
    c: 'Animals & Nature',
  },
  {
    d: 'dragon face',
    e: '🐲',
    c: 'Animals & Nature',
  },
  {
    d: 'dragon',
    e: '🐉',
    c: 'Animals & Nature',
  },
  {
    d: 'sauropod',
    e: '🦕',
    c: 'Animals & Nature',
  },
  {
    d: 'T-Rex',
    e: '🦖',
    c: 'Animals & Nature',
  },
  {
    d: 'spouting whale',
    e: '🐳',
    c: 'Animals & Nature',
  },
  {
    d: 'whale',
    e: '🐋',
    c: 'Animals & Nature',
  },
  {
    d: 'dolphin',
    e: '🐬',
    c: 'Animals & Nature',
  },
  {
    d: 'seal',
    e: '🦭',
    c: 'Animals & Nature',
  },
  {
    d: 'fish',
    e: '🐟',
    c: 'Animals & Nature',
  },
  {
    d: 'tropical fish',
    e: '🐠',
    c: 'Animals & Nature',
  },
  {
    d: 'blowfish',
    e: '🐡',
    c: 'Animals & Nature',
  },
  {
    d: 'shark',
    e: '🦈',
    c: 'Animals & Nature',
  },
  {
    d: 'octopus',
    e: '🐙',
    c: 'Animals & Nature',
  },
  {
    d: 'spiral shell',
    e: '🐚',
    c: 'Animals & Nature',
  },
  {
    d: 'snail',
    e: '🐌',
    c: 'Animals & Nature',
  },
  {
    d: 'butterfly',
    e: '🦋',
    c: 'Animals & Nature',
  },
  {
    d: 'bug',
    e: '🐛',
    c: 'Animals & Nature',
  },
  {
    d: 'ant',
    e: '🐜',
    c: 'Animals & Nature',
  },
  {
    d: 'honeybee',
    e: '🐝',
    c: 'Animals & Nature',
  },
  {
    d: 'beetle',
    e: '🪲',
    c: 'Animals & Nature',
  },
  {
    d: 'lady beetle',
    e: '🐞',
    c: 'Animals & Nature',
  },
  {
    d: 'cricket',
    e: '🦗',
    c: 'Animals & Nature',
  },
  {
    d: 'cockroach',
    e: '🪳',
    c: 'Animals & Nature',
  },
  {
    d: 'spider',
    e: '🕷️',
    c: 'Animals & Nature',
  },
  {
    d: 'spider web',
    e: '🕸️',
    c: 'Animals & Nature',
  },
  {
    d: 'scorpion',
    e: '🦂',
    c: 'Animals & Nature',
  },
  {
    d: 'mosquito',
    e: '🦟',
    c: 'Animals & Nature',
  },
  {
    d: 'fly',
    e: '🪰',
    c: 'Animals & Nature',
  },
  {
    d: 'worm',
    e: '🪱',
    c: 'Animals & Nature',
  },
  {
    d: 'microbe',
    e: '🦠',
    c: 'Animals & Nature',
  },
  {
    d: 'bouquet',
    e: '💐',
    c: 'Animals & Nature',
  },
  {
    d: 'cherry blossom',
    e: '🌸',
    c: 'Animals & Nature',
  },
  {
    d: 'white flower',
    e: '💮',
    c: 'Animals & Nature',
  },
  {
    d: 'rosette',
    e: '🏵️',
    c: 'Animals & Nature',
  },
  {
    d: 'rose',
    e: '🌹',
    c: 'Animals & Nature',
  },
  {
    d: 'wilted flower',
    e: '🥀',
    c: 'Animals & Nature',
  },
  {
    d: 'hibiscus',
    e: '🌺',
    c: 'Animals & Nature',
  },
  {
    d: 'sunflower',
    e: '🌻',
    c: 'Animals & Nature',
  },
  {
    d: 'blossom',
    e: '🌼',
    c: 'Animals & Nature',
  },
  {
    d: 'tulip',
    e: '🌷',
    c: 'Animals & Nature',
  },
  {
    d: 'seedling',
    e: '🌱',
    c: 'Animals & Nature',
  },
  {
    d: 'potted plant',
    e: '🪴',
    c: 'Animals & Nature',
  },
  {
    d: 'evergreen tree',
    e: '🌲',
    c: 'Animals & Nature',
  },
  {
    d: 'deciduous tree',
    e: '🌳',
    c: 'Animals & Nature',
  },
  {
    d: 'palm tree',
    e: '🌴',
    c: 'Animals & Nature',
  },
  {
    d: 'cactus',
    e: '🌵',
    c: 'Animals & Nature',
  },
  {
    d: 'sheaf of rice',
    e: '🌾',
    c: 'Animals & Nature',
  },
  {
    d: 'herb',
    e: '🌿',
    c: 'Animals & Nature',
  },
  {
    d: 'shamrock',
    e: '☘️',
    c: 'Animals & Nature',
  },
  {
    d: 'four leaf clover',
    e: '🍀',
    c: 'Animals & Nature',
  },
  {
    d: 'maple leaf',
    e: '🍁',
    c: 'Animals & Nature',
  },
  {
    d: 'fallen leaf',
    e: '🍂',
    c: 'Animals & Nature',
  },
  {
    d: 'leaf fluttering in wind',
    e: '🍃',
    c: 'Animals & Nature',
  },
  {
    d: 'grapes',
    e: '🍇',
    c: 'Food & Drink',
  },
  {
    d: 'melon',
    e: '🍈',
    c: 'Food & Drink',
  },
  {
    d: 'watermelon',
    e: '🍉',
    c: 'Food & Drink',
  },
  {
    d: 'tangerine',
    e: '🍊',
    c: 'Food & Drink',
  },
  {
    d: 'lemon',
    e: '🍋',
    c: 'Food & Drink',
  },
  {
    d: 'banana',
    e: '🍌',
    c: 'Food & Drink',
  },
  {
    d: 'pineapple',
    e: '🍍',
    c: 'Food & Drink',
  },
  {
    d: 'mango',
    e: '🥭',
    c: 'Food & Drink',
  },
  {
    d: 'red apple',
    e: '🍎',
    c: 'Food & Drink',
  },
  {
    d: 'green apple',
    e: '🍏',
    c: 'Food & Drink',
  },
  {
    d: 'pear',
    e: '🍐',
    c: 'Food & Drink',
  },
  {
    d: 'peach',
    e: '🍑',
    c: 'Food & Drink',
  },
  {
    d: 'cherries',
    e: '🍒',
    c: 'Food & Drink',
  },
  {
    d: 'strawberry',
    e: '🍓',
    c: 'Food & Drink',
  },
  {
    d: 'blueberries',
    e: '🫐',
    c: 'Food & Drink',
  },
  {
    d: 'kiwi fruit',
    e: '🥝',
    c: 'Food & Drink',
  },
  {
    d: 'tomato',
    e: '🍅',
    c: 'Food & Drink',
  },
  {
    d: 'olive',
    e: '🫒',
    c: 'Food & Drink',
  },
  {
    d: 'coconut',
    e: '🥥',
    c: 'Food & Drink',
  },
  {
    d: 'avocado',
    e: '🥑',
    c: 'Food & Drink',
  },
  {
    d: 'eggplant',
    e: '🍆',
    c: 'Food & Drink',
  },
  {
    d: 'potato',
    e: '🥔',
    c: 'Food & Drink',
  },
  {
    d: 'carrot',
    e: '🥕',
    c: 'Food & Drink',
  },
  {
    d: 'ear of corn',
    e: '🌽',
    c: 'Food & Drink',
  },
  {
    d: 'hot pepper',
    e: '🌶️',
    c: 'Food & Drink',
  },
  {
    d: 'bell pepper',
    e: '🫑',
    c: 'Food & Drink',
  },
  {
    d: 'cucumber',
    e: '🥒',
    c: 'Food & Drink',
  },
  {
    d: 'leafy green',
    e: '🥬',
    c: 'Food & Drink',
  },
  {
    d: 'broccoli',
    e: '🥦',
    c: 'Food & Drink',
  },
  {
    d: 'garlic',
    e: '🧄',
    c: 'Food & Drink',
  },
  {
    d: 'onion',
    e: '🧅',
    c: 'Food & Drink',
  },
  {
    d: 'mushroom',
    e: '🍄',
    c: 'Food & Drink',
  },
  {
    d: 'peanuts',
    e: '🥜',
    c: 'Food & Drink',
  },
  {
    d: 'chestnut',
    e: '🌰',
    c: 'Food & Drink',
  },
  {
    d: 'bread',
    e: '🍞',
    c: 'Food & Drink',
  },
  {
    d: 'croissant',
    e: '🥐',
    c: 'Food & Drink',
  },
  {
    d: 'baguette bread',
    e: '🥖',
    c: 'Food & Drink',
  },
  {
    d: 'flatbread',
    e: '🫓',
    c: 'Food & Drink',
  },
  {
    d: 'pretzel',
    e: '🥨',
    c: 'Food & Drink',
  },
  {
    d: 'bagel',
    e: '🥯',
    c: 'Food & Drink',
  },
  {
    d: 'pancakes',
    e: '🥞',
    c: 'Food & Drink',
  },
  {
    d: 'waffle',
    e: '🧇',
    c: 'Food & Drink',
  },
  {
    d: 'cheese wedge',
    e: '🧀',
    c: 'Food & Drink',
  },
  {
    d: 'meat on bone',
    e: '🍖',
    c: 'Food & Drink',
  },
  {
    d: 'poultry leg',
    e: '🍗',
    c: 'Food & Drink',
  },
  {
    d: 'cut of meat',
    e: '🥩',
    c: 'Food & Drink',
  },
  {
    d: 'bacon',
    e: '🥓',
    c: 'Food & Drink',
  },
  {
    d: 'hamburger',
    e: '🍔',
    c: 'Food & Drink',
  },
  {
    d: 'french fries',
    e: '🍟',
    c: 'Food & Drink',
  },
  {
    d: 'pizza',
    e: '🍕',
    c: 'Food & Drink',
  },
  {
    d: 'hot dog',
    e: '🌭',
    c: 'Food & Drink',
  },
  {
    d: 'sandwich',
    e: '🥪',
    c: 'Food & Drink',
  },
  {
    d: 'taco',
    e: '🌮',
    c: 'Food & Drink',
  },
  {
    d: 'burrito',
    e: '🌯',
    c: 'Food & Drink',
  },
  {
    d: 'tamale',
    e: '🫔',
    c: 'Food & Drink',
  },
  {
    d: 'stuffed flatbread',
    e: '🥙',
    c: 'Food & Drink',
  },
  {
    d: 'falafel',
    e: '🧆',
    c: 'Food & Drink',
  },
  {
    d: 'egg',
    e: '🥚',
    c: 'Food & Drink',
  },
  {
    d: 'cooking',
    e: '🍳',
    c: 'Food & Drink',
  },
  {
    d: 'shallow pan of food',
    e: '🥘',
    c: 'Food & Drink',
  },
  {
    d: 'pot of food',
    e: '🍲',
    c: 'Food & Drink',
  },
  {
    d: 'fondue',
    e: '🫕',
    c: 'Food & Drink',
  },
  {
    d: 'bowl with spoon',
    e: '🥣',
    c: 'Food & Drink',
  },
  {
    d: 'green salad',
    e: '🥗',
    c: 'Food & Drink',
  },
  {
    d: 'popcorn',
    e: '🍿',
    c: 'Food & Drink',
  },
  {
    d: 'butter',
    e: '🧈',
    c: 'Food & Drink',
  },
  {
    d: 'salt',
    e: '🧂',
    c: 'Food & Drink',
  },
  {
    d: 'canned food',
    e: '🥫',
    c: 'Food & Drink',
  },
  {
    d: 'bento box',
    e: '🍱',
    c: 'Food & Drink',
  },
  {
    d: 'rice cracker',
    e: '🍘',
    c: 'Food & Drink',
  },
  {
    d: 'rice ball',
    e: '🍙',
    c: 'Food & Drink',
  },
  {
    d: 'cooked rice',
    e: '🍚',
    c: 'Food & Drink',
  },
  {
    d: 'curry rice',
    e: '🍛',
    c: 'Food & Drink',
  },
  {
    d: 'steaming bowl',
    e: '🍜',
    c: 'Food & Drink',
  },
  {
    d: 'spaghetti',
    e: '🍝',
    c: 'Food & Drink',
  },
  {
    d: 'roasted sweet potato',
    e: '🍠',
    c: 'Food & Drink',
  },
  {
    d: 'oden',
    e: '🍢',
    c: 'Food & Drink',
  },
  {
    d: 'sushi',
    e: '🍣',
    c: 'Food & Drink',
  },
  {
    d: 'fried shrimp',
    e: '🍤',
    c: 'Food & Drink',
  },
  {
    d: 'fish cake with swirl',
    e: '🍥',
    c: 'Food & Drink',
  },
  {
    d: 'moon cake',
    e: '🥮',
    c: 'Food & Drink',
  },
  {
    d: 'dango',
    e: '🍡',
    c: 'Food & Drink',
  },
  {
    d: 'dumpling',
    e: '🥟',
    c: 'Food & Drink',
  },
  {
    d: 'fortune cookie',
    e: '🥠',
    c: 'Food & Drink',
  },
  {
    d: 'takeout box',
    e: '🥡',
    c: 'Food & Drink',
  },
  {
    d: 'crab',
    e: '🦀',
    c: 'Food & Drink',
  },
  {
    d: 'lobster',
    e: '🦞',
    c: 'Food & Drink',
  },
  {
    d: 'shrimp',
    e: '🦐',
    c: 'Food & Drink',
  },
  {
    d: 'squid',
    e: '🦑',
    c: 'Food & Drink',
  },
  {
    d: 'oyster',
    e: '🦪',
    c: 'Food & Drink',
  },
  {
    d: 'soft ice cream',
    e: '🍦',
    c: 'Food & Drink',
  },
  {
    d: 'shaved ice',
    e: '🍧',
    c: 'Food & Drink',
  },
  {
    d: 'ice cream',
    e: '🍨',
    c: 'Food & Drink',
  },
  {
    d: 'doughnut',
    e: '🍩',
    c: 'Food & Drink',
  },
  {
    d: 'cookie',
    e: '🍪',
    c: 'Food & Drink',
  },
  {
    d: 'birthday cake',
    e: '🎂',
    c: 'Food & Drink',
  },
  {
    d: 'shortcake',
    e: '🍰',
    c: 'Food & Drink',
  },
  {
    d: 'cupcake',
    e: '🧁',
    c: 'Food & Drink',
  },
  {
    d: 'pie',
    e: '🥧',
    c: 'Food & Drink',
  },
  {
    d: 'chocolate bar',
    e: '🍫',
    c: 'Food & Drink',
  },
  {
    d: 'candy',
    e: '🍬',
    c: 'Food & Drink',
  },
  {
    d: 'lollipop',
    e: '🍭',
    c: 'Food & Drink',
  },
  {
    d: 'custard',
    e: '🍮',
    c: 'Food & Drink',
  },
  {
    d: 'honey pot',
    e: '🍯',
    c: 'Food & Drink',
  },
  {
    d: 'baby bottle',
    e: '🍼',
    c: 'Food & Drink',
  },
  {
    d: 'glass of milk',
    e: '🥛',
    c: 'Food & Drink',
  },
  {
    d: 'hot beverage',
    e: '☕',
    c: 'Food & Drink',
  },
  {
    d: 'teapot',
    e: '🫖',
    c: 'Food & Drink',
  },
  {
    d: 'teacup without handle',
    e: '🍵',
    c: 'Food & Drink',
  },
  {
    d: 'sake',
    e: '🍶',
    c: 'Food & Drink',
  },
  {
    d: 'bottle with popping cork',
    e: '🍾',
    c: 'Food & Drink',
  },
  {
    d: 'wine glass',
    e: '🍷',
    c: 'Food & Drink',
  },
  {
    d: 'cocktail glass',
    e: '🍸',
    c: 'Food & Drink',
  },
  {
    d: 'tropical drink',
    e: '🍹',
    c: 'Food & Drink',
  },
  {
    d: 'beer mug',
    e: '🍺',
    c: 'Food & Drink',
  },
  {
    d: 'clinking beer mugs',
    e: '🍻',
    c: 'Food & Drink',
  },
  {
    d: 'clinking glasses',
    e: '🥂',
    c: 'Food & Drink',
  },
  {
    d: 'tumbler glass',
    e: '🥃',
    c: 'Food & Drink',
  },
  {
    d: 'cup with straw',
    e: '🥤',
    c: 'Food & Drink',
  },
  {
    d: 'bubble tea',
    e: '🧋',
    c: 'Food & Drink',
  },
  {
    d: 'beverage box',
    e: '🧃',
    c: 'Food & Drink',
  },
  {
    d: 'mate',
    e: '🧉',
    c: 'Food & Drink',
  },
  {
    d: 'ice',
    e: '🧊',
    c: 'Food & Drink',
  },
  {
    d: 'chopsticks',
    e: '🥢',
    c: 'Food & Drink',
  },
  {
    d: 'fork and knife with plate',
    e: '🍽️',
    c: 'Food & Drink',
  },
  {
    d: 'fork and knife',
    e: '🍴',
    c: 'Food & Drink',
  },
  {
    d: 'spoon',
    e: '🥄',
    c: 'Food & Drink',
  },
  {
    d: 'kitchen knife',
    e: '🔪',
    c: 'Food & Drink',
  },
  {
    d: 'amphora',
    e: '🏺',
    c: 'Food & Drink',
  },
  {
    d: 'globe showing Europe-Africa',
    e: '🌍',
    c: 'Travel & Places',
  },
  {
    d: 'globe showing Americas',
    e: '🌎',
    c: 'Travel & Places',
  },
  {
    d: 'globe showing Asia-Australia',
    e: '🌏',
    c: 'Travel & Places',
  },
  {
    d: 'globe with meridians',
    e: '🌐',
    c: 'Travel & Places',
  },
  {
    d: 'world map',
    e: '🗺️',
    c: 'Travel & Places',
  },
  {
    d: 'map of Japan',
    e: '🗾',
    c: 'Travel & Places',
  },
  {
    d: 'compass',
    e: '🧭',
    c: 'Travel & Places',
  },
  {
    d: 'snow-capped mountain',
    e: '🏔️',
    c: 'Travel & Places',
  },
  {
    d: 'mountain',
    e: '⛰️',
    c: 'Travel & Places',
  },
  {
    d: 'volcano',
    e: '🌋',
    c: 'Travel & Places',
  },
  {
    d: 'mount fuji',
    e: '🗻',
    c: 'Travel & Places',
  },
  {
    d: 'camping',
    e: '🏕️',
    c: 'Travel & Places',
  },
  {
    d: 'beach with umbrella',
    e: '🏖️',
    c: 'Travel & Places',
  },
  {
    d: 'desert',
    e: '🏜️',
    c: 'Travel & Places',
  },
  {
    d: 'desert island',
    e: '🏝️',
    c: 'Travel & Places',
  },
  {
    d: 'national park',
    e: '🏞️',
    c: 'Travel & Places',
  },
  {
    d: 'stadium',
    e: '🏟️',
    c: 'Travel & Places',
  },
  {
    d: 'classical building',
    e: '🏛️',
    c: 'Travel & Places',
  },
  {
    d: 'building construction',
    e: '🏗️',
    c: 'Travel & Places',
  },
  {
    d: 'brick',
    e: '🧱',
    c: 'Travel & Places',
  },
  {
    d: 'rock',
    e: '🪨',
    c: 'Travel & Places',
  },
  {
    d: 'wood',
    e: '🪵',
    c: 'Travel & Places',
  },
  {
    d: 'hut',
    e: '🛖',
    c: 'Travel & Places',
  },
  {
    d: 'houses',
    e: '🏘️',
    c: 'Travel & Places',
  },
  {
    d: 'derelict house',
    e: '🏚️',
    c: 'Travel & Places',
  },
  {
    d: 'house',
    e: '🏠',
    c: 'Travel & Places',
  },
  {
    d: 'house with garden',
    e: '🏡',
    c: 'Travel & Places',
  },
  {
    d: 'office building',
    e: '🏢',
    c: 'Travel & Places',
  },
  {
    d: 'Japanese post office',
    e: '🏣',
    c: 'Travel & Places',
  },
  {
    d: 'post office',
    e: '🏤',
    c: 'Travel & Places',
  },
  {
    d: 'hospital',
    e: '🏥',
    c: 'Travel & Places',
  },
  {
    d: 'bank',
    e: '🏦',
    c: 'Travel & Places',
  },
  {
    d: 'hotel',
    e: '🏨',
    c: 'Travel & Places',
  },
  {
    d: 'love hotel',
    e: '🏩',
    c: 'Travel & Places',
  },
  {
    d: 'convenience store',
    e: '🏪',
    c: 'Travel & Places',
  },
  {
    d: 'school',
    e: '🏫',
    c: 'Travel & Places',
  },
  {
    d: 'department store',
    e: '🏬',
    c: 'Travel & Places',
  },
  {
    d: 'factory',
    e: '🏭',
    c: 'Travel & Places',
  },
  {
    d: 'Japanese castle',
    e: '🏯',
    c: 'Travel & Places',
  },
  {
    d: 'castle',
    e: '🏰',
    c: 'Travel & Places',
  },
  {
    d: 'wedding',
    e: '💒',
    c: 'Travel & Places',
  },
  {
    d: 'Tokyo tower',
    e: '🗼',
    c: 'Travel & Places',
  },
  {
    d: 'Statue of Liberty',
    e: '🗽',
    c: 'Travel & Places',
  },
  {
    d: 'church',
    e: '⛪',
    c: 'Travel & Places',
  },
  {
    d: 'mosque',
    e: '🕌',
    c: 'Travel & Places',
  },
  {
    d: 'hindu temple',
    e: '🛕',
    c: 'Travel & Places',
  },
  {
    d: 'synagogue',
    e: '🕍',
    c: 'Travel & Places',
  },
  {
    d: 'shinto shrine',
    e: '⛩️',
    c: 'Travel & Places',
  },
  {
    d: 'kaaba',
    e: '🕋',
    c: 'Travel & Places',
  },
  {
    d: 'fountain',
    e: '⛲',
    c: 'Travel & Places',
  },
  {
    d: 'tent',
    e: '⛺',
    c: 'Travel & Places',
  },
  {
    d: 'foggy',
    e: '🌁',
    c: 'Travel & Places',
  },
  {
    d: 'night with stars',
    e: '🌃',
    c: 'Travel & Places',
  },
  {
    d: 'cityscape',
    e: '🏙️',
    c: 'Travel & Places',
  },
  {
    d: 'sunrise over mountains',
    e: '🌄',
    c: 'Travel & Places',
  },
  {
    d: 'sunrise',
    e: '🌅',
    c: 'Travel & Places',
  },
  {
    d: 'cityscape at dusk',
    e: '🌆',
    c: 'Travel & Places',
  },
  {
    d: 'sunset',
    e: '🌇',
    c: 'Travel & Places',
  },
  {
    d: 'bridge at night',
    e: '🌉',
    c: 'Travel & Places',
  },
  {
    d: 'hot springs',
    e: '♨️',
    c: 'Travel & Places',
  },
  {
    d: 'carousel horse',
    e: '🎠',
    c: 'Travel & Places',
  },
  {
    d: 'ferris wheel',
    e: '🎡',
    c: 'Travel & Places',
  },
  {
    d: 'roller coaster',
    e: '🎢',
    c: 'Travel & Places',
  },
  {
    d: 'barber pole',
    e: '💈',
    c: 'Travel & Places',
  },
  {
    d: 'circus tent',
    e: '🎪',
    c: 'Travel & Places',
  },
  {
    d: 'locomotive',
    e: '🚂',
    c: 'Travel & Places',
  },
  {
    d: 'railway car',
    e: '🚃',
    c: 'Travel & Places',
  },
  {
    d: 'high-speed train',
    e: '🚄',
    c: 'Travel & Places',
  },
  {
    d: 'bullet train',
    e: '🚅',
    c: 'Travel & Places',
  },
  {
    d: 'train',
    e: '🚆',
    c: 'Travel & Places',
  },
  {
    d: 'metro',
    e: '🚇',
    c: 'Travel & Places',
  },
  {
    d: 'light rail',
    e: '🚈',
    c: 'Travel & Places',
  },
  {
    d: 'station',
    e: '🚉',
    c: 'Travel & Places',
  },
  {
    d: 'tram',
    e: '🚊',
    c: 'Travel & Places',
  },
  {
    d: 'monorail',
    e: '🚝',
    c: 'Travel & Places',
  },
  {
    d: 'mountain railway',
    e: '🚞',
    c: 'Travel & Places',
  },
  {
    d: 'tram car',
    e: '🚋',
    c: 'Travel & Places',
  },
  {
    d: 'bus',
    e: '🚌',
    c: 'Travel & Places',
  },
  {
    d: 'oncoming bus',
    e: '🚍',
    c: 'Travel & Places',
  },
  {
    d: 'trolleybus',
    e: '🚎',
    c: 'Travel & Places',
  },
  {
    d: 'minibus',
    e: '🚐',
    c: 'Travel & Places',
  },
  {
    d: 'ambulance',
    e: '🚑',
    c: 'Travel & Places',
  },
  {
    d: 'fire engine',
    e: '🚒',
    c: 'Travel & Places',
  },
  {
    d: 'police car',
    e: '🚓',
    c: 'Travel & Places',
  },
  {
    d: 'oncoming police car',
    e: '🚔',
    c: 'Travel & Places',
  },
  {
    d: 'taxi',
    e: '🚕',
    c: 'Travel & Places',
  },
  {
    d: 'oncoming taxi',
    e: '🚖',
    c: 'Travel & Places',
  },
  {
    d: 'automobile',
    e: '🚗',
    c: 'Travel & Places',
  },
  {
    d: 'oncoming automobile',
    e: '🚘',
    c: 'Travel & Places',
  },
  {
    d: 'sport utility vehicle',
    e: '🚙',
    c: 'Travel & Places',
  },
  {
    d: 'pickup truck',
    e: '🛻',
    c: 'Travel & Places',
  },
  {
    d: 'delivery truck',
    e: '🚚',
    c: 'Travel & Places',
  },
  {
    d: 'articulated lorry',
    e: '🚛',
    c: 'Travel & Places',
  },
  {
    d: 'tractor',
    e: '🚜',
    c: 'Travel & Places',
  },
  {
    d: 'racing car',
    e: '🏎️',
    c: 'Travel & Places',
  },
  {
    d: 'motorcycle',
    e: '🏍️',
    c: 'Travel & Places',
  },
  {
    d: 'motor scooter',
    e: '🛵',
    c: 'Travel & Places',
  },
  {
    d: 'manual wheelchair',
    e: '🦽',
    c: 'Travel & Places',
  },
  {
    d: 'motorized wheelchair',
    e: '🦼',
    c: 'Travel & Places',
  },
  {
    d: 'auto rickshaw',
    e: '🛺',
    c: 'Travel & Places',
  },
  {
    d: 'bicycle',
    e: '🚲',
    c: 'Travel & Places',
  },
  {
    d: 'kick scooter',
    e: '🛴',
    c: 'Travel & Places',
  },
  {
    d: 'skateboard',
    e: '🛹',
    c: 'Travel & Places',
  },
  {
    d: 'roller skate',
    e: '🛼',
    c: 'Travel & Places',
  },
  {
    d: 'bus stop',
    e: '🚏',
    c: 'Travel & Places',
  },
  {
    d: 'motorway',
    e: '🛣️',
    c: 'Travel & Places',
  },
  {
    d: 'railway track',
    e: '🛤️',
    c: 'Travel & Places',
  },
  {
    d: 'oil drum',
    e: '🛢️',
    c: 'Travel & Places',
  },
  {
    d: 'fuel pump',
    e: '⛽',
    c: 'Travel & Places',
  },
  {
    d: 'police car light',
    e: '🚨',
    c: 'Travel & Places',
  },
  {
    d: 'horizontal traffic light',
    e: '🚥',
    c: 'Travel & Places',
  },
  {
    d: 'vertical traffic light',
    e: '🚦',
    c: 'Travel & Places',
  },
  {
    d: 'stop sign',
    e: '🛑',
    c: 'Travel & Places',
  },
  {
    d: 'construction',
    e: '🚧',
    c: 'Travel & Places',
  },
  {
    d: 'anchor',
    e: '⚓',
    c: 'Travel & Places',
  },
  {
    d: 'sailboat',
    e: '⛵',
    c: 'Travel & Places',
  },
  {
    d: 'canoe',
    e: '🛶',
    c: 'Travel & Places',
  },
  {
    d: 'speedboat',
    e: '🚤',
    c: 'Travel & Places',
  },
  {
    d: 'passenger ship',
    e: '🛳️',
    c: 'Travel & Places',
  },
  {
    d: 'ferry',
    e: '⛴️',
    c: 'Travel & Places',
  },
  {
    d: 'motor boat',
    e: '🛥️',
    c: 'Travel & Places',
  },
  {
    d: 'ship',
    e: '🚢',
    c: 'Travel & Places',
  },
  {
    d: 'airplane',
    e: '✈️',
    c: 'Travel & Places',
  },
  {
    d: 'small airplane',
    e: '🛩️',
    c: 'Travel & Places',
  },
  {
    d: 'airplane departure',
    e: '🛫',
    c: 'Travel & Places',
  },
  {
    d: 'airplane arrival',
    e: '🛬',
    c: 'Travel & Places',
  },
  {
    d: 'parachute',
    e: '🪂',
    c: 'Travel & Places',
  },
  {
    d: 'seat',
    e: '💺',
    c: 'Travel & Places',
  },
  {
    d: 'helicopter',
    e: '🚁',
    c: 'Travel & Places',
  },
  {
    d: 'suspension railway',
    e: '🚟',
    c: 'Travel & Places',
  },
  {
    d: 'mountain cableway',
    e: '🚠',
    c: 'Travel & Places',
  },
  {
    d: 'aerial tramway',
    e: '🚡',
    c: 'Travel & Places',
  },
  {
    d: 'satellite',
    e: '🛰️',
    c: 'Travel & Places',
  },
  {
    d: 'rocket',
    e: '🚀',
    c: 'Travel & Places',
  },
  {
    d: 'flying saucer',
    e: '🛸',
    c: 'Travel & Places',
  },
  {
    d: 'bellhop bell',
    e: '🛎️',
    c: 'Travel & Places',
  },
  {
    d: 'luggage',
    e: '🧳',
    c: 'Travel & Places',
  },
  {
    d: 'hourglass done',
    e: '⌛',
    c: 'Travel & Places',
  },
  {
    d: 'hourglass not done',
    e: '⏳',
    c: 'Travel & Places',
  },
  {
    d: 'watch',
    e: '⌚',
    c: 'Travel & Places',
  },
  {
    d: 'alarm clock',
    e: '⏰',
    c: 'Travel & Places',
  },
  {
    d: 'stopwatch',
    e: '⏱️',
    c: 'Travel & Places',
  },
  {
    d: 'timer clock',
    e: '⏲️',
    c: 'Travel & Places',
  },
  {
    d: 'mantelpiece clock',
    e: '🕰️',
    c: 'Travel & Places',
  },
  {
    d: 'twelve o’clock',
    e: '🕛',
    c: 'Travel & Places',
  },
  {
    d: 'twelve-thirty',
    e: '🕧',
    c: 'Travel & Places',
  },
  {
    d: 'one o’clock',
    e: '🕐',
    c: 'Travel & Places',
  },
  {
    d: 'one-thirty',
    e: '🕜',
    c: 'Travel & Places',
  },
  {
    d: 'two o’clock',
    e: '🕑',
    c: 'Travel & Places',
  },
  {
    d: 'two-thirty',
    e: '🕝',
    c: 'Travel & Places',
  },
  {
    d: 'three o’clock',
    e: '🕒',
    c: 'Travel & Places',
  },
  {
    d: 'three-thirty',
    e: '🕞',
    c: 'Travel & Places',
  },
  {
    d: 'four o’clock',
    e: '🕓',
    c: 'Travel & Places',
  },
  {
    d: 'four-thirty',
    e: '🕟',
    c: 'Travel & Places',
  },
  {
    d: 'five o’clock',
    e: '🕔',
    c: 'Travel & Places',
  },
  {
    d: 'five-thirty',
    e: '🕠',
    c: 'Travel & Places',
  },
  {
    d: 'six o’clock',
    e: '🕕',
    c: 'Travel & Places',
  },
  {
    d: 'six-thirty',
    e: '🕡',
    c: 'Travel & Places',
  },
  {
    d: 'seven o’clock',
    e: '🕖',
    c: 'Travel & Places',
  },
  {
    d: 'seven-thirty',
    e: '🕢',
    c: 'Travel & Places',
  },
  {
    d: 'eight o’clock',
    e: '🕗',
    c: 'Travel & Places',
  },
  {
    d: 'eight-thirty',
    e: '🕣',
    c: 'Travel & Places',
  },
  {
    d: 'nine o’clock',
    e: '🕘',
    c: 'Travel & Places',
  },
  {
    d: 'nine-thirty',
    e: '🕤',
    c: 'Travel & Places',
  },
  {
    d: 'ten o’clock',
    e: '🕙',
    c: 'Travel & Places',
  },
  {
    d: 'ten-thirty',
    e: '🕥',
    c: 'Travel & Places',
  },
  {
    d: 'eleven o’clock',
    e: '🕚',
    c: 'Travel & Places',
  },
  {
    d: 'eleven-thirty',
    e: '🕦',
    c: 'Travel & Places',
  },
  {
    d: 'new moon',
    e: '🌑',
    c: 'Travel & Places',
  },
  {
    d: 'waxing crescent moon',
    e: '🌒',
    c: 'Travel & Places',
  },
  {
    d: 'first quarter moon',
    e: '🌓',
    c: 'Travel & Places',
  },
  {
    d: 'waxing gibbous moon',
    e: '🌔',
    c: 'Travel & Places',
  },
  {
    d: 'full moon',
    e: '🌕',
    c: 'Travel & Places',
  },
  {
    d: 'waning gibbous moon',
    e: '🌖',
    c: 'Travel & Places',
  },
  {
    d: 'last quarter moon',
    e: '🌗',
    c: 'Travel & Places',
  },
  {
    d: 'waning crescent moon',
    e: '🌘',
    c: 'Travel & Places',
  },
  {
    d: 'crescent moon',
    e: '🌙',
    c: 'Travel & Places',
  },
  {
    d: 'new moon face',
    e: '🌚',
    c: 'Travel & Places',
  },
  {
    d: 'first quarter moon face',
    e: '🌛',
    c: 'Travel & Places',
  },
  {
    d: 'last quarter moon face',
    e: '🌜',
    c: 'Travel & Places',
  },
  {
    d: 'thermometer',
    e: '🌡️',
    c: 'Travel & Places',
  },
  {
    d: 'sun',
    e: '☀️',
    c: 'Travel & Places',
  },
  {
    d: 'full moon face',
    e: '🌝',
    c: 'Travel & Places',
  },
  {
    d: 'sun with face',
    e: '🌞',
    c: 'Travel & Places',
  },
  {
    d: 'ringed planet',
    e: '🪐',
    c: 'Travel & Places',
  },
  {
    d: 'star',
    e: '⭐',
    c: 'Travel & Places',
  },
  {
    d: 'glowing star',
    e: '🌟',
    c: 'Travel & Places',
  },
  {
    d: 'shooting star',
    e: '🌠',
    c: 'Travel & Places',
  },
  {
    d: 'milky way',
    e: '🌌',
    c: 'Travel & Places',
  },
  {
    d: 'cloud',
    e: '☁️',
    c: 'Travel & Places',
  },
  {
    d: 'sun behind cloud',
    e: '⛅',
    c: 'Travel & Places',
  },
  {
    d: 'cloud with lightning and rain',
    e: '⛈️',
    c: 'Travel & Places',
  },
  {
    d: 'sun behind small cloud',
    e: '🌤️',
    c: 'Travel & Places',
  },
  {
    d: 'sun behind large cloud',
    e: '🌥️',
    c: 'Travel & Places',
  },
  {
    d: 'sun behind rain cloud',
    e: '🌦️',
    c: 'Travel & Places',
  },
  {
    d: 'cloud with rain',
    e: '🌧️',
    c: 'Travel & Places',
  },
  {
    d: 'cloud with snow',
    e: '🌨️',
    c: 'Travel & Places',
  },
  {
    d: 'cloud with lightning',
    e: '🌩️',
    c: 'Travel & Places',
  },
  {
    d: 'tornado',
    e: '🌪️',
    c: 'Travel & Places',
  },
  {
    d: 'fog',
    e: '🌫️',
    c: 'Travel & Places',
  },
  {
    d: 'wind face',
    e: '🌬️',
    c: 'Travel & Places',
  },
  {
    d: 'cyclone',
    e: '🌀',
    c: 'Travel & Places',
  },
  {
    d: 'rainbow',
    e: '🌈',
    c: 'Travel & Places',
  },
  {
    d: 'closed umbrella',
    e: '🌂',
    c: 'Travel & Places',
  },
  {
    d: 'umbrella',
    e: '☂️',
    c: 'Travel & Places',
  },
  {
    d: 'umbrella with rain drops',
    e: '☔',
    c: 'Travel & Places',
  },
  {
    d: 'umbrella on ground',
    e: '⛱️',
    c: 'Travel & Places',
  },
  {
    d: 'high voltage',
    e: '⚡',
    c: 'Travel & Places',
  },
  {
    d: 'snowflake',
    e: '❄️',
    c: 'Travel & Places',
  },
  {
    d: 'snowman',
    e: '☃️',
    c: 'Travel & Places',
  },
  {
    d: 'snowman without snow',
    e: '⛄',
    c: 'Travel & Places',
  },
  {
    d: 'comet',
    e: '☄️',
    c: 'Travel & Places',
  },
  {
    d: 'fire',
    e: '🔥',
    c: 'Travel & Places',
  },
  {
    d: 'droplet',
    e: '💧',
    c: 'Travel & Places',
  },
  {
    d: 'water wave',
    e: '🌊',
    c: 'Travel & Places',
  },
  {
    d: 'jack-o-lantern',
    e: '🎃',
    c: 'Activities',
  },
  {
    d: 'Christmas tree',
    e: '🎄',
    c: 'Activities',
  },
  {
    d: 'fireworks',
    e: '🎆',
    c: 'Activities',
  },
  {
    d: 'sparkler',
    e: '🎇',
    c: 'Activities',
  },
  {
    d: 'firecracker',
    e: '🧨',
    c: 'Activities',
  },
  {
    d: 'sparkles',
    e: '✨',
    c: 'Activities',
  },
  {
    d: 'balloon',
    e: '🎈',
    c: 'Activities',
  },
  {
    d: 'party popper',
    e: '🎉',
    c: 'Activities',
  },
  {
    d: 'confetti ball',
    e: '🎊',
    c: 'Activities',
  },
  {
    d: 'tanabata tree',
    e: '🎋',
    c: 'Activities',
  },
  {
    d: 'pine decoration',
    e: '🎍',
    c: 'Activities',
  },
  {
    d: 'Japanese dolls',
    e: '🎎',
    c: 'Activities',
  },
  {
    d: 'carp streamer',
    e: '🎏',
    c: 'Activities',
  },
  {
    d: 'wind chime',
    e: '🎐',
    c: 'Activities',
  },
  {
    d: 'moon viewing ceremony',
    e: '🎑',
    c: 'Activities',
  },
  {
    d: 'red envelope',
    e: '🧧',
    c: 'Activities',
  },
  {
    d: 'ribbon',
    e: '🎀',
    c: 'Activities',
  },
  {
    d: 'wrapped gift',
    e: '🎁',
    c: 'Activities',
  },
  {
    d: 'reminder ribbon',
    e: '🎗️',
    c: 'Activities',
  },
  {
    d: 'admission tickets',
    e: '🎟️',
    c: 'Activities',
  },
  {
    d: 'ticket',
    e: '🎫',
    c: 'Activities',
  },
  {
    d: 'military medal',
    e: '🎖️',
    c: 'Activities',
  },
  {
    d: 'trophy',
    e: '🏆',
    c: 'Activities',
  },
  {
    d: 'sports medal',
    e: '🏅',
    c: 'Activities',
  },
  {
    d: '1st place medal',
    e: '🥇',
    c: 'Activities',
  },
  {
    d: '2nd place medal',
    e: '🥈',
    c: 'Activities',
  },
  {
    d: '3rd place medal',
    e: '🥉',
    c: 'Activities',
  },
  {
    d: 'soccer ball',
    e: '⚽',
    c: 'Activities',
  },
  {
    d: 'baseball',
    e: '⚾',
    c: 'Activities',
  },
  {
    d: 'softball',
    e: '🥎',
    c: 'Activities',
  },
  {
    d: 'basketball',
    e: '🏀',
    c: 'Activities',
  },
  {
    d: 'volleyball',
    e: '🏐',
    c: 'Activities',
  },
  {
    d: 'american football',
    e: '🏈',
    c: 'Activities',
  },
  {
    d: 'rugby football',
    e: '🏉',
    c: 'Activities',
  },
  {
    d: 'tennis',
    e: '🎾',
    c: 'Activities',
  },
  {
    d: 'flying disc',
    e: '🥏',
    c: 'Activities',
  },
  {
    d: 'bowling',
    e: '🎳',
    c: 'Activities',
  },
  {
    d: 'cricket game',
    e: '🏏',
    c: 'Activities',
  },
  {
    d: 'field hockey',
    e: '🏑',
    c: 'Activities',
  },
  {
    d: 'ice hockey',
    e: '🏒',
    c: 'Activities',
  },
  {
    d: 'lacrosse',
    e: '🥍',
    c: 'Activities',
  },
  {
    d: 'ping pong',
    e: '🏓',
    c: 'Activities',
  },
  {
    d: 'badminton',
    e: '🏸',
    c: 'Activities',
  },
  {
    d: 'boxing glove',
    e: '🥊',
    c: 'Activities',
  },
  {
    d: 'martial arts uniform',
    e: '🥋',
    c: 'Activities',
  },
  {
    d: 'goal net',
    e: '🥅',
    c: 'Activities',
  },
  {
    d: 'flag in hole',
    e: '⛳',
    c: 'Activities',
  },
  {
    d: 'ice skate',
    e: '⛸️',
    c: 'Activities',
  },
  {
    d: 'fishing pole',
    e: '🎣',
    c: 'Activities',
  },
  {
    d: 'diving mask',
    e: '🤿',
    c: 'Activities',
  },
  {
    d: 'running shirt',
    e: '🎽',
    c: 'Activities',
  },
  {
    d: 'skis',
    e: '🎿',
    c: 'Activities',
  },
  {
    d: 'sled',
    e: '🛷',
    c: 'Activities',
  },
  {
    d: 'curling stone',
    e: '🥌',
    c: 'Activities',
  },
  {
    d: 'bullseye',
    e: '🎯',
    c: 'Activities',
  },
  {
    d: 'yo-yo',
    e: '🪀',
    c: 'Activities',
  },
  {
    d: 'kite',
    e: '🪁',
    c: 'Activities',
  },
  {
    d: 'pool 8 ball',
    e: '🎱',
    c: 'Activities',
  },
  {
    d: 'crystal ball',
    e: '🔮',
    c: 'Activities',
  },
  {
    d: 'magic wand',
    e: '🪄',
    c: 'Activities',
  },
  {
    d: 'nazar amulet',
    e: '🧿',
    c: 'Activities',
  },
  {
    d: 'video game',
    e: '🎮',
    c: 'Activities',
  },
  {
    d: 'joystick',
    e: '🕹️',
    c: 'Activities',
  },
  {
    d: 'slot machine',
    e: '🎰',
    c: 'Activities',
  },
  {
    d: 'game die',
    e: '🎲',
    c: 'Activities',
  },
  {
    d: 'puzzle piece',
    e: '🧩',
    c: 'Activities',
  },
  {
    d: 'teddy bear',
    e: '🧸',
    c: 'Activities',
  },
  {
    d: 'piñata',
    e: '🪅',
    c: 'Activities',
  },
  {
    d: 'nesting dolls',
    e: '🪆',
    c: 'Activities',
  },
  {
    d: 'spade suit',
    e: '♠️',
    c: 'Activities',
  },
  {
    d: 'heart suit',
    e: '♥️',
    c: 'Activities',
  },
  {
    d: 'diamond suit',
    e: '♦️',
    c: 'Activities',
  },
  {
    d: 'club suit',
    e: '♣️',
    c: 'Activities',
  },
  {
    d: 'chess pawn',
    e: '♟️',
    c: 'Activities',
  },
  {
    d: 'joker',
    e: '🃏',
    c: 'Activities',
  },
  {
    d: 'mahjong red dragon',
    e: '🀄',
    c: 'Activities',
  },
  {
    d: 'flower playing cards',
    e: '🎴',
    c: 'Activities',
  },
  {
    d: 'performing arts',
    e: '🎭',
    c: 'Activities',
  },
  {
    d: 'framed picture',
    e: '🖼️',
    c: 'Activities',
  },
  {
    d: 'artist palette',
    e: '🎨',
    c: 'Activities',
  },
  {
    d: 'thread',
    e: '🧵',
    c: 'Activities',
  },
  {
    d: 'sewing needle',
    e: '🪡',
    c: 'Activities',
  },
  {
    d: 'yarn',
    e: '🧶',
    c: 'Activities',
  },
  {
    d: 'knot',
    e: '🪢',
    c: 'Activities',
  },
  {
    d: 'glasses',
    e: '👓',
    c: 'Objects',
  },
  {
    d: 'sunglasses',
    e: '🕶️',
    c: 'Objects',
  },
  {
    d: 'goggles',
    e: '🥽',
    c: 'Objects',
  },
  {
    d: 'lab coat',
    e: '🥼',
    c: 'Objects',
  },
  {
    d: 'safety vest',
    e: '🦺',
    c: 'Objects',
  },
  {
    d: 'necktie',
    e: '👔',
    c: 'Objects',
  },
  {
    d: 't-shirt',
    e: '👕',
    c: 'Objects',
  },
  {
    d: 'jeans',
    e: '👖',
    c: 'Objects',
  },
  {
    d: 'scarf',
    e: '🧣',
    c: 'Objects',
  },
  {
    d: 'gloves',
    e: '🧤',
    c: 'Objects',
  },
  {
    d: 'coat',
    e: '🧥',
    c: 'Objects',
  },
  {
    d: 'socks',
    e: '🧦',
    c: 'Objects',
  },
  {
    d: 'dress',
    e: '👗',
    c: 'Objects',
  },
  {
    d: 'kimono',
    e: '👘',
    c: 'Objects',
  },
  {
    d: 'sari',
    e: '🥻',
    c: 'Objects',
  },
  {
    d: 'one-piece swimsuit',
    e: '🩱',
    c: 'Objects',
  },
  {
    d: 'briefs',
    e: '🩲',
    c: 'Objects',
  },
  {
    d: 'shorts',
    e: '🩳',
    c: 'Objects',
  },
  {
    d: 'bikini',
    e: '👙',
    c: 'Objects',
  },
  {
    d: 'woman’s clothes',
    e: '👚',
    c: 'Objects',
  },
  {
    d: 'purse',
    e: '👛',
    c: 'Objects',
  },
  {
    d: 'handbag',
    e: '👜',
    c: 'Objects',
  },
  {
    d: 'clutch bag',
    e: '👝',
    c: 'Objects',
  },
  {
    d: 'shopping bags',
    e: '🛍️',
    c: 'Objects',
  },
  {
    d: 'backpack',
    e: '🎒',
    c: 'Objects',
  },
  {
    d: 'thong sandal',
    e: '🩴',
    c: 'Objects',
  },
  {
    d: 'man’s shoe',
    e: '👞',
    c: 'Objects',
  },
  {
    d: 'running shoe',
    e: '👟',
    c: 'Objects',
  },
  {
    d: 'hiking boot',
    e: '🥾',
    c: 'Objects',
  },
  {
    d: 'flat shoe',
    e: '🥿',
    c: 'Objects',
  },
  {
    d: 'high-heeled shoe',
    e: '👠',
    c: 'Objects',
  },
  {
    d: 'woman’s sandal',
    e: '👡',
    c: 'Objects',
  },
  {
    d: 'ballet shoes',
    e: '🩰',
    c: 'Objects',
  },
  {
    d: 'woman’s boot',
    e: '👢',
    c: 'Objects',
  },
  {
    d: 'crown',
    e: '👑',
    c: 'Objects',
  },
  {
    d: 'woman’s hat',
    e: '👒',
    c: 'Objects',
  },
  {
    d: 'top hat',
    e: '🎩',
    c: 'Objects',
  },
  {
    d: 'graduation cap',
    e: '🎓',
    c: 'Objects',
  },
  {
    d: 'billed cap',
    e: '🧢',
    c: 'Objects',
  },
  {
    d: 'military helmet',
    e: '🪖',
    c: 'Objects',
  },
  {
    d: 'rescue worker’s helmet',
    e: '⛑️',
    c: 'Objects',
  },
  {
    d: 'prayer beads',
    e: '📿',
    c: 'Objects',
  },
  {
    d: 'lipstick',
    e: '💄',
    c: 'Objects',
  },
  {
    d: 'ring',
    e: '💍',
    c: 'Objects',
  },
  {
    d: 'gem stone',
    e: '💎',
    c: 'Objects',
  },
  {
    d: 'muted speaker',
    e: '🔇',
    c: 'Objects',
  },
  {
    d: 'speaker low volume',
    e: '🔈',
    c: 'Objects',
  },
  {
    d: 'speaker medium volume',
    e: '🔉',
    c: 'Objects',
  },
  {
    d: 'speaker high volume',
    e: '🔊',
    c: 'Objects',
  },
  {
    d: 'loudspeaker',
    e: '📢',
    c: 'Objects',
  },
  {
    d: 'megaphone',
    e: '📣',
    c: 'Objects',
  },
  {
    d: 'postal horn',
    e: '📯',
    c: 'Objects',
  },
  {
    d: 'bell',
    e: '🔔',
    c: 'Objects',
  },
  {
    d: 'bell with slash',
    e: '🔕',
    c: 'Objects',
  },
  {
    d: 'musical score',
    e: '🎼',
    c: 'Objects',
  },
  {
    d: 'musical note',
    e: '🎵',
    c: 'Objects',
  },
  {
    d: 'musical notes',
    e: '🎶',
    c: 'Objects',
  },
  {
    d: 'studio microphone',
    e: '🎙️',
    c: 'Objects',
  },
  {
    d: 'level slider',
    e: '🎚️',
    c: 'Objects',
  },
  {
    d: 'control knobs',
    e: '🎛️',
    c: 'Objects',
  },
  {
    d: 'microphone',
    e: '🎤',
    c: 'Objects',
  },
  {
    d: 'headphone',
    e: '🎧',
    c: 'Objects',
  },
  {
    d: 'radio',
    e: '📻',
    c: 'Objects',
  },
  {
    d: 'saxophone',
    e: '🎷',
    c: 'Objects',
  },
  {
    d: 'accordion',
    e: '🪗',
    c: 'Objects',
  },
  {
    d: 'guitar',
    e: '🎸',
    c: 'Objects',
  },
  {
    d: 'musical keyboard',
    e: '🎹',
    c: 'Objects',
  },
  {
    d: 'trumpet',
    e: '🎺',
    c: 'Objects',
  },
  {
    d: 'violin',
    e: '🎻',
    c: 'Objects',
  },
  {
    d: 'banjo',
    e: '🪕',
    c: 'Objects',
  },
  {
    d: 'drum',
    e: '🥁',
    c: 'Objects',
  },
  {
    d: 'long drum',
    e: '🪘',
    c: 'Objects',
  },
  {
    d: 'mobile phone',
    e: '📱',
    c: 'Objects',
  },
  {
    d: 'mobile phone with arrow',
    e: '📲',
    c: 'Objects',
  },
  {
    d: 'telephone',
    e: '☎️',
    c: 'Objects',
  },
  {
    d: 'telephone receiver',
    e: '📞',
    c: 'Objects',
  },
  {
    d: 'pager',
    e: '📟',
    c: 'Objects',
  },
  {
    d: 'fax machine',
    e: '📠',
    c: 'Objects',
  },
  {
    d: 'battery',
    e: '🔋',
    c: 'Objects',
  },
  {
    d: 'electric plug',
    e: '🔌',
    c: 'Objects',
  },
  {
    d: 'laptop',
    e: '💻',
    c: 'Objects',
  },
  {
    d: 'desktop computer',
    e: '🖥️',
    c: 'Objects',
  },
  {
    d: 'printer',
    e: '🖨️',
    c: 'Objects',
  },
  {
    d: 'keyboard',
    e: '⌨️',
    c: 'Objects',
  },
  {
    d: 'computer mouse',
    e: '🖱️',
    c: 'Objects',
  },
  {
    d: 'trackball',
    e: '🖲️',
    c: 'Objects',
  },
  {
    d: 'computer disk',
    e: '💽',
    c: 'Objects',
  },
  {
    d: 'floppy disk',
    e: '💾',
    c: 'Objects',
  },
  {
    d: 'optical disk',
    e: '💿',
    c: 'Objects',
  },
  {
    d: 'dvd',
    e: '📀',
    c: 'Objects',
  },
  {
    d: 'abacus',
    e: '🧮',
    c: 'Objects',
  },
  {
    d: 'movie camera',
    e: '🎥',
    c: 'Objects',
  },
  {
    d: 'film frames',
    e: '🎞️',
    c: 'Objects',
  },
  {
    d: 'film projector',
    e: '📽️',
    c: 'Objects',
  },
  {
    d: 'clapper board',
    e: '🎬',
    c: 'Objects',
  },
  {
    d: 'television',
    e: '📺',
    c: 'Objects',
  },
  {
    d: 'camera',
    e: '📷',
    c: 'Objects',
  },
  {
    d: 'camera with flash',
    e: '📸',
    c: 'Objects',
  },
  {
    d: 'video camera',
    e: '📹',
    c: 'Objects',
  },
  {
    d: 'videocassette',
    e: '📼',
    c: 'Objects',
  },
  {
    d: 'magnifying glass tilted left',
    e: '🔍',
    c: 'Objects',
  },
  {
    d: 'magnifying glass tilted right',
    e: '🔎',
    c: 'Objects',
  },
  {
    d: 'candle',
    e: '🕯️',
    c: 'Objects',
  },
  {
    d: 'light bulb',
    e: '💡',
    c: 'Objects',
  },
  {
    d: 'flashlight',
    e: '🔦',
    c: 'Objects',
  },
  {
    d: 'red paper lantern',
    e: '🏮',
    c: 'Objects',
  },
  {
    d: 'diya lamp',
    e: '🪔',
    c: 'Objects',
  },
  {
    d: 'notebook with decorative cover',
    e: '📔',
    c: 'Objects',
  },
  {
    d: 'closed book',
    e: '📕',
    c: 'Objects',
  },
  {
    d: 'open book',
    e: '📖',
    c: 'Objects',
  },
  {
    d: 'green book',
    e: '📗',
    c: 'Objects',
  },
  {
    d: 'blue book',
    e: '📘',
    c: 'Objects',
  },
  {
    d: 'orange book',
    e: '📙',
    c: 'Objects',
  },
  {
    d: 'books',
    e: '📚',
    c: 'Objects',
  },
  {
    d: 'notebook',
    e: '📓',
    c: 'Objects',
  },
  {
    d: 'ledger',
    e: '📒',
    c: 'Objects',
  },
  {
    d: 'page with curl',
    e: '📃',
    c: 'Objects',
  },
  {
    d: 'scroll',
    e: '📜',
    c: 'Objects',
  },
  {
    d: 'page facing up',
    e: '📄',
    c: 'Objects',
  },
  {
    d: 'newspaper',
    e: '📰',
    c: 'Objects',
  },
  {
    d: 'rolled-up newspaper',
    e: '🗞️',
    c: 'Objects',
  },
  {
    d: 'bookmark tabs',
    e: '📑',
    c: 'Objects',
  },
  {
    d: 'bookmark',
    e: '🔖',
    c: 'Objects',
  },
  {
    d: 'label',
    e: '🏷️',
    c: 'Objects',
  },
  {
    d: 'money bag',
    e: '💰',
    c: 'Objects',
  },
  {
    d: 'coin',
    e: '🪙',
    c: 'Objects',
  },
  {
    d: 'yen banknote',
    e: '💴',
    c: 'Objects',
  },
  {
    d: 'dollar banknote',
    e: '💵',
    c: 'Objects',
  },
  {
    d: 'euro banknote',
    e: '💶',
    c: 'Objects',
  },
  {
    d: 'pound banknote',
    e: '💷',
    c: 'Objects',
  },
  {
    d: 'money with wings',
    e: '💸',
    c: 'Objects',
  },
  {
    d: 'credit card',
    e: '💳',
    c: 'Objects',
  },
  {
    d: 'receipt',
    e: '🧾',
    c: 'Objects',
  },
  {
    d: 'chart increasing with yen',
    e: '💹',
    c: 'Objects',
  },
  {
    d: 'envelope',
    e: '✉️',
    c: 'Objects',
  },
  {
    d: 'e-mail',
    e: '📧',
    c: 'Objects',
  },
  {
    d: 'incoming envelope',
    e: '📨',
    c: 'Objects',
  },
  {
    d: 'envelope with arrow',
    e: '📩',
    c: 'Objects',
  },
  {
    d: 'outbox tray',
    e: '📤',
    c: 'Objects',
  },
  {
    d: 'inbox tray',
    e: '📥',
    c: 'Objects',
  },
  {
    d: 'package',
    e: '📦',
    c: 'Objects',
  },
  {
    d: 'closed mailbox with raised flag',
    e: '📫',
    c: 'Objects',
  },
  {
    d: 'closed mailbox with lowered flag',
    e: '📪',
    c: 'Objects',
  },
  {
    d: 'open mailbox with raised flag',
    e: '📬',
    c: 'Objects',
  },
  {
    d: 'open mailbox with lowered flag',
    e: '📭',
    c: 'Objects',
  },
  {
    d: 'postbox',
    e: '📮',
    c: 'Objects',
  },
  {
    d: 'ballot box with ballot',
    e: '🗳️',
    c: 'Objects',
  },
  {
    d: 'pencil',
    e: '✏️',
    c: 'Objects',
  },
  {
    d: 'black nib',
    e: '✒️',
    c: 'Objects',
  },
  {
    d: 'fountain pen',
    e: '🖋️',
    c: 'Objects',
  },
  {
    d: 'pen',
    e: '🖊️',
    c: 'Objects',
  },
  {
    d: 'paintbrush',
    e: '🖌️',
    c: 'Objects',
  },
  {
    d: 'crayon',
    e: '🖍️',
    c: 'Objects',
  },
  {
    d: 'memo',
    e: '📝',
    c: 'Objects',
  },
  {
    d: 'briefcase',
    e: '💼',
    c: 'Objects',
  },
  {
    d: 'file folder',
    e: '📁',
    c: 'Objects',
  },
  {
    d: 'open file folder',
    e: '📂',
    c: 'Objects',
  },
  {
    d: 'card index dividers',
    e: '🗂️',
    c: 'Objects',
  },
  {
    d: 'calendar',
    e: '📅',
    c: 'Objects',
  },
  {
    d: 'tear-off calendar',
    e: '📆',
    c: 'Objects',
  },
  {
    d: 'spiral notepad',
    e: '🗒️',
    c: 'Objects',
  },
  {
    d: 'spiral calendar',
    e: '🗓️',
    c: 'Objects',
  },
  {
    d: 'card index',
    e: '📇',
    c: 'Objects',
  },
  {
    d: 'chart increasing',
    e: '📈',
    c: 'Objects',
  },
  {
    d: 'chart decreasing',
    e: '📉',
    c: 'Objects',
  },
  {
    d: 'bar chart',
    e: '📊',
    c: 'Objects',
  },
  {
    d: 'clipboard',
    e: '📋',
    c: 'Objects',
  },
  {
    d: 'pushpin',
    e: '📌',
    c: 'Objects',
  },
  {
    d: 'round pushpin',
    e: '📍',
    c: 'Objects',
  },
  {
    d: 'paperclip',
    e: '📎',
    c: 'Objects',
  },
  {
    d: 'linked paperclips',
    e: '🖇️',
    c: 'Objects',
  },
  {
    d: 'straight ruler',
    e: '📏',
    c: 'Objects',
  },
  {
    d: 'triangular ruler',
    e: '📐',
    c: 'Objects',
  },
  {
    d: 'scissors',
    e: '✂️',
    c: 'Objects',
  },
  {
    d: 'card file box',
    e: '🗃️',
    c: 'Objects',
  },
  {
    d: 'file cabinet',
    e: '🗄️',
    c: 'Objects',
  },
  {
    d: 'wastebasket',
    e: '🗑️',
    c: 'Objects',
  },
  {
    d: 'locked',
    e: '🔒',
    c: 'Objects',
  },
  {
    d: 'unlocked',
    e: '🔓',
    c: 'Objects',
  },
  {
    d: 'locked with pen',
    e: '🔏',
    c: 'Objects',
  },
  {
    d: 'locked with key',
    e: '🔐',
    c: 'Objects',
  },
  {
    d: 'key',
    e: '🔑',
    c: 'Objects',
  },
  {
    d: 'old key',
    e: '🗝️',
    c: 'Objects',
  },
  {
    d: 'hammer',
    e: '🔨',
    c: 'Objects',
  },
  {
    d: 'axe',
    e: '🪓',
    c: 'Objects',
  },
  {
    d: 'pick',
    e: '⛏️',
    c: 'Objects',
  },
  {
    d: 'hammer and pick',
    e: '⚒️',
    c: 'Objects',
  },
  {
    d: 'hammer and wrench',
    e: '🛠️',
    c: 'Objects',
  },
  {
    d: 'dagger',
    e: '🗡️',
    c: 'Objects',
  },
  {
    d: 'crossed swords',
    e: '⚔️',
    c: 'Objects',
  },
  {
    d: 'water pistol',
    e: '🔫',
    c: 'Objects',
  },
  {
    d: 'boomerang',
    e: '🪃',
    c: 'Objects',
  },
  {
    d: 'bow and arrow',
    e: '🏹',
    c: 'Objects',
  },
  {
    d: 'shield',
    e: '🛡️',
    c: 'Objects',
  },
  {
    d: 'carpentry saw',
    e: '🪚',
    c: 'Objects',
  },
  {
    d: 'wrench',
    e: '🔧',
    c: 'Objects',
  },
  {
    d: 'screwdriver',
    e: '🪛',
    c: 'Objects',
  },
  {
    d: 'nut and bolt',
    e: '🔩',
    c: 'Objects',
  },
  {
    d: 'gear',
    e: '⚙️',
    c: 'Objects',
  },
  {
    d: 'clamp',
    e: '🗜️',
    c: 'Objects',
  },
  {
    d: 'balance scale',
    e: '⚖️',
    c: 'Objects',
  },
  {
    d: 'white cane',
    e: '🦯',
    c: 'Objects',
  },
  {
    d: 'link',
    e: '🔗',
    c: 'Objects',
  },
  {
    d: 'chains',
    e: '⛓️',
    c: 'Objects',
  },
  {
    d: 'hook',
    e: '🪝',
    c: 'Objects',
  },
  {
    d: 'toolbox',
    e: '🧰',
    c: 'Objects',
  },
  {
    d: 'magnet',
    e: '🧲',
    c: 'Objects',
  },
  {
    d: 'ladder',
    e: '🪜',
    c: 'Objects',
  },
  {
    d: 'alembic',
    e: '⚗️',
    c: 'Objects',
  },
  {
    d: 'test tube',
    e: '🧪',
    c: 'Objects',
  },
  {
    d: 'petri dish',
    e: '🧫',
    c: 'Objects',
  },
  {
    d: 'dna',
    e: '🧬',
    c: 'Objects',
  },
  {
    d: 'microscope',
    e: '🔬',
    c: 'Objects',
  },
  {
    d: 'telescope',
    e: '🔭',
    c: 'Objects',
  },
  {
    d: 'satellite antenna',
    e: '📡',
    c: 'Objects',
  },
  {
    d: 'syringe',
    e: '💉',
    c: 'Objects',
  },
  {
    d: 'drop of blood',
    e: '🩸',
    c: 'Objects',
  },
  {
    d: 'pill',
    e: '💊',
    c: 'Objects',
  },
  {
    d: 'adhesive bandage',
    e: '🩹',
    c: 'Objects',
  },
  {
    d: 'stethoscope',
    e: '🩺',
    c: 'Objects',
  },
  {
    d: 'door',
    e: '🚪',
    c: 'Objects',
  },
  {
    d: 'elevator',
    e: '🛗',
    c: 'Objects',
  },
  {
    d: 'mirror',
    e: '🪞',
    c: 'Objects',
  },
  {
    d: 'window',
    e: '🪟',
    c: 'Objects',
  },
  {
    d: 'bed',
    e: '🛏️',
    c: 'Objects',
  },
  {
    d: 'couch and lamp',
    e: '🛋️',
    c: 'Objects',
  },
  {
    d: 'chair',
    e: '🪑',
    c: 'Objects',
  },
  {
    d: 'toilet',
    e: '🚽',
    c: 'Objects',
  },
  {
    d: 'plunger',
    e: '🪠',
    c: 'Objects',
  },
  {
    d: 'shower',
    e: '🚿',
    c: 'Objects',
  },
  {
    d: 'bathtub',
    e: '🛁',
    c: 'Objects',
  },
  {
    d: 'mouse trap',
    e: '🪤',
    c: 'Objects',
  },
  {
    d: 'razor',
    e: '🪒',
    c: 'Objects',
  },
  {
    d: 'lotion bottle',
    e: '🧴',
    c: 'Objects',
  },
  {
    d: 'safety pin',
    e: '🧷',
    c: 'Objects',
  },
  {
    d: 'broom',
    e: '🧹',
    c: 'Objects',
  },
  {
    d: 'basket',
    e: '🧺',
    c: 'Objects',
  },
  {
    d: 'roll of paper',
    e: '🧻',
    c: 'Objects',
  },
  {
    d: 'bucket',
    e: '🪣',
    c: 'Objects',
  },
  {
    d: 'soap',
    e: '🧼',
    c: 'Objects',
  },
  {
    d: 'toothbrush',
    e: '🪥',
    c: 'Objects',
  },
  {
    d: 'sponge',
    e: '🧽',
    c: 'Objects',
  },
  {
    d: 'fire extinguisher',
    e: '🧯',
    c: 'Objects',
  },
  {
    d: 'shopping cart',
    e: '🛒',
    c: 'Objects',
  },
  {
    d: 'cigarette',
    e: '🚬',
    c: 'Objects',
  },
  {
    d: 'coffin',
    e: '⚰️',
    c: 'Objects',
  },
  {
    d: 'headstone',
    e: '🪦',
    c: 'Objects',
  },
  {
    d: 'funeral urn',
    e: '⚱️',
    c: 'Objects',
  },
  {
    d: 'moai',
    e: '🗿',
    c: 'Objects',
  },
  {
    d: 'placard',
    e: '🪧',
    c: 'Objects',
  },
  {
    d: 'ATM sign',
    e: '🏧',
    c: 'Symbols',
  },
  {
    d: 'litter in bin sign',
    e: '🚮',
    c: 'Symbols',
  },
  {
    d: 'potable water',
    e: '🚰',
    c: 'Symbols',
  },
  {
    d: 'wheelchair symbol',
    e: '♿',
    c: 'Symbols',
  },
  {
    d: 'men’s room',
    e: '🚹',
    c: 'Symbols',
  },
  {
    d: 'women’s room',
    e: '🚺',
    c: 'Symbols',
  },
  {
    d: 'restroom',
    e: '🚻',
    c: 'Symbols',
  },
  {
    d: 'baby symbol',
    e: '🚼',
    c: 'Symbols',
  },
  {
    d: 'water closet',
    e: '🚾',
    c: 'Symbols',
  },
  {
    d: 'passport control',
    e: '🛂',
    c: 'Symbols',
  },
  {
    d: 'customs',
    e: '🛃',
    c: 'Symbols',
  },
  {
    d: 'baggage claim',
    e: '🛄',
    c: 'Symbols',
  },
  {
    d: 'left luggage',
    e: '🛅',
    c: 'Symbols',
  },
  {
    d: 'warning',
    e: '⚠️',
    c: 'Symbols',
  },
  {
    d: 'children crossing',
    e: '🚸',
    c: 'Symbols',
  },
  {
    d: 'no entry',
    e: '⛔',
    c: 'Symbols',
  },
  {
    d: 'prohibited',
    e: '🚫',
    c: 'Symbols',
  },
  {
    d: 'no bicycles',
    e: '🚳',
    c: 'Symbols',
  },
  {
    d: 'no smoking',
    e: '🚭',
    c: 'Symbols',
  },
  {
    d: 'no littering',
    e: '🚯',
    c: 'Symbols',
  },
  {
    d: 'non-potable water',
    e: '🚱',
    c: 'Symbols',
  },
  {
    d: 'no pedestrians',
    e: '🚷',
    c: 'Symbols',
  },
  {
    d: 'no mobile phones',
    e: '📵',
    c: 'Symbols',
  },
  {
    d: 'no one under eighteen',
    e: '🔞',
    c: 'Symbols',
  },
  {
    d: 'radioactive',
    e: '☢️',
    c: 'Symbols',
  },
  {
    d: 'biohazard',
    e: '☣️',
    c: 'Symbols',
  },
  {
    d: 'up arrow',
    e: '⬆️',
    c: 'Symbols',
  },
  {
    d: 'up-right arrow',
    e: '↗️',
    c: 'Symbols',
  },
  {
    d: 'right arrow',
    e: '➡️',
    c: 'Symbols',
  },
  {
    d: 'down-right arrow',
    e: '↘️',
    c: 'Symbols',
  },
  {
    d: 'down arrow',
    e: '⬇️',
    c: 'Symbols',
  },
  {
    d: 'down-left arrow',
    e: '↙️',
    c: 'Symbols',
  },
  {
    d: 'left arrow',
    e: '⬅️',
    c: 'Symbols',
  },
  {
    d: 'up-left arrow',
    e: '↖️',
    c: 'Symbols',
  },
  {
    d: 'up-down arrow',
    e: '↕️',
    c: 'Symbols',
  },
  {
    d: 'left-right arrow',
    e: '↔️',
    c: 'Symbols',
  },
  {
    d: 'right arrow curving left',
    e: '↩️',
    c: 'Symbols',
  },
  {
    d: 'left arrow curving right',
    e: '↪️',
    c: 'Symbols',
  },
  {
    d: 'right arrow curving up',
    e: '⤴️',
    c: 'Symbols',
  },
  {
    d: 'right arrow curving down',
    e: '⤵️',
    c: 'Symbols',
  },
  {
    d: 'clockwise vertical arrows',
    e: '🔃',
    c: 'Symbols',
  },
  {
    d: 'counterclockwise arrows button',
    e: '🔄',
    c: 'Symbols',
  },
  {
    d: 'BACK arrow',
    e: '🔙',
    c: 'Symbols',
  },
  {
    d: 'END arrow',
    e: '🔚',
    c: 'Symbols',
  },
  {
    d: 'ON! arrow',
    e: '🔛',
    c: 'Symbols',
  },
  {
    d: 'SOON arrow',
    e: '🔜',
    c: 'Symbols',
  },
  {
    d: 'TOP arrow',
    e: '🔝',
    c: 'Symbols',
  },
  {
    d: 'place of worship',
    e: '🛐',
    c: 'Symbols',
  },
  {
    d: 'atom symbol',
    e: '⚛️',
    c: 'Symbols',
  },
  {
    d: 'om',
    e: '🕉️',
    c: 'Symbols',
  },
  {
    d: 'star of David',
    e: '✡️',
    c: 'Symbols',
  },
  {
    d: 'wheel of dharma',
    e: '☸️',
    c: 'Symbols',
  },
  {
    d: 'yin yang',
    e: '☯️',
    c: 'Symbols',
  },
  {
    d: 'latin cross',
    e: '✝️',
    c: 'Symbols',
  },
  {
    d: 'orthodox cross',
    e: '☦️',
    c: 'Symbols',
  },
  {
    d: 'star and crescent',
    e: '☪️',
    c: 'Symbols',
  },
  {
    d: 'peace symbol',
    e: '☮️',
    c: 'Symbols',
  },
  {
    d: 'menorah',
    e: '🕎',
    c: 'Symbols',
  },
  {
    d: 'dotted six-pointed star',
    e: '🔯',
    c: 'Symbols',
  },
  {
    d: 'Aries',
    e: '♈',
    c: 'Symbols',
  },
  {
    d: 'Taurus',
    e: '♉',
    c: 'Symbols',
  },
  {
    d: 'Gemini',
    e: '♊',
    c: 'Symbols',
  },
  {
    d: 'Cancer',
    e: '♋',
    c: 'Symbols',
  },
  {
    d: 'Leo',
    e: '♌',
    c: 'Symbols',
  },
  {
    d: 'Virgo',
    e: '♍',
    c: 'Symbols',
  },
  {
    d: 'Libra',
    e: '♎',
    c: 'Symbols',
  },
  {
    d: 'Scorpio',
    e: '♏',
    c: 'Symbols',
  },
  {
    d: 'Sagittarius',
    e: '♐',
    c: 'Symbols',
  },
  {
    d: 'Capricorn',
    e: '♑',
    c: 'Symbols',
  },
  {
    d: 'Aquarius',
    e: '♒',
    c: 'Symbols',
  },
  {
    d: 'Pisces',
    e: '♓',
    c: 'Symbols',
  },
  {
    d: 'Ophiuchus',
    e: '⛎',
    c: 'Symbols',
  },
  {
    d: 'shuffle tracks button',
    e: '🔀',
    c: 'Symbols',
  },
  {
    d: 'repeat button',
    e: '🔁',
    c: 'Symbols',
  },
  {
    d: 'repeat single button',
    e: '🔂',
    c: 'Symbols',
  },
  {
    d: 'play button',
    e: '▶️',
    c: 'Symbols',
  },
  {
    d: 'fast-forward button',
    e: '⏩',
    c: 'Symbols',
  },
  {
    d: 'next track button',
    e: '⏭️',
    c: 'Symbols',
  },
  {
    d: 'play or pause button',
    e: '⏯️',
    c: 'Symbols',
  },
  {
    d: 'reverse button',
    e: '◀️',
    c: 'Symbols',
  },
  {
    d: 'fast reverse button',
    e: '⏪',
    c: 'Symbols',
  },
  {
    d: 'last track button',
    e: '⏮️',
    c: 'Symbols',
  },
  {
    d: 'upwards button',
    e: '🔼',
    c: 'Symbols',
  },
  {
    d: 'fast up button',
    e: '⏫',
    c: 'Symbols',
  },
  {
    d: 'downwards button',
    e: '🔽',
    c: 'Symbols',
  },
  {
    d: 'fast down button',
    e: '⏬',
    c: 'Symbols',
  },
  {
    d: 'pause button',
    e: '⏸️',
    c: 'Symbols',
  },
  {
    d: 'stop button',
    e: '⏹️',
    c: 'Symbols',
  },
  {
    d: 'record button',
    e: '⏺️',
    c: 'Symbols',
  },
  {
    d: 'eject button',
    e: '⏏️',
    c: 'Symbols',
  },
  {
    d: 'cinema',
    e: '🎦',
    c: 'Symbols',
  },
  {
    d: 'dim button',
    e: '🔅',
    c: 'Symbols',
  },
  {
    d: 'bright button',
    e: '🔆',
    c: 'Symbols',
  },
  {
    d: 'antenna bars',
    e: '📶',
    c: 'Symbols',
  },
  {
    d: 'vibration mode',
    e: '📳',
    c: 'Symbols',
  },
  {
    d: 'mobile phone off',
    e: '📴',
    c: 'Symbols',
  },
  {
    d: 'female sign',
    e: '♀️',
    c: 'Symbols',
  },
  {
    d: 'male sign',
    e: '♂️',
    c: 'Symbols',
  },
  {
    d: 'transgender symbol',
    e: '⚧️',
    c: 'Symbols',
  },
  {
    d: 'multiply',
    e: '✖️',
    c: 'Symbols',
  },
  {
    d: 'plus',
    e: '➕',
    c: 'Symbols',
  },
  {
    d: 'minus',
    e: '➖',
    c: 'Symbols',
  },
  {
    d: 'divide',
    e: '➗',
    c: 'Symbols',
  },
  {
    d: 'infinity',
    e: '♾️',
    c: 'Symbols',
  },
  {
    d: 'double exclamation mark',
    e: '‼️',
    c: 'Symbols',
  },
  {
    d: 'exclamation question mark',
    e: '⁉️',
    c: 'Symbols',
  },
  {
    d: 'red question mark',
    e: '❓',
    c: 'Symbols',
  },
  {
    d: 'white question mark',
    e: '❔',
    c: 'Symbols',
  },
  {
    d: 'white exclamation mark',
    e: '❕',
    c: 'Symbols',
  },
  {
    d: 'red exclamation mark',
    e: '❗',
    c: 'Symbols',
  },
  {
    d: 'wavy dash',
    e: '〰️',
    c: 'Symbols',
  },
  {
    d: 'currency exchange',
    e: '💱',
    c: 'Symbols',
  },
  {
    d: 'heavy dollar sign',
    e: '💲',
    c: 'Symbols',
  },
  {
    d: 'medical symbol',
    e: '⚕️',
    c: 'Symbols',
  },
  {
    d: 'recycling symbol',
    e: '♻️',
    c: 'Symbols',
  },
  {
    d: 'fleur-de-lis',
    e: '⚜️',
    c: 'Symbols',
  },
  {
    d: 'trident emblem',
    e: '🔱',
    c: 'Symbols',
  },
  {
    d: 'name badge',
    e: '📛',
    c: 'Symbols',
  },
  {
    d: 'Japanese symbol for beginner',
    e: '🔰',
    c: 'Symbols',
  },
  {
    d: 'hollow red circle',
    e: '⭕',
    c: 'Symbols',
  },
  {
    d: 'check mark button',
    e: '✅',
    c: 'Symbols',
  },
  {
    d: 'check box with check',
    e: '☑️',
    c: 'Symbols',
  },
  {
    d: 'check mark',
    e: '✔️',
    c: 'Symbols',
  },
  {
    d: 'cross mark',
    e: '❌',
    c: 'Symbols',
  },
  {
    d: 'cross mark button',
    e: '❎',
    c: 'Symbols',
  },
  {
    d: 'curly loop',
    e: '➰',
    c: 'Symbols',
  },
  {
    d: 'double curly loop',
    e: '➿',
    c: 'Symbols',
  },
  {
    d: 'part alternation mark',
    e: '〽️',
    c: 'Symbols',
  },
  {
    d: 'eight-spoked asterisk',
    e: '✳️',
    c: 'Symbols',
  },
  {
    d: 'eight-pointed star',
    e: '✴️',
    c: 'Symbols',
  },
  {
    d: 'sparkle',
    e: '❇️',
    c: 'Symbols',
  },
  {
    d: 'copyright',
    e: '©️',
    c: 'Symbols',
  },
  {
    d: 'registered',
    e: '®️',
    c: 'Symbols',
  },
  {
    d: 'trade mark',
    e: '™️',
    c: 'Symbols',
  },
  {
    d: 'keycap: #',
    e: '#️⃣',
    c: 'Symbols',
  },
  {
    d: 'keycap: *',
    e: '*️⃣',
    c: 'Symbols',
  },
  {
    d: 'keycap: 0',
    e: '0️⃣',
    c: 'Symbols',
  },
  {
    d: 'keycap: 1',
    e: '1️⃣',
    c: 'Symbols',
  },
  {
    d: 'keycap: 2',
    e: '2️⃣',
    c: 'Symbols',
  },
  {
    d: 'keycap: 3',
    e: '3️⃣',
    c: 'Symbols',
  },
  {
    d: 'keycap: 4',
    e: '4️⃣',
    c: 'Symbols',
  },
  {
    d: 'keycap: 5',
    e: '5️⃣',
    c: 'Symbols',
  },
  {
    d: 'keycap: 6',
    e: '6️⃣',
    c: 'Symbols',
  },
  {
    d: 'keycap: 7',
    e: '7️⃣',
    c: 'Symbols',
  },
  {
    d: 'keycap: 8',
    e: '8️⃣',
    c: 'Symbols',
  },
  {
    d: 'keycap: 9',
    e: '9️⃣',
    c: 'Symbols',
  },
  {
    d: 'keycap: 10',
    e: '🔟',
    c: 'Symbols',
  },
  {
    d: 'input latin uppercase',
    e: '🔠',
    c: 'Symbols',
  },
  {
    d: 'input latin lowercase',
    e: '🔡',
    c: 'Symbols',
  },
  {
    d: 'input numbers',
    e: '🔢',
    c: 'Symbols',
  },
  {
    d: 'input symbols',
    e: '🔣',
    c: 'Symbols',
  },
  {
    d: 'input latin letters',
    e: '🔤',
    c: 'Symbols',
  },
  {
    d: 'A button (blood type)',
    e: '🅰️',
    c: 'Symbols',
  },
  {
    d: 'AB button (blood type)',
    e: '🆎',
    c: 'Symbols',
  },
  {
    d: 'B button (blood type)',
    e: '🅱️',
    c: 'Symbols',
  },
  {
    d: 'CL button',
    e: '🆑',
    c: 'Symbols',
  },
  {
    d: 'COOL button',
    e: '🆒',
    c: 'Symbols',
  },
  {
    d: 'FREE button',
    e: '🆓',
    c: 'Symbols',
  },
  {
    d: 'information',
    e: 'ℹ️',
    c: 'Symbols',
  },
  {
    d: 'ID button',
    e: '🆔',
    c: 'Symbols',
  },
  {
    d: 'circled M',
    e: 'Ⓜ️',
    c: 'Symbols',
  },
  {
    d: 'NEW button',
    e: '🆕',
    c: 'Symbols',
  },
  {
    d: 'NG button',
    e: '🆖',
    c: 'Symbols',
  },
  {
    d: 'O button (blood type)',
    e: '🅾️',
    c: 'Symbols',
  },
  {
    d: 'OK button',
    e: '🆗',
    c: 'Symbols',
  },
  {
    d: 'P button',
    e: '🅿️',
    c: 'Symbols',
  },
  {
    d: 'SOS button',
    e: '🆘',
    c: 'Symbols',
  },
  {
    d: 'UP! button',
    e: '🆙',
    c: 'Symbols',
  },
  {
    d: 'VS button',
    e: '🆚',
    c: 'Symbols',
  },
  {
    d: 'Japanese “here” button',
    e: '🈁',
    c: 'Symbols',
  },
  {
    d: 'Japanese “service charge” button',
    e: '🈂️',
    c: 'Symbols',
  },
  {
    d: 'Japanese “monthly amount” button',
    e: '🈷️',
    c: 'Symbols',
  },
  {
    d: 'Japanese “not free of charge” button',
    e: '🈶',
    c: 'Symbols',
  },
  {
    d: 'Japanese “reserved” button',
    e: '🈯',
    c: 'Symbols',
  },
  {
    d: 'Japanese “bargain” button',
    e: '🉐',
    c: 'Symbols',
  },
  {
    d: 'Japanese “discount” button',
    e: '🈹',
    c: 'Symbols',
  },
  {
    d: 'Japanese “free of charge” button',
    e: '🈚',
    c: 'Symbols',
  },
  {
    d: 'Japanese “prohibited” button',
    e: '🈲',
    c: 'Symbols',
  },
  {
    d: 'Japanese “acceptable” button',
    e: '🉑',
    c: 'Symbols',
  },
  {
    d: 'Japanese “application” button',
    e: '🈸',
    c: 'Symbols',
  },
  {
    d: 'Japanese “passing grade” button',
    e: '🈴',
    c: 'Symbols',
  },
  {
    d: 'Japanese “vacancy” button',
    e: '🈳',
    c: 'Symbols',
  },
  {
    d: 'Japanese “congratulations” button',
    e: '㊗️',
    c: 'Symbols',
  },
  {
    d: 'Japanese “secret” button',
    e: '㊙️',
    c: 'Symbols',
  },
  {
    d: 'Japanese “open for business” button',
    e: '🈺',
    c: 'Symbols',
  },
  {
    d: 'Japanese “no vacancy” button',
    e: '🈵',
    c: 'Symbols',
  },
  {
    d: 'red circle',
    e: '🔴',
    c: 'Symbols',
  },
  {
    d: 'orange circle',
    e: '🟠',
    c: 'Symbols',
  },
  {
    d: 'yellow circle',
    e: '🟡',
    c: 'Symbols',
  },
  {
    d: 'green circle',
    e: '🟢',
    c: 'Symbols',
  },
  {
    d: 'blue circle',
    e: '🔵',
    c: 'Symbols',
  },
  {
    d: 'purple circle',
    e: '🟣',
    c: 'Symbols',
  },
  {
    d: 'brown circle',
    e: '🟤',
    c: 'Symbols',
  },
  {
    d: 'black circle',
    e: '⚫',
    c: 'Symbols',
  },
  {
    d: 'white circle',
    e: '⚪',
    c: 'Symbols',
  },
  {
    d: 'red square',
    e: '🟥',
    c: 'Symbols',
  },
  {
    d: 'orange square',
    e: '🟧',
    c: 'Symbols',
  },
  {
    d: 'yellow square',
    e: '🟨',
    c: 'Symbols',
  },
  {
    d: 'green square',
    e: '🟩',
    c: 'Symbols',
  },
  {
    d: 'blue square',
    e: '🟦',
    c: 'Symbols',
  },
  {
    d: 'purple square',
    e: '🟪',
    c: 'Symbols',
  },
  {
    d: 'brown square',
    e: '🟫',
    c: 'Symbols',
  },
  {
    d: 'black large square',
    e: '⬛',
    c: 'Symbols',
  },
  {
    d: 'white large square',
    e: '⬜',
    c: 'Symbols',
  },
  {
    d: 'black medium square',
    e: '◼️',
    c: 'Symbols',
  },
  {
    d: 'white medium square',
    e: '◻️',
    c: 'Symbols',
  },
  {
    d: 'black medium-small square',
    e: '◾',
    c: 'Symbols',
  },
  {
    d: 'white medium-small square',
    e: '◽',
    c: 'Symbols',
  },
  {
    d: 'black small square',
    e: '▪️',
    c: 'Symbols',
  },
  {
    d: 'white small square',
    e: '▫️',
    c: 'Symbols',
  },
  {
    d: 'large orange diamond',
    e: '🔶',
    c: 'Symbols',
  },
  {
    d: 'large blue diamond',
    e: '🔷',
    c: 'Symbols',
  },
  {
    d: 'small orange diamond',
    e: '🔸',
    c: 'Symbols',
  },
  {
    d: 'small blue diamond',
    e: '🔹',
    c: 'Symbols',
  },
  {
    d: 'red triangle pointed up',
    e: '🔺',
    c: 'Symbols',
  },
  {
    d: 'red triangle pointed down',
    e: '🔻',
    c: 'Symbols',
  },
  {
    d: 'diamond with a dot',
    e: '💠',
    c: 'Symbols',
  },
  {
    d: 'radio button',
    e: '🔘',
    c: 'Symbols',
  },
  {
    d: 'white square button',
    e: '🔳',
    c: 'Symbols',
  },
  {
    d: 'black square button',
    e: '🔲',
    c: 'Symbols',
  },
  {
    d: 'chequered flag',
    e: '🏁',
    c: 'Flags',
  },
  {
    d: 'triangular flag',
    e: '🚩',
    c: 'Flags',
  },
  {
    d: 'crossed flags',
    e: '🎌',
    c: 'Flags',
  },
  {
    d: 'black flag',
    e: '🏴',
    c: 'Flags',
  },
  {
    d: 'white flag',
    e: '🏳️',
    c: 'Flags',
  },
  {
    d: 'rainbow flag',
    e: '🏳️‍🌈',
    c: 'Flags',
  },
  {
    d: 'transgender flag',
    e: '🏳️‍⚧️',
    c: 'Flags',
  },
  {
    d: 'pirate flag',
    e: '🏴‍☠️',
    c: 'Flags',
  },
  {
    d: 'flag: Ascension Island',
    e: '🇦🇨',
    c: 'Flags',
  },
  {
    d: 'flag: Andorra',
    e: '🇦🇩',
    c: 'Flags',
  },
  {
    d: 'flag: United Arab Emirates',
    e: '🇦🇪',
    c: 'Flags',
  },
  {
    d: 'flag: Afghanistan',
    e: '🇦🇫',
    c: 'Flags',
  },
  {
    d: 'flag: Antigua & Barbuda',
    e: '🇦🇬',
    c: 'Flags',
  },
  {
    d: 'flag: Anguilla',
    e: '🇦🇮',
    c: 'Flags',
  },
  {
    d: 'flag: Albania',
    e: '🇦🇱',
    c: 'Flags',
  },
  {
    d: 'flag: Armenia',
    e: '🇦🇲',
    c: 'Flags',
  },
  {
    d: 'flag: Angola',
    e: '🇦🇴',
    c: 'Flags',
  },
  {
    d: 'flag: Antarctica',
    e: '🇦🇶',
    c: 'Flags',
  },
  {
    d: 'flag: Argentina',
    e: '🇦🇷',
    c: 'Flags',
  },
  {
    d: 'flag: American Samoa',
    e: '🇦🇸',
    c: 'Flags',
  },
  {
    d: 'flag: Austria',
    e: '🇦🇹',
    c: 'Flags',
  },
  {
    d: 'flag: Australia',
    e: '🇦🇺',
    c: 'Flags',
  },
  {
    d: 'flag: Aruba',
    e: '🇦🇼',
    c: 'Flags',
  },
  {
    d: 'flag: Åland Islands',
    e: '🇦🇽',
    c: 'Flags',
  },
  {
    d: 'flag: Azerbaijan',
    e: '🇦🇿',
    c: 'Flags',
  },
  {
    d: 'flag: Bosnia & Herzegovina',
    e: '🇧🇦',
    c: 'Flags',
  },
  {
    d: 'flag: Barbados',
    e: '🇧🇧',
    c: 'Flags',
  },
  {
    d: 'flag: Bangladesh',
    e: '🇧🇩',
    c: 'Flags',
  },
  {
    d: 'flag: Belgium',
    e: '🇧🇪',
    c: 'Flags',
  },
  {
    d: 'flag: Burkina Faso',
    e: '🇧🇫',
    c: 'Flags',
  },
  {
    d: 'flag: Bulgaria',
    e: '🇧🇬',
    c: 'Flags',
  },
  {
    d: 'flag: Bahrain',
    e: '🇧🇭',
    c: 'Flags',
  },
  {
    d: 'flag: Burundi',
    e: '🇧🇮',
    c: 'Flags',
  },
  {
    d: 'flag: Benin',
    e: '🇧🇯',
    c: 'Flags',
  },
  {
    d: 'flag: St. Barthélemy',
    e: '🇧🇱',
    c: 'Flags',
  },
  {
    d: 'flag: Bermuda',
    e: '🇧🇲',
    c: 'Flags',
  },
  {
    d: 'flag: Brunei',
    e: '🇧🇳',
    c: 'Flags',
  },
  {
    d: 'flag: Bolivia',
    e: '🇧🇴',
    c: 'Flags',
  },
  {
    d: 'flag: Caribbean Netherlands',
    e: '🇧🇶',
    c: 'Flags',
  },
  {
    d: 'flag: Brazil',
    e: '🇧🇷',
    c: 'Flags',
  },
  {
    d: 'flag: Bahamas',
    e: '🇧🇸',
    c: 'Flags',
  },
  {
    d: 'flag: Bhutan',
    e: '🇧🇹',
    c: 'Flags',
  },
  {
    d: 'flag: Bouvet Island',
    e: '🇧🇻',
    c: 'Flags',
  },
  {
    d: 'flag: Botswana',
    e: '🇧🇼',
    c: 'Flags',
  },
  {
    d: 'flag: Belarus',
    e: '🇧🇾',
    c: 'Flags',
  },
  {
    d: 'flag: Belize',
    e: '🇧🇿',
    c: 'Flags',
  },
  {
    d: 'flag: Canada',
    e: '🇨🇦',
    c: 'Flags',
  },
  {
    d: 'flag: Cocos (Keeling) Islands',
    e: '🇨🇨',
    c: 'Flags',
  },
  {
    d: 'flag: Congo - Kinshasa',
    e: '🇨🇩',
    c: 'Flags',
  },
  {
    d: 'flag: Central African Republic',
    e: '🇨🇫',
    c: 'Flags',
  },
  {
    d: 'flag: Congo - Brazzaville',
    e: '🇨🇬',
    c: 'Flags',
  },
  {
    d: 'flag: Switzerland',
    e: '🇨🇭',
    c: 'Flags',
  },
  {
    d: 'flag: Côte d’Ivoire',
    e: '🇨🇮',
    c: 'Flags',
  },
  {
    d: 'flag: Cook Islands',
    e: '🇨🇰',
    c: 'Flags',
  },
  {
    d: 'flag: Chile',
    e: '🇨🇱',
    c: 'Flags',
  },
  {
    d: 'flag: Cameroon',
    e: '🇨🇲',
    c: 'Flags',
  },
  {
    d: 'flag: China',
    e: '🇨🇳',
    c: 'Flags',
  },
  {
    d: 'flag: Colombia',
    e: '🇨🇴',
    c: 'Flags',
  },
  {
    d: 'flag: Clipperton Island',
    e: '🇨🇵',
    c: 'Flags',
  },
  {
    d: 'flag: Costa Rica',
    e: '🇨🇷',
    c: 'Flags',
  },
  {
    d: 'flag: Cuba',
    e: '🇨🇺',
    c: 'Flags',
  },
  {
    d: 'flag: Cape Verde',
    e: '🇨🇻',
    c: 'Flags',
  },
  {
    d: 'flag: Curaçao',
    e: '🇨🇼',
    c: 'Flags',
  },
  {
    d: 'flag: Christmas Island',
    e: '🇨🇽',
    c: 'Flags',
  },
  {
    d: 'flag: Cyprus',
    e: '🇨🇾',
    c: 'Flags',
  },
  {
    d: 'flag: Czechia',
    e: '🇨🇿',
    c: 'Flags',
  },
  {
    d: 'flag: Germany',
    e: '🇩🇪',
    c: 'Flags',
  },
  {
    d: 'flag: Diego Garcia',
    e: '🇩🇬',
    c: 'Flags',
  },
  {
    d: 'flag: Djibouti',
    e: '🇩🇯',
    c: 'Flags',
  },
  {
    d: 'flag: Denmark',
    e: '🇩🇰',
    c: 'Flags',
  },
  {
    d: 'flag: Dominica',
    e: '🇩🇲',
    c: 'Flags',
  },
  {
    d: 'flag: Dominican Republic',
    e: '🇩🇴',
    c: 'Flags',
  },
  {
    d: 'flag: Algeria',
    e: '🇩🇿',
    c: 'Flags',
  },
  {
    d: 'flag: Ceuta & Melilla',
    e: '🇪🇦',
    c: 'Flags',
  },
  {
    d: 'flag: Ecuador',
    e: '🇪🇨',
    c: 'Flags',
  },
  {
    d: 'flag: Estonia',
    e: '🇪🇪',
    c: 'Flags',
  },
  {
    d: 'flag: Egypt',
    e: '🇪🇬',
    c: 'Flags',
  },
  {
    d: 'flag: Western Sahara',
    e: '🇪🇭',
    c: 'Flags',
  },
  {
    d: 'flag: Eritrea',
    e: '🇪🇷',
    c: 'Flags',
  },
  {
    d: 'flag: Spain',
    e: '🇪🇸',
    c: 'Flags',
  },
  {
    d: 'flag: Ethiopia',
    e: '🇪🇹',
    c: 'Flags',
  },
  {
    d: 'flag: European Union',
    e: '🇪🇺',
    c: 'Flags',
  },
  {
    d: 'flag: Finland',
    e: '🇫🇮',
    c: 'Flags',
  },
  {
    d: 'flag: Fiji',
    e: '🇫🇯',
    c: 'Flags',
  },
  {
    d: 'flag: Falkland Islands',
    e: '🇫🇰',
    c: 'Flags',
  },
  {
    d: 'flag: Micronesia',
    e: '🇫🇲',
    c: 'Flags',
  },
  {
    d: 'flag: Faroe Islands',
    e: '🇫🇴',
    c: 'Flags',
  },
  {
    d: 'flag: France',
    e: '🇫🇷',
    c: 'Flags',
  },
  {
    d: 'flag: Gabon',
    e: '🇬🇦',
    c: 'Flags',
  },
  {
    d: 'flag: United Kingdom',
    e: '🇬🇧',
    c: 'Flags',
  },
  {
    d: 'flag: Grenada',
    e: '🇬🇩',
    c: 'Flags',
  },
  {
    d: 'flag: Georgia',
    e: '🇬🇪',
    c: 'Flags',
  },
  {
    d: 'flag: French Guiana',
    e: '🇬🇫',
    c: 'Flags',
  },
  {
    d: 'flag: Guernsey',
    e: '🇬🇬',
    c: 'Flags',
  },
  {
    d: 'flag: Ghana',
    e: '🇬🇭',
    c: 'Flags',
  },
  {
    d: 'flag: Gibraltar',
    e: '🇬🇮',
    c: 'Flags',
  },
  {
    d: 'flag: Greenland',
    e: '🇬🇱',
    c: 'Flags',
  },
  {
    d: 'flag: Gambia',
    e: '🇬🇲',
    c: 'Flags',
  },
  {
    d: 'flag: Guinea',
    e: '🇬🇳',
    c: 'Flags',
  },
  {
    d: 'flag: Guadeloupe',
    e: '🇬🇵',
    c: 'Flags',
  },
  {
    d: 'flag: Equatorial Guinea',
    e: '🇬🇶',
    c: 'Flags',
  },
  {
    d: 'flag: Greece',
    e: '🇬🇷',
    c: 'Flags',
  },
  {
    d: 'flag: South Georgia & South Sandwich Islands',
    e: '🇬🇸',
    c: 'Flags',
  },
  {
    d: 'flag: Guatemala',
    e: '🇬🇹',
    c: 'Flags',
  },
  {
    d: 'flag: Guam',
    e: '🇬🇺',
    c: 'Flags',
  },
  {
    d: 'flag: Guinea-Bissau',
    e: '🇬🇼',
    c: 'Flags',
  },
  {
    d: 'flag: Guyana',
    e: '🇬🇾',
    c: 'Flags',
  },
  {
    d: 'flag: Hong Kong SAR China',
    e: '🇭🇰',
    c: 'Flags',
  },
  {
    d: 'flag: Heard & McDonald Islands',
    e: '🇭🇲',
    c: 'Flags',
  },
  {
    d: 'flag: Honduras',
    e: '🇭🇳',
    c: 'Flags',
  },
  {
    d: 'flag: Croatia',
    e: '🇭🇷',
    c: 'Flags',
  },
  {
    d: 'flag: Haiti',
    e: '🇭🇹',
    c: 'Flags',
  },
  {
    d: 'flag: Hungary',
    e: '🇭🇺',
    c: 'Flags',
  },
  {
    d: 'flag: Canary Islands',
    e: '🇮🇨',
    c: 'Flags',
  },
  {
    d: 'flag: Indonesia',
    e: '🇮🇩',
    c: 'Flags',
  },
  {
    d: 'flag: Ireland',
    e: '🇮🇪',
    c: 'Flags',
  },
  {
    d: 'flag: Israel',
    e: '🇮🇱',
    c: 'Flags',
  },
  {
    d: 'flag: Isle of Man',
    e: '🇮🇲',
    c: 'Flags',
  },
  {
    d: 'flag: India',
    e: '🇮🇳',
    c: 'Flags',
  },
  {
    d: 'flag: British Indian Ocean Territory',
    e: '🇮🇴',
    c: 'Flags',
  },
  {
    d: 'flag: Iraq',
    e: '🇮🇶',
    c: 'Flags',
  },
  {
    d: 'flag: Iran',
    e: '🇮🇷',
    c: 'Flags',
  },
  {
    d: 'flag: Iceland',
    e: '🇮🇸',
    c: 'Flags',
  },
  {
    d: 'flag: Italy',
    e: '🇮🇹',
    c: 'Flags',
  },
  {
    d: 'flag: Jersey',
    e: '🇯🇪',
    c: 'Flags',
  },
  {
    d: 'flag: Jamaica',
    e: '🇯🇲',
    c: 'Flags',
  },
  {
    d: 'flag: Jordan',
    e: '🇯🇴',
    c: 'Flags',
  },
  {
    d: 'flag: Japan',
    e: '🇯🇵',
    c: 'Flags',
  },
  {
    d: 'flag: Kenya',
    e: '🇰🇪',
    c: 'Flags',
  },
  {
    d: 'flag: Kyrgyzstan',
    e: '🇰🇬',
    c: 'Flags',
  },
  {
    d: 'flag: Cambodia',
    e: '🇰🇭',
    c: 'Flags',
  },
  {
    d: 'flag: Kiribati',
    e: '🇰🇮',
    c: 'Flags',
  },
  {
    d: 'flag: Comoros',
    e: '🇰🇲',
    c: 'Flags',
  },
  {
    d: 'flag: St. Kitts & Nevis',
    e: '🇰🇳',
    c: 'Flags',
  },
  {
    d: 'flag: North Korea',
    e: '🇰🇵',
    c: 'Flags',
  },
  {
    d: 'flag: South Korea',
    e: '🇰🇷',
    c: 'Flags',
  },
  {
    d: 'flag: Kuwait',
    e: '🇰🇼',
    c: 'Flags',
  },
  {
    d: 'flag: Cayman Islands',
    e: '🇰🇾',
    c: 'Flags',
  },
  {
    d: 'flag: Kazakhstan',
    e: '🇰🇿',
    c: 'Flags',
  },
  {
    d: 'flag: Laos',
    e: '🇱🇦',
    c: 'Flags',
  },
  {
    d: 'flag: Lebanon',
    e: '🇱🇧',
    c: 'Flags',
  },
  {
    d: 'flag: St. Lucia',
    e: '🇱🇨',
    c: 'Flags',
  },
  {
    d: 'flag: Liechtenstein',
    e: '🇱🇮',
    c: 'Flags',
  },
  {
    d: 'flag: Sri Lanka',
    e: '🇱🇰',
    c: 'Flags',
  },
  {
    d: 'flag: Liberia',
    e: '🇱🇷',
    c: 'Flags',
  },
  {
    d: 'flag: Lesotho',
    e: '🇱🇸',
    c: 'Flags',
  },
  {
    d: 'flag: Lithuania',
    e: '🇱🇹',
    c: 'Flags',
  },
  {
    d: 'flag: Luxembourg',
    e: '🇱🇺',
    c: 'Flags',
  },
  {
    d: 'flag: Latvia',
    e: '🇱🇻',
    c: 'Flags',
  },
  {
    d: 'flag: Libya',
    e: '🇱🇾',
    c: 'Flags',
  },
  {
    d: 'flag: Morocco',
    e: '🇲🇦',
    c: 'Flags',
  },
  {
    d: 'flag: Monaco',
    e: '🇲🇨',
    c: 'Flags',
  },
  {
    d: 'flag: Moldova',
    e: '🇲🇩',
    c: 'Flags',
  },
  {
    d: 'flag: Montenegro',
    e: '🇲🇪',
    c: 'Flags',
  },
  {
    d: 'flag: St. Martin',
    e: '🇲🇫',
    c: 'Flags',
  },
  {
    d: 'flag: Madagascar',
    e: '🇲🇬',
    c: 'Flags',
  },
  {
    d: 'flag: Marshall Islands',
    e: '🇲🇭',
    c: 'Flags',
  },
  {
    d: 'flag: North Macedonia',
    e: '🇲🇰',
    c: 'Flags',
  },
  {
    d: 'flag: Mali',
    e: '🇲🇱',
    c: 'Flags',
  },
  {
    d: 'flag: Myanmar (Burma)',
    e: '🇲🇲',
    c: 'Flags',
  },
  {
    d: 'flag: Mongolia',
    e: '🇲🇳',
    c: 'Flags',
  },
  {
    d: 'flag: Macao SAR China',
    e: '🇲🇴',
    c: 'Flags',
  },
  {
    d: 'flag: Northern Mariana Islands',
    e: '🇲🇵',
    c: 'Flags',
  },
  {
    d: 'flag: Martinique',
    e: '🇲🇶',
    c: 'Flags',
  },
  {
    d: 'flag: Mauritania',
    e: '🇲🇷',
    c: 'Flags',
  },
  {
    d: 'flag: Montserrat',
    e: '🇲🇸',
    c: 'Flags',
  },
  {
    d: 'flag: Malta',
    e: '🇲🇹',
    c: 'Flags',
  },
  {
    d: 'flag: Mauritius',
    e: '🇲🇺',
    c: 'Flags',
  },
  {
    d: 'flag: Maldives',
    e: '🇲🇻',
    c: 'Flags',
  },
  {
    d: 'flag: Malawi',
    e: '🇲🇼',
    c: 'Flags',
  },
  {
    d: 'flag: Mexico',
    e: '🇲🇽',
    c: 'Flags',
  },
  {
    d: 'flag: Malaysia',
    e: '🇲🇾',
    c: 'Flags',
  },
  {
    d: 'flag: Mozambique',
    e: '🇲🇿',
    c: 'Flags',
  },
  {
    d: 'flag: Namibia',
    e: '🇳🇦',
    c: 'Flags',
  },
  {
    d: 'flag: New Caledonia',
    e: '🇳🇨',
    c: 'Flags',
  },
  {
    d: 'flag: Niger',
    e: '🇳🇪',
    c: 'Flags',
  },
  {
    d: 'flag: Norfolk Island',
    e: '🇳🇫',
    c: 'Flags',
  },
  {
    d: 'flag: Nigeria',
    e: '🇳🇬',
    c: 'Flags',
  },
  {
    d: 'flag: Nicaragua',
    e: '🇳🇮',
    c: 'Flags',
  },
  {
    d: 'flag: Netherlands',
    e: '🇳🇱',
    c: 'Flags',
  },
  {
    d: 'flag: Norway',
    e: '🇳🇴',
    c: 'Flags',
  },
  {
    d: 'flag: Nepal',
    e: '🇳🇵',
    c: 'Flags',
  },
  {
    d: 'flag: Nauru',
    e: '🇳🇷',
    c: 'Flags',
  },
  {
    d: 'flag: Niue',
    e: '🇳🇺',
    c: 'Flags',
  },
  {
    d: 'flag: New Zealand',
    e: '🇳🇿',
    c: 'Flags',
  },
  {
    d: 'flag: Oman',
    e: '🇴🇲',
    c: 'Flags',
  },
  {
    d: 'flag: Panama',
    e: '🇵🇦',
    c: 'Flags',
  },
  {
    d: 'flag: Peru',
    e: '🇵🇪',
    c: 'Flags',
  },
  {
    d: 'flag: French Polynesia',
    e: '🇵🇫',
    c: 'Flags',
  },
  {
    d: 'flag: Papua New Guinea',
    e: '🇵🇬',
    c: 'Flags',
  },
  {
    d: 'flag: Philippines',
    e: '🇵🇭',
    c: 'Flags',
  },
  {
    d: 'flag: Pakistan',
    e: '🇵🇰',
    c: 'Flags',
  },
  {
    d: 'flag: Poland',
    e: '🇵🇱',
    c: 'Flags',
  },
  {
    d: 'flag: St. Pierre & Miquelon',
    e: '🇵🇲',
    c: 'Flags',
  },
  {
    d: 'flag: Pitcairn Islands',
    e: '🇵🇳',
    c: 'Flags',
  },
  {
    d: 'flag: Puerto Rico',
    e: '🇵🇷',
    c: 'Flags',
  },
  {
    d: 'flag: Palestinian Territories',
    e: '🇵🇸',
    c: 'Flags',
  },
  {
    d: 'flag: Portugal',
    e: '🇵🇹',
    c: 'Flags',
  },
  {
    d: 'flag: Palau',
    e: '🇵🇼',
    c: 'Flags',
  },
  {
    d: 'flag: Paraguay',
    e: '🇵🇾',
    c: 'Flags',
  },
  {
    d: 'flag: Qatar',
    e: '🇶🇦',
    c: 'Flags',
  },
  {
    d: 'flag: Réunion',
    e: '🇷🇪',
    c: 'Flags',
  },
  {
    d: 'flag: Romania',
    e: '🇷🇴',
    c: 'Flags',
  },
  {
    d: 'flag: Serbia',
    e: '🇷🇸',
    c: 'Flags',
  },
  {
    d: 'flag: Russia',
    e: '🇷🇺',
    c: 'Flags',
  },
  {
    d: 'flag: Rwanda',
    e: '🇷🇼',
    c: 'Flags',
  },
  {
    d: 'flag: Saudi Arabia',
    e: '🇸🇦',
    c: 'Flags',
  },
  {
    d: 'flag: Solomon Islands',
    e: '🇸🇧',
    c: 'Flags',
  },
  {
    d: 'flag: Seychelles',
    e: '🇸🇨',
    c: 'Flags',
  },
  {
    d: 'flag: Sudan',
    e: '🇸🇩',
    c: 'Flags',
  },
  {
    d: 'flag: Sweden',
    e: '🇸🇪',
    c: 'Flags',
  },
  {
    d: 'flag: Singapore',
    e: '🇸🇬',
    c: 'Flags',
  },
  {
    d: 'flag: St. Helena',
    e: '🇸🇭',
    c: 'Flags',
  },
  {
    d: 'flag: Slovenia',
    e: '🇸🇮',
    c: 'Flags',
  },
  {
    d: 'flag: Svalbard & Jan Mayen',
    e: '🇸🇯',
    c: 'Flags',
  },
  {
    d: 'flag: Slovakia',
    e: '🇸🇰',
    c: 'Flags',
  },
  {
    d: 'flag: Sierra Leone',
    e: '🇸🇱',
    c: 'Flags',
  },
  {
    d: 'flag: San Marino',
    e: '🇸🇲',
    c: 'Flags',
  },
  {
    d: 'flag: Senegal',
    e: '🇸🇳',
    c: 'Flags',
  },
  {
    d: 'flag: Somalia',
    e: '🇸🇴',
    c: 'Flags',
  },
  {
    d: 'flag: Suriname',
    e: '🇸🇷',
    c: 'Flags',
  },
  {
    d: 'flag: South Sudan',
    e: '🇸🇸',
    c: 'Flags',
  },
  {
    d: 'flag: São Tomé & Príncipe',
    e: '🇸🇹',
    c: 'Flags',
  },
  {
    d: 'flag: El Salvador',
    e: '🇸🇻',
    c: 'Flags',
  },
  {
    d: 'flag: Sint Maarten',
    e: '🇸🇽',
    c: 'Flags',
  },
  {
    d: 'flag: Syria',
    e: '🇸🇾',
    c: 'Flags',
  },
  {
    d: 'flag: Eswatini',
    e: '🇸🇿',
    c: 'Flags',
  },
  {
    d: 'flag: Tristan da Cunha',
    e: '🇹🇦',
    c: 'Flags',
  },
  {
    d: 'flag: Turks & Caicos Islands',
    e: '🇹🇨',
    c: 'Flags',
  },
  {
    d: 'flag: Chad',
    e: '🇹🇩',
    c: 'Flags',
  },
  {
    d: 'flag: French Southern Territories',
    e: '🇹🇫',
    c: 'Flags',
  },
  {
    d: 'flag: Togo',
    e: '🇹🇬',
    c: 'Flags',
  },
  {
    d: 'flag: Thailand',
    e: '🇹🇭',
    c: 'Flags',
  },
  {
    d: 'flag: Tajikistan',
    e: '🇹🇯',
    c: 'Flags',
  },
  {
    d: 'flag: Tokelau',
    e: '🇹🇰',
    c: 'Flags',
  },
  {
    d: 'flag: Timor-Leste',
    e: '🇹🇱',
    c: 'Flags',
  },
  {
    d: 'flag: Turkmenistan',
    e: '🇹🇲',
    c: 'Flags',
  },
  {
    d: 'flag: Tunisia',
    e: '🇹🇳',
    c: 'Flags',
  },
  {
    d: 'flag: Tonga',
    e: '🇹🇴',
    c: 'Flags',
  },
  {
    d: 'flag: Turkey',
    e: '🇹🇷',
    c: 'Flags',
  },
  {
    d: 'flag: Trinidad & Tobago',
    e: '🇹🇹',
    c: 'Flags',
  },
  {
    d: 'flag: Tuvalu',
    e: '🇹🇻',
    c: 'Flags',
  },
  {
    d: 'flag: Taiwan',
    e: '🇹🇼',
    c: 'Flags',
  },
  {
    d: 'flag: Tanzania',
    e: '🇹🇿',
    c: 'Flags',
  },
  {
    d: 'flag: Ukraine',
    e: '🇺🇦',
    c: 'Flags',
  },
  {
    d: 'flag: Uganda',
    e: '🇺🇬',
    c: 'Flags',
  },
  {
    d: 'flag: U.S. Outlying Islands',
    e: '🇺🇲',
    c: 'Flags',
  },
  {
    d: 'flag: United Nations',
    e: '🇺🇳',
    c: 'Flags',
  },
  {
    d: 'flag: United States',
    e: '🇺🇸',
    c: 'Flags',
  },
  {
    d: 'flag: Uruguay',
    e: '🇺🇾',
    c: 'Flags',
  },
  {
    d: 'flag: Uzbekistan',
    e: '🇺🇿',
    c: 'Flags',
  },
  {
    d: 'flag: Vatican City',
    e: '🇻🇦',
    c: 'Flags',
  },
  {
    d: 'flag: St. Vincent & Grenadines',
    e: '🇻🇨',
    c: 'Flags',
  },
  {
    d: 'flag: Venezuela',
    e: '🇻🇪',
    c: 'Flags',
  },
  {
    d: 'flag: British Virgin Islands',
    e: '🇻🇬',
    c: 'Flags',
  },
  {
    d: 'flag: U.S. Virgin Islands',
    e: '🇻🇮',
    c: 'Flags',
  },
  {
    d: 'flag: Vietnam',
    e: '🇻🇳',
    c: 'Flags',
  },
  {
    d: 'flag: Vanuatu',
    e: '🇻🇺',
    c: 'Flags',
  },
  {
    d: 'flag: Wallis & Futuna',
    e: '🇼🇫',
    c: 'Flags',
  },
  {
    d: 'flag: Samoa',
    e: '🇼🇸',
    c: 'Flags',
  },
  {
    d: 'flag: Kosovo',
    e: '🇽🇰',
    c: 'Flags',
  },
  {
    d: 'flag: Yemen',
    e: '🇾🇪',
    c: 'Flags',
  },
  {
    d: 'flag: Mayotte',
    e: '🇾🇹',
    c: 'Flags',
  },
  {
    d: 'flag: South Africa',
    e: '🇿🇦',
    c: 'Flags',
  },
  {
    d: 'flag: Zambia',
    e: '🇿🇲',
    c: 'Flags',
  },
  {
    d: 'flag: Zimbabwe',
    e: '🇿🇼',
    c: 'Flags',
  },
  {
    d: 'flag: England',
    e: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
    c: 'Flags',
  },
  {
    d: 'flag: Scotland',
    e: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
    c: 'Flags',
  },
  {
    d: 'flag: Wales',
    e: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
    c: 'Flags',
  },
]

export const emojiMap = {
  'o/': '👋',
  '</3': '💔',
  '<3': '💗',
  '8-D': '😁',
  '8D': '😁',
  ':-D': '😁',
  ':-3': '😁',
  ':3': '😁',
  ':D': '😁',
  'B^D': '😁',
  'X-D': '😁',
  XD: '😁',
  'x-D': '😁',
  xD: '😁',
  ":')": '😂',
  ":'-)": '😂',
  ':-))': '😃',
  '8)': '😄',
  ':)': '😊',
  ':-)': '😄',
  ':]': '😄',
  ':^)': '😄',
  ':c)': '😄',
  ':o)': '😄',
  ':}': '😄',
  ':っ)': '😄',
  '0:)': '😇',
  '0:-)': '😇',
  '0:-3': '😇',
  '0:3': '😇',
  '0;^)': '😇',
  'O:-)': '😇',
  '3:)': '😈',
  '3:-)': '😈',
  '}:)': '😈',
  '}:-)': '😈',
  '*)': '😉',
  '*-)': '😉',
  ':-,': '😉',
  ';)': '😉',
  ';-)': '😉',
  ';-]': '😉',
  ';D': '😉',
  ';]': '😉',
  ';^)': '😉',
  ':-|': '😐',
  ':|': '😐',
  ':(': '😞',
  ':-(': '😒',
  ':-<': '😒',
  ':-[': '😒',
  ':-c': '😒',
  ':<': '😒',
  ':[': '😒',
  ':c': '😒',
  ':{': '😒',
  ':っC': '😒',
  '%)': '😖',
  '%-)': '😖',
  ':-P': '😜',
  ':-b': '😜',
  ':-p': '😜',
  ':-Þ': '😜',
  ':-þ': '😜',
  ':P': '😜',
  ':b': '😜',
  ':p': '😜',
  ':Þ': '😜',
  ':þ': '😜',
  ';(': '😢',
  'X-P': '😜',
  XP: '😜',
  'd:': '😜',
  'x-p': '😜',
  xp: '😜',
  ':-||': '😠',
  ':@': '😠',
  ':-.': '😡',
  ':-/': '😡',
  ':/': '😐',
  ':L': '😡',
  ':S': '😡',
  ':\\': '😡',
  ":'(": '😢',
  ":'-(": '😢',
  '^5': '😤',
  '^<_<': '😤',
  'o/\\o': '😤',
  '|-O': '😫',
  '|;-)': '😫',
  ':###..': '😰',
  ':#': '😅',
  ':-###..': '😰',
  "D-':": '😱',
  D8: '😱',
  'D:': '😱',
  'D:<': '😱',
  'D;': '😱',
  DX: '😱',
  'v.v': '😱',
  '8-0': '😲',
  ':-O': '😲',
  ':-o': '😲',
  ':O': '😲',
  ':o': '😲',
  'O-O': '😲',
  O_O: '😲',
  O_o: '😲',
  'o-o': '😲',
  o_O: '😲',
  o_o: '😲',
  ':$': '😳',
  '#-)': '😵',
  ':&': '😶',
  ':-#': '😶',
  ':-&': '😶',
  ':-X': '😶',
  ':X': '😶',
  ':-J': '😼',
  ':*': '😘',
  ':^*': '😽',
  ಠ_ಠ: '🙅',
  '*\\0/*': '🙆',
  '\\o/': '🙆',
  ':>': '😄',
  '>.<': '😡',
  '>:(': '😠',
  '>:)': '😈',
  '>:-)': '😈',
  '>:/': '😡',
  '>:O': '😲',
  '>:P': '😜',
  '>:[': '😒',
  '>:\\': '😡',
  '>;)': '😈',
  '>_>^': '😤',
  '^^': '😊',
  ':sweat': '😅',
  ':smile:': '😄',
  ':laughing:': '😆',
  ':blush:': '😊',
  ':smiley:': '😃',
  ':relaxed:': '☺️',
  ':smirk:': '😏',
  ':heart_eyes:': '😍',
  ':kissing_heart:': '😘',
  ':kissing_closed_eyes:': '😚',
  ':flushed:': '😳',
  ':relieved:': '😌',
  ':satisfied:': '😆',
  ':grin:': '😁',
  ':wink:': '😉',
  ':stuck_out_tongue_winking_eye:': '😜',
  ':stuck_out_tongue_closed_eyes:': '😝',
  ':grinning:': '😀',
  ':kissing:': '😗',
  ':kissing_smiling_eyes:': '😙',
  ':stuck_out_tongue:': '😛',
  ':sleeping:': '😴',
  ':worried:': '😟',
  ':frowning:': '😦',
  ':anguished:': '😧',
  ':open_mouth:': '😮',
  ':grimacing:': '😬',
  ':confused:': '😕',
  ':hushed:': '😯',
  ':expressionless:': '😑',
  ':unamused:': '😒',
  ':sweat_smile:': '😅',
  ':sweat:': '😓',
  ':disappointed_relieved:': '😥',
  ':weary:': '😩',
  ':pensive:': '😔',
  ':disappointed:': '😞',
  ':confounded:': '😖',
  ':fearful:': '😨',
  ':cold_sweat:': '😰',
  ':persevere:': '😣',
  ':cry:': '😢',
  ':sob:': '😭',
  ':joy:': '😂',
  ':astonished:': '😲',
  ':scream:': '😱',
  ':tired_face:': '😫',
  ':angry:': '😠',
  ':rage:': '😡',
  ':triumph:': '😤',
  ':sleepy:': '😪',
  ':yum:': '😋',
  ':mask:': '😷',
  ':sunglasses:': '😎',
  ':dizzy_face:': '😵',
  ':imp:': '👿',
  ':smiling_imp:': '😈',
  ':neutral_face:': '😐',
  ':no_mouth:': '😶',
  ':innocent:': '😇',
  ':alien:': '👽',
  ':yellow_heart:': '💛',
  ':blue_heart:': '💙',
  ':purple_heart:': '💜',
  ':heart:': '❤️',
  ':green_heart:': '💚',
  ':broken_heart:': '💔',
  ':heartbeat:': '💓',
  ':heartpulse:': '💗',
  ':two_hearts:': '💕',
  ':revolving_hearts:': '💞',
  ':cupid:': '💘',
  ':sparkling_heart:': '💖',
  ':sparkles:': '✨',
  ':star:': '⭐',
  ':star2:': '🌟',
  ':dizzy:': '💫',
  ':boom:': '💥',
  ':collision:': '💥',
  ':anger:': '💢',
  ':exclamation:': '❗',
  ':question:': '❓',
  ':grey_exclamation:': '❕',
  ':grey_question:': '❔',
  ':zzz:': '💤',
  ':dash:': '💨',
  ':sweat_drops:': '💦',
  ':notes:': '🎶',
  ':musical_note:': '🎵',
  ':fire:': '🔥',
  ':hankey:': '💩',
  ':poop:': '💩',
  ':shit:': '💩',
  ':+1:': '👍',
  ':thumbsup:': '👍',
  ':-1:': '👎',
  ':thumbsdown:': '👎',
  ':ok_hand:': '👌',
  ':punch:': '👊',
  ':facepunch:': '👊',
  ':fist:': '✊',
  ':v:': '✌️',
  ':wave:': '👋',
  ':hand:': '✋',
  ':raised_hand:': '✋',
  ':open_hands:': '👐',
  ':point_up:': '☝️',
  ':point_down:': '👇',
  ':point_left:': '👈',
  ':point_right:': '👉',
  ':raised_hands:': '🙌',
  ':pray:': '🙏',
  ':point_up_2:': '👆',
  ':clap:': '👏',
  ':muscle:': '💪',
  ':metal:': '🤘',
  ':fu:': '🖕',
  ':walking:': '🚶',
  ':runner:': '🏃',
  ':running:': '🏃',
  ':couple:': '👫',
  ':family:': '👪',
  ':two_men_holding_hands:': '👬',
  ':two_women_holding_hands:': '👭',
  ':dancer:': '💃',
  ':dancers:': '👯',
  ':no_good:': '🙅',
  ':information_desk_person:': '💁',
  ':raising_hand:': '🙋',
  ':bride_with_veil:': '👰',
  ':bow:': '🙇',
  ':couplekiss:': '💏',
  ':couple_with_heart:': '💑',
  ':massage:': '💆',
  ':haircut:': '💇',
  ':nail_care:': '💅',
  ':boy:': '👦',
  ':girl:': '👧',
  ':woman:': '👩',
  ':man:': '👨',
  ':baby:': '👶',
  ':older_woman:': '👵',
  ':older_man:': '👴',
  ':man_with_gua_pi_mao:': '👲',
  ':construction_worker:': '👷',
  ':cop:': '👮',
  ':angel:': '👼',
  ':princess:': '👸',
  ':smiley_cat:': '😺',
  ':smile_cat:': '😸',
  ':heart_eyes_cat:': '😻',
  ':kissing_cat:': '😽',
  ':smirk_cat:': '😼',
  ':scream_cat:': '🙀',
  ':crying_cat_face:': '😿',
  ':joy_cat:': '😹',
  ':pouting_cat:': '😾',
  ':japanese_ogre:': '👹',
  ':japanese_goblin:': '👺',
  ':see_no_evil:': '🙈',
  ':hear_no_evil:': '🙉',
  ':speak_no_evil:': '🙊',
  ':skull:': '💀',
  ':feet:': '🐾',
  ':lips:': '👄',
  ':kiss:': '💋',
  ':droplet:': '💧',
  ':ear:': '👂',
  ':eyes:': '👀',
  ':nose:': '👃',
  ':tongue:': '👅',
  ':love_letter:': '💌',
  ':bust_in_silhouette:': '👤',
  ':busts_in_silhouette:': '👥',
  ':speech_balloon:': '💬',
  ':thought_balloon:': '💭',
  ':sunny:': '☀️',
  ':umbrella:': '☔',
  ':cloud:': '☁️',
  ':snowflake:': '❄️',
  ':snowman:': '⛄',
  ':zap:': '⚡',
  ':cyclone:': '🌀',
  ':foggy:': '🌁',
  ':ocean:': '🌊',
  ':cat:': '🐱',
  ':dog:': '🐶',
  ':mouse:': '🐭',
  ':hamster:': '🐹',
  ':rabbit:': '🐰',
  ':wolf:': '🐺',
  ':frog:': '🐸',
  ':tiger:': '🐯',
  ':koala:': '🐨',
  ':bear:': '🐻',
  ':pig:': '🐷',
  ':pig_nose:': '🐽',
  ':cow:': '🐮',
  ':boar:': '🐗',
  ':monkey_face:': '🐵',
  ':monkey:': '🐒',
  ':horse:': '🐴',
  ':racehorse:': '🐎',
  ':camel:': '🐫',
  ':sheep:': '🐑',
  ':elephant:': '🐘',
  ':panda_face:': '🐼',
  ':snake:': '🐍',
  ':bird:': '🐦',
  ':baby_chick:': '🐤',
  ':hatched_chick:': '🐥',
  ':hatching_chick:': '🐣',
  ':chicken:': '🐔',
  ':penguin:': '🐧',
  ':turtle:': '🐢',
  ':bug:': '🐛',
  ':honeybee:': '🐝',
  ':ant:': '🐜',
  ':beetle:': '🐞',
  ':snail:': '🐌',
  ':octopus:': '🐙',
  ':tropical_fish:': '🐠',
  ':fish:': '🐟',
  ':whale:': '🐳',
  ':whale2:': '🐋',
  ':dolphin:': '🐬',
  ':cow2:': '🐄',
  ':ram:': '🐏',
  ':rat:': '🐀',
  ':water_buffalo:': '🐃',
  ':tiger2:': '🐅',
  ':rabbit2:': '🐇',
  ':dragon:': '🐉',
  ':goat:': '🐐',
  ':rooster:': '🐓',
  ':dog2:': '🐕',
  ':pig2:': '🐖',
  ':mouse2:': '🐁',
  ':ox:': '🐂',
  ':dragon_face:': '🐲',
  ':blowfish:': '🐡',
  ':crocodile:': '🐊',
  ':dromedary_camel:': '🐪',
  ':leopard:': '🐆',
  ':cat2:': '🐈',
  ':poodle:': '🐩',
  ':paw_prints:': '🐾',
  ':bouquet:': '💐',
  ':cherry_blossom:': '🌸',
  ':tulip:': '🌷',
  ':four_leaf_clover:': '🍀',
  ':rose:': '🌹',
  ':sunflower:': '🌻',
  ':hibiscus:': '🌺',
  ':maple_leaf:': '🍁',
  ':leaves:': '🍃',
  ':fallen_leaf:': '🍂',
  ':herb:': '🌿',
  ':mushroom:': '🍄',
  ':cactus:': '🌵',
  ':palm_tree:': '🌴',
  ':evergreen_tree:': '🌲',
  ':deciduous_tree:': '🌳',
  ':chestnut:': '🌰',
  ':seedling:': '🌱',
  ':blossom:': '🌼',
  ':ear_of_rice:': '🌾',
  ':shell:': '🐚',
  ':globe_with_meridians:': '🌐',
  ':sun_with_face:': '🌞',
  ':full_moon_with_face:': '🌝',
  ':new_moon_with_face:': '🌚',
  ':new_moon:': '🌑',
  ':waxing_crescent_moon:': '🌒',
  ':first_quarter_moon:': '🌓',
  ':waxing_gibbous_moon:': '🌔',
  ':full_moon:': '🌕',
  ':waning_gibbous_moon:': '🌖',
  ':last_quarter_moon:': '🌗',
  ':waning_crescent_moon:': '🌘',
  ':last_quarter_moon_with_face:': '🌜',
  ':first_quarter_moon_with_face:': '🌛',
  ':moon:': '🌔',
  ':earth_africa:': '🌍',
  ':earth_americas:': '🌎',
  ':earth_asia:': '🌏',
  ':volcano:': '🌋',
  ':milky_way:': '🌌',
  ':partly_sunny:': '⛅',
  ':bamboo:': '🎍',
  ':gift_heart:': '💝',
  ':dolls:': '🎎',
  ':school_satchel:': '🎒',
  ':mortar_board:': '🎓',
  ':flags:': '🎏',
  ':fireworks:': '🎆',
  ':sparkler:': '🎇',
  ':wind_chime:': '🎐',
  ':rice_scene:': '🎑',
  ':jack_o_lantern:': '🎃',
  ':ghost:': '👻',
  ':santa:': '🎅',
  ':christmas_tree:': '🎄',
  ':gift:': '🎁',
  ':bell:': '🔔',
  ':no_bell:': '🔕',
  ':tanabata_tree:': '🎋',
  ':tada:': '🎉',
  ':confetti_ball:': '🎊',
  ':balloon:': '🎈',
  ':crystal_ball:': '🔮',
  ':cd:': '💿',
  ':dvd:': '📀',
  ':floppy_disk:': '💾',
  ':camera:': '📷',
  ':video_camera:': '📹',
  ':movie_camera:': '🎥',
  ':computer:': '💻',
  ':tv:': '📺',
  ':iphone:': '📱',
  ':phone:': '☎️',
  ':telephone:': '☎️',
  ':telephone_receiver:': '📞',
  ':pager:': '📟',
  ':fax:': '📠',
  ':minidisc:': '💽',
  ':vhs:': '📼',
  ':sound:': '🔉',
  ':speaker:': '🔈',
  ':mute:': '🔇',
  ':loudspeaker:': '📢',
  ':mega:': '📣',
  ':hourglass:': '⌛',
  ':hourglass_flowing_sand:': '⏳',
  ':alarm_clock:': '⏰',
  ':watch:': '⌚',
  ':radio:': '📻',
  ':satellite:': '📡',
  ':loop:': '➿',
  ':mag:': '🔍',
  ':mag_right:': '🔎',
  ':unlock:': '🔓',
  ':lock:': '🔒',
  ':lock_with_ink_pen:': '🔏',
  ':closed_lock_with_key:': '🔐',
  ':key:': '🔑',
  ':bulb:': '💡',
  ':flashlight:': '🔦',
  ':high_brightness:': '🔆',
  ':low_brightness:': '🔅',
  ':electric_plug:': '🔌',
  ':battery:': '🔋',
  ':calling:': '📲',
  ':email:': '✉️',
  ':mailbox:': '📫',
  ':postbox:': '📮',
  ':bath:': '🛀',
  ':bathtub:': '🛁',
  ':shower:': '🚿',
  ':toilet:': '🚽',
  ':wrench:': '🔧',
  ':nut_and_bolt:': '🔩',
  ':hammer:': '🔨',
  ':seat:': '💺',
  ':moneybag:': '💰',
  ':yen:': '💴',
  ':dollar:': '💵',
  ':pound:': '💷',
  ':euro:': '💶',
  ':credit_card:': '💳',
  ':money_with_wings:': '💸',
  ':e-mail:': '📧',
  ':inbox_tray:': '📥',
  ':outbox_tray:': '📤',
  ':envelope:': '✉️',
  ':incoming_envelope:': '📨',
  ':postal_horn:': '📯',
  ':mailbox_closed:': '📪',
  ':mailbox_with_mail:': '📬',
  ':mailbox_with_no_mail:': '📭',
  ':package:': '📦',
  ':door:': '🚪',
  ':smoking:': '🚬',
  ':bomb:': '💣',
  ':gun:': '🔫',
  ':hocho:': '🔪',
  ':pill:': '💊',
  ':syringe:': '💉',
  ':page_facing_up:': '📄',
  ':page_with_curl:': '📃',
  ':bookmark_tabs:': '📑',
  ':bar_chart:': '📊',
  ':chart_with_upwards_trend:': '📈',
  ':chart_with_downwards_trend:': '📉',
  ':scroll:': '📜',
  ':clipboard:': '📋',
  ':calendar:': '📆',
  ':date:': '📅',
  ':card_index:': '📇',
  ':file_folder:': '📁',
  ':open_file_folder:': '📂',
  ':scissors:': '✂️',
  ':pushpin:': '📌',
  ':paperclip:': '📎',
  ':black_nib:': '✒️',
  ':pencil2:': '✏️',
  ':straight_ruler:': '📏',
  ':triangular_ruler:': '📐',
  ':closed_book:': '📕',
  ':green_book:': '📗',
  ':blue_book:': '📘',
  ':orange_book:': '📙',
  ':notebook:': '📓',
  ':notebook_with_decorative_cover:': '📔',
  ':ledger:': '📒',
  ':books:': '📚',
  ':bookmark:': '🔖',
  ':name_badge:': '📛',
  ':microscope:': '🔬',
  ':telescope:': '🔭',
  ':newspaper:': '📰',
  ':football:': '🏈',
  ':basketball:': '🏀',
  ':soccer:': '⚽',
  ':baseball:': '⚾',
  ':tennis:': '🎾',
  ':8ball:': '🎱',
  ':rugby_football:': '🏉',
  ':bowling:': '🎳',
  ':golf:': '⛳',
  ':mountain_bicyclist:': '🚵',
  ':bicyclist:': '🚴',
  ':horse_racing:': '🏇',
  ':snowboarder:': '🏂',
  ':swimmer:': '🏊',
  ':surfer:': '🏄',
  ':ski:': '🎿',
  ':spades:': '♠️',
  ':hearts:': '♥️',
  ':clubs:': '♣️',
  ':diamonds:': '♦️',
  ':gem:': '💎',
  ':ring:': '💍',
  ':trophy:': '🏆',
  ':musical_score:': '🎼',
  ':musical_keyboard:': '🎹',
  ':violin:': '🎻',
  ':space_invader:': '👾',
  ':video_game:': '🎮',
  ':black_joker:': '🃏',
  ':flower_playing_cards:': '🎴',
  ':game_die:': '🎲',
  ':dart:': '🎯',
  ':mahjong:': '🀄',
  ':clapper:': '🎬',
  ':memo:': '📝',
  ':pencil:': '📝',
  ':book:': '📖',
  ':art:': '🎨',
  ':microphone:': '🎤',
  ':headphones:': '🎧',
  ':trumpet:': '🎺',
  ':saxophone:': '🎷',
  ':guitar:': '🎸',
  ':shoe:': '👞',
  ':sandal:': '👡',
  ':high_heel:': '👠',
  ':lipstick:': '💄',
  ':boot:': '👢',
  ':shirt:': '👕',
  ':tshirt:': '👕',
  ':necktie:': '👔',
  ':womans_clothes:': '👚',
  ':dress:': '👗',
  ':running_shirt_with_sash:': '🎽',
  ':jeans:': '👖',
  ':kimono:': '👘',
  ':bikini:': '👙',
  ':ribbon:': '🎀',
  ':tophat:': '🎩',
  ':crown:': '👑',
  ':womans_hat:': '👒',
  ':mans_shoe:': '👞',
  ':closed_umbrella:': '🌂',
  ':briefcase:': '💼',
  ':handbag:': '👜',
  ':pouch:': '👝',
  ':purse:': '👛',
  ':eyeglasses:': '👓',
  ':fishing_pole_and_fish:': '🎣',
  ':coffee:': '☕',
  ':tea:': '🍵',
  ':sake:': '🍶',
  ':baby_bottle:': '🍼',
  ':beer:': '🍺',
  ':beers:': '🍻',
  ':cocktail:': '🍸',
  ':tropical_drink:': '🍹',
  ':wine_glass:': '🍷',
  ':fork_and_knife:': '🍴',
  ':pizza:': '🍕',
  ':hamburger:': '🍔',
  ':fries:': '🍟',
  ':poultry_leg:': '🍗',
  ':meat_on_bone:': '🍖',
  ':spaghetti:': '🍝',
  ':curry:': '🍛',
  ':fried_shrimp:': '🍤',
  ':bento:': '🍱',
  ':sushi:': '🍣',
  ':fish_cake:': '🍥',
  ':rice_ball:': '🍙',
  ':rice_cracker:': '🍘',
  ':rice:': '🍚',
  ':ramen:': '🍜',
  ':stew:': '🍲',
  ':oden:': '🍢',
  ':dango:': '🍡',
  ':egg:': '🥚',
  ':bread:': '🍞',
  ':doughnut:': '🍩',
  ':custard:': '🍮',
  ':icecream:': '🍦',
  ':ice_cream:': '🍨',
  ':shaved_ice:': '🍧',
  ':birthday:': '🎂',
  ':cake:': '🍰',
  ':cookie:': '🍪',
  ':chocolate_bar:': '🍫',
  ':candy:': '🍬',
  ':lollipop:': '🍭',
  ':honey_pot:': '🍯',
  ':apple:': '🍎',
  ':green_apple:': '🍏',
  ':tangerine:': '🍊',
  ':lemon:': '🍋',
  ':cherries:': '🍒',
  ':grapes:': '🍇',
  ':watermelon:': '🍉',
  ':strawberry:': '🍓',
  ':peach:': '🍑',
  ':melon:': '🍈',
  ':banana:': '🍌',
  ':pear:': '🍐',
  ':pineapple:': '🍍',
  ':sweet_potato:': '🍠',
  ':eggplant:': '🍆',
  ':tomato:': '🍅',
  ':corn:': '🌽',
  ':house:': '🏠',
  ':house_with_garden:': '🏡',
  ':school:': '🏫',
  ':office:': '🏢',
  ':post_office:': '🏣',
  ':hospital:': '🏥',
  ':bank:': '🏦',
  ':convenience_store:': '🏪',
  ':love_hotel:': '🏩',
  ':hotel:': '🏨',
  ':wedding:': '💒',
  ':church:': '⛪',
  ':department_store:': '🏬',
  ':european_post_office:': '🏤',
  ':city_sunrise:': '🌇',
  ':city_sunset:': '🌆',
  ':japanese_castle:': '🏯',
  ':european_castle:': '🏰',
  ':tent:': '⛺',
  ':factory:': '🏭',
  ':tokyo_tower:': '🗼',
  ':japan:': '🗾',
  ':mount_fuji:': '🗻',
  ':sunrise_over_mountains:': '🌄',
  ':sunrise:': '🌅',
  ':stars:': '🌠',
  ':statue_of_liberty:': '🗽',
  ':bridge_at_night:': '🌉',
  ':carousel_horse:': '🎠',
  ':rainbow:': '🌈',
  ':ferris_wheel:': '🎡',
  ':fountain:': '⛲',
  ':roller_coaster:': '🎢',
  ':ship:': '🚢',
  ':speedboat:': '🚤',
  ':boat:': '⛵',
  ':sailboat:': '⛵',
  ':rowboat:': '🚣',
  ':anchor:': '⚓',
  ':rocket:': '🚀',
  ':airplane:': '✈️',
  ':helicopter:': '🚁',
  ':steam_locomotive:': '🚂',
  ':tram:': '🚊',
  ':mountain_railway:': '🚞',
  ':bike:': '🚲',
  ':aerial_tramway:': '🚡',
  ':suspension_railway:': '🚟',
  ':mountain_cableway:': '🚠',
  ':tractor:': '🚜',
  ':blue_car:': '🚙',
  ':oncoming_automobile:': '🚘',
  ':car:': '🚗',
  ':red_car:': '🚗',
  ':taxi:': '🚕',
  ':oncoming_taxi:': '🚖',
  ':articulated_lorry:': '🚛',
  ':bus:': '🚌',
  ':oncoming_bus:': '🚍',
  ':rotating_light:': '🚨',
  ':police_car:': '🚓',
  ':oncoming_police_car:': '🚔',
  ':fire_engine:': '🚒',
  ':ambulance:': '🚑',
  ':minibus:': '🚐',
  ':truck:': '🚚',
  ':train:': '🚋',
  ':station:': '🚉',
  ':train2:': '🚆',
  ':bullettrain_front:': '🚅',
  ':bullettrain_side:': '🚄',
  ':light_rail:': '🚈',
  ':monorail:': '🚝',
  ':railway_car:': '🚃',
  ':trolleybus:': '🚎',
  ':ticket:': '🎫',
  ':fuelpump:': '⛽',
  ':vertical_traffic_light:': '🚦',
  ':traffic_light:': '🚥',
  ':warning:': '⚠️',
  ':construction:': '🚧',
  ':beginner:': '🔰',
  ':atm:': '🏧',
  ':slot_machine:': '🎰',
  ':busstop:': '🚏',
  ':barber:': '💈',
  ':hotsprings:': '♨️',
  ':checkered_flag:': '🏁',
  ':crossed_flags:': '🎌',
  ':izakaya_lantern:': '🏮',
  ':moyai:': '🗿',
  ':circus_tent:': '🎪',
  ':performing_arts:': '🎭',
  ':round_pushpin:': '📍',
  ':triangular_flag_on_post:': '🚩',
  ':jp:': '🇯🇵',
  ':kr:': '🇰🇷',
  ':cn:': '🇨🇳',
  ':us:': '🇺🇸',
  ':fr:': '🇫🇷',
  ':es:': '🇪🇸',
  ':it:': '🇮🇹',
  ':ru:': '🇷🇺',
  ':gb:': '🇬🇧',
  ':uk:': '🇬🇧',
  ':de:': '🇩🇪',
  ':one:': '1️⃣',
  ':two:': '2️⃣',
  ':three:': '3️⃣',
  ':four:': '4️⃣',
  ':five:': '5️⃣',
  ':six:': '6️⃣',
  ':seven:': '7️⃣',
  ':eight:': '8️⃣',
  ':nine:': '9️⃣',
  ':keycap_ten:': '🔟',
  ':1234:': '🔢',
  ':zero:': '0️⃣',
  ':hash:': '#️⃣',
  ':symbols:': '🔣',
  ':arrow_backward:': '◀️',
  ':arrow_down:': '⬇️',
  ':arrow_forward:': '▶️',
  ':arrow_left:': '⬅️',
  ':capital_abcd:': '🔠',
  ':abcd:': '🔡',
  ':abc:': '🔤',
  ':arrow_lower_left:': '↙️',
  ':arrow_lower_right:': '↘️',
  ':arrow_right:': '➡️',
  ':arrow_up:': '⬆️',
  ':arrow_upper_left:': '↖️',
  ':arrow_upper_right:': '↗️',
  ':arrow_double_down:': '⏬',
  ':arrow_double_up:': '⏫',
  ':arrow_down_small:': '🔽',
  ':arrow_heading_down:': '⤵️',
  ':arrow_heading_up:': '⤴️',
  ':leftwards_arrow_with_hook:': '↩️',
  ':arrow_right_hook:': '↪️',
  ':left_right_arrow:': '↔️',
  ':arrow_up_down:': '↕️',
  ':arrow_up_small:': '🔼',
  ':arrows_clockwise:': '🔃',
  ':arrows_counterclockwise:': '🔄',
  ':rewind:': '⏪',
  ':fast_forward:': '⏩',
  ':information_source:': 'ℹ️',
  ':ok:': '🆗',
  ':twisted_rightwards_arrows:': '🔀',
  ':repeat:': '🔁',
  ':repeat_one:': '🔂',
  ':new:': '🆕',
  ':top:': '🔝',
  ':up:': '🆙',
  ':cool:': '🆒',
  ':free:': '🆓',
  ':ng:': '🆖',
  ':cinema:': '🎦',
  ':koko:': '🈁',
  ':signal_strength:': '📶',
  ':u5272:': '🈹',
  ':u5408:': '🈴',
  ':u55b6:': '🈺',
  ':u6307:': '🈯',
  ':u6708:': '🈷️',
  ':u6709:': '🈶',
  ':u6e80:': '🈵',
  ':u7121:': '🈚',
  ':u7533:': '🈸',
  ':u7a7a:': '🈳',
  ':u7981:': '🈲',
  ':sa:': '🈂️',
  ':restroom:': '🚻',
  ':mens:': '🚹',
  ':womens:': '🚺',
  ':baby_symbol:': '🚼',
  ':no_smoking:': '🚭',
  ':parking:': '🅿️',
  ':wheelchair:': '♿',
  ':metro:': '🚇',
  ':baggage_claim:': '🛄',
  ':accept:': '🉑',
  ':wc:': '🚾',
  ':potable_water:': '🚰',
  ':put_litter_in_its_place:': '🚮',
  ':secret:': '㊙️',
  ':congratulations:': '㊗️',
  ':m:': 'Ⓜ️',
  ':passport_control:': '🛂',
  ':left_luggage:': '🛅',
  ':customs:': '🛃',
  ':ideograph_advantage:': '🉐',
  ':cl:': '🆑',
  ':sos:': '🆘',
  ':id:': '🆔',
  ':no_entry_sign:': '🚫',
  ':underage:': '🔞',
  ':no_mobile_phones:': '📵',
  ':do_not_litter:': '🚯',
  ':non-potable_water:': '🚱',
  ':no_bicycles:': '🚳',
  ':no_pedestrians:': '🚷',
  ':children_crossing:': '🚸',
  ':no_entry:': '⛔',
  ':eight_spoked_asterisk:': '✳️',
  ':sparkle:': '❇️',
  ':eight_pointed_black_star:': '✴️',
  ':heart_decoration:': '💟',
  ':vs:': '🆚',
  ':vibration_mode:': '📳',
  ':mobile_phone_off:': '📴',
  ':chart:': '💹',
  ':currency_exchange:': '💱',
  ':aries:': '♈',
  ':taurus:': '♉',
  ':gemini:': '♊',
  ':cancer:': '♋',
  ':leo:': '♌',
  ':virgo:': '♍',
  ':libra:': '♎',
  ':scorpius:': '♏',
  ':sagittarius:': '♐',
  ':capricorn:': '♑',
  ':aquarius:': '♒',
  ':pisces:': '♓',
  ':ophiuchus:': '⛎',
  ':six_pointed_star:': '🔯',
  ':negative_squared_cross_mark:': '❎',
  ':a:': '🅰️',
  ':b:': '🅱️',
  ':ab:': '🆎',
  ':o2:': '🅾️',
  ':diamond_shape_with_a_dot_inside:': '💠',
  ':recycle:': '♻️',
  ':end:': '🔚',
  ':back:': '🔙',
  ':on:': '🔛',
  ':soon:': '🔜',
  ':clock1:': '🕐',
  ':clock130:': '🕜',
  ':clock10:': '🕙',
  ':clock1030:': '🕥',
  ':clock11:': '🕚',
  ':clock1130:': '🕦',
  ':clock12:': '🕛',
  ':clock1230:': '🕧',
  ':clock2:': '🕑',
  ':clock230:': '🕝',
  ':clock3:': '🕒',
  ':clock330:': '🕞',
  ':clock4:': '🕓',
  ':clock430:': '🕟',
  ':clock5:': '🕔',
  ':clock530:': '🕠',
  ':clock6:': '🕕',
  ':clock630:': '🕡',
  ':clock7:': '🕖',
  ':clock730:': '🕢',
  ':clock8:': '🕗',
  ':clock830:': '🕣',
  ':clock9:': '🕘',
  ':clock930:': '🕤',
  ':heavy_dollar_sign:': '💲',
  ':copyright:': '©️',
  ':registered:': '®️',
  ':tm:': '™️',
  ':x:': '❌',
  ':heavy_exclamation_mark:': '❗',
  ':bangbang:': '‼️',
  ':interrobang:': '⁉️',
  ':o:': '⭕',
  ':heavy_multiplication_x:': '✖️',
  ':heavy_plus_sign:': '➕',
  ':heavy_minus_sign:': '➖',
  ':heavy_division_sign:': '➗',
  ':white_flower:': '💮',
  ':100:': '💯',
  ':heavy_check_mark:': '✔️',
  ':ballot_box_with_check:': '☑️',
  ':radio_button:': '🔘',
  ':link:': '🔗',
  ':curly_loop:': '➰',
  ':wavy_dash:': '〰️',
  ':part_alternation_mark:': '〽️',
}

export const checkText = (text) => {
  const words = text && text.split(' ')
  const newText = []
  if (words) {
    words.forEach((word) => {
      let w = word
      if (word in emojiMap) {
        w = emojiMap[word]
      }
      newText.push(w)
    })
  }
  return newText.join(' ')
}
