import { useContext, createContext, useReducer, useMemo, useEffect } from 'react'
import { alpha, createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material'
import * as locales from '@mui/material/locale'
import { useTheme } from '@mui/material/styles'
import { lightTheme, darkTheme } from 'theme'
import { useUserPreferences } from '../UserPreferencesContext'
import { createNewCustomShadowsPalette } from 'theme/lightShadows'
import defaultPalette from 'theme/lightPalette'
import overrides from 'theme/overrides'
import { defaultPrimaryColors } from 'config'

const themeReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'CHANGE_THEME':
      localStorage.setItem('theme', state.paletteType === 'light' ? 'dark' : 'light')
      return {
        ...state,
        paletteType: state.paletteType === 'light' ? 'dark' : 'light',
      }
    case 'CHANGE_PRIMARY_COLOR':
      localStorage.setItem('primaryColor', payload ?? 'green')
      const customColorShadows = createNewCustomShadowsPalette(
        defaultPrimaryColors[payload] ?? defaultPrimaryColors.green
      )
      return {
        ...state,
        primaryColor: defaultPrimaryColors[payload] ?? defaultPrimaryColors.green,
        customColorShadows,
      }
    default:
      throw new Error()
  }
}

const initialState = {
  paletteType: localStorage.getItem('theme') || 'dark',
  primaryColor: defaultPrimaryColors[localStorage.getItem('primaryColor')] || defaultPrimaryColors.green,
  customColorShadows: {
    primary: `0 8px 16px 0 ${alpha(defaultPrimaryColors[localStorage.getItem('primaryColor') || 'green'].light, 0.24)}`,
    info: `0 8px 16px 0 ${alpha(defaultPalette.info.light, 0.24)}`,
    success: `0 8px 16px 0 ${alpha(defaultPalette.success.light, 0.24)}`,
    warning: `0 8px 16px 0 ${alpha(defaultPalette.warning.light, 0.24)}`,
    error: `0 8px 16px 0 ${alpha(defaultPalette.error.light, 0.24)}`,
  },
}

const ThemeContext = createContext(null)

export const ThemeProvider = ({ children }) => {
  const theme = useTheme()
  const { language } = useUserPreferences()

  useEffect(() => {
    localStorage.getItem('theme') === null && localStorage.setItem('theme', 'dark')
    localStorage.getItem('primaryColor') === null && localStorage.setItem('primaryColor', 'green')
  }, [])

  const [state, dispatch] = useReducer(themeReducer, initialState)

  const memoizedTheme = useMemo(() => {
    return createTheme(
      state.paletteType === 'light'
        ? {
            ...lightTheme,
            customColorShadows: state.customColorShadows,
            palette: {
              ...lightTheme.palette,
              mode: state.paletteType,
              primary: state.primaryColor,
            },
          }
        : {
            ...darkTheme,
            customColorShadows: state.customColorShadows,
            palette: {
              ...darkTheme.palette,
              mode: state.paletteType,
              primary: state.primaryColor,
            },
          },
      locales[language]
    )
  }, [state, language])

  memoizedTheme.components = overrides(memoizedTheme)

  const changeTheme = () => {
    dispatch({
      type: 'CHANGE_THEME',
      payload: theme.palette.mode === 'light' ? 'dark' : 'light',
    })
  }

  const changePrimaryColor = (color) => {
    dispatch({
      type: 'CHANGE_PRIMARY_COLOR',
      payload: color,
    })
  }

  return (
    <MuiThemeProvider theme={memoizedTheme}>
      <ThemeContext.Provider value={{ changeTheme, changePrimaryColor }} displayName="Edit Theme">
        {children}
      </ThemeContext.Provider>
    </MuiThemeProvider>
  )
}

export const useThemeEdit = () => {
  const context = useContext(ThemeContext)

  if (context === undefined) {
    throw new Error('useThemeEdit can only be used inside ThemeContext')
  }

  return context
}
