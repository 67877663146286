import axios from 'axios'
import { ACCEPTED_ADMIN_GROUPS } from 'config'
import { API_URL } from 'config'
import { createContext, useReducer, useContext, useEffect, useCallback } from 'react'
import { transformTenant } from 'utils'
import { useError } from 'utils/hooks'
import { useAuth } from '../AuthContext'
import { useConfiguration } from '../ConfigurationContext'

const tenantInfoReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'LOAD_TENANT_INFO':
      return {
        ...state,
        tenants: payload.tenants,
        activeTenant: payload.activeTenant,
        activeTenantFullInfo: payload.tenants.find((tenant) => tenant.id === payload.activeTenant),
        activeTenantUsers: payload.activeTenantUsers,
        currentUserRoleInTenant: payload.currentUserRoleInTenant,
        stripeData: payload.stripeData,
        products: payload.productsData,
        loadingTenants: false,
      }
    case 'LOAD_TENANT_INFO_ADMIN':
      return {
        ...state,
        tenants: payload,
        loadingTenants: false,
      }
    case 'LOAD_TENANT_INFO_ERROR':
      return {
        ...state,
        tenants: [],
        loadingTenants: false,
        activeTenant: null,
        activeTenantFullInfo: null,
        activeTenantUsers: null,
        currentUserRoleInTenant: null,
        products: [],
        stripeData: null,
      }
    case 'SET_ACTIVE_TENANT':
      return {
        ...state,
        activeTenant: payload,
        activeTenantFullInfo: state.tenants.find((tenant) => tenant.id === payload),
      }
    case 'SET_PRODUCTS':
      return {
        ...state,
        products: payload,
      }
    case 'SET_LOADING':
      return {
        ...state,
        loadingTenants: payload,
      }
    case 'SET_ACTIVE_TENANT_USERS':
      return {
        ...state,
        activeTenantUsers: payload.activeTenantUsers,
        currentUserRoleInTenant: payload.currentUserRoleInTenant,
      }
    case 'SET_LOADED_TENANTS':
      return {
        ...state,
        tenants: payload,
      }
    default:
      return state
  }
}

const initialState = {
  tenants: [],
  loadingTenants: true,
  activeTenant: null,
  activeTenantFullInfo: null,
  activeTenantUsers: null,
  currentUserRoleInTenant: null,
  products: [],
  stripeData: null,
}

const TenantInfoContext = createContext(initialState)

const TenantInfoProvider = ({ children }) => {
  const [state, dispatch] = useReducer(tenantInfoReducer, initialState)
  const { isAuthenticated, loadingAuth, user } = useAuth()
  const { config, loadingConfiguration, hasConfig } = useConfiguration()
  const { setError } = useError()

  const loadTenantInfo = useCallback(async () => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    })

    if (user?.username) {
      try {
        if (ACCEPTED_ADMIN_GROUPS.includes(user.userGroup)) {
          const { data: tenantData } = await axios(`${API_URL}/tenant/info/all`)
          const activeTenant =
            state.activeTenant || config.defaultTenant === 'last-selected-tenant'
              ? localStorage.getItem('last-selected-company')
              : config.defaultTenant || localStorage.getItem('last-selected-company') || null

          if (activeTenant) {
            const promises = [
              axios(`${API_URL}/tenant/${activeTenant}/users`),
              axios(`${API_URL}/${activeTenant}/product`),
              axios(`${API_URL}/tenant/roles/${activeTenant}`),
              axios(`${API_URL}/stripe-connect/${activeTenant}/details-submitted`),
            ]

            Promise.allSettled(promises).then(([users, products, roles, stripe]) => {
              const usersData = users.value?.data
              const productsData = products.value?.data ?? initialState.products
              const rolesData = roles.value?.data
              const stripeData = stripe.value?.data ?? initialState.stripeData

              const payload = {
                tenants: tenantData.map((tenant) => transformTenant(tenant)),
                activeTenant,
                activeTenantUsers: usersData.map((user) => {
                  const userRole = rolesData.find((role) => role.username.toLowerCase() === user.username.toLowerCase())

                  return { ...user, role: userRole.role }
                }),
                currentUserRoleInTenant: rolesData.find(
                  (role) => role.username.toLowerCase() === user.username.toLowerCase()
                )?.role,
                stripeData,
                productsData,
              }

              dispatch({
                type: 'LOAD_TENANT_INFO',
                payload: payload,
              })
            })
          } else {
            dispatch({
              type: 'LOAD_TENANT_INFO_ADMIN',
              payload: tenantData.map((tenant) => transformTenant(tenant)),
            })
          }
        } else {
          const { data: tenantData } = await axios(`${API_URL}/tenant/user/${user.username}`)
          const activeTenant = state.activeTenant || config.defaultTenant || tenantData[0].tenant.id

          const promises = [
            axios(`${API_URL}/tenant/${activeTenant}/users`),
            axios(`${API_URL}/${activeTenant}/product`),
            axios(`${API_URL}/tenant/roles/${activeTenant}`),
            axios(`${API_URL}/stripe-connect/${activeTenant}/details-submitted`),
          ]

          Promise.allSettled(promises).then(([users, products, roles, stripe]) => {
            const usersData = users.value?.data
            const productsData = products.value?.data ?? initialState.products
            const rolesData = roles.value?.data
            const stripeData = stripe.value?.data ?? initialState.stripeData

            const payload = {
              tenants: tenantData.map((tenant) => transformTenant(tenant)),
              activeTenant,
              activeTenantUsers: usersData.map((user) => {
                const userRole = rolesData.find((role) => role.username.toLowerCase() === user.username.toLowerCase())

                return { ...user, role: userRole.role }
              }),
              currentUserRoleInTenant: rolesData.find(
                (role) => role.username.toLowerCase() === user.username.toLowerCase()
              )?.role,
              stripeData,
              productsData,
            }

            dispatch({
              type: 'LOAD_TENANT_INFO',
              payload: payload,
            })
          })
        }
      } catch (error) {
        dispatch({
          type: 'LOAD_TENANT_INFO_ERROR',
        })
      }
    } else {
      dispatch({
        type: 'LOAD_TENANT_INFO_ERROR',
      })
    }
  }, [user, config, state.activeTenant])

  useEffect(() => {
    if (!loadingConfiguration) {
      if (hasConfig) {
        user && loadTenantInfo()
      } else {
        dispatch({
          type: 'LOAD_TENANT_INFO_ERROR',
        })
      }
    }
    // eslint-disable-next-line
  }, [loadingAuth, isAuthenticated, loadingConfiguration, hasConfig, user])

  const getTenantUsers = useCallback(
    async (newTenant = null) => {
      try {
        const { data: usersData } = await axios(`${API_URL}/tenant/${newTenant ?? state.activeTenant}/users`)
        const { data: rolesData } = await axios(`${API_URL}/tenant/roles/${newTenant ?? state.activeTenant}`)

        dispatch({
          type: 'SET_ACTIVE_TENANT_USERS',
          payload: {
            activeTenantUsers: usersData.map((user) => {
              const userRole = rolesData.find((role) => role.username.toLowerCase() === user.username.toLowerCase())

              return { ...user, role: userRole.role }
            }),
            currentUserRoleInTenant: !ACCEPTED_ADMIN_GROUPS.includes(user.userGroup)
              ? rolesData.find((role) => role.username.toLowerCase() === user.username.toLowerCase())?.role
              : 'OWNER',
          },
        })
      } catch (error) {
        setError(error)
      }
    },
    [setError, state.activeTenant, user?.userGroup, user?.username]
  )

  const setActiveTenant = useCallback(
    async (tenant) => {
      try {
        await getTenantUsers(tenant)
        dispatch({
          type: 'SET_ACTIVE_TENANT',
          payload: tenant,
        })
      } catch (error) {
        setError(error)
      }
    },
    [getTenantUsers, setError]
  )

  const refetchProducts = useCallback(async () => {
    try {
      const { data } = await axios(`${API_URL}/${state.activeTenant}/product`)

      dispatch({
        type: 'SET_PRODUCTS',
        payload: data,
      })
    } catch (error) {
      setError(error)
    }
  }, [setError, state.activeTenant])

  const addEmployee = async (employee) => {
    try {
      await axios.post(`${API_URL}/tenant/roles`, employee)
      await getTenantUsers()
    } catch (error) {
      setError(error)
    }
  }

  const deleteEmployee = async (username) => {
    try {
      await axios.delete(`${API_URL}/tenant/roles/${state.activeTenant}/${username}`)
      await getTenantUsers()
    } catch (error) {
      setError(error)
    }
  }

  const changeEmployeeRole = async (role, username) => {
    const request = {
      tenantId: state.activeTenant,
      role,
      username,
    }
    try {
      await axios.put(`${API_URL}/tenant/roles/`, request)
      await getTenantUsers()
    } catch (error) {
      setError(error)
    }
  }

  const editTenant = async (request) => {
    try {
      await axios.put(`${API_URL}/tenant`, {
        ...request,
        active: state.activeTenantFullInfo.active,
        id: state.activeTenant,
        owner: state.activeTenantFullInfo.owner,
      })
      loadTenantInfo()
    } catch (error) {
      setError(error)
    }
  }

  const getUserById = (userId) => {
    return state.activeTenantUsers.find((user) => user.id === userId)
  }

  const getUserByUsername = (username) => {
    if (!state.activeTenantUsers) {
      return
    }
    return state.activeTenantUsers.find((user) => user.username === username)
  }

  const getProductsForRoom = (roomId) => {
    return state.products.filter((product) => product.relatedRoomIds.includes(roomId))
  }

  return (
    <TenantInfoContext.Provider
      value={{
        tenants: state.tenants,
        products: state.products,
        stripeData: state.stripeData,
        activeTenant: state.activeTenant,
        loadingTenants: state.loadingTenants,
        activeTenantUsers: state.activeTenantUsers,
        activeTenantFullInfo: state.activeTenantFullInfo,
        currentUserRoleInTenant: state.currentUserRoleInTenant,
        isStripeActivated: !!state.stripeData
          ? state.stripeData?.detailsSubmitted && state.stripeData?.chargesEnabled
          : false,
        editTenant,
        getUserById,
        addEmployee,
        deleteEmployee,
        getTenantUsers,
        loadTenantInfo,
        setActiveTenant,
        refetchProducts,
        getUserByUsername,
        getProductsForRoom,
        changeEmployeeRole,
      }}
      displayName="Tenant Info"
    >
      {children}
    </TenantInfoContext.Provider>
  )
}

const useTenantInfo = () => {
  const context = useContext(TenantInfoContext)

  if (context === undefined) {
    throw new Error('useTenantInfo can only be used inside TenantInfoProvider')
  }

  return context
}

export { TenantInfoProvider, useTenantInfo }
