/* eslint-disable import/no-anonymous-default-export */
export default (theme) => ({
  MuiBackdrop: {
    styleOverrides: {
      root: {
        background: 'rgba(22, 28, 36, 0.8)',
      },
      invisible: {
        background: 'transparent',
      },
    },
  },
})
