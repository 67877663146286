/* eslint-disable import/no-anonymous-default-export */
export default (theme) => ({
  MuiTypography: {
    styleOverrides: {
      h1: {
        fontSize: 64,
        lineHeight: '80px',
        fontWeight: 600,
      },
      h2: {
        fontSize: 48,
        lineHeight: '64px',
        fontWeight: 600,
      },
      h3: {
        fontSize: 32,
        lineHeight: '48px',
        fontWeight: 600,
      },
      h4: {
        fontSize: 24,
        lineHeight: '36px',
        fontWeight: 600,
      },
      h5: {
        fontSize: 20,
        lineHeight: '30px',
        fontWeight: 600,
      },
      h6: {
        fontSize: 18,
        lineHeight: '28px',
        fontWeight: 600,
      },
      subtitle1: {
        fontSize: 16,
        lineHeight: '22px',
        fontWeight: 600,
      },
      subtitle2: {
        fontSize: 14,
        lineHeight: '22px',
        fontWeight: 600,
      },
      body1: {
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 500,
      },
      body2: {
        fontSize: 14,
        lineHeight: '18px',
        fontWeight: 500,
      },
      caption: {
        fontSize: 12,
        lineHeight: '18px',
        fontWeight: 500,
      },
      overline: {
        fontSize: 12,
        lineHeight: '18px',
        fontWeight: 600,
        letterSpacing: 1.2,
      },
      button: {
        textTransform: 'none',
      },
    },
  },
})
