import axios from 'axios'
import { API_URL } from 'config'
import { createContext, useReducer, useContext, useEffect } from 'react'
import { useAuth } from '../AuthContext'
import { useTenantInfo } from '../TenantInfoContext'

const subscriptionReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'LOAD_SUBSCRIPTION':
      return {
        ...state,
        plan: payload,
        loadingSubscription: false,
      }
    case 'LOAD_SUBSCRIPTION_ERROR':
      return {
        ...state,
        plan: null,
        loadingSubscription: false,
      }
    default:
      return state
  }
}

const initialState = {
  plan: null,
  loadingSubscription: true,
}

const SubscriptionContext = createContext(initialState)

const SubscriptionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(subscriptionReducer, initialState)
  const { activeTenant, loadingTenants } = useTenantInfo()
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (!loadingTenants) {
      if (activeTenant) {
        loadSubscriptionInfo()
      } else {
        dispatch({
          type: 'LOAD_SUBSCRIPTION_ERROR',
        })
      }
    }
    // eslint-disable-next-line
  }, [activeTenant, isAuthenticated, loadingTenants])

  const loadSubscriptionInfo = async () => {
    try {
      const { data } = await axios(`${API_URL}/subscription/${activeTenant}`)

      dispatch({
        type: 'LOAD_SUBSCRIPTION',
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: 'LOAD_SUBSCRIPTION_ERROR',
      })
    }
  }

  const setLoadingSubscription = (boolean) => {
    dispatch({
      type: 'SET_LOADING_ESCAPE_ROOMS',
      payload: boolean,
    })
  }

  return (
    <SubscriptionContext.Provider
      value={{
        plan: state.plan,
        loadingSubscription: state.loadingSubscription,
        setLoadingSubscription,
      }}
      displayName="Subscription"
    >
      {children}
    </SubscriptionContext.Provider>
  )
}

const useSubscription = () => {
  const context = useContext(SubscriptionContext)

  if (context === undefined) {
    throw new Error('useSubscription can only be used inside SubscriptionProvider')
  }

  return context
}

export { SubscriptionProvider, useSubscription }
