/* eslint-disable import/no-anonymous-default-export */
export default (theme) => ({
  MuiMenuItem: {
    styleOverrides: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
        fontSize: 14,
      },
    },
  },
})
