import { Delete, AddAPhoto, Download } from '@mui/icons-material'
import { Box, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material'
import axios from 'axios'
import { ButtonTriggeredDialog, PhotoBuilder } from 'components'
import { SERVICES_URL } from 'config'
import { useTenantInfo } from 'core'
import { transformLanguage } from 'helpers'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { handleDownload } from 'utils'
import { useError } from 'utils/hooks'
import { DeleteAfterEditDialog } from './components'

export const POCKET_ITEM_SIZE = 80
export const DAYS_BEFORE_AUTO_DELETE = 45

// isSelect works as a simple file selector which triggers the onSelect function

export default function PocketItem({ pocket, mutate, isSelect = false, onSelect }) {
  const { t, i18n } = useTranslation('pocket')
  const { activeTenant: tenantId } = useTenantInfo()
  const { setError } = useError()

  const [anchorEl, setAnchorEl] = useState(null)
  const [isPhotoBuilderOpen, setIsPhotoBuilderOpen] = useState(false)
  const [isDeleteAfterEditDialogOpen, setIsDeleteAfterEditDialogOpen] = useState(false)

  const open = Boolean(anchorEl)
  const deleteDate = DateTime.fromMillis(pocket.timeCreated).plus({ days: DAYS_BEFORE_AUTO_DELETE }).startOf('day')
  const daysUntilDelete = Math.floor((deleteDate.valueOf() - Date.now()) / (1000 * 60 * 60 * 24))

  const handleClick = (event) => {
    if (isSelect) {
      onSelect()
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = async () => {
    try {
      await axios.delete(`${SERVICES_URL}/pocket/${tenantId}/${pocket.id}`)
      handleClose()
      mutate()
    } catch (error) {
      setError(error)
    }
  }

  return (
    <>
      <Box
        id="pocket"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: POCKET_ITEM_SIZE,
          height: POCKET_ITEM_SIZE,
          border: '1px solid',
          borderColor: daysUntilDelete <= 7 ? 'error.dark' : 'divider',
          borderRadius: 2,
          cursor: 'pointer',
          overflow: 'hidden',
          transition: 'all 0.25s',
          filter: 'brightness(100%)',
          '&:hover img': {
            filter: 'brightness(80%)',
            transition: 'all 0.25s',
          },
        }}
      >
        <Tooltip
          title={
            <>
              <b>{daysUntilDelete <= 7 && t('ItemWillBeDeletedInLessThanXDays', { x: daysUntilDelete })}</b>
              {daysUntilDelete <= 7 && <br />}
              {pocket.description}
              {!!pocket.description && <Divider />}
              {t('UploadedOnX', {
                x: DateTime.fromMillis(pocket.timeCreated)
                  .setLocale(transformLanguage(i18n.language))
                  .toLocaleString({ dateStyle: 'short', timeStyle: 'short' }),
              })}
              <br />
              {t('DeletingOnX', {
                x: deleteDate
                  .setLocale(transformLanguage(i18n.language))
                  .toLocaleString({ dateStyle: 'short', timeStyle: 'short' }),
              })}
            </>
          }
          arrow
        >
          <img
            src={pocket.url}
            alt={pocket.description}
            style={{ objectFit: 'cover', objectPosition: 'center', width: POCKET_ITEM_SIZE, height: POCKET_ITEM_SIZE }}
          />
        </Tooltip>
      </Box>
      {!isSelect && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'pocket',
          }}
        >
          <MenuItem onClick={() => setIsPhotoBuilderOpen(true)}>
            <ListItemIcon>
              <AddAPhoto fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t('translation:Open')} {t('photos:PhotoBuilder')}
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => handleDownload(pocket.url, `${pocket.id}.${pocket.url.split('.').at(-1)}`, handleClose)}
          >
            <ListItemIcon>
              <Download fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('photos:Download')}</ListItemText>
          </MenuItem>
          <ButtonTriggeredDialog
            buttonComponent={MenuItem}
            dialogTitle={t('translation:Delete')}
            handleClick={handleDelete}
            buttonText={
              <>
                <ListItemIcon>
                  <Delete fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t('translation:Delete')}</ListItemText>
              </>
            }
          >
            {t('translation:AreYouSureToDeleteTheItem')}
          </ButtonTriggeredDialog>
        </Menu>
      )}

      {!isSelect && (
        <PhotoBuilder
          isOpen={isPhotoBuilderOpen}
          setIsOpen={setIsPhotoBuilderOpen}
          onCreate={() => setIsDeleteAfterEditDialogOpen(true)}
          initialPhoto={pocket.url}
          hideCreateOtherOneOption
        />
      )}

      {!isSelect && (
        <DeleteAfterEditDialog
          open={isDeleteAfterEditDialogOpen}
          setOpen={setIsDeleteAfterEditDialogOpen}
          handleDelete={handleDelete}
        />
      )}
    </>
  )
}
