/* eslint-disable import/no-anonymous-default-export */
export default (theme) => ({
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'none',
      },
      rounded: {
        borderRadius: 2,
      },
      elevation1: {
        boxShadow: '0 1px 3px rgba(0,0,0,.09)',
      },
    },
  },
})
