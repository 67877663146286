import { alpha } from '@mui/material'

/* eslint-disable import/no-anonymous-default-export */
export default (theme) => ({
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottom: '0 !important',
      },
      head: {
        padding: 16,
        background: theme.palette.mode === 'light' ? theme.palette.grey[200] : alpha(theme.palette.grey[500], 0.16),

        '&:first-of-type': {
          background: theme.palette.mode === 'light' ? theme.palette.grey[200] : alpha(theme.palette.grey[500], 0.16),
          paddingLeft: 24,
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
          boxShadow: `${theme.palette.background.default} 8px 0px 0px inset`,
        },

        '&:last-child': {
          background: theme.palette.mode === 'light' ? theme.palette.grey[200] : alpha(theme.palette.grey[500], 0.16),
          paddingRight: 24,
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8,
          boxShadow: `${theme.palette.background.default} -8px 0px 0px inset`,
        },
      },
    },
  },
})
