import { Avatar, ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { ButtonTriggeredMenu } from 'components'
import { API_URL } from 'config'
import { useCalendar, useEscapeRooms, useTenantInfo } from 'core'
import { getInitials } from 'helpers'
import { useTranslation } from 'react-i18next'
import { getReservationAssignmentsForReservation } from 'utils'
import PeopleIcon from '@mui/icons-material/PeopleAltOutlined'
import { memo } from 'react'

const GameMasters = ({ escapeRoom, reservationId, reservationAssignments, handleCloseMainMenu, date }) => {
  const { t } = useTranslation()
  const { removeAssigneeFromReservation, addAssigneeToReservation } = useEscapeRooms()
  const { activeTenantUsers } = useTenantInfo()
  const { updateReservationAssignment } = useCalendar()

  const reservationAssignmentsForCurrentReservation = getReservationAssignmentsForReservation({
    reservationId,
    reservationAssignments,
  })

  return (
    <ButtonTriggeredMenu
      buttonComponent={MenuItem}
      additionalOnClose={handleCloseMainMenu}
      menuProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      buttonText={
        <>
          <ListItemIcon>
            <PeopleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('reservations:GameMasters')}</ListItemText>
        </>
      }
      size="small"
    >
      {({ handleClose }) =>
        escapeRoom.roomAssignments.length ? (
          escapeRoom.roomAssignments.map((assignee) => {
            const user = activeTenantUsers.find((user) => assignee.userId === user.id)
            const isUserAssigned = reservationAssignmentsForCurrentReservation
              .map((assignment) => assignment.userId)
              .includes(user?.id)
            return (
              user && (
                <MenuItem
                  key={user.id}
                  disabled={
                    !isUserAssigned &&
                    escapeRoom.maxReservationAssignees === reservationAssignmentsForCurrentReservation.length
                  }
                  onClick={() => {
                    if (isUserAssigned) {
                      removeAssigneeFromReservation({
                        userId: user.id,
                        reservationId,
                        roomId: escapeRoom.id,
                      })
                      updateReservationAssignment({
                        type: 'REMOVE',
                        userId: user.id,
                        reservationId,
                        date,
                        roomId: escapeRoom.id,
                      })
                    } else {
                      addAssigneeToReservation({
                        userId: user.id,
                        reservationId,
                        roomId: escapeRoom.id,
                      })
                      updateReservationAssignment({
                        type: 'ADD',
                        userId: user.id,
                        reservationId,
                        date,
                        roomId: escapeRoom.id,
                      })
                    }
                    escapeRoom.maxReservationAssignees - 1 === reservationAssignmentsForCurrentReservation.length &&
                      handleClose() &&
                      handleCloseMainMenu()
                  }}
                >
                  <ListItemIcon>
                    <Avatar
                      sx={{ marginRight: 1, width: 30, height: 30 }}
                      key={user.id}
                      alt={getInitials(`${user?.firstName} ${user?.lastName}`)}
                      src={user?.avatar && `${API_URL}/media/${user?.avatar}`}
                    >
                      {getInitials(`${user?.firstName} ${user?.lastName}`)}
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    primary={`${user?.firstName} ${user?.lastName}`}
                    secondary={isUserAssigned ? t('translation:Remove') : t('translation:Add')}
                  />
                </MenuItem>
              )
            )
          })
        ) : (
          <MenuItem disabled>{t('reservations:NoGameMastersAdded')}</MenuItem>
        )
      }
    </ButtonTriggeredMenu>
  )
}

export default memo(GameMasters)
