import { Box, Divider, IconButton, InputAdornment, TextField } from '@mui/material'
import { SearchSharp, Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

export default function SearchField({
  label,
  placeholder = '',
  searchText,
  handleSearch,
  setSearchText,
  handleEmptyTextAdditional = () => {},
  handleSearchAdditional = () => {},
  sx,
  ...rest
}) {
  const { t } = useTranslation()

  return (
    <form
      style={{ width: '100%' }}
      onSubmit={(e) => {
        e.preventDefault()
        if (searchText.length >= 3) {
          handleSearch()
        }
      }}
    >
      <TextField
        sx={sx}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Box display="flex">
                <IconButton
                  size="small"
                  edge="start"
                  sx={{ mr: 0.25 }}
                  disabled={searchText.length === 0}
                  onClick={() => {
                    setSearchText('')
                    handleEmptyTextAdditional()
                  }}
                >
                  <Close />
                </IconButton>
                <Divider flexItem variant="middle" orientation="vertical" />
                <IconButton
                  size="small"
                  edge="end"
                  sx={{ ml: 0.25 }}
                  onClick={handleSearch}
                  disabled={searchText.length < 3}
                >
                  <SearchSharp />
                </IconButton>
              </Box>
            </InputAdornment>
          ),
        }}
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value)
          handleSearchAdditional(e)
        }}
        size="small"
        label={label ?? t('translation:Search')}
        placeholder={placeholder}
        {...rest}
      />
    </form>
  )
}
