import { TRANSACTION_TYPES } from 'config'

export const getDescription = (finance, t) => {
  switch (finance.transactionType) {
    case TRANSACTION_TYPES.NONE.key:
      return `${finance.description} (${t(`finances:NotIncluded`)})`
    case TRANSACTION_TYPES.PRODUCT.key:
      return finance.description
    default:
      return t(`finances:${TRANSACTION_TYPES[finance.transactionType].value}`)
  }
}
