import { Box, Drawer, IconButton, Tooltip } from '@mui/material'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import Markdown from 'react-markdown'
import { Close } from '@mui/icons-material'

export default function FAQ({ filePath }) {
  const { t } = useTranslation()

  const [content, setContent] = useState('')
  const [open, setOpen] = useState(false)

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen)
  }

  useEffect(() => {
    fetch(filePath)
      .then((response) => response.text())
      .then((text) => setContent(text))
      .catch((error) => console.error('Error loading Markdown file:', error))
  }, [filePath])

  return (
    <>
      <Tooltip title={t('ReadMore')}>
        <Box
          onClick={toggleDrawer(true)}
          sx={(theme) => ({
            bgcolor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 25,
            height: 25,
            borderRadius: '50%',
            cursor: 'pointer',
          })}
        >
          <QuestionMarkIcon sx={{ fontSize: '1.1rem' }} />
        </Box>
      </Tooltip>

      <Drawer
        open={open}
        onClose={toggleDrawer(false)}
        anchor="right"
        PaperProps={{ sx: { maxWidth: 500 } }}
        sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}
      >
        <Box py={1} px={3}>
          <IconButton sx={{ position: 'absolute', top: 36, right: 16 }} onClick={toggleDrawer(false)}>
            <Close />
          </IconButton>
          <Markdown>{content}</Markdown>
        </Box>
      </Drawer>
    </>
  )
}
