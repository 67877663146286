import axios from 'axios'
import { DateTime } from 'luxon'

/**
 * Get name initials
 * @param name
 */
export const getInitials = (name = '') =>
  name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join('')

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else {
    delete axios.defaults.headers.common['Authorization']
  }
}

/**
 * Transform standard specific language to Material-UI friendly language
 * @param language
 */
export const transformLanguage = (language) => {
  return language
    .replace(/([A-Z]+|[a-z]+)/g, '-$&')
    .split('-')
    .filter((s) => s !== '')
    .join('-')
}

/**
 * HTTP query builder
 * @param string
 * @param array
 */
export const queryParamAsArray = (string, array) => {
  return array
    .map((id) => {
      return `${string}=${id}`
    })
    .join('&')
}

/**
 * Calculate and round amount
 */
export const calculate = {
  roundFinalAmount: (amount) => +(Math.round(100 * (Number.EPSILON + amount)) / 100).toFixed(2),
}

/**
 * Simple object check
 * @param item
 * @returns {boolean}
 */
export const isObject = (item) => {
  return item.constructor === Object
}

/**
 * Deep merge two objects
 * @param target
 * @param ...sources
 */
export const mergeDeep = (target, ...sources) => {
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return mergeDeep(target, ...sources)
}

/**
 * Creates base64 string from an image
 * @param url
 */
export const imageToBase64 = async (url) => {
  const response = await fetch(url)
  const buffer = await response.arrayBuffer()
  return btoa(String.fromCharCode(...new Uint8Array(buffer)))
}

export const getCookie = (cookieName) => {
  let name = cookieName + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export const removeCookie = (cookieName) => {
  document.cookie = `${cookieName}=; Max-Age=-99999999; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${
    import.meta.env.MODE === 'production' ? '.keyhowl.com' : 'localhost'
  }`
}

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

export function isValidEmail(email) {
  // Regular expression pattern for email validation
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  // Check if the email matches the pattern
  return emailPattern.test(email)
}

export function findClosestDate(datesInMS) {
  if (datesInMS.length === 0) return null

  const currentMS = Date.now()
  const currentTimeInMinutes = Math.floor(currentMS / 60000) // Convert milliseconds to minutes

  let closestDateMS = null
  let closestDifference = Infinity

  for (const dateMS of datesInMS) {
    if (dateMS >= currentMS) {
      const minutes = Math.floor(dateMS / 60000) // Convert milliseconds to minutes
      const difference = Math.abs(currentTimeInMinutes - minutes)

      if (difference < closestDifference) {
        closestDateMS = dateMS
        closestDifference = difference
      }
    }
  }

  return closestDateMS
}

/**
 * Check equality of two objects
 * @param obj1
 * @param obj2
 */
export function deepEqual(obj1, obj2) {
  if (obj1 === obj2) return true

  // Check if both are primitives
  if (isPrimitive(obj1) && isPrimitive(obj2)) return obj1 === obj2

  // Check if both are arrays
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false
    for (let i = 0; i < obj1.length; i++) {
      if (!deepEqual(obj1[i], obj2[i])) return false
    }
    return true
  }

  // Check if one is an array and the other is not
  if (Array.isArray(obj1) !== Array.isArray(obj2)) return false

  // Check if both are objects (but not arrays)
  if (typeof obj1 === 'object' && typeof obj2 === 'object') {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) return false
    for (let key in obj1) {
      if (!(key in obj2)) return false
      if (!deepEqual(obj1[key], obj2[key])) return false
    }
    return true
  }

  // If none of the above conditions are met, they're not equal
  return false
}

function isPrimitive(obj) {
  return obj !== Object(obj)
}

export const base64toBlob = (base64Image) => {
  const base64Data = base64Image.split(',')[1]
  const contentType = base64Image.split(';')[0].split(':')[1]

  const byteCharacters = atob(base64Data)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  return new Blob([byteArray], { type: contentType })
}

export async function urlToFile(url, filename, mimeType = 'image/jpeg') {
  const response = await fetch(url)
  const blob = await response.blob()
  return new File([blob], filename, { type: mimeType })
}

export const muteWarningsPlugin = (warningsToIgnore) => {
  const mutedMessages = new Set()
  return {
    name: 'mute-warnings',
    enforce: 'pre',
    config: (userConfig) => ({
      build: {
        rollupOptions: {
          onwarn(warning, defaultHandler) {
            if (warning.code) {
              const muted = warningsToIgnore.find(
                ([code, message]) => code === warning.code && warning.message.includes(message)
              )

              if (muted) {
                mutedMessages.add(muted.join())
                return
              }
            }

            if (userConfig.build?.rollupOptions?.onwarn) {
              userConfig.build.rollupOptions.onwarn(warning, defaultHandler)
            } else {
              defaultHandler(warning)
            }
          },
        },
      },
    }),
    closeBundle() {
      const diff = warningsToIgnore.filter((x) => !mutedMessages.has(x.join()))
      if (diff.length > 0) {
        this.warn('Some of your muted warnings never appeared during the build process:')
        diff.forEach((m) => this.warn(`- ${m.join(': ')}`))
      }
    },
  }
}

export const getReservationName = (reservation, locale) => {
  return (
    reservation?.teamName ||
    reservation?.player?.name ||
    DateTime.fromMillis(reservation?.bookedDate ?? 0)
      .setLocale(transformLanguage(locale))
      .toLocaleString({ dateStyle: 'short', timeStyle: 'short' })
  )
}
