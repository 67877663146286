import { Tab, Tabs, alpha, useTheme } from '@mui/material'

export function TableTabs({ children, activeTab, handleChangeTab, ...rest }) {
  const theme = useTheme()

  return (
    <Tabs
      sx={{
        boxShadow: 'rgba(145, 158, 171, 0.08) 0px -2px 0px 0px inset',
        backgroundColor:
          theme.palette.mode === 'light' ? theme.palette.background.default : alpha(theme.palette.grey[500], 0.16),
        px: 2,
        textColor: 'inherit',
      }}
      variant="scrollable"
      scrollButtons="auto"
      {...rest}
    >
      {children}
    </Tabs>
  )
}

export function TableTab({ label }) {
  return (
    <Tab
      label={label}
      disableRipple
      sx={{
        '&.Mui-selected': {
          color: 'text.primary',
        },
      }}
    />
  )
}
