import { Box, Chip, CircularProgress, Collapse, Divider, Grid, Stack, Typography } from '@mui/material'
import ButtonTriggeredDialog from 'components/ButtonTriggeredDialog'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { alpha } from 'utils'
import useSWR from 'swr'
import { API_URL } from 'config'
import { useAuth, useTenantInfo } from 'core'
import { TransitionGroup } from 'react-transition-group'
import { PocketItem } from 'layouts/Main/components/Pocket/components'

export default function PocketBrowser({ isTiny = false, selectedItem, setSelectedItem }) {
  const { t } = useTranslation()
  const { activeTenant: tenantId, activeTenantUsers } = useTenantInfo()
  const { user } = useAuth()

  const [isOpen, setIsOpen] = useState(false)

  const { data, isLoading } = useSWR(isOpen && `${API_URL}/pocket/tenant/${tenantId}`, {
    fallbackData: [],
    refreshInterval: isOpen ? 10000 : undefined,
    keepPreviousData: true,
    revalidateOnFocus: isOpen,
  })

  const userIdData = !isLoading
    ? data.reduce((accumulator, currentValue) => {
        return {
          ...accumulator,
          [currentValue.userId]: [
            ...(currentValue.userId in accumulator ? accumulator[currentValue.userId] : []),
            currentValue,
          ],
        }
      }, {})
    : {}

  const handleClose = () => {
    setIsOpen(false)
    setSelectedItem(undefined)
  }

  return (
    <ButtonTriggeredDialog
      dialogProps={{ fullWidth: true, maxWidth: 'sm' }}
      buttonComponent={Box}
      customActionButton={<></>}
      closeIcon
      onOpen={() => {
        setIsOpen(true)
      }}
      onClose={handleClose}
      prematureClose={!!selectedItem}
      buttonComponentProps={{
        sx: (theme) => ({
          padding: theme.spacing(isTiny ? 3 : 5, 1),
          borderRadius: theme.spacing(2),
          border: '1px dashed',
          borderColor: alpha(theme.palette.grey[500], 0.32),
          background: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.background.default,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }),
      }}
      buttonText={
        <>
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ minHeight: 85 }}>
            <img src="/illustrations/pocket.svg" width={isTiny ? '100' : '180'} alt="pocket" />
          </Box>
          <div>
            <Typography variant={isTiny ? 'h6' : 'h5'} gutterBottom>
              {t('GetFileFromPocket')}
            </Typography>
            <Typography variant="body2" color="textSecondary" fontSize={isTiny ? '0.75rem' : '0.875rem'}>
              {t('GetFileFromPocketDescription')}
            </Typography>
          </div>
        </>
      }
      dialogTitle={t('PocketBrowser')}
    >
      {!isLoading ? (
        <TransitionGroup>
          {Object.keys(userIdData)
            .sort((a, b) => {
              if (+a === user.id) return -1
              if (+b === user.id) return 1
              return a - b
            })
            .map((userId) => (
              <Collapse key={userId}>
                <Stack sx={{ width: '100%' }}>
                  <Divider textAlign="left" sx={{ mt: 1, mb: 0.5 }}>
                    <Chip
                      size="small"
                      label={`${activeTenantUsers?.find((user) => user.id === +userId)?.firstName} 
                          ${activeTenantUsers?.find((user) => user.id === +userId)?.lastName}`}
                    />
                  </Divider>
                  <Grid container spacing={1} component={TransitionGroup}>
                    {userIdData?.[userId]
                      ?.sort((a, b) => b.timeCreated - a.timeCreated)
                      .map((pocket) => (
                        <Grid key={pocket.id} item component={Collapse} orientation="horizontal">
                          <PocketItem pocket={pocket} isSelect onSelect={() => setSelectedItem(pocket.url)} />
                        </Grid>
                      ))}
                  </Grid>
                </Stack>
              </Collapse>
            ))}
        </TransitionGroup>
      ) : (
        <CircularProgress />
      )}
    </ButtonTriggeredDialog>
  )
}
