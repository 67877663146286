import axios from 'axios'
import { API_URL } from 'config'
import { useEffect, useRef } from 'react'
import { createContext, useReducer, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { PageURLs } from 'Routes'
import { dateToShow, getScheduleFromDay } from 'utils'
import { useError } from 'utils/hooks'
import { useConfiguration, useDate, useEscapeRooms, useTenantInfo } from '..'
import { DateTime } from 'luxon'
import { queryParamAsArray } from 'helpers'
import { useCallback } from 'react'

const calendarReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'LOAD_CALENDAR':
      return {
        ...state,
        days: payload.days,
        firstDate: payload.firstDate,
        lastDate: payload.lastDate,
        loadingCalendar: false,
        lastUpdated: Date.now(),
      }
    case 'SET_IS_CALENDAR_LOADING':
      return {
        ...state,
        loadingCalendar: payload,
      }
    case 'UPDATE_DAYS':
      return {
        ...state,
        days: payload,
      }
    case 'LOAD_CALENDAR_ERROR':
      return {
        ...state,
        days: initialState.days,
        firstDate: initialState.firstDate,
        lastDate: initialState.lastDate,
        loadingCalendar: false,
      }
    case 'LOAD_CALENDAR_INITIAL_VALUES':
      return {
        ...state,
        days: initialState.days,
        firstDate: initialState.firstDate,
        lastDate: initialState.lastDate,
        loadingCalendar: true,
      }
    default:
      return state
  }
}

const initialState = {
  days: [],
  firstDate: null,
  lastDate: null,
  weeklyCalendarDays: 7,
  loadingCalendar: true,
  lastUpdated: null,
}

const CalendarContext = createContext(initialState)

const CalendarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(calendarReducer, initialState)
  const { setError } = useError()
  const { activeEscapeRoomsIds, activeEscapeRooms } = useEscapeRooms()
  const { activeTenant } = useTenantInfo()
  const { timezone } = useDate()
  const { config } = useConfiguration()
  const { pathname } = useLocation()
  const manualLoadRef = useRef(false)

  useEffect(() => {
    pathname !== PageURLs.Calendar && !state.loadingCalendar && dispatch({ type: 'LOAD_CALENDAR_INITIAL_VALUES' })
  }, [pathname, state.loadingCalendar])

  const createReservationObject = useCallback((reservation, assignments = [], order = {}) => {
    return {
      data: reservation,
      assignments,
      order,
      isLoading: false,
    }
  }, [])

  const loadCalendar = useCallback(
    async (days = state.days, isManualLoad = true) => {
      if (isManualLoad) {
        manualLoadRef.current = true
      }

      const wholeDay = 86399000 // 23h59m59s
      const firstDate = days[0].date
      const lastDate = days[days.length - 1].date + wholeDay

      try {
        const promises = [
          axios(
            `${API_URL}/reservation/room/calendar-with-assignment?from=${firstDate}&to=${lastDate}&${queryParamAsArray(
              'roomIds',
              activeEscapeRoomsIds
            )}`
          ),
          axios(
            `${API_URL}/${activeTenant}/order-history/by-period?from=${firstDate}&to=${lastDate}&${queryParamAsArray(
              'roomIds',
              activeEscapeRoomsIds
            )}`
          ),
          axios(
            `${API_URL}/calendar/assignment/by-room-and-period?from=${firstDate}&to=${lastDate}&${queryParamAsArray(
              'roomIds',
              activeEscapeRoomsIds
            )}`
          ),
        ]

        Promise.allSettled(promises).then(([reservations, orderHistory, calendarAssignments]) => {
          const calendarDays = days.map((day) => {
            const existingReservationsWithAssignmentsAllRooms = reservations?.value?.data
            const orderHistoryAllRooms = orderHistory?.value?.data
            const calendarAssignmentsDataAllRooms = calendarAssignments?.value?.data

            const escapeRoomsObject = activeEscapeRooms.reduce((acc, escapeRoom) => {
              const allHoursForCurrentDay = getScheduleFromDay(
                DateTime.fromMillis(day.date, { zone: timezone }).weekday,
                escapeRoom.roomSchedule.schedule
              )
              const allReservationsForCurrentDay =
                existingReservationsWithAssignmentsAllRooms &&
                escapeRoom.id in existingReservationsWithAssignmentsAllRooms &&
                existingReservationsWithAssignmentsAllRooms?.[escapeRoom.id]?.reservations
                  .filter(
                    (reservation) =>
                      DateTime.fromMillis(dateToShow(reservation.bookedDate, timezone))
                        .setZone(timezone)
                        .startOf('day')
                        .valueOf() === day.date
                  )
                  .map((reservation) => {
                    const assignments = existingReservationsWithAssignmentsAllRooms[escapeRoom.id]?.assignments.filter(
                      (assignment) => assignment.reservationId === reservation.key.reservationId
                    )
                    const order =
                      orderHistoryAllRooms && escapeRoom.id in orderHistoryAllRooms
                        ? orderHistoryAllRooms[escapeRoom.id]?.find(
                            (order) => order.reservationId === reservation.key.reservationId
                          )
                        : {}
                    return createReservationObject(reservation, assignments, order)
                  })

              const formattedReservations = allReservationsForCurrentDay?.map(
                (reservation) => reservation.data.bookedDate
              )
              const filteredHoursForCurrentDay = allHoursForCurrentDay
                ?.map((hour) =>
                  createReservationObject({
                    key: {
                      roomId: escapeRoom.id,
                      reservationId: DateTime.fromMillis(hour + day.date, { zone: timezone }).valueOf(),
                    },
                    status: 'OPEN',
                    bookedDate: DateTime.fromMillis(hour + day.date, { zone: timezone }).valueOf(),
                  })
                )
                .filter((day) => !formattedReservations?.includes(day.data.bookedDate))

              const combinedReservations = config.showOpenHours
                ? [...filteredHoursForCurrentDay, ...allReservationsForCurrentDay]
                : allReservationsForCurrentDay

              return {
                ...acc,
                [escapeRoom.id]: {
                  reservations: combinedReservations,
                  calendarAssignments: calendarAssignmentsDataAllRooms?.[escapeRoom.id]?.filter(
                    ({ assignmentDate }) => assignmentDate === day.date
                  ),
                },
              }
            }, {})

            return { ...day, rooms: escapeRoomsObject }
          })

          dispatch({
            type: 'LOAD_CALENDAR',
            payload: { days: calendarDays, firstDate, lastDate },
          })

          if (isManualLoad) {
            manualLoadRef.current = false
          }
        })
      } catch (error) {
        setError(error)

        if (isManualLoad) {
          manualLoadRef.current = false
        }
      }
    },
    [
      activeEscapeRooms,
      activeEscapeRoomsIds,
      activeTenant,
      config.showOpenHours,
      createReservationObject,
      setError,
      state.days,
      timezone,
    ]
  )

  useEffect(() => {
    const handleFocus = () => {
      if (!manualLoadRef.current) {
        loadCalendar(undefined, false)
      }
    }

    if (pathname === PageURLs.Calendar && !state.loadingCalendar) {
      window.addEventListener('focus', handleFocus)
    }

    return () => {
      if (pathname === PageURLs.Calendar && !state.loadingCalendar) {
        window.removeEventListener('focus', handleFocus)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadCalendar, pathname])

  const setIsCalendarLoading = (payload) => {
    dispatch({
      type: 'SET_IS_CALENDAR_LOADING',
      payload,
    })
  }

  const updateCalendarAssignment = useCallback(
    ({ date, userId, type, roomId }) => {
      if (type === 'ADD') {
        let foundDay = state.days.find((day) => day.date === date)
        foundDay.rooms[roomId].calendarAssignments = [
          ...foundDay.rooms[roomId].calendarAssignments,
          { roomId, assignmentDate: date, tenantId: activeTenant, userId },
        ]

        dispatch({
          type: 'UPDATE_DAYS',
          payload: state.days.map((day) => (day.date === foundDay.date ? foundDay : day)),
        })
      }
      if (type === 'REMOVE') {
        let foundDay = state.days.find((day) => day.date === date)
        foundDay.rooms[roomId].calendarAssignments = foundDay.rooms[roomId].calendarAssignments.filter(
          (assignment) => assignment.userId !== userId
        )

        dispatch({
          type: 'UPDATE_DAYS',
          payload: state.days.map((day) => (day.date === foundDay.date ? foundDay : day)),
        })
      }
    },
    [activeTenant, state?.days]
  )

  const updateReservationAssignment = useCallback(
    ({ date, reservationId, userId, type, roomId }) => {
      if (type === 'ADD') {
        let foundDay = state.days.find((day) => day.date === date)
        let foundReservation = foundDay.rooms[roomId].reservations.find(
          (reservation) => reservation.data.key.reservationId === reservationId
        )
        foundReservation.assignments = [
          ...foundReservation.assignments,
          { roomId, reservationId, tenantId: activeTenant, userId },
        ]

        foundDay.rooms[roomId].reservations = foundDay.rooms[roomId].reservations.map((reservation) =>
          reservation.data.key.reservationId === reservationId ? foundReservation : reservation
        )

        dispatch({
          type: 'UPDATE_DAYS',
          payload: state.days.map((day) => (day.date === foundDay.date ? foundDay : day)),
        })
      }
      if (type === 'REMOVE') {
        let foundDay = state.days.find((day) => day.date === date)
        let foundReservation = foundDay.rooms[roomId].reservations.find(
          (reservation) => reservation.data.key.reservationId === reservationId
        )
        foundReservation.assignments = foundReservation.assignments.filter((assignment) => assignment.userId !== userId)

        foundDay.rooms[roomId].reservations = foundDay.rooms[roomId].reservations.map((reservation) =>
          reservation.data.key.reservationId === reservationId ? foundReservation : reservation
        )

        dispatch({
          type: 'UPDATE_DAYS',
          payload: state.days.map((day) => (day.date === foundDay.date ? foundDay : day)),
        })
      }
    },
    [activeTenant, state?.days]
  )

  return (
    <CalendarContext.Provider
      value={{
        days: state.days,
        firstDate: state.firstDate,
        lastDate: state.lastDate,
        loadingCalendar: state.loadingCalendar,
        weeklyCalendarDays: state.weeklyCalendarDays,
        lastUpdated: state.lastUpdated,
        loadCalendar,
        setIsCalendarLoading,
        updateCalendarAssignment,
        updateReservationAssignment,
      }}
      displayName="Calendar"
    >
      {children}
    </CalendarContext.Provider>
  )
}

const useCalendar = () => {
  const context = useContext(CalendarContext)

  if (context === undefined) {
    throw new Error('useCalendar can only be used inside CalendarProvider')
  }

  return context
}

export { CalendarProvider, useCalendar }
