import { MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import { ButtonTriggeredMenu } from 'components'
import { AT_VENUE_PAYMENT_STATUSES } from 'config'
import { ONLINE_PAYMENT_STATUSES } from 'config'
import { PAYMENT_STATUSES } from 'config'
import { useTenantInfo } from 'core'
import { useTranslation } from 'react-i18next'
import { isOnlinePaymentActivatedForRoom } from 'utils'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import { useState } from 'react'
import { SetAmountDialog, UnpaidDialog } from './components'

export default function PaymentStatus({ reservation, handleCloseMainMenu, escapeRoom }) {
  const { t } = useTranslation('reservations')
  const { isStripeActivated } = useTenantInfo()

  const [openAmountDialog, setOpenAmountDialog] = useState({ type: null, open: false })
  const [openUnpaidDialog, setOpenUnpaidDialog] = useState(false)

  const handleSelectStatus = async ({ paymentStatus, handleClose }) => {
    if (paymentStatus === PAYMENT_STATUSES.NOT_PAID.key) {
      setOpenUnpaidDialog(true)
    } else if (
      paymentStatus === PAYMENT_STATUSES.PARTIALLY_PAID_AT_VENUE.key ||
      paymentStatus === PAYMENT_STATUSES.PAID_AT_VENUE.key
    ) {
      setOpenAmountDialog({ type: paymentStatus, open: true })
    }
  }

  return (
    <>
      <ButtonTriggeredMenu
        buttonComponent={MenuItem}
        additionalOnClose={handleCloseMainMenu}
        menuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        buttonText={
          <>
            <ListItemIcon>
              <AttachMoneyOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('ChangePaymentStatus')}</ListItemText>
          </>
        }
        size="small"
      >
        {({ handleClose }) =>
          isOnlinePaymentActivatedForRoom(escapeRoom) && isStripeActivated
            ? [...ONLINE_PAYMENT_STATUSES, ...AT_VENUE_PAYMENT_STATUSES].map((statusItem) => (
                <MenuItem
                  key={statusItem.key}
                  onClick={() => handleSelectStatus({ paymentStatus: statusItem.key, handleClose })}
                  disabled={
                    reservation.paymentStatus === statusItem.key ||
                    statusItem.key === PAYMENT_STATUSES.ONLINE_PAYMENT_PENDING.key ||
                    statusItem.key === PAYMENT_STATUSES.PARTIALLY_PAID_ONLINE.key ||
                    statusItem.key === PAYMENT_STATUSES.PAID_ONLINE.key
                  }
                >
                  {t(statusItem.value)}
                </MenuItem>
              ))
            : AT_VENUE_PAYMENT_STATUSES.map((statusItem) => (
                <MenuItem
                  key={statusItem.key}
                  value={statusItem.key}
                  disabled={reservation.paymentStatus === statusItem.key}
                  onClick={() => handleSelectStatus({ paymentStatus: statusItem.key, handleClose })}
                >
                  {t(statusItem.value)}
                </MenuItem>
              ))
        }
      </ButtonTriggeredMenu>
      {openAmountDialog.open && (
        <SetAmountDialog
          openDialog={openAmountDialog}
          handleCloseMainMenu={handleCloseMainMenu}
          handleCloseDialog={() => setOpenAmountDialog({ type: null, open: false })}
          reservation={reservation}
          escapeRoom={escapeRoom}
        />
      )}
      {openUnpaidDialog && (
        <UnpaidDialog
          openDialog={openUnpaidDialog}
          handleCloseMainMenu={handleCloseMainMenu}
          handleCloseDialog={() => setOpenUnpaidDialog(false)}
          reservation={reservation}
          escapeRoom={escapeRoom}
        />
      )}
    </>
  )
}
