import { Box, Chip, Typography } from '@mui/material'
import { ButtonTriggeredDialog } from 'components'
import AssignmentIcon from '@mui/icons-material/Assignment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

// if you add a new value don't forget to add it to the array below AND to:
// src\components\PhotoBuilder\components\Review\utils.js
export const ROOM_NAME = '[RoomName]'
export const TEAM_NAME = '[TeamName]'
export const TOTAL_TIME = '[TotalTime]'

export const DYNAMIC_VALUES_FOR_PHOTO_FRAME_TEXTS = [ROOM_NAME, TEAM_NAME, TOTAL_TIME]

export default function TextDynamicValues({ handleUpdate }) {
  const { t } = useTranslation('photos')

  const [isDynamicValue, setIsDynamicValue] = useState(null)

  return (
    <ButtonTriggeredDialog
      icon={AssignmentIcon}
      color="primary"
      customActionButton={<></>}
      prematureClose={isDynamicValue}
      closeIcon
      onClose={() => {
        setIsDynamicValue(false)
      }}
    >
      <Typography>{t('SelectADynamicValue')}:</Typography>
      <Box display="flex" gap={0.5}>
        {DYNAMIC_VALUES_FOR_PHOTO_FRAME_TEXTS.map((type) => (
          <Chip
            key={type}
            clickable
            onClick={() => {
              handleUpdate(type)
              setIsDynamicValue(true)
            }}
            label={type}
          />
        ))}
      </Box>
    </ButtonTriggeredDialog>
  )
}
