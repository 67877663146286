import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { memo } from 'react'

const ButtonTriggeredDialog = ({
  handleClick = () => {},
  actionText,
  buttonComponent: Component = Button,
  secondaryActionText,
  buttonText,
  dialogTitle = buttonText,
  children,
  icon: Icon,
  autoClose = true,
  dialogProps,
  dialogContent,
  actionButtonProps,
  iconProps,
  buttonComponentProps,
  closeIcon = false,
  onOpen = () => {},
  onClose = () => {},
  prematureClose = false,
  handleCloseAdditional = () => {},
  hideActions = false,
  customActionButton = null,
  ...rest
}) => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
    onOpen()
  }

  const handleClose = useCallback(() => {
    setOpen(false)
    onClose()
  }, [onClose])

  const handleAction = () => {
    handleClick()
    autoClose && handleClose()
  }

  useEffect(() => {
    prematureClose && handleClose()
  }, [handleClose, prematureClose])

  return (
    <>
      {Icon ? (
        buttonText ? (
          <Tooltip title={buttonText}>
            <IconButton {...rest} aria-label="dialog" onClick={handleOpen}>
              <Icon {...iconProps} />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton {...rest} aria-label="dialog" onClick={handleOpen}>
            <Icon {...iconProps} />
          </IconButton>
        )
      ) : (
        <Component {...rest} {...buttonComponentProps} onClick={handleOpen}>
          {buttonText}
        </Component>
      )}

      <Dialog onClose={handleClose} aria-labelledby="dialog" open={open} {...dialogProps}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} id="dialog">
          {dialogTitle || t('Confirm')}
          {closeIcon && (
            <IconButton onClick={handleClose} size="small">
              <Close />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent sx={{ paddingTop: '8px !important' }} {...dialogContent}>
          {children}
        </DialogContent>
        {!hideActions && (
          <DialogActions>
            <Button
              variant="text"
              onClick={() => {
                handleClose()
                handleCloseAdditional()
              }}
            >
              {secondaryActionText || t('Cancel')}
            </Button>
            {customActionButton ? (
              customActionButton
            ) : (
              <Button color="primary" variant="contained" onClick={handleAction} {...actionButtonProps}>
                {actionText || t('Confirm')}
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    </>
  )
}

export default memo(ButtonTriggeredDialog)
