import { useChat } from 'core'
import { memo, useEffect, useRef, useState } from 'react'
import { ChatContainer, ChatTextField } from './components'
import SimpleBar from 'simplebar-react'

const ChatComponent = ({ tiny = false, expanded = null }) => {
  const { sendMessage, loadChat, hasNext, messages } = useChat()

  const [message, setMessage] = useState('')

  const simpleBarRef = useRef(null)
  const inputRef = useRef(null)

  const chatHeight = tiny ? 400 : 500

  useEffect(() => {
    if (!simpleBarRef.current) return
    simpleBarRef.current.getScrollElement().scrollTo(0, 100000000)
  }, [messages, expanded])

  useEffect(() => {
    if (!simpleBarRef.current) return

    const scrollEventListener = async (e) => {
      if (e.srcElement.scrollTop === 0) {
        // simpleBarRef.current.getScrollElement().scrollTo(0, 1)
        await loadChat({ previousMessagesContext: true, pageSize: 25, from: messages[0].date })
      }
    }

    if (hasNext) {
      simpleBarRef.current.getScrollElement().addEventListener('scroll', scrollEventListener)
    }

    const sb = simpleBarRef.current.getScrollElement()

    return () => sb.removeEventListener('scroll', scrollEventListener)
    // eslint-disable-next-line
  }, [hasNext, messages, expanded])

  useEffect(() => {
    if (!inputRef.current) return

    const listener = async (event) => {
      if (
        event.target.value !== '' &&
        event.keyCode === 13 &&
        !event.shiftKey &&
        event.target.value.trim().length !== 0
      ) {
        await sendMessage(message)
        setMessage('')
        event.preventDefault()
      }
    }
    inputRef.current.addEventListener('keydown', listener)
    const inputRefCurrent = inputRef.current

    return () => {
      inputRefCurrent.removeEventListener('keydown', listener)
    }
    // eslint-disable-next-line
  }, [message])

  return (
    <>
      <SimpleBar style={{ height: chatHeight }} ref={simpleBarRef}>
        <ChatContainer tiny={tiny} />
      </SimpleBar>
      <ChatTextField tiny={tiny} inputRef={inputRef} message={message} setMessage={setMessage} />
    </>
  )
}

export default memo(ChatComponent)
