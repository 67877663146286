import sizing from './sizing'
import lightPalette from './lightPalette'
import darkPalette from './darkPalette'
import overrides from './overrides'
import {
  shadows as lightShadows,
  customShadows as customShadowsLight,
  customColorShadows as customColorShadowsLight,
} from './lightShadows'
import {
  shadows as darkShadows,
  customShadows as customShadowsDark,
  customColorShadows as customColorShadowsDark,
} from './darkShadows'

const lightTheme = {
  palette: lightPalette,
  sizing,
  shadows: lightShadows,
  customColorShadows: customColorShadowsLight,
  customShadows: customShadowsLight,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "'Source Sans Pro', 'Roboto', 'Helvetica', 'Arial', sans-serif",
  },
}

lightTheme.components = overrides(lightTheme)

export { lightTheme }

export const darkTheme = {
  components: overrides,
  palette: darkPalette,
  sizing,
  shadows: darkShadows,
  customColorShadows: customColorShadowsDark,
  customShadows: customShadowsDark,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "'Source Sans Pro', 'Roboto', 'Helvetica', 'Arial', sans-serif",
  },
}
