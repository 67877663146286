import { useTranslation } from 'react-i18next'
import { useDate } from 'core'
import { DateTime } from 'luxon'
import { transformLanguage } from 'helpers'

const DateValue = ({ isUTC = false, isRoomTz = false, isFullDate, children: value }) => {
  const { i18n } = useTranslation()
  const { timezone, localizedDateTimeInMillis } = useDate()

  const format = isFullDate ? { dateStyle: 'short', timeStyle: 'short' } : { dateStyle: 'short' }

  if (isUTC) {
    return DateTime.fromMillis(value, { zone: timezone })
      .setLocale(transformLanguage(i18n.language))
      .setZone('UTC')
      .toLocaleString(format)
  }

  if (isRoomTz) {
    return localizedDateTimeInMillis(value, format)
  }

  return DateTime.fromMillis(value, { zone: timezone })
    .setLocale(transformLanguage(i18n.language))
    .toLocaleString(format)
}

export default DateValue
