import { GIFTCARD_TYPES } from 'config'

export const calculateFinalPrice = (
  numberOfPlayers,
  room,
  giftCard,
  productsPrices,
  gameMode = { priceVariation: 0 },
  priceFromExistingFinances = 0
) => {
  let price = room?.pricingList?.find((item) => +item.numberOfPlayers === numberOfPlayers)?.price ?? 0

  if (!!giftCard) {
    price = calculateFinalPriceWithGiftCard(numberOfPlayers, room, giftCard, priceFromExistingFinances, gameMode)
  }

  return isNaN(price)
    ? 0
    : price - priceFromExistingFinances < 0
    ? 0
    : price +
      calculateFinalPriceFromProducts(productsPrices) +
      (giftCard ? 0 : gameMode.priceVariation) -
      priceFromExistingFinances
}

export const calculatePercentage = (partialValue, totalValue) => {
  if (typeof partialValue !== 'number' || typeof totalValue !== 'number' || totalValue < 0) {
    return 'Invalid input'
  }

  const percentage = (totalValue / 100) * partialValue
  return percentage.toFixed(2)
}

export const calculateFinalPriceFromProducts = (productsPrices) => {
  if (!productsPrices || productsPrices.length === 0) {
    return 0
  } else {
    return productsPrices.reduce((acc, val) => {
      return acc + val
    }, 0)
  }
}

export const calculateFinalPriceWithGiftCard = (
  numberOfPlayers,
  room,
  giftCard,
  priceFromExistingFinances = 0,
  gameMode = { priceVariation: 0 }
) => {
  let price = 0
  const priceForSelectedPlayers =
    room?.pricingList?.find((item) => +item.numberOfPlayers === numberOfPlayers)?.price + gameMode.priceVariation

  if (giftCard?.type === GIFTCARD_TYPES.PERCENTAGE.key) {
    const calculatedPrice = priceForSelectedPlayers - calculatePercentage(giftCard?.amount, priceForSelectedPlayers)

    price = calculatedPrice
  } else if (giftCard?.type === GIFTCARD_TYPES.FIXED_AMOUNT.key) {
    const calculatedPrice = giftCard?.amount > priceForSelectedPlayers ? 0 : priceForSelectedPlayers - giftCard?.amount

    price = calculatedPrice
  }

  return isNaN(price) ? 0 : price - priceFromExistingFinances < 0 ? 0 : price - priceFromExistingFinances
}
