import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useTheme } from '@mui/material'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { ChatComponent } from 'views/Chat/components'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useChat } from 'core'

const Chat = () => {
  const theme = useTheme()
  const { t } = useTranslation('chat')
  const { hasNewMessages, setChatExpand, expanded } = useChat()

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        right: '5%',
        maxWidth: 330,
        width: '100%',
        boxShadow: theme.customShadows.z16,
        zIndex: theme.zIndex.drawer,
      }}
    >
      <Accordion
        sx={{ boxShadow: theme.customShadows.z16, background: 'transparent', border: 0 }}
        expanded={expanded}
        onChange={setChatExpand}
      >
        <AccordionSummary
          expandIcon={<ExpandLessIcon />}
          aria-controls="chat-content"
          id="chat-header"
          sx={{
            bgcolor: hasNewMessages
              ? 'primary.main'
              : theme.palette.mode === 'light'
              ? expanded
                ? theme.palette.grey[200]
                : theme.palette.background.default
              : theme.palette.background.default,
            borderRadius: '8px 8px 0 0',
            '&.MuiAccordion-root': {
              borderRadius: '8px 8px 0 0',
            },
            '& .MuiAccordionSummary-root': {
              borderRadius: '8px 8px 0 0',
            },
          }}
        >
          <Typography variant="body2">
            {t('Chat')} {hasNewMessages && `(${t('NewMessages')})`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0, bgcolor: 'background.paper' }}>
          <ChatComponent tiny={true} expanded={expanded} />
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default memo(Chat)
