import axios from 'axios'
import { API_URL, TRANSACTION_TYPES, FINANCE_TYPES } from 'config'
import useError from './useError'
import { getEntriesToRemove } from 'components/AddRemoveProductsFromCart/utils'

export default function useFinanceMethods() {
  const { setError } = useError()

  const refundFinance = async (income, amount) => {
    try {
      if (income.paymentIntentId) {
        await axios.post(`${API_URL}/stripe-payment/intent/refund`, { paymentIntentId: income.paymentIntentId })
      } else {
        await axios.post(`${API_URL}/finances`, {
          tenantId: income.tenantId,
          roomId: income.roomId,
          financeType: FINANCE_TYPES['EXPENSE'].key,
          transactionType: TRANSACTION_TYPES['REFUND_AT_VENUE'].key,
          amount,
          reservationId: income.reservationId,
          description: `Refund ${income.reservationId} ${income.roomId}`,
          executionDate: Date.now(),
        })
      }
    } catch (error) {
      setError(error)
    }
  }

  const refundProducts = async (income, cart, initialCart) => {
    try {
      if (income.reservationId) {
        await axios.put(`${API_URL}/${income.tenantId}/product/reservation`, {
          key: {
            roomId: income.roomId,
            reservationId: income.reservationId,
          },
          entriesToAdd: [],
          entriesToRemove: getEntriesToRemove(cart, initialCart),
        })
      } else {
        await axios.post(`${API_URL}/${income.tenantId}/product/refund`, {
          orderHistoryId: income.orderHistoryId,
          entriesToRefund: getEntriesToRemove(cart, initialCart),
        })
      }
    } catch (error) {
      setError(error)
    }
  }

  const deleteFinance = async (incomeId) => {
    try {
      await axios.delete(`${API_URL}/finances/${incomeId}`)
    } catch (error) {
      setError(error)
    }
  }

  return {
    refundFinance,
    refundProducts,
    deleteFinance,
  }
}
