import { Box, Button, Icon, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { memo, useEffect, useState } from 'react'
import { RESERVATION_STATUSES } from 'config'
import { useCalendar, useEscapeRooms } from 'core'
import { BlockedReservationDialog, CanceledReservationDialog, PlayedReservationDialog } from './components'
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined'
import { getReservationColor } from 'utils'
import { makeStyles } from '@mui/styles'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 32,
    minWidth: 158,
    justifyContent: 'space-between',
  },
  BLOCKED: {
    color: theme.palette[getReservationColor(RESERVATION_STATUSES.BLOCKED.key)],
  },
  UNCONFIRMED: {
    color: theme.palette[getReservationColor(RESERVATION_STATUSES.UNCONFIRMED.key)].main,
  },
  CONFIRMED: {
    color: theme.palette[getReservationColor(RESERVATION_STATUSES.CONFIRMED.key)].main,
  },
  PLAYED: {
    color: theme.palette[getReservationColor(RESERVATION_STATUSES.PLAYED.key)].main,
  },
  CANCELED: {
    color: theme.palette[getReservationColor(RESERVATION_STATUSES.CANCELED.key)].main,
  },
}))

const Status = ({
  isCalendarView = true,
  reservation,
  order,
  handleCloseMainMenu = () => {},
  setIsUpdated = () => {},
  reservationAssignments = [],
}) => {
  const classes = useStyles()
  const { changeReservationStatus } = useEscapeRooms()
  const { t } = useTranslation(['reservations'])
  const { loadCalendar } = useCalendar()

  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [isGiftCardLoading, setIsGiftCardLoading] = useState(true)

  const open = Boolean(anchorEl)

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setSelectedStatus(null)
    handleCloseMainMenu()
  }

  const handleChangeReservationStatus = async () => {
    if (selectedStatus !== RESERVATION_STATUSES.PLAYED.key && selectedStatus !== RESERVATION_STATUSES.BLOCKED.key) {
      await changeReservationStatus({
        reservationId: reservation.key.reservationId,
        roomId: reservation.key.roomId,
        status: selectedStatus,
      })
      if (isCalendarView) {
        loadCalendar()
      } else {
        setIsUpdated()
      }
      handleCloseMenu()
    }
  }

  useEffect(() => {
    if (!!selectedStatus) {
      if (selectedStatus === RESERVATION_STATUSES.PLAYED.key) {
        !reservation.discountCode && setIsGiftCardLoading(false)
      } else if (
        selectedStatus !== RESERVATION_STATUSES.BLOCKED.key &&
        selectedStatus !== RESERVATION_STATUSES.CANCELED.key
      ) {
        handleChangeReservationStatus()
      }
    }
    // eslint-disable-next-line
  }, [selectedStatus])

  return (
    <>
      {isCalendarView ? (
        <MenuItem id="reservation-status-button" onClick={handleOpenMenu}>
          <ListItemIcon>
            <BookmarkBorderOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('ChangeStatus')}</ListItemText>
        </MenuItem>
      ) : (
        <Button
          color={getReservationColor(RESERVATION_STATUSES[reservation.status].key)}
          variant="outlined"
          size="small"
          disabled={
            RESERVATION_STATUSES[reservation.status].key === RESERVATION_STATUSES.PLAYED.key ||
            RESERVATION_STATUSES[reservation.status].key === RESERVATION_STATUSES.BLOCKED.key
          }
          className={classes.button}
          startIcon={
            <Icon fontSize="large" classes={{ root: classes[reservation.status] }}>
              {RESERVATION_STATUSES[reservation.status].icon}
            </Icon>
          }
          onClick={handleOpenMenu}
        >
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
            className={classes[reservation.status]}
          >
            {t(RESERVATION_STATUSES[reservation.status].value)}
            {RESERVATION_STATUSES[reservation.status].key !== RESERVATION_STATUSES.PLAYED.key &&
              RESERVATION_STATUSES[reservation.status].key !== RESERVATION_STATUSES.BLOCKED.key && (
                <ArrowDropDownIcon />
              )}
          </Box>
        </Button>
      )}

      <Menu
        id="reservation-status-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'reservation-status-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {Object.values(RESERVATION_STATUSES).map(({ key, value: statusValue }) => (
          <MenuItem
            key={key}
            value={key}
            onClick={() => {
              setSelectedStatus(key)
              !isCalendarView && setAnchorEl(null)
            }}
            disabled={
              reservation.status === key ||
              (reservation.status !== RESERVATION_STATUSES.CONFIRMED.key && key === RESERVATION_STATUSES.PLAYED.key)
            }
          >
            {t(statusValue)}
          </MenuItem>
        ))}
      </Menu>

      {selectedStatus === RESERVATION_STATUSES.PLAYED.key && (
        <PlayedReservationDialog
          isCalendarView={isCalendarView}
          order={order}
          isLoading={isGiftCardLoading}
          reservation={reservation}
          setIsLoading={setIsGiftCardLoading}
          open={selectedStatus === RESERVATION_STATUSES.PLAYED.key}
          handleCloseMainMenu={handleCloseMainMenu}
          reservationAssignments={reservationAssignments}
          handleClose={() => setSelectedStatus(null)}
          setIsUpdated={setIsUpdated}
        />
      )}
      {selectedStatus === RESERVATION_STATUSES.BLOCKED.key && (
        <BlockedReservationDialog
          isCalendarView={isCalendarView}
          reservation={reservation}
          open={selectedStatus === RESERVATION_STATUSES.BLOCKED.key}
          handleCloseMainMenu={handleCloseMainMenu}
          handleClose={() => setSelectedStatus(null)}
          setIsUpdated={setIsUpdated}
        />
      )}
      {selectedStatus === RESERVATION_STATUSES.CANCELED.key && (
        <CanceledReservationDialog
          isCalendarView={isCalendarView}
          reservation={reservation}
          open={selectedStatus === RESERVATION_STATUSES.CANCELED.key}
          handleCloseMainMenu={handleCloseMainMenu}
          handleClose={() => setSelectedStatus(null)}
          setIsUpdated={setIsUpdated}
        />
      )}
    </>
  )
}

export default memo(Status)
