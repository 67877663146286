import { TimePicker } from '@mui/x-date-pickers-pro'
import { DateTime } from 'luxon'
import { convertDateTimeToMilliseconds, convertMillisecondsToDateTime } from './helpers'
import { useTranslation } from 'react-i18next'

export default function CompletionTimeField({ value, onChange, textFieldProps }) {
  const { t } = useTranslation('reservations')

  return (
    <TimePicker
      slotProps={{
        textField: {
          fullWidth: true,
          variant: 'outlined',
          ...textFieldProps,
        },
      }}
      ampm={false}
      openTo="hours"
      views={['hours', 'minutes', 'seconds']}
      inputFormat="HH:mm:ss"
      mask="__:__:__"
      label={t('TotalTime')}
      value={convertMillisecondsToDateTime(value ?? 0)}
      onChange={(newValue) => {
        const timeInMilliseconds = convertDateTimeToMilliseconds(
          DateTime.fromJSDate(newValue.toJSDate(), { zone: 'utc' })
        )
        onChange(timeInMilliseconds)
      }}
      timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
      {...textFieldProps}
    />
  )
}
