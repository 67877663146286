import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: `calc(100vh - ${theme.sizing.header.height * 2}px)`,
    padding: theme.spacing(1.5),
  },
}))

const Error = ({ error }) => {
  const classes = useStyles()
  const { t } = useTranslation('errors')

  const {
    response: { status, statusText },
    config: { method, url },
    isAxiosError,
    message,
  } = error

  return (
    <div className={classes.root}>
      {console.log({ error })}
      <Typography variant="h3">
        {status}: {statusText}
      </Typography>
      <Typography variant="h6" color="textSecondary" style={{ fontWeight: 400 }} gutterBottom align="center">
        {message}
      </Typography>
      <img src="/illustrations/error.svg" alt="error" style={{ maxWidth: 550, width: '100%', height: 'auto' }} />

      {isAxiosError && (
        <Typography color="textSecondary" style={{ fontWeight: 400, fontSize: 14 }}>
          This is an axios related issue!
        </Typography>
      )}
      {method && url && (
        <Typography color="textSecondary" style={{ fontWeight: 400, fontSize: 14 }} gutterBottom>
          {t('ErrorComesFromHere', { method: method.toUpperCase(), url })}
        </Typography>
      )}
    </div>
  )
}

export default Error
