import { ShoppingCartOutlined } from '@mui/icons-material'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import axios from 'axios'
import { AddRemoveProductsFromCart, ButtonTriggeredDialog } from 'components'
import { getEntriesToAdd, getEntriesToRemove } from 'components/AddRemoveProductsFromCart/utils'
import { API_URL } from 'config'
import { useCalendar, useTenantInfo } from 'core'
import { deepEqual } from 'helpers'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { transformOrderEntriesToCartObject } from 'utils'
import { useError } from 'utils/hooks'

export default function Order({ order, reservation, handleCloseMainMenu }) {
  const { t } = useTranslation('reservations')
  const { setError } = useError()
  const { activeTenant: tenantId, refetchProducts, products } = useTenantInfo()
  const { loadCalendar } = useCalendar()

  const orderedProducts = transformOrderEntriesToCartObject(order?.orderEntries ?? [], products)

  const [cart, setCart] = useState(orderedProducts)
  const [initialCart, setInitialCart] = useState(orderedProducts)

  const handleUpdateOrder = async () => {
    try {
      const { data: updatedOrderData } = await axios.put(`${API_URL}/${tenantId}/product/reservation`, {
        key: {
          roomId: reservation.key.roomId,
          reservationId: reservation.key.reservationId,
        },
        entriesToAdd: getEntriesToAdd(cart, initialCart),
        entriesToRemove: getEntriesToRemove(cart, initialCart),
      })
      const newCart = transformOrderEntriesToCartObject(updatedOrderData.orderEntries, products)

      if (!deepEqual(newCart, initialCart)) {
        setInitialCart(newCart)
      }
      await loadCalendar()
      refetchProducts()
      handleCloseMainMenu()
    } catch (error) {
      setError(error)
    }
  }

  return (
    <ButtonTriggeredDialog
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
      buttonComponent={MenuItem}
      actionButtonProps={{ disabled: deepEqual(cart, initialCart) }}
      dialogTitle={t('Products')}
      handleClick={handleUpdateOrder}
      buttonText={
        <>
          <ListItemIcon>
            <ShoppingCartOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('Products')}</ListItemText>
        </>
      }
    >
      <AddRemoveProductsFromCart roomId={reservation.key.roomId} cart={cart} setCart={setCart} />
    </ButtonTriggeredDialog>
  )
}
