import { useState, useEffect, memo, useRef } from 'react'
import { makeStyles } from '@mui/styles'
import { CustomPaperComponent } from 'components'
import {
  CircularProgress,
  TablePagination,
  Typography,
  Checkbox,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Link,
} from '@mui/material'
import { getComparator, getObjectValueFromArray, stableSort } from './functions'
import { TableHead, TableToolbar } from './components'
import { useError } from 'utils/hooks'
import DisplayValue from '../TableDisplayValues'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { useUserPreferences } from 'core'
import { PAGE_SIZES } from 'config'
import { DeleteDialog } from './components/TableToolbar/components'
import { alpha, calculateColumnLength } from 'utils'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import useSWR from 'swr'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  noPaper: {
    background: 'transparent !important',
    boxShadow: 'none !important',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  row: {
    borderBottom: '1px solid rgba(81, 81, 81, 0.33) !important',
  },
  select: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    fontSize: theme.typography.caption.fontSize,
  },
  selectedRow: {
    backgroundColor:
      theme.palette.mode === 'light' ? `${theme.palette.grey[200]} !important` : `rgba(255, 255, 255, 0.10) !important`,
  },
  hover: {
    '&:hover': {
      backgroundColor: `${alpha(theme.palette.grey[500], 0.08)} !important`,
    },
  },
}))

const EnhancedTable = ({
  getEndpoint,
  deleteEndpoint = null,
  singularDeleteEndpoint = null,
  putEndpoint = null,
  postEndpoint = null,
  searchEndpoint = null,
  noPaper = false,
  columns,
  size = 'small',
  isUpdatedFromOutside = false,
  filters,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const withCheckbox = deleteEndpoint || putEndpoint || postEndpoint
  const withActions = singularDeleteEndpoint
  const columnsLength = calculateColumnLength(columns.length, withActions, withCheckbox)

  const { data: response, isLoading, mutate } = useSWR(getEndpoint)
  const { pageSize: rowsPerPage, changePageSize } = useUserPreferences()

  const { setError } = useError()

  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('id')
  const [page, setPage] = useState(0)
  const [selected, setSelected] = useState([])

  const tableBody = useRef(0)

  useEffect(() => {
    isUpdatedFromOutside && mutate()

    // eslint-disable-next-line
  }, [isUpdatedFromOutside])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    changePageSize(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  const handleDelete = async () => {
    try {
      await axios.delete(deleteEndpoint, { data: selected })
      mutate()
      setSelected([])
    } catch (error) {
      setError(error)
    }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1

  return (
    <div className={classes.root}>
      <CustomPaperComponent className={clsx(noPaper && classes.noPaper, classes.paper)}>
        <TableToolbar numSelected={selected.length} deleteEndpoint={deleteEndpoint} handleDelete={handleDelete} />
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" aria-label="table" size={size}>
            <TableHead
              classes={classes}
              selected={selected}
              setSelected={setSelected}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              columns={columns}
              withCheckbox={withCheckbox}
              withActions={withActions}
              rows={response?.data}
              isLoading={isLoading}
            />
            {!isLoading ? (
              <TableBody ref={tableBody}>
                {stableSort(response, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id)

                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                        classes={{ selected: classes.selectedRow, hover: classes.hover }}
                      >
                        {withCheckbox && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              onClick={(event) => handleClick(event, row.id)}
                            />
                          </TableCell>
                        )}
                        {columns.map((column) => {
                          const content = (
                            <DisplayValue
                              row={row}
                              column={column}
                              additionalFieldValue={
                                column.additionalField && getObjectValueFromArray(column.additionalField, row)
                              }
                              setIsUpdated={mutate}
                            >
                              {!column.json
                                ? getObjectValueFromArray(column.field, row)
                                : getObjectValueFromArray(column.field, JSON.parse(row[column.json]))}
                            </DisplayValue>
                          )
                          return (
                            <TableCell key={column.name} align={column.align}>
                              {column.isLink ? (
                                <Link
                                  component={RouterLink}
                                  to={`${column.linkPrepend ?? ''}${
                                    column.linkPrependWithObjectValue
                                      ? getObjectValueFromArray(column.linkPrependWithObjectValue, row)
                                      : ''
                                  }/${getObjectValueFromArray(column.link, row)}${column.linkAppend ?? ''}${
                                    column.linkAppendWithObjectValue
                                      ? getObjectValueFromArray(column.linkAppendWithObjectValue, row)
                                      : ''
                                  }`}
                                >
                                  {content}
                                </Link>
                              ) : (
                                content
                              )}
                            </TableCell>
                          )
                        })}
                        {withActions && (
                          <TableCell align="right">
                            {singularDeleteEndpoint && (
                              <DeleteDialog
                                row={row}
                                setSelected={setSelected}
                                setIsUpdated={mutate}
                                singularDeleteEndpoint={singularDeleteEndpoint}
                              />
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow style={{ height: tableBody.current?.clientHeight || 0 }}>
                  <TableCell align="center" colSpan={columnsLength}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {!isLoading ? (
          <TablePagination
            rowsPerPageOptions={PAGE_SIZES}
            component="div"
            count={response?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => (
              <Typography color="textSecondary" variant="caption">
                {t('ProductRange', { start: from, end: to, totalProducts: count })}
              </Typography>
            )}
            labelRowsPerPage={
              <Typography color="textSecondary" variant="caption">
                {t('RowsPerPage')}:
              </Typography>
            }
            SelectProps={{ className: classes.select }}
          />
        ) : (
          ''
        )}
      </CustomPaperComponent>
    </div>
  )
}

export default memo(EnhancedTable)
