import { useUserPreferences } from 'core'
import { useState, useEffect } from 'react'

const getNestedValue = (obj, path) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj)
}

export const usePagination = ({ paginateBy = 'id' } = {}) => {
  const defaultAscValue = false
  const { pageSize, changePageSize } = useUserPreferences()

  const [hasNext, setHasNext] = useState(false)
  const [paginationData, setPaginationData] = useState({ data: [], hasNext: hasNext })
  const [from, setFrom] = useState(0)
  const [countClicks, setCountClicks] = useState(0)
  const [startNextOrPreviousPageFrom, setStartNextOrPreviousPageFrom] = useState(0)
  const [asc, setAsc] = useState(defaultAscValue)

  const handleResetPagination = () => {
    setFrom(0)
    setAsc(defaultAscValue)
    setCountClicks(0)
  }
  useEffect(() => {
    handleResetPagination()
    // eslint-disable-next-line
  }, [pageSize, defaultAscValue])

  useEffect(() => {
    if (paginationData.data.length) {
      setHasNext(paginationData.hasNext)
      const allIds = paginationData.data.map((instance) => getNestedValue(instance, paginateBy))
      const startNextPageFrom = allIds.reduce((a, b) => {
        return Math.max(a, b)
      })
      const startPrevPageFrom = allIds.reduce((a, b) => {
        return Math.min(a, b)
      })
      setStartNextOrPreviousPageFrom({
        next: startNextPageFrom,
        prev: startPrevPageFrom,
      })
    }
  }, [paginationData, asc, pageSize, paginateBy])

  return {
    from,
    countClicks,
    setCountClicks,
    pageSize,
    setPageSize: changePageSize,
    startNextOrPreviousPageFrom,
    hasNext,
    setFrom,
    asc,
    setAsc,
    setPaginationData,
    handleResetPagination,
  }
}
