import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  Box,
  Alert,
  InputAdornment,
} from '@mui/material'
import { API_URL } from 'config'
import { PAYMENT_STATUSES } from 'config'
import { useCalendar, useEscapeRooms, useTenantInfo } from 'core'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { transformOrderEntriesToCartObject } from 'utils'
import { usePricing } from 'utils/hooks'
import { Summary } from 'views/Calendar/components/Reservations/components/Reservation/components/Status/components/PlayedReservationDialog/components'

const SetAmountDialog = ({
  openDialog,
  handleCloseMainMenu,
  handleCloseDialog,
  reservation,
  escapeRoom,
  isCalendarView = true,
  setIsUpdated = () => {},
}) => {
  const { t } = useTranslation('reservations')
  const { changeReservationStatus } = useEscapeRooms()
  const { loadCalendar } = useCalendar()
  const { products, activeTenantFullInfo } = useTenantInfo()

  const { data: giftCard, isLoading: isValidateLoading } = useSWR(
    openDialog.open &&
      openDialog.type === PAYMENT_STATUSES.PAID_AT_VENUE.key &&
      reservation.discountCode &&
      `${API_URL}/voucher/byCode/${reservation.discountCode}/${escapeRoom.tenantId}`,
    { shouldRetryOnError: false }
  )

  const { data: orderData } = useSWR(
    openDialog.open &&
      `${API_URL}/${escapeRoom.tenantId}/order-history/${escapeRoom.id}/${reservation.key.reservationId}`,
    { shouldRetryOnError: false, fallbackData: [] }
  )

  const cart = transformOrderEntriesToCartObject(orderData?.orderEntries ?? [], products)

  const { balanceDue, subtotal, validProducts, selectedGameMode, existingFinances } = usePricing({
    cart,
    reservation,
    giftCard,
    numberOfPlayers: reservation.playerCount,
  })

  const [amount, setAmount] = useState(
    openDialog.type === PAYMENT_STATUSES.PARTIALLY_PAID_AT_VENUE.key ? escapeRoom.depositAmount : balanceDue
  )

  useEffect(() => {
    if (openDialog.open) {
      setAmount(
        openDialog.type === PAYMENT_STATUSES.PARTIALLY_PAID_AT_VENUE.key ? escapeRoom.depositAmount : balanceDue
      )
    }
    // eslint-disable-next-line
  }, [balanceDue, openDialog.open, openDialog.type])

  const handleClose = () => {
    handleCloseDialog()
    setAmount(null)
  }

  return (
    <Dialog open={openDialog.open} onClose={handleCloseDialog} fullWidth maxWidth="xs">
      <DialogTitle>
        {openDialog.type === PAYMENT_STATUSES.PARTIALLY_PAID_AT_VENUE.key ? t('SetDepositAmount') : t('SetAmount')}
      </DialogTitle>
      <DialogContent sx={{ pt: '8px !important' }}>
        {openDialog.type === PAYMENT_STATUSES.PAID_AT_VENUE.key && (
          <Alert severity="info">{t('FullPaymentDisclaimer')}</Alert>
        )}
        {openDialog.type === PAYMENT_STATUSES.PAID_AT_VENUE.key && (
          <Box mb={2}>
            <Summary
              {...{
                balanceDue,
                subtotal,
                cart,
                escapeRoom,
                giftCard,
                isValidateLoading,
                selectedGameMode,
                validProducts,
                reservation,
                existingFinances,
                selectedPlayers: reservation.playerCount,
              }}
            />
          </Box>
        )}
        <TextField
          label={
            openDialog.type === PAYMENT_STATUSES.PARTIALLY_PAID_AT_VENUE.key
              ? t('escaperooms:DepositAmount')
              : t('finances:Amount')
          }
          onChange={(e) => setAmount(e.target.value)}
          value={amount}
          fullWidth
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">{activeTenantFullInfo?.currency}</InputAdornment>,
          }}
        />
        {amount <
          (openDialog.type === PAYMENT_STATUSES.PARTIALLY_PAID_AT_VENUE.key
            ? escapeRoom.depositAmount
            : balanceDue) && (
          <Alert sx={{ mt: 2 }} severity="warning">
            {t('AmountIsLessThanTotal')}
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            handleClose()
          }}
        >
          {t('translation:Cancel')}
        </Button>
        <Button
          color="primary"
          onClick={async () => {
            handleClose()
            handleCloseMainMenu()
            await changeReservationStatus({
              reservationId: reservation.key.reservationId,
              roomId: reservation.key.roomId,
              paymentStatus: openDialog.type,
              amount,
            })
            isCalendarView && (await loadCalendar())
            setIsUpdated()
          }}
        >
          {t('translation:Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SetAmountDialog
