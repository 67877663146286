import { SnackbarProvider } from 'notistack'
import {
  ThemeProvider,
  AuthProvider,
  UserPreferencesProvider,
  TenantInfoProvider,
  EscapeRoomsProvider,
  ConfigurationProvider,
  TasksProvider,
  DateProvider,
  SubscriptionProvider,
  CalendarProvider,
  ChatProvider,
  MiscellaneousProvider,
} from './contexts'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon'
import { useTranslation } from 'react-i18next'
import { StyledEngineProvider } from '@mui/material/styles'
import { transformLanguage } from 'helpers'
import axios from 'axios'
import { SWRConfig } from 'swr'
import { SERVICES_URL, UNLEASH_CLIENT_KEY } from 'config'
import { capitalize } from '@mui/material'
import FlagProvider from '@unleash/proxy-client-react'

export const fetcher = (url) => axios.get(`${url}`).then(({ data }) => data)

const featureFlagsConfig = {
  url: `${SERVICES_URL}/unleash/feature-flags`,
  clientKey: UNLEASH_CLIENT_KEY,
  appName: 'Keyhowl',
  disableMetrics: true,
  environment: capitalize(process.env.NODE_ENV),
  refreshInterval: 600, // 10 minutes
}

const Store = ({ children }) => {
  const { i18n } = useTranslation()

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <SnackbarProvider maxSnack={3} preventDuplicate>
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={transformLanguage(i18n.language)}>
            <FlagProvider config={featureFlagsConfig}>
              <AuthProvider>
                <SWRConfig value={{ fetcher }}>
                  <MiscellaneousProvider>
                    <ConfigurationProvider>
                      <TenantInfoProvider>
                        <EscapeRoomsProvider>
                          <SubscriptionProvider>
                            <UserPreferencesProvider>
                              <TasksProvider>
                                <DateProvider>
                                  <CalendarProvider>
                                    <ChatProvider>{children}</ChatProvider>
                                  </CalendarProvider>
                                </DateProvider>
                              </TasksProvider>
                            </UserPreferencesProvider>
                          </SubscriptionProvider>
                        </EscapeRoomsProvider>
                      </TenantInfoProvider>
                    </ConfigurationProvider>
                  </MiscellaneousProvider>
                </SWRConfig>
              </AuthProvider>
            </FlagProvider>
          </LocalizationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default Store
