import { createContext, useReducer, useEffect, useContext } from 'react'
import axios, { interceptors } from 'axios'
import { getCookie, parseJwt, removeCookie, setAuthToken } from 'helpers'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useError } from 'utils/hooks'
import { ACCEPTED_GROUPS, API_URL, client } from 'config'
import { useCallback } from 'react'
import * as Sentry from '@sentry/browser'

export const AUTH_TOKEN = 'auth_token'
export const SESSION_ID = 'session_id'
export const REFRESH_TOKEN = 'refresh_token'

const authReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'USER_LOADED':
      return {
        ...state,
        loadingAuth: false,
        isAuthenticated: true,
        user: payload,
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        loadingAuth: false,
        isAuthenticated: true,
        user: payload,
        loggingIn: true,
      }
    case 'SET_TOKEN':
      return {
        ...state,
        token: payload,
      }
    case 'EDIT_PROFILE':
      return {
        ...state,
        user: payload,
      }
    case 'SET_LOGGING_IN':
      return {
        ...state,
        loggingIn: payload,
      }
    case 'LOGIN_FAIL':
    case 'LOGOUT':
    case 'AUTH_ERROR':
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loadingAuth: false,
        user: null,
      }
    default:
      return state
  }
}

const initialState = {
  token: null,
  isAuthenticated: null,
  loadingAuth: true,
  user: null,
  loggingIn: false,
}

const AuthContext = createContext(initialState)

const AuthProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [state, dispatch] = useReducer(authReducer, initialState)
  const { t } = useTranslation(['auth'])
  const { setError } = useError()

  interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (
        error?.response?.config?.url?.includes(API_URL) &&
        error?.response?.status === 401 &&
        error?.response?.data?.errorCode !== 1000
      ) {
        logout({ sessionExpired: true })
      }

      return Promise.reject(error)
    }
  )

  useEffect(() => {
    loadUser()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (state.token) {
      const tokenExpiryDateInMs = parseJwt(getCookie(AUTH_TOKEN))?.exp * 1000

      const revalidateTimeInMs = tokenExpiryDateInMs - Date.now()

      console.info(`Token will be refreshed after ${((revalidateTimeInMs - 60000) / 1000 / 60).toFixed(2)} minutes`)
      const timer = setTimeout(() => {
        refreshToken()
      }, revalidateTimeInMs - 60000)
      return () => clearTimeout(timer)
      // }
    }
    // eslint-disable-next-line
  }, [state.token])

  const saveAuthToken = () => {
    const token = getCookie(AUTH_TOKEN)
    dispatch({
      type: 'SET_TOKEN',
      payload: token,
    })
    setAuthToken(token)
  }

  const refreshToken = useCallback(async () => {
    removeCookie(AUTH_TOKEN)
    try {
      delete axios.defaults.headers.common['Authorization']
      await axios.post(
        `${API_URL}/login/refresh`,
        {
          sessionId: getCookie(SESSION_ID),
          refreshToken: getCookie(REFRESH_TOKEN),
          username: localStorage.username,
        },
        { withCredentials: true }
      )

      saveAuthToken()
    } catch (error) {
      setError(error)
      removeCookie(SESSION_ID)
      removeCookie(REFRESH_TOKEN)
      dispatch({ type: 'AUTH_ERROR' })
    }
  }, [setError])

  const loadUser = useCallback(async () => {
    if (getCookie(AUTH_TOKEN)) {
      saveAuthToken()
      try {
        const { data } = await axios.get(`${API_URL}/user/me`)

        Sentry.setUser({ email: data.username, username: `${data?.firstName} ${data?.lastName}`, id: data.id })

        dispatch({
          type: 'USER_LOADED',
          payload: data,
        })
      } catch (error) {
        setError(error)
        dispatch({ type: 'AUTH_ERROR' })
      }
    } else if (getCookie(REFRESH_TOKEN)) {
      await refreshToken()
      loadUser()
    } else {
      dispatch({ type: 'AUTH_ERROR' })
    }
  }, [refreshToken, setError])

  const updateUser = (data) => {
    dispatch({
      type: 'USER_LOADED',
      payload: data,
    })
  }

  const login = async ({ email, password, greet = true }) => {
    const body = { username: email.toLowerCase(), pass: password }

    try {
      delete axios.defaults.headers.common['Authorization']
      const { data, status } = await axios.post(`${API_URL}/login`, body, { withCredentials: true })
      if (status === 200) {
        saveAuthToken()
        localStorage.setItem('username', data.username)
      }

      if (ACCEPTED_GROUPS.includes(data.userGroup)) {
        greet && enqueueSnackbar(`${t('auth:WelcomeBackUser', { name: data.firstName })}`, { variant: 'success' })
        dispatch({
          type: 'LOGIN_SUCCESS',
          payload: data,
        })
      } else {
        enqueueSnackbar(`${t('auth:NoRightsToLogin')}`, { variant: 'error' })
      }
    } catch (error) {
      setError(error)

      dispatch({
        type: 'LOGIN_FAIL',
      })
    }
  }

  const logout = async ({ sessionExpired = false } = {}) => {
    dispatch({ type: 'LOGOUT' })

    Sentry.setUser(null)

    removeCookie(AUTH_TOKEN)
    removeCookie(REFRESH_TOKEN)
    removeCookie(SESSION_ID)

    sessionExpired
      ? enqueueSnackbar(t('auth:SessionExpired'), { variant: 'warning' })
      : enqueueSnackbar(t('auth:SeeYouSoon'), { variant: 'success' })
  }

  const changePassword = async ({ newPass, oldPass, username }) => {
    try {
      await axios.post(`${API_URL}/user/change-pass`, { newPass, oldPass, username })
      enqueueSnackbar(t('auth:PasswordChangedSuccessfully'), { variant: 'success' })
    } catch (error) {
      setError(error)
    }
  }

  const register = async (values) => {
    const { password, firstName, lastName, phoneNumber, email } = values
    try {
      await axios.post(`${API_URL}/user/register`, {
        pass: password,
        user: {
          avatar: '',
          firstName,
          lastName,
          phoneNumber,
          userGroup: client,
          username: email.toLowerCase(),
        },
      })
      enqueueSnackbar(t('auth:UserCreated'), { variant: 'success' })
      login({ email, password, greet: false })
    } catch (error) {
      setError(error)
    }
  }

  const editProfile = async (values) => {
    try {
      const { data } = await axios.put(`${API_URL}/user/${state.user.username}`, {
        ...values,
        username: state.user.username,
        userGroup: state.user.userGroup,
      })

      dispatch({
        type: 'EDIT_PROFILE',
        payload: data,
      })
      enqueueSnackbar(t('auth:ProfileUpdatedSuccessfully'), { variant: 'success' })
    } catch (error) {
      setError(error)
    }
  }

  const requestPasswordReset = async (username) => {
    try {
      await axios.post(`${API_URL}/user/reset-request/${username}`)
    } catch (error) {
      setError(error)
    }
  }

  const resetPassword = async (request) => {
    try {
      await axios.post(`${API_URL}/user/reset`, request)
      enqueueSnackbar(t('auth:PasswordSuccessfullyReset'), { variant: 'success' })
    } catch (error) {
      setError(error)
    }
  }

  const invalidateSessions = async () => {
    try {
      await axios.post(`${API_URL}/login/sessions/invalidate`)
      await logout()
      enqueueSnackbar(t('auth:AllSessionsWereInvalidated'), { variant: 'success' })
    } catch (error) {
      setError(error)
    }
  }

  const setLoggingIn = (boolean) => {
    dispatch({
      type: 'SET_LOGGING_IN',
      payload: boolean,
    })
  }

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        token: state.token,
        loggingIn: state.loggingIn,
        loadingAuth: state.loadingAuth,
        isAuthenticated: state.isAuthenticated,
        login,
        logout,
        register,
        updateUser,
        editProfile,
        setLoggingIn,
        resetPassword,
        changePassword,
        invalidateSessions,
        requestPasswordReset,
      }}
      displayName="Authentication"
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth can only be used inside AuthProvider')
  }

  return context
}

export { AuthProvider, useAuth }
