import { Box, FormHelperText, Grid, IconButton, ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { Add } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { ButtonTriggeredMenu, ProfileAvatar } from 'components'
import { API_URL } from 'config'
import { useCalendar, useEscapeRooms, useTenantInfo } from 'core'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState } from 'react'
import { useError } from 'utils/hooks'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  avatar: {
    position: 'relative',

    '&:hover:before': {
      content: `"✖"`,
      fontSize: 14,
      position: 'absolute',
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,0.4)',
    },
  },
}))

const Assignees = ({
  reservationId,
  roomId,
  reservationAssignments,
  setReservationAssignments,
  isCalendarView,
  setDisableActionButton,
}) => {
  const classes = useStyles()
  const { setError } = useError()
  const { t } = useTranslation('reservations')
  const { activeTenantUsers } = useTenantInfo()
  const { escapeRooms, addAssigneeToReservation, removeAssigneeFromReservation } = useEscapeRooms()
  const { loadCalendar } = useCalendar()

  const [isUpdated, setIsUpdated] = useState(true)

  const escapeRoom = escapeRooms.find((escapeRoom) => escapeRoom.id === roomId)

  const getReservationAssignmentsForReservationAsync = useCallback(async () => {
    try {
      const { data } = await axios(`${API_URL}/reservation/assignment/${roomId}/by-reservation/${reservationId}`)
      setReservationAssignments(data)
      setIsUpdated(false)
      setDisableActionButton(false)
    } catch (error) {
      setError(error)
      setIsUpdated(false)
      setDisableActionButton(false)
    }
  }, [reservationId, roomId, setDisableActionButton, setError, setReservationAssignments])

  useEffect(() => {
    if (!isCalendarView && isUpdated) {
      getReservationAssignmentsForReservationAsync()
    } else {
      setDisableActionButton(false)
    }

    // eslint-disable-next-line
  }, [isCalendarView, reservationId, isUpdated])

  return (
    <Box>
      <Grid container>
        <Grid item>
          <Box display="flex">
            {reservationAssignments.map((assignee) => {
              const user = activeTenantUsers.find((user) => assignee.userId === user.id)
              return (
                <ProfileAvatar
                  key={assignee.userId}
                  sx={{
                    width: 36,
                    height: 36,
                    marginRight: 0.75,
                    cursor: 'pointer',
                  }}
                  user={user}
                  className={classes.avatar}
                  onClick={async () => {
                    setDisableActionButton(true)
                    await removeAssigneeFromReservation({
                      userId: user.id,
                      roomId,
                      reservationId,
                    })

                    if (isCalendarView) {
                      await loadCalendar()
                      await getReservationAssignmentsForReservationAsync()
                    } else {
                      setIsUpdated(true)
                    }
                  }}
                />
              )
            })}
          </Box>
        </Grid>
        <Grid item>
          <ButtonTriggeredMenu
            buttonComponent={IconButton}
            size="small"
            sx={{ border: '1px dashed', borderColor: 'divider' }}
            buttonText={<Add />}
            disabled={
              escapeRoom.maxReservationAssignees <= reservationAssignments.length ||
              reservationAssignments.length === escapeRoom.roomAssignments.length
            }
          >
            {({ handleClose }) =>
              escapeRoom.roomAssignments
                .filter(
                  (assignee) => !reservationAssignments.map((assignment) => assignment.userId).includes(assignee.userId)
                )
                .map((assignee) => {
                  const user = activeTenantUsers.find((user) => assignee.userId === user.id)
                  return (
                    <MenuItem
                      key={user.id}
                      onClick={async () => {
                        setDisableActionButton(true)
                        await addAssigneeToReservation({
                          userId: user.id,
                          reservationId,
                          roomId,
                        })

                        if (isCalendarView) {
                          await loadCalendar()
                          await getReservationAssignmentsForReservationAsync()
                        } else {
                          setIsUpdated(true)
                        }

                        escapeRoom.maxReservationAssignees - 1 === reservationAssignments.length && handleClose()
                      }}
                    >
                      <ListItemIcon>
                        <ProfileAvatar sx={{ marginRight: 1, width: 30, height: 30 }} key={user.id} user={user} />
                      </ListItemIcon>
                      <ListItemText>
                        {user.firstName} {user.lastName}
                      </ListItemText>
                    </MenuItem>
                  )
                })
            }
          </ButtonTriggeredMenu>
        </Grid>
        {reservationAssignments.length === 0 && (
          <Grid item xs={12}>
            <FormHelperText error>{t('NoGameMastersAddedDisclaimer')}</FormHelperText>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default Assignees
