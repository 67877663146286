import { Grid, Typography } from '@mui/material'
import CustomPaperComponent from 'components/CustomPaperComponent'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useError } from 'utils/hooks'
import Loading from 'views/Loading'
import { FrameItem } from 'views/PhotoFrames/components'

export default function SelectFrame({
  isLoading,
  error,
  framesData,
  setActiveStep,
  setStageDimensions,
  setAspectRatio,
  setElements,
  frameId,
  setFrameId,
}) {
  const { t } = useTranslation('photos')
  const { setError } = useError()

  const handleSelectFrame = (frame) => {
    const frameData = JSON.parse(frame.content)
    setStageDimensions(frameData.stageDimensions)
    setAspectRatio(frameData.aspectRatio)
    setElements(frameData.elements.map((element) => ({ ...element, listening: false })))
    setFrameId(frame.id)
    setActiveStep((prevStep) => prevStep + 1)
  }

  useEffect(() => {
    if (error) {
      setError(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  if (isLoading) return <Loading height="auto" />

  return (
    <Grid container spacing={3}>
      {!!framesData.length ? (
        framesData.map((frame) => (
          <Grid item xs={12} sm={6} md={4} key={frame.id}>
            <CustomPaperComponent
              sx={(theme) => ({
                transform: frameId === frame.id ? 'scale(1.05)' : 'scale(1)',
                boxShadow: frameId === frame.id ? `${theme.customShadows.z12} !important` : 'none',
                transition: 'all 0.25s',
                '&:hover': {
                  transform: 'scale(1.01)',
                  transition: 'all 0.25s',
                  boxShadow: `${theme.customShadows.z12} !important`,
                },
              })}
            >
              <FrameItem frame={frame} hideButtons onClick={() => handleSelectFrame(frame)} />
            </CustomPaperComponent>
          </Grid>
        ))
      ) : (
        <Typography>{t('NoFramesCreated')}</Typography>
      )}
    </Grid>
  )
}
