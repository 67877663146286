const KeyhowlLogo = () => {
  return (
    <>
      <img
        src={localStorage.theme === 'dark' ? '/images/logoLight.png' : '/images/logo.png'}
        alt="logo"
        width="40"
        height="40"
      />
    </>
  )
}

export default KeyhowlLogo
