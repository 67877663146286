import { Avatar, Tooltip } from '@mui/material'
import { API_URL } from 'config'
import { getInitials } from 'helpers'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * Display user avatar with initials if no image is provided
 * @param width - default is 40px
 * @param height - default is 40px
 * @param avatar - full avatar URL
 * @param firstName
 * @param lastName
 * @param showTooltip - default is false,
 * @param tooltipPlacement - default is top,
 * @param cursor - default is default
 * @param {...rest} - goes to the avatar
 */
const ProfileAvatar = ({
  width = 40,
  height = 40,
  avatar = null,
  firstName = null,
  lastName = null,
  showTooltip = true,
  tooltipPlacement = 'top',
  cursor = 'default',
  user = null,
  tooltipTitle = `${user?.firstName ?? firstName} ${user?.lastName ?? lastName}`,
  ...rest
}) => {
  const { t } = useTranslation('chat')
  const _tooltipTitle = (firstName && lastName) || user ? tooltipTitle : t('RemovedUser')
  const src = avatar ?? (user?.avatar && `${API_URL}/media/${user?.avatar}`)
  const avatarComponent = (
    <Avatar
      sx={{ width, height, cursor }}
      aria-label="user avatar"
      src={src}
      // loading="lazy"
      alt={getInitials(`${firstName ?? user?.firstName} ${lastName ?? user?.lastName}`)}
      {...rest}
    >
      {((firstName && lastName) || user) &&
        getInitials(`${firstName ?? user?.firstName} ${lastName ?? user?.lastName}`)}
    </Avatar>
  )

  return !showTooltip ? (
    avatarComponent
  ) : (
    <Tooltip title={_tooltipTitle} placement={tooltipPlacement}>
      {avatarComponent}
    </Tooltip>
  )
}

export default memo(ProfileAvatar)
