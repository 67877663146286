import { Box, Checkbox, FormControlLabel, InputAdornment, Paper, TextField } from '@mui/material'
import { ButtonTriggeredDialog } from 'components'
import { Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { Delete } from '@mui/icons-material'
import { StyleText, TextDynamicValues } from './components'

export default function Element({ element, handleChange, setElements, index }) {
  const { t } = useTranslation('photos')

  const handleDeleteElement = (id) => {
    setElements((prevElements) => prevElements.filter((element) => element.id !== id))
  }

  return (
    <Draggable draggableId={element.id} index={index}>
      {(provided) => (
        <Paper sx={{ mb: 1, p: 1 }} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
              <DragIndicatorIcon sx={{ color: 'textSecondary', ml: 1, mr: 1 }} />
              {element.type === 'text' && (
                <TextField
                  label={t('Text')}
                  size="small"
                  value={element.text}
                  onChange={(e) =>
                    setElements((prevElements) =>
                      prevElements.map((_element) =>
                        _element.id === element.id && _element.type === 'text'
                          ? { ..._element, text: e.target.value }
                          : _element
                      )
                    )
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextDynamicValues
                          handleUpdate={(type) =>
                            setElements((prevElements) =>
                              prevElements.map((_element) =>
                                _element.id === element.id && _element.type === 'text'
                                  ? { ..._element, text: type }
                                  : _element
                              )
                            )
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              {element.type === 'image' && (
                <img src={element.src} style={{ width: 'auto', maxHeight: 40 }} alt={element.id} />
              )}
              <TextField
                size="small"
                type="number"
                label="X axis"
                value={Math.round(element.x)}
                onChange={(e) => handleChange(element.id, 'x', Math.round(Number(e.target.value)))}
                sx={{ maxWidth: 80 }}
              />
              <TextField
                size="small"
                type="number"
                label="Y axis"
                value={Math.round(element.y)}
                onChange={(e) => handleChange(element.id, 'y', Math.round(Number(e.target.value)))}
                sx={{ maxWidth: 80 }}
              />
              {element.type === 'text' && (
                <>
                  <TextField
                    size="small"
                    type="number"
                    label={t('Width')}
                    value={element.width}
                    onChange={(e) => {
                      const newWidth = Math.round(Number(e.target.value))
                      handleChange(element.id, 'width', newWidth)
                    }}
                    sx={{ maxWidth: 80 }}
                  />
                </>
              )}
              {element.type === 'image' && (
                <>
                  <TextField
                    size="small"
                    type="number"
                    label={t('Width')}
                    value={element.width}
                    onChange={(e) => {
                      const newWidth = Math.round(Number(e.target.value))

                      if (element.keepRatio) {
                        // Calculate the new height based on the aspect ratio
                        const newHeight = Math.round(newWidth / (element.width / element.height))
                        handleChange(element.id, 'width', newWidth)
                        handleChange(element.id, 'height', newHeight)
                      } else {
                        handleChange(element.id, 'width', newWidth) // Only update width if keepRatio is false
                      }
                    }}
                    sx={{ maxWidth: 80 }}
                  />
                  <TextField
                    size="small"
                    type="number"
                    label={t('Height')}
                    value={element.height}
                    onChange={(e) => {
                      const newHeight = Math.round(Number(e.target.value))

                      if (element.keepRatio) {
                        // Calculate the new width based on the aspect ratio
                        const newWidth = Math.round(newHeight * (element.width / element.height))
                        handleChange(element.id, 'width', newWidth)
                        handleChange(element.id, 'height', newHeight)
                      } else {
                        handleChange(element.id, 'height', newHeight) // Only update height if keepRatio is false
                      }
                    }}
                    sx={{ maxWidth: 80 }}
                  />
                  <TextField
                    size="small"
                    type="number"
                    label={t('Rotation')}
                    value={element.rotation}
                    onChange={(e) => handleChange(element.id, 'rotation', Number(e.target.value))}
                    sx={{ maxWidth: 80 }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!element.keepRatio}
                        onChange={() => handleChange(element.id, 'keepRatio', !element.keepRatio)}
                      />
                    }
                    label={t('KeepRatio')}
                  />
                </>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!element.listening}
                    onChange={() => handleChange(element.id, 'listening', !element.listening)}
                  />
                }
                label={t('Lock')}
              />
            </Box>
            <Box display="flex" alignItems="center">
              {element.type === 'text' && <StyleText element={element} handleChange={handleChange} />}
              <ButtonTriggeredDialog icon={Delete} handleClick={() => handleDeleteElement(element.id)}>
                {t('translation:AreYouSureToDeleteTheItem')}
              </ButtonTriggeredDialog>
            </Box>
          </Box>
        </Paper>
      )}
    </Draggable>
  )
}
