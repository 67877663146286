import { Box } from '@mui/material'
import { memo } from 'react'

const PricingItem = ({ label, children, bold = false }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '4pt',
        justifyContent: 'space-between',
        width: '100%',
        '& span': {
          fontWeight: bold ? 'bold' : 'normal',
        },
      }}
    >
      <span>{label}</span>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <span>{children}</span>
      </Box>
    </Box>
  )
}

export default memo(PricingItem)
