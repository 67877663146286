import { useEffect, Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  alpha,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  styled,
  Typography,
  Link as MuiLink,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { NavItem, UserInformation, SelectOtherCompany } from './components'
import { PageURLs, sidebarLinks } from 'Routes'
import { useTranslation } from 'react-i18next'
import { useAuth, useEscapeRooms, useTenantInfo, useUserPreferences } from 'core'
import { sideBarVisibilityRestrictions } from 'utils'
import SimpleBar from 'simplebar-react'
import { CustomPaperComponent, KeyhowlLogo } from 'components'
import { ROLES } from 'config'
import { useTheme } from '@emotion/react'
import { ACCEPTED_ADMIN_GROUPS } from 'config'
import { API_URL } from 'config'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import changelog from 'views/Changelog/versions'
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone'

const useStyles = makeStyles((theme) => ({
  navigation: {
    padding: theme.spacing(2),
    boxSizing: 'border-box',
  },
  mobileDrawer: {
    width: theme.sizing.sidebar.width,
  },
  desktopDrawer: {
    width: theme.sizing.sidebar.width,
    height: '100%',
    borderRight: `1px dashed ${theme.palette.divider}`,
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  title: {
    padding: theme.spacing(0, 2.5, 0, 2),
    margin: theme.spacing(3, 0, 1.25),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 2,
  },
}))

const openedMixin = (theme) => ({
  width: theme.sizing.sidebar.width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  borderRight: `1px dashed ${theme.palette.divider} !important`,
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: theme.sizing.sidebar.collapsedWidth,
  borderRight: `1px dashed ${theme.palette.divider} !important`,
})

const DesktopDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: theme.sizing.sidebar.width,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

const NavBar = ({ onMobileClose, openMobile }) => {
  const version = changelog[0].version
  const classes = useStyles()
  const location = useLocation()
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    user: { userGroup },
  } = useAuth()
  const { tenants, activeTenant, activeTenantFullInfo } = useTenantInfo()
  const { activeEscapeRooms } = useEscapeRooms()
  const { isDrawerOpen, changeDrawerSize, isDesktop } = useUserPreferences()

  const isMoreThanOneTenant = tenants.length > 1
  const { HAS_SELECTED_ESCAPE_ROOM, IS_OWNER } = sideBarVisibilityRestrictions

  const restrictions = ({ visibilityRestrictions }) => {
    if (visibilityRestrictions.includes(HAS_SELECTED_ESCAPE_ROOM) && activeEscapeRooms.length === 0) {
      return false
    }
    if (visibilityRestrictions.includes(IS_OWNER) && activeTenantFullInfo?.role !== ROLES.OWNER.key) {
      return false
    }
    return true
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
    // eslint-disable-next-line
  }, [location.pathname])

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <SimpleBar style={{ height: '100%' }}>
        <Box pt={3} pb={3} pl={2.5} pr={2.5} position="relative" sx={!isDrawerOpen ? { overflowX: 'hidden' } : {}}>
          {activeTenant ? (
            <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} component="div">
              <Box display="flex" justifyContent="center" alignItems="center">
                {!activeTenantFullInfo?.logo ? (
                  <KeyhowlLogo />
                ) : (
                  <img
                    src={`${API_URL}/media/${activeTenantFullInfo?.logo}`}
                    alt="logo"
                    width="40"
                    height="40"
                    style={{ width: 'auto', height: 40 }}
                  />
                )}
                {isDrawerOpen && (
                  <Box ml={1} sx={{ maxWidth: 155 }}>
                    <Typography noWrap={true}>{activeTenantFullInfo?.name}</Typography>
                  </Box>
                )}
              </Box>
              {(isMoreThanOneTenant || ACCEPTED_ADMIN_GROUPS.includes(userGroup)) && isDrawerOpen && (
                <SelectOtherCompany />
              )}
            </Typography>
          ) : (
            <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} component="div">
              <Box display="flex" alignItems="center">
                <KeyhowlLogo />
                {isDrawerOpen && (
                  <Box ml={1}>
                    <Typography noWrap={true}>Keyhowl</Typography>
                  </Box>
                )}
              </Box>
              {ACCEPTED_ADMIN_GROUPS.includes(userGroup) && isDrawerOpen && <SelectOtherCompany />}
            </Typography>
          )}
        </Box>
        <UserInformation />
        <Box>
          <List className={classes.navigation}>
            {sidebarLinks
              .filter((item) => item.visibleTo.includes(userGroup))
              .filter(restrictions)
              .map((item) => (
                <Fragment key={item.title}>
                  <ListItem style={{ padding: 0 }}>
                    <Typography variant="overline" className={classes.title}>
                      {isDrawerOpen ? t(item.title) : <span style={{ opacity: '0' }}>A</span>}
                    </Typography>
                  </ListItem>
                  {item.links.map((link, index) => (
                    <NavItem
                      href={link.to}
                      key={index}
                      title={link.name}
                      icon={link.icon}
                      children={link.links}
                      exact={link.exact}
                      acceptedRoles={link.acceptedRoles}
                      userGroup={userGroup}
                    />
                  ))}
                </Fragment>
              ))}
          </List>
        </Box>
        {isDrawerOpen && (
          <>
            <CustomPaperComponent
              elevation={0}
              backgroundColor={alpha(theme.palette.primary.main, 0.08)}
              sx={{ m: 1.5, p: 2.5 }}
            >
              <DescriptionTwoToneIcon sx={{ color: 'primary.main' }} fontSize="large" />
              <Typography variant="subtitle1" sx={{ my: 1.5 }} whiteSpace="initial">
                {t('NeedHelp?')}
              </Typography>
              <Typography variant="body2" color="textSecondary" whiteSpace="initial">
                {t('ComeAskAtDiscord')}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ marginTop: 2 }}
                component={MuiLink}
                href="https://discord.gg/GFNsGRcp5s"
                target="_blank"
              >
                {t('JoinDiscord')}
              </Button>
            </CustomPaperComponent>
            <Box px={2.5} py={1.5} pt={0}>
              <Link to={PageURLs.Changelog}>
                <Typography variant="caption" color="textSecondary">
                  v{version} - BETA
                </Typography>
              </Link>
            </Box>
          </>
        )}
        {isDesktop && (
          <IconButton
            size="small"
            onClick={changeDrawerSize}
            sx={{
              position: 'fixed',
              bottom: 16,
              p: 0.5,
              left: isDrawerOpen ? theme.sizing.sidebar.width - 16 : theme.sizing.sidebar.collapsedWidth - 16,
              background: theme.palette.background.main,
              border: `1px dashed ${theme.palette.divider}`,
              '&:hover': {
                background: theme.palette.background.main,
              },
            }}
          >
            {isDrawerOpen ? <ChevronLeft fontSize="small" /> : <ChevronRight fontSize="small" />}
          </IconButton>
        )}
      </SimpleBar>
    </Box>
  )

  return (
    <>
      <Box sx={{ display: { lg: 'none', xs: 'initial' } }}>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{ sx: { bgcolor: 'background.main' } }}
        >
          {content}
        </Drawer>
      </Box>
      <Box sx={{ display: { lg: 'initial', xs: 'none', position: 'relative' } }}>
        <DesktopDrawer variant="permanent" open={isDrawerOpen} PaperProps={{ sx: { bgcolor: 'background.main' } }}>
          {content}
        </DesktopDrawer>
      </Box>
    </>
  )
}

export default NavBar
