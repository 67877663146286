import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@mui/icons-material/Delete'

const useStyles = makeStyles((theme) => ({
  root: {},
}))

const DeleteDialog = ({ handleDelete }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDeleteItems = () => {
    handleDelete()
    handleClose()
  }

  return (
    <div className={classes.root}>
      <IconButton aria-label="delete" onClick={handleOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog onClose={handleClose} aria-labelledby="delete items" open={open}>
        <DialogTitle>{t('Confirm')}</DialogTitle>
        <DialogContent>{t('AreYouSureToDelete')}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <Button color="primary" onClick={handleDeleteItems}>
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DeleteDialog
