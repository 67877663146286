import {
  Badge,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Popover,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'
import { useChat, useTenantInfo } from 'core'
import { ProfileAvatar } from 'components'
import { API_URL } from 'config'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'

const OnlineUsers = ({ ...rest }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { activeTenantUsers } = useTenantInfo()
  const { userStatuses } = useChat()

  const [anchorEl, setAnchorEl] = useState(null)

  const isUserOnline = (username) =>
    username in userStatuses ? (userStatuses[username] === 'ONLINE' ? true : false) : false

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    activeTenantUsers !== null &&
    activeTenantUsers.length && (
      <div {...rest}>
        <Tooltip title={t('Employees')} placement="bottom" style={{ marginRight: 8 }}>
          <IconButton onClick={handleClick}>
            <PeopleAltIcon />
          </IconButton>
        </Tooltip>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          PaperProps={{
            sx: {
              borderRadius: 2,
              boxShadow: theme.customShadows.z16,
              backgroundColor: theme.palette.background.default,
              color: theme.palette.text.primary,
              minWidth: 220,
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box sx={{ width: 300, p: 1 }}>
            <SimpleBar style={{ maxHeight: '500px' }}>
              {activeTenantUsers.length && (
                <List
                  subheader={
                    <ListSubheader
                      component="div"
                      id="read-notifications"
                      sx={{
                        backgroundColor: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        width: '100%',
                        zIndex: theme.zIndex.mobileStepper,
                      }}
                    >
                      <Typography variant="h6" component="div" sx={{ minHeight: '48px', lineHeight: '48px' }}>
                        {t('Employees')} ({activeTenantUsers.length})
                      </Typography>
                    </ListSubheader>
                  }
                >
                  {activeTenantUsers.map((user) => (
                    <ListItem key={user.id} button sx={{ borderRadius: 2 }}>
                      <ListItemIcon>
                        <Badge
                          overlap="circular"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          variant="dot"
                          sx={{
                            '& .MuiBadge-badge': {
                              backgroundColor: isUserOnline(user.username) ? '#44b700' : '#be0000',
                              color: isUserOnline(user.username) ? '#44b700' : '#be0000',
                              boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                              '&::after': {
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                border: '1px solid currentColor',
                                content: '""',
                                zIndex: theme.zIndex.mobileStepper,
                              },
                            },
                          }}
                        >
                          <ProfileAvatar
                            firstName={user?.firstName}
                            lastName={user?.lastName}
                            src={user?.avatar && `${API_URL}/media/${user?.avatar}`}
                          />
                        </Badge>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="subtitle2" style={{ fontWeight: 400 }}>
                            {user?.firstName} {user?.lastName}
                          </Typography>
                        }
                        secondary={
                          <Typography variant="caption" color="textSecondary">
                            {t(user?.role)}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              )}
            </SimpleBar>
          </Box>
        </Popover>
      </div>
    )
  )
}

export default OnlineUsers
