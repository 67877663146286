import { Box, IconButton, MenuItem, Select, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import LastPageIcon from '@mui/icons-material/LastPage'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import { getItemRange } from 'utils'
import { useTranslation } from 'react-i18next'
import { PAGE_SIZES } from 'config'
import { useCallback } from 'react'

const useStyles = makeStyles((theme) => ({
  root: {},
  range: {
    marginRight: theme.spacing(2.5),
  },
  select: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    fontSize: theme.typography.caption.fontSize,
  },
}))

const TablePagination = ({
  asc,
  setAsc,
  from,
  hasNext,
  startNextOrPreviousPageFrom,
  setFrom,
  countClicks,
  setCountClicks,
  isLoading,
  removeButtons = false,
  pageSize,
  setPageSize,
  totalProducts,
  itemsOnCurrentPage,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { start, end } = getItemRange({
    itemsOnCurrentPage,
    totalPages: Math.ceil(totalProducts / pageSize) - 1,
    totalProducts,
    page: countClicks * -1,
  })

  const handleFirstPageButtonClick = useCallback(() => {
    setAsc(false)
    setFrom(0)
    if (countClicks !== undefined) {
      setCountClicks(0)
    }
  }, [countClicks, setAsc, setCountClicks, setFrom])

  const handleNextButtonClick = useCallback(() => {
    asc && setAsc(false)
    setFrom(startNextOrPreviousPageFrom.prev)
    if (countClicks !== undefined) {
      setCountClicks(countClicks - 1)
    }
  }, [asc, countClicks, setAsc, setCountClicks, setFrom, startNextOrPreviousPageFrom.prev])

  const handleBackButtonClick = useCallback(() => {
    !asc && setAsc(true)
    setFrom(startNextOrPreviousPageFrom.next)
    if (countClicks !== undefined) {
      setCountClicks(countClicks + 1)
    }
  }, [asc, countClicks, setAsc, setCountClicks, setFrom, startNextOrPreviousPageFrom.next])

  const handleLastPageButtonClick = useCallback(() => {
    setAsc(true)
    setFrom(0)
    if (countClicks !== undefined) {
      setCountClicks(0)
    }
  }, [countClicks, setAsc, setCountClicks, setFrom])

  return (
    <Box display="flex" alignItems="center" className={classes.root}>
      <Typography variant="caption">{t('RowsPerPage')}:</Typography>
      <Select
        className={classes.select}
        value={pageSize}
        variant="standard"
        onChange={(e) => setPageSize(e.target.value)}
        disableUnderline
      >
        {PAGE_SIZES.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      <Typography variant="caption" className={classes.range}>
        {t('ProductRange', { start, end, totalProducts })}
      </Typography>
      {!removeButtons ? (
        <IconButton onClick={handleFirstPageButtonClick} disabled={isLoading || (!hasNext && asc) || (!from && !asc)}>
          <FirstPageIcon />
        </IconButton>
      ) : (
        ''
      )}
      <IconButton onClick={() => handleBackButtonClick()} disabled={isLoading || (!hasNext && asc) || (!from && !asc)}>
        <NavigateBeforeIcon />
      </IconButton>
      <IconButton onClick={() => handleNextButtonClick()} disabled={isLoading || (!hasNext && !asc) || (!from && asc)}>
        <NavigateNextIcon />
      </IconButton>
      {!removeButtons ? (
        <IconButton onClick={handleLastPageButtonClick} disabled={isLoading || (!hasNext && !asc) || (!from && asc)}>
          <LastPageIcon />
        </IconButton>
      ) : (
        ''
      )}
    </Box>
  )
}

export default TablePagination
