import { createContext, useReducer, useContext, useCallback, useEffect } from 'react'
import { useError } from 'utils/hooks'
import { v4 as uuid } from 'uuid'
import axios from 'axios'
import { useTenantInfo } from '../TenantInfoContext'
import { isObject } from 'helpers'
import { API_URL } from 'config'

const tasksReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'LOAD_TASKS':
      return {
        ...state,
        tasks: payload.tasks,
        columns: payload.columns,
        columnOrder: payload.columnOrder,
        loadingTasks: false,
      }
    case 'EDIT_COLUMN_ORDER':
      return {
        ...state,
        columnOrder: payload,
        isInitialLoadFinished: true,
      }
    case 'EDIT_COLUMNS':
      return {
        ...state,
        columns: payload,
        isInitialLoadFinished: true,
      }
    case 'EDIT_COLUMN_TITLE':
      return {
        ...state,
        columns: { ...state.columns, [payload.id]: payload },
        isInitialLoadFinished: true,
      }
    case 'DELETE_COLUMN':
      delete state.columns[payload.id]
      return {
        ...state,
        columnOrder: state.columnOrder.filter((item) => item !== payload.id),
        isInitialLoadFinished: true,
      }
    case 'CREATE_COLUMN':
      return {
        ...state,
        columns: { ...state.columns, ...payload },
        columnOrder: [...state.columnOrder, Object.keys(payload)[0]],
        isInitialLoadFinished: true,
      }
    case 'CREATE_TASK':
      return {
        ...state,
        tasks: { ...state.tasks, ...payload.task },
        columns: {
          ...state.columns,
          [payload.columnId]: {
            ...state.columns[payload.columnId],
            taskIds: [...state.columns[payload.columnId].taskIds, Object.keys(payload.task)[0]],
          },
        },
        isInitialLoadFinished: true,
      }
    case 'DELETE_TASK':
      delete state.tasks[payload.taskId]
      return {
        ...state,
        columns: {
          ...state.columns,
          [payload.columnId]: {
            ...state.columns[payload.columnId],
            taskIds: state.columns[payload.columnId].taskIds.filter((taskId) => taskId !== payload.taskId),
          },
        },
        isInitialLoadFinished: true,
      }
    case 'EDIT_TASK':
      return {
        ...state,
        tasks: { ...state.tasks, [payload.id]: payload },
        isInitialLoadFinished: true,
      }
    case 'CHANGE_TASK_STATUS':
      return {
        ...state,
        tasks: { ...state.tasks, [payload]: { ...state.tasks[payload], status: !state.tasks[payload].status } },
        isInitialLoadFinished: true,
      }
    case 'LOAD_TASKS_ERROR':
      return {
        ...state,
        tasks: {},
        columns: {},
        columnOrder: [],
        loadingTasks: false,
        isInitialLoadFinished: false,
      }
    default:
      return state
  }
}

const initialState = {
  tasks: {},
  columns: {},
  columnOrder: [],
  loadingTasks: true,
  isInitialLoadFinished: false,
}

const TasksContext = createContext(initialState)

const TasksProvider = ({ children }) => {
  const [state, dispatch] = useReducer(tasksReducer, initialState)
  const { setError } = useError()
  const { activeTenant } = useTenantInfo()

  useEffect(() => {
    !state.loadingTasks && state.isInitialLoadFinished && updateTasks()
    // eslint-disable-next-line
  }, [state.columnOrder, state.columns, state.tasks])

  const generateColumn = (title) => {
    const randomId = uuid()
    return { [randomId]: { id: randomId, title, taskIds: [] } }
  }

  const generateTask = (content) => {
    const randomId = uuid()
    return { [randomId]: { id: randomId, content, status: false, assignees: [] } }
  }

  const loadTasks = useCallback(async () => {
    try {
      const { data } = await axios(`${API_URL}/todo-board/${activeTenant}`)
      const board = JSON.parse(data.board)

      if (Object.keys(board).length === 0 && isObject(board)) {
        dispatch({
          type: 'LOAD_TASKS',
          payload: { tasks: {}, columns: {}, columnOrder: [] },
        })
      } else {
        dispatch({
          type: 'LOAD_TASKS',
          payload: board,
        })
      }
    } catch (error) {
      setError(error)
      dispatch({
        type: 'LOAD_TASKS_ERROR',
      })
    }
    // eslint-disable-next-line
  }, [activeTenant])

  const updateTasks = useCallback(async () => {
    try {
      const board = JSON.stringify({ columns: state.columns, columnOrder: state.columnOrder, tasks: state.tasks })
      const request = { board, tenantId: activeTenant }
      await axios.put(`${API_URL}/todo-board`, request)
    } catch (error) {
      setError(error)
    }
    // eslint-disable-next-line
  }, [state, activeTenant])

  const editColumnOrder = (columnOrder) => {
    dispatch({
      type: 'EDIT_COLUMN_ORDER',
      payload: columnOrder,
    })
  }

  const editColumns = (columns) => {
    dispatch({
      type: 'EDIT_COLUMNS',
      payload: columns,
    })
  }

  const editColumnTitle = (column) => {
    dispatch({
      type: 'EDIT_COLUMN_TITLE',
      payload: column,
    })
  }

  const deleteColumn = (column) => {
    const taskIds = column.taskIds

    dispatch({
      type: 'DELETE_COLUMN',
      payload: column,
    })

    if (taskIds.length) {
      for (let i = 0; i < taskIds.length; i++) {
        delete state.tasks[taskIds[i]]
      }
    }
  }

  const deleteTask = (taskId, columnId) => {
    dispatch({
      type: 'DELETE_TASK',
      payload: { taskId, columnId },
    })
  }

  const createColumn = (title) => {
    const column = generateColumn(title)

    dispatch({
      type: 'CREATE_COLUMN',
      payload: column,
    })
  }

  const createTask = (columnId, taskContent) => {
    const task = generateTask(taskContent)

    const payload = { columnId, task }

    dispatch({
      type: 'CREATE_TASK',
      payload,
    })
  }

  const editTask = (task) => {
    dispatch({
      type: 'EDIT_TASK',
      payload: task,
    })
  }

  const changeTaskStatus = (taskId) => {
    dispatch({
      type: 'CHANGE_TASK_STATUS',
      payload: taskId,
    })
  }

  return (
    <TasksContext.Provider
      value={{
        tasks: state.tasks,
        columnOrder: state.columnOrder,
        columns: state.columns,
        loadingTasks: state.loadingTasks,
        updateTasks,
        loadTasks,
        editColumnOrder,
        editColumns,
        editColumnTitle,
        deleteColumn,
        createColumn,
        deleteTask,
        createTask,
        changeTaskStatus,
        editTask,
      }}
      displayName="Tasks"
    >
      {children}
    </TasksContext.Provider>
  )
}

const useTasks = () => {
  const context = useContext(TasksContext)

  if (context === undefined) {
    throw new Error('useTasks can only be used inside TasksProvider')
  }

  return context
}

export { TasksProvider, useTasks }
