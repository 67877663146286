import { Add, AddAPhoto, Upload } from '@mui/icons-material'
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { ButtonTriggeredDialog, InfoTooltip, PhotoBuilder } from 'components'
import { isValidEmail } from 'helpers'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MAX_FILES } from '../../PlayedReservationDialog'

export default function Marketing({
  open,
  getInputProps,
  files,
  mutatePhotos,
  previews,
  emails,
  setEmails,
  setSendPostGameEmail,
  sendPostGameEmail,
  reservation,
}) {
  const { t } = useTranslation('reservations')

  const [isCreateOpen, setIsCreateOpen] = useState(false)
  const [emailToAdd, setEmailToAdd] = useState('')

  return (
    <>
      <Box display="flex" alignItems="center">
        <FormControlLabel
          sx={{ mx: 0 }}
          control={
            <Checkbox
              size="small"
              checked={sendPostGameEmail}
              onChange={(e) => setSendPostGameEmail(e.target.checked)}
            />
          }
          label={t('SendPostGameEmail')}
        />
        <InfoTooltip title={t('SendPostGameEmailTooltip')} />
      </Box>
      {sendPostGameEmail && (
        <>
          <Divider sx={{ mt: 1, mb: 1.5 }} />
          <Stack gap={1}>
            <Typography variant="overline">{t('EmailRecipients')}</Typography>
            <Box display="flex" flexWrap="wrap" gap={0.5} mb={1.5}>
              {emails.map((email) => (
                <Chip
                  label={email}
                  key={email}
                  onDelete={() => setEmails(emails.filter((_email) => _email !== email))}
                />
              ))}
              <ButtonTriggeredDialog
                buttonComponent={Chip}
                buttonComponentProps={{
                  label: t('translation:AddNewEmail'),
                  icon: <Add />,
                }}
                actionButtonProps={{ disabled: !isValidEmail(emailToAdd) || emails.includes(emailToAdd) }}
                handleClick={() => {
                  if (isValidEmail(emailToAdd) && !emails.includes(emailToAdd)) {
                    setEmails((state) => [...state, emailToAdd])
                    setEmailToAdd('')
                  }
                }}
              >
                <TextField
                  label={t('Email')}
                  type="email"
                  required
                  fullWidth
                  autoFocus
                  value={emailToAdd}
                  onChange={(e) => setEmailToAdd(e.target.value)}
                />
              </ButtonTriggeredDialog>
            </Box>
            <Typography variant="overline">{t('AttachFile', { max: MAX_FILES })}</Typography>
            <ButtonGroup disableElevation variant="outlined">
              <Button startIcon={<AddAPhoto />} onClick={() => setIsCreateOpen(true)}>
                {t('translation:Open')} {t('photos:PhotoBuilder')}
              </Button>
              <Tooltip title={t('IfDontWantToEditPhotos')}>
                <Button startIcon={<Upload />} onClick={open} disabled={!sendPostGameEmail}>
                  {t('translation:UploadFromDevice')}
                  <input {...getInputProps()} />
                </Button>
              </Tooltip>
            </ButtonGroup>
            <Box display="flex" flexWrap="wrap">
              {previews}
            </Box>
          </Stack>
          <PhotoBuilder
            isOpen={isCreateOpen}
            setIsOpen={setIsCreateOpen}
            passedReservation={reservation}
            onCreate={mutatePhotos}
          />
        </>
      )}
      {!files.length && sendPostGameEmail && (
        <Typography variant="caption" sx={{ color: 'warning.main' }}>
          {t('NoPhotoAddedDisclaimer')}
        </Typography>
      )}
    </>
  )
}
