import { useState } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import { Button, Collapse, ListItem, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { alpha } from 'utils'
import { useTranslation } from 'react-i18next'
import { useTenantInfo, useUserPreferences } from 'core'
import { ACCEPTED_ADMIN_GROUPS } from 'config'

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: theme.spacing(0.5),
  },
  button: {
    padding: theme.spacing(1.5, 1.75, 1.5, 1.75),
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    textTransform: 'none',
    width: '100%',
    minWidth: theme.spacing(6.5),
    '&:hover': {
      background: 'rgba(145, 158, 171, 0.08)',
    },
  },
  icon: {
    marginRight: theme.spacing(2),
    fontSize: theme.spacing(3),
  },
  title: {
    marginRight: 'auto',
    fontWeight: theme.typography.fontWeightRegular,
  },
  childIcon: {
    marginRight: theme.spacing(2),
    fontSize: 6,
    width: theme.spacing(3),
    color: theme.palette.primary.main,
  },
  rotateChevron: {
    transform: 'rotate(90deg)',
  },
  hidden: {
    display: 'none',
  },
}))

const NavItem = ({
  className,
  href,
  icon: Icon,
  title,
  children = [],
  exact = false,
  acceptedRoles,
  userGroup,
  ...rest
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const { currentUserRoleInTenant } = useTenantInfo()
  const { isDrawerOpen } = useUserPreferences()

  const [openCollapse, setOpenCollapse] = useState(false)

  const content = (
    <>
      {Icon && <Icon className={classes.icon} />}
      {isDrawerOpen && <span className={classes.title}>{t(title)}</span>}
    </>
  )

  return (
    <>
      <ListItem
        className={clsx(
          classes.item,
          className,
          acceptedRoles &&
            !acceptedRoles.includes(currentUserRoleInTenant) &&
            !ACCEPTED_ADMIN_GROUPS.includes(userGroup) &&
            classes.hidden
        )}
        disableGutters
        {...rest}
      >
        {children.length ? (
          <Button
            onClick={() => setOpenCollapse((prev) => !prev)}
            className={classes.button}
            disableRipple
            sx={!isDrawerOpen ? { width: '0 !important' } : {}}
            endIcon={
              children.length ? (
                <ChevronRightIcon
                  style={{ transition: 'all 0.2s' }}
                  className={clsx(openCollapse && classes.rotateChevron)}
                />
              ) : (
                ''
              )
            }
          >
            {content}
          </Button>
        ) : (
          <Button
            className={classes.button}
            component={RouterLink}
            disableRipple
            to={href}
            end={exact}
            sx={!isDrawerOpen ? { width: '0 !important' } : {}}
            style={({ isActive }) =>
              isActive
                ? {
                    background: alpha(theme.palette.primary.main, 0.08),
                    color: theme.palette.primary.main,
                    '& $title': {
                      fontWeight: theme.typography.fontWeightMedium,
                    },
                    '& $icon': {
                      color: theme.palette.primary.main,
                    },
                  }
                : {}
            }
          >
            {content}
          </Button>
        )}
      </ListItem>
      <Collapse in={openCollapse}>
        {children.map((link) => (
          <ListItem key={link.name} style={{ padding: 0 }}>
            <Button
              disableRipple
              className={classes.button}
              component={RouterLink}
              to={href}
              sx={!isDrawerOpen ? { width: '0 !important' } : {}}
              style={({ isActive }) =>
                isActive
                  ? {
                      color: theme.palette.grey[800],
                      '& $title': {
                        fontWeight: theme.typography.fontWeightBold,
                      },
                    }
                  : {}
              }
            >
              <FiberManualRecordIcon className={clsx(classes.childIcon)} />
              <span className={classes.title}>{t(link.name)}</span>
            </Button>
          </ListItem>
        ))}
      </Collapse>
    </>
  )
}

export default NavItem
