import { useUserPreferences } from 'core'
import { useEffect, useRef, useState } from 'react'
import { Image, Transformer } from 'react-konva'
import useImage from 'use-image'

export default function DraggableImage({
  image,
  onDragEnd,
  isSelected,
  onSelect,
  onTransformEnd,
  stageWidth,
  stageHeight,
}) {
  const { isDesktop } = useUserPreferences()
  const [img] = useImage(image.src, 'anonymous')
  const imageRef = useRef(null)
  const transformerRef = useRef(null)
  const [isResizing, setIsResizing] = useState(false) // Track if the image is being manually resized

  useEffect(() => {
    if (isSelected) {
      transformerRef.current.nodes([imageRef.current])
      transformerRef.current.getLayer().batchDraw()
      transformerRef.current.moveToTop()
    }
  }, [isSelected])

  let imgWidth = Math.round(image.width)
  let imgHeight = Math.round(image.height)

  const aspectRatio = img ? img.width / img.height : 1

  if (img && !isResizing) {
    // Fit logic: Make sure the image fits inside the stage without cropping
    if (stageWidth / stageHeight > aspectRatio) {
      imgWidth = Math.round(stageHeight * aspectRatio)
      imgHeight = stageHeight
    } else {
      imgWidth = stageWidth
      imgHeight = Math.round(stageWidth / aspectRatio)
    }
  }

  useEffect(() => {
    setIsResizing(true)
  }, [])

  // Handle resizing transformations
  const handleTransformEnd = (e) => {
    const node = imageRef.current
    const newWidth = Math.round(node.width() * node.scaleX())
    const newHeight = Math.round(node.height() * node.scaleY())
    const newRotation = node.rotation()

    // Update the image size in the parent component
    onTransformEnd(e, image.id, newWidth, newHeight, newRotation)

    // Reset the scale to 1 after resizing
    node.scaleX(1)
    node.scaleY(1)
  }

  useEffect(() => {
    if (!!image.isDefaultSelected) {
      onSelect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Image
        {...image}
        image={img}
        width={imgWidth}
        height={imgHeight}
        ref={imageRef}
        draggable={image.listening}
        onClick={onSelect}
        onTap={onSelect} // Touch event for mobile
        onDragEnd={(e) => onDragEnd(e, image.id)}
        onTransformEnd={handleTransformEnd} // Handle resizing via transformer
      />
      {isSelected && image.listening && (
        <Transformer
          anchorSize={isDesktop ? 12 : 25} // Make the dots bigger on mobile
          ref={transformerRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (image.keepRatio) {
              // Limit minimum size for the image
              if (newBox.width < 30 || newBox.height < 30) {
                return oldBox
              }
              return newBox
            } else {
              const newHeight = Math.round(newBox.width / aspectRatio)
              return {
                ...newBox,
                height: newHeight, // Enforce the height based on the aspect ratio
              }
            }
          }}
        />
      )}
    </>
  )
}
