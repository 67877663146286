export const getEntriesToAdd = (cart, initialCart) =>
  Object.entries(cart)
    .filter(([product, quantity]) => quantity > 0 && (!initialCart[product] || quantity > initialCart[product]))
    .map(([product, quantity]) => ({
      productId: product,
      quantity: initialCart[product] ? quantity - initialCart[product] : quantity,
    }))

export const getEntriesToRemove = (cart, initialCart) =>
  Object.entries(initialCart)
    .filter(([product, quantity]) => !cart[product] || cart[product] < quantity)
    .map(([product, quantity]) => ({
      productId: product,
      quantity: cart[product] ? quantity - cart[product] : quantity,
    }))
