import { Box } from '@mui/material'
import { Delete } from '@mui/icons-material'

export default function File({ imgSrc, removeFile, ...rest }) {
  return (
    <Box
      sx={{
        width: 80,
        height: 80,
        overflow: 'hidden',
        borderRadius: 2,
        mr: 1,
        mb: 1,
        border: 'thin solid',
        borderColor: 'divider',
      }}
      {...rest}
    >
      <div
        style={{
          display: 'flex',
          minWidth: 0,
          overflow: 'hidden',
          position: 'relative',
          height: '100%',
        }}
      >
        <img
          alt="preview"
          src={imgSrc}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '100%',
            height: '100%',
            transition: 'all 0.3s',
            opacity: 0,
            '&:hover': {
              background: 'rgba(22, 28, 36, 0.72)',
              transition: 'all 0.3s',
              cursor: 'pointer',
              opacity: 1,
            },
          }}
          onClick={removeFile}
        >
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <Delete sx={{ color: 'white' }} />
          </Box>
        </Box>
      </div>
    </Box>
  )
}
