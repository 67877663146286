/* eslint-disable import/no-anonymous-default-export */
export default (theme) => ({
  MuiTab: {
    styleOverrides: {
      root: {
        marginRight: 40,
        minWidth: '48px !important',
        textTransform: 'initial',
        minHeight: 48,
        padding: 0,
        flexDirection: 'row',
      },
      wrapper: {
        flexDirection: 'row',
      },
      labelIcon: {
        minHeight: 'initial',
        paddingTop: 0,
        '& *': {
          marginBottom: '0 !important',
          marginRight: 8,
        },
      },
    },
  },
})
