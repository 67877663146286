import { Divider, IconButton, InputAdornment, InputBase, Box } from '@mui/material'
import { useChat } from 'core'
import { useTranslation } from 'react-i18next'
import SendIcon from '@mui/icons-material/Send'
import { EmojiPicker } from './components'

const ChatTextField = ({ tiny, inputRef, message, setMessage }) => {
  const { t } = useTranslation('chat')
  const { sendMessage } = useChat()

  return (
    <>
      <Divider light={true} />
      <Box display="flex" alignItems="center" pl={tiny ? 1 : 1.5} sx={{ minHeight: tiny ? 42 : 56 }}>
        <InputBase
          startAdornment={
            <InputAdornment position="start">
              <EmojiPicker setMessage={setMessage} />
            </InputAdornment>
          }
          ref={inputRef}
          multiline
          fullWidth
          sx={{ fontSize: tiny ? 14 : 'inherit', pb: tiny ? 0 : 0.5 }}
          size={tiny ? 'small' : 'medium'}
          maxRows={4}
          placeholder={t('WriteAMessage')}
          value={message}
          onChange={(e) => {
            e.target.value !== '\n' && setMessage(e.target.value)
          }}
        />
        <Divider flexItem orientation="vertical" />
        <IconButton
          color="primary"
          disabled={message === '' || message.trim().length === 0 || message === '\n'}
          variant="outlined"
          sx={{ mx: 1 }}
          onClick={async (e) => {
            e.preventDefault()
            await sendMessage(message)
            setMessage('')
          }}
          alt={t('SendMessage')}
        >
          <SendIcon fontSize="small" />
        </IconButton>
      </Box>
    </>
  )
}

export default ChatTextField
