import { useState, useEffect, memo, useRef } from 'react'
import { makeStyles } from '@mui/styles'
import { CustomPaperComponent } from 'components'
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Checkbox,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Tooltip,
  Link,
} from '@mui/material'
import { getComparator, getObjectValueFromArray, stableSort } from './functions'
import { TableHead, TablePagination, TableToolbar } from './components'
import DisplayValue from '../TableDisplayValues'
import { useError } from 'utils/hooks'
import { usePagination } from './components/TablePagination/usePagination'
import { useTranslation } from 'react-i18next'
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone'
import axios from 'axios'
import { alpha, calculateColumnLength } from 'utils'
import { DeleteDialog } from '../TableNoPagination/components/TableToolbar/components'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import useSWR from 'swr'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  noPaper: {
    background: 'transparent !important',
    boxShadow: 'none !important',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  row: {
    borderBottom: '1px solid rgba(81, 81, 81, 0.33) !important',
  },
  selectedRow: {
    backgroundColor:
      theme.palette.mode === 'light' ? `${theme.palette.grey[200]} !important` : `rgba(255, 255, 255, 0.10) !important`,
  },
  hover: {
    '&:hover': {
      backgroundColor: `${alpha(theme.palette.grey[500], 0.08)} !important`,
    },
  },
}))

const EnhancedTable = ({
  getEndpoint,
  deleteEndpoint,
  putEndpoint,
  customComponent,
  postEndpoint,
  searchEndpoint,
  columns,
  paginateBy,
  noPaper = false,
  singularDeleteEndpoint,
  size = 'small',
  orderBy: orderByProp = 'id',
  getEndpointQueryParams,
  filters,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const withCheckbox = deleteEndpoint || putEndpoint || postEndpoint
  const withActions = singularDeleteEndpoint
  const columnsLength = calculateColumnLength(columns.length, withActions, withCheckbox)

  const { setError } = useError()

  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState(orderByProp)
  const [selected, setSelected] = useState([])

  const tableBody = useRef(0)

  const {
    from,
    setFrom,
    countClicks,
    setCountClicks,
    pageSize,
    setPageSize,
    startNextOrPreviousPageFrom,
    hasNext,
    setAsc,
    asc,
    setPaginationData,
    handleResetPagination,
  } = usePagination({ paginateBy })

  const { data: response, isLoading, mutate, error } = useSWR(
    `${getEndpoint}?asc=${asc}&from=${from}&pageSize=${pageSize}${getEndpointQueryParams ?? ''}`,
    {
      shouldRetryOnError: false,
    }
  )

  useEffect(() => {
    mutate()
  }, [getEndpoint, asc, from, pageSize, getEndpointQueryParams, mutate])

  useEffect(() => {
    if (!isLoading && error) {
      setError(error)
    }
    // eslint-disable-next-line
  }, [error, isLoading])

  useEffect(() => {
    response?.data?.length && setPaginationData({ data: response?.data, hasNext: response?.hasNext })
    // eslint-disable-next-line
  }, [response])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  const handleDelete = async () => {
    try {
      await axios.delete(deleteEndpoint, { data: selected })
      mutate()
      handleResetPagination()
      setSelected([])
    } catch (error) {
      setError(error)
    }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1

  return (
    <div className={classes.root}>
      <CustomPaperComponent className={clsx(noPaper && classes.noPaper, classes.paper)}>
        <TableToolbar
          numSelected={selected.length}
          deleteEndpoint={deleteEndpoint}
          handleDelete={handleDelete}
          customComponent={customComponent}
        />
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" aria-label="table" size={size}>
            <TableHead
              classes={classes}
              selected={selected}
              setSelected={setSelected}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              columns={columns}
              withCheckbox={withCheckbox}
              withActions={withActions}
              rows={response?.data}
              isLoading={isLoading}
            />
            {!isLoading && !error ? (
              <TableBody ref={tableBody}>
                {stableSort(response.data, getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row.id)
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                      classes={{ selected: classes.selectedRow, hover: classes.hover }}
                    >
                      {withCheckbox && (
                        <TableCell padding="checkbox" classes={{ root: classes.row }}>
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        </TableCell>
                      )}
                      {columns.map((column) => {
                        const content = (
                          <DisplayValue
                            row={row}
                            column={column}
                            additionalFieldValue={
                              column.additionalField && getObjectValueFromArray(column.additionalField, row)
                            }
                            setIsUpdated={mutate}
                          >
                            {!column.json
                              ? getObjectValueFromArray(column.field, row)
                              : getObjectValueFromArray(column.field, JSON.parse(row[column.json]))}
                          </DisplayValue>
                        )
                        return (
                          <TableCell key={column.name} align={column.align}>
                            {column.isLink ? (
                              <Link
                                component={RouterLink}
                                to={`${column.linkPrepend ?? ''}${
                                  column.linkPrependWithObjectValue
                                    ? getObjectValueFromArray(column.linkPrependWithObjectValue, row)
                                    : ''
                                }/${getObjectValueFromArray(column.link, row)}${column.linkAppend ?? ''}${
                                  column.linkAppendWithObjectValue
                                    ? getObjectValueFromArray(column.linkAppendWithObjectValue, row)
                                    : ''
                                }`}
                              >
                                {content}
                              </Link>
                            ) : (
                              content
                            )}
                          </TableCell>
                        )
                      })}
                      {withActions && (
                        <TableCell align="right" classes={{ root: classes.row }}>
                          {singularDeleteEndpoint && (
                            <DeleteDialog
                              row={row}
                              setSelected={setSelected}
                              setIsUpdated={mutate}
                              singularDeleteEndpoint={singularDeleteEndpoint}
                            />
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow style={{ height: tableBody.current?.clientHeight || 0 }}>
                  <TableCell align="center" colSpan={columnsLength}>
                    {!error && <CircularProgress />}
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ minHeight: 52 }}>
          {selected.length > 0 ? (
            <Box display="flex" alignItems="center">
              <Typography style={{ marginRight: 4 }} color="inherit" variant="caption" component="div">
                {selected.length} {t('Selected').toLowerCase()}
              </Typography>
              <Tooltip title={t('RemoveSelection')} placement="right">
                <IconButton size="small" onClick={() => setSelected([])}>
                  <CancelTwoToneIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            <span></span>
          )}
          <TablePagination
            removeButtons
            setFrom={setFrom}
            asc={asc}
            setAsc={setAsc}
            from={from}
            countClicks={countClicks}
            setCountClicks={setCountClicks}
            hasNext={hasNext}
            startNextOrPreviousPageFrom={startNextOrPreviousPageFrom}
            isLoading={isLoading}
            pageSize={pageSize}
            setPageSize={setPageSize}
            totalProducts={response?.total}
            itemsOnCurrentPage={response?.data?.length}
          />
        </Box>
      </CustomPaperComponent>
    </div>
  )
}

export default memo(EnhancedTable)
