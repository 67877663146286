/* eslint-disable import/no-anonymous-default-export */
export default (theme) => ({
  MuiDialog: {
    styleOverrides: {
      root: {
        backdropFilter: 'blur(3px) brightness(0.25)',
      },
      paper: {
        borderRadius: 16,
        boxShadow: 'none',
        margin: 16,
        maxHeight: 'calc(100% - 32px)',
        '@media only screen and (max-width: 600px)': {
          width: 'calc(100% - 32px)',
        },
      },
    },
  },
})
