/* eslint-disable import/no-anonymous-default-export */
export default {
  sidebar: {
    width: 280,
    collapsedWidth: 89,
  },
  header: {
    height: 80,
    collapsedHeight: 66,
  },
}
