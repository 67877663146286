import Delete from '@mui/icons-material/Delete'
import Edit from '@mui/icons-material/Edit'
import { Box, Button, Stack, Typography } from '@mui/material'
import axios from 'axios'
import { ButtonTriggeredDialog, CustomPaperComponent } from 'components'
import { API_URL, ASPECT_RATIOS } from 'config'
import { useEscapeRooms } from 'core'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { PageURLs } from 'Routes'
import { useError } from 'utils/hooks'
import { Preview } from 'views/PhotoFrame/components'

export default function FrameItem({ frame, mutate, hideButtons = false, onClick }) {
  const { t } = useTranslation()
  const { setError } = useError()
  const { enqueueSnackbar } = useSnackbar()
  const { escapeRooms } = useEscapeRooms()
  const navigate = useNavigate()

  const roomIds = frame.roomIds
  const aspectRatio = JSON.parse(frame.content).aspectRatio
  const elements = JSON.parse(frame.content).elements
  const stageDimensions = JSON.parse(frame.content).stageDimensions
  const fullAspectRatioInfo = ASPECT_RATIOS.find((ratio) => ratio.value === aspectRatio)

  const handleDelete = async () => {
    try {
      await axios.delete(`${API_URL}/overlay/${frame.id}`)
      enqueueSnackbar(t('photos:PhotoFrameDeletedSuccessfully'), { variant: 'success' })
      mutate()
    } catch (error) {
      setError(error)
    }
  }

  return (
    <CustomPaperComponent sx={{ overflow: 'hidden' }}>
      <Box
        sx={{ height: 180, overflow: 'hidden', display: 'flex', alignItems: 'flex-start', cursor: 'pointer' }}
        onClick={() => (onClick ? onClick() : navigate(`${PageURLs.PhotoFrames}/${frame.id}`))}
      >
        <Preview
          stageDimensions={stageDimensions}
          elements={elements}
          isCreate={false}
          interactable={false}
          hideButtons
          setElements={() => {}}
          setStageDimensions={() => {}}
          aspectRatio={aspectRatio}
        />
      </Box>
      {!hideButtons && (
        <Stack sx={{ p: 2 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Stack>
              <Typography variant="h6">
                {t('For')}:{' '}
                {escapeRooms
                  .filter((escapeRoom) => roomIds.includes(escapeRoom.id))
                  .map((escapeRoom) => escapeRoom.name)
                  .join(', ')}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {t('photos:AspectRatio')}: {fullAspectRatioInfo.text} ({fullAspectRatioInfo.width}x
                {fullAspectRatioInfo.height})
              </Typography>
            </Stack>
            <Box display="flex" gap={1}>
              <ButtonTriggeredDialog
                handleClick={handleDelete}
                dialogTitle={t('Delete')}
                color="error"
                buttonText={<Delete />}
                variant="contained"
                sx={{ p: 1, borderRadius: '50%', boxSizing: 'border-box', minWidth: '40px' }}
              >
                {t('AreYouSureToDeleteTheItem')}
              </ButtonTriggeredDialog>
              <Button
                aria-label={t('Edit')}
                color="primary"
                component={Link}
                variant="contained"
                sx={{ p: 1, borderRadius: '50%', boxSizing: 'border-box', minWidth: '40px' }}
                to={`${PageURLs.PhotoFrames}/${frame.id}`}
              >
                <Edit />
              </Button>
            </Box>
          </Box>
        </Stack>
      )}
    </CustomPaperComponent>
  )
}
