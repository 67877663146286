import { InfoOutlined } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

export default function InfoTooltip({ title = '', ...rest }) {
  return (
    <Tooltip title={title}>
      <InfoOutlined fontSize="small" color="disabled" sx={{ mx: 0.5 }} {...rest} />
    </Tooltip>
  )
}
