/* eslint-disable import/no-anonymous-default-export */
export default (theme) => ({
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: 4,
      },
    },
  },
})
