/* eslint-disable import/no-anonymous-default-export */
export default (theme) => ({
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        letterSpacing: 0,
        borderRadius: 8,

        '&:hover': {
          boxShadow: 'none !important',
        },
      },
      containedPrimary: {
        boxShadow: theme.customColorShadows.primary,
      },
    },
    variants: [
      {
        props: { color: 'error', variant: 'contained' },
        style: {
          boxShadow: theme.customColorShadows.error,
          '&:hover': {
            boxShadow: 'none'
          }
        }
      },
      {
        props: { color: 'success', variant: 'contained' },
        style: {
          boxShadow: theme.customColorShadows.success,
          '&:hover': {
            boxShadow: 'none'
          }
        }
      },
      {
        props: { color: 'warning', variant: 'contained' },
        style: {
          boxShadow: theme.customColorShadows.warning,
          '&:hover': {
            boxShadow: 'none'
          }
        }
      },
      {
        props: { color: 'info', variant: 'contained' },
        style: {
          boxShadow: theme.customColorShadows.info,
          '&:hover': {
            boxShadow: 'none'
          }
        }
      },
      {
        props: { color: 'primary', variant: 'contained' },
        style: {
          boxShadow: theme.customColorShadows.primary,
          '&:hover': {
            boxShadow: 'none'
          }
        }
      },
    ],
    defaultProps: {
      color: 'secondary',
    },
  },
})
