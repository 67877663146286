import { FileUpload } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import axios from 'axios'
import { SERVICES_URL } from 'config'
import { useTenantInfo } from 'core'
import { enqueueSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useError, useSingleUpload } from 'utils/hooks'

export default function FileUploadButton({ mutate }) {
  const { t } = useTranslation('pocket')
  const { activeTenant: tenantId } = useTenantInfo()
  const { open, getInputProps, image, removeFile } = useSingleUpload({ type: 'photo', instantRevoke: false })
  const { setError } = useError()

  const [isLoading, setIsLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [description, setDescription] = useState('')

  const handleClose = () => {
    removeFile()
    setOpenDialog(false)
    setDescription('')
    mutate()
  }

  const handleUpload = async () => {
    let formData = new FormData()

    if (!image) {
      enqueueSnackbar(t('NoFileAttached'), { variant: 'error' })
      return
    }

    setIsLoading(true)

    try {
      formData.append('image', image)
      formData.append('description', description)

      const result = await axios.post(`${SERVICES_URL}/pocket/${tenantId}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (result.status === 200) {
        setIsLoading(false)
        setOpenDialog(false)
        setDescription('')
        mutate()
      }
    } catch (error) {
      setError(error)
    }
  }

  useEffect(() => {
    if (image) {
      setOpenDialog(true)
    }
  }, [image])

  return (
    <>
      <Button color="primary" variant="contained" startIcon={<FileUpload />} onClick={open}>
        <input {...getInputProps()} />
        {t('translation:Upload')}
      </Button>
      <Dialog onClose={handleClose} open={openDialog} maxWidth="xs">
        <DialogTitle>{t('Review')}</DialogTitle>
        <DialogContent>
          {image && <img src={image?.preview} alt="" style={{ width: '100%', height: 'auto', marginBottom: 8 }} />}
          <TextField
            label={t('Description')}
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            rows={3}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('translation:Cancel')}</Button>
          <LoadingButton loading={isLoading} variant="contained" color="primary" onClick={handleUpload} autoFocus>
            {t('translation:Upload')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
