import { Toolbar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { DeleteDialog } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const EnhancedTableToolbar = (props) => {
  const classes = useStyles()
  const { numSelected, deleteEndpoint, handleDelete } = props

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <span>{/* Search should be here  */}</span>
      {numSelected > 0 && deleteEndpoint && <DeleteDialog handleDelete={handleDelete} />}
    </Toolbar>
  )
}

export default EnhancedTableToolbar
