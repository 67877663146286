import { makeStyles } from '@mui/styles'
import { TopBar } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
}))

const MinimalLayout = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <TopBar />
      {children}
    </div>
  )
}

export default MinimalLayout
