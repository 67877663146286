import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  Stack,
  FormControl,
  RadioGroup,
  FormLabel,
  Radio,
  FormControlLabel,
  TextField,
} from '@mui/material'
import { API_URL, TRANSACTION_TYPES, FINANCE_TYPES, PAYMENT_STATUSES } from 'config'
import { useCalendar, useEscapeRooms, useTenantInfo } from 'core'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { transformOrderEntriesToCartObject } from 'utils'
import { usePricing } from 'utils/hooks'
import { PricingItem } from 'views/Calendar/components/Reservations/components/Reservation/components/Status/components/PlayedReservationDialog/components'
import { getDescription } from 'views/Calendar/components/Reservations/components/Reservation/components/Status/components/PlayedReservationDialog/components/Summary/utils'
import { DisplayCurrency } from 'components'
import { useEffect, useState } from 'react'
import Loading from 'views/Loading'
import useFinanceMethods from 'utils/hooks/useFinanceMethods'

const DO_NOTHING_ACTION = 'nothing'
const REFUND_ACTION = 'refund'
const DELETE_ACTION = 'delete'

const UnpaidDialog = ({ openDialog, handleCloseMainMenu, handleCloseDialog, reservation, escapeRoom }) => {
  const { t } = useTranslation('reservations')
  const { products } = useTenantInfo()
  const { changeReservationStatus } = useEscapeRooms()
  const { loadCalendar } = useCalendar()
  const { refundFinance, deleteFinance } = useFinanceMethods()

  const [action, setAction] = useState(DO_NOTHING_ACTION)
  const [amount, setAmount] = useState(0)

  const { data: giftCard } = useSWR(
    openDialog &&
      reservation.discountCode &&
      `${API_URL}/voucher/byCode/${reservation.discountCode}/${escapeRoom.tenantId}`,
    { shouldRetryOnError: false }
  )

  const { data: orderData } = useSWR(
    openDialog && `${API_URL}/${escapeRoom.tenantId}/order-history/${escapeRoom.id}/${reservation.key.reservationId}`,
    { shouldRetryOnError: false, fallbackData: [] }
  )

  const cart = transformOrderEntriesToCartObject(orderData?.orderEntries ?? [], products)

  const { existingFinances, priceFromExistingFinancesWithStandard, isFinancesLoading, displayCurrency } = usePricing({
    cart,
    reservation,
    giftCard,
    numberOfPlayers: reservation.playerCount,
  })

  useEffect(() => {
    if (action === REFUND_ACTION && amount === 0) {
      setAmount(priceFromExistingFinancesWithStandard)
    }
    // eslint-disable-next-line
  }, [action])

  const handleUpdateReservation = async () => {
    if (action === REFUND_ACTION) {
      await refundFinance(existingFinances[0], amount)
    }

    if (action === DELETE_ACTION) {
      await deleteFinance(existingFinances[0]?.id)
    }

    await changeReservationStatus({
      reservationId: reservation.key.reservationId,
      roomId: reservation.key.roomId,
      paymentStatus: PAYMENT_STATUSES.NOT_PAID.key,
      amount: 0,
    })

    loadCalendar()
    handleCloseDialog()
    handleCloseMainMenu()
  }

  useEffect(() => {
    if (!isFinancesLoading && priceFromExistingFinancesWithStandard === 0) {
      handleUpdateReservation()
    }
    // eslint-disable-next-line
  }, [isFinancesLoading, priceFromExistingFinancesWithStandard])

  return (
    <Dialog
      open={openDialog && !isFinancesLoading && priceFromExistingFinancesWithStandard !== 0}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>{t('SelectAnOption')}</DialogTitle>
      <DialogContent sx={{ pt: '8px !important' }}>
        {isFinancesLoading ? (
          <Loading height="auto" />
        ) : (
          <>
            <Alert severity="warning">
              {t('ReservationHasFinancesChooseWhatToDo', {
                value: displayCurrency(priceFromExistingFinancesWithStandard),
              })}
            </Alert>
            <Stack sx={{ fontSize: '12pt', marginTop: '9pt' }} mb={2}>
              {existingFinances.map((finance) => {
                const label = getDescription(finance, t)
                return (
                  <PricingItem
                    key={finance.id}
                    label={finance.transactionType === TRANSACTION_TYPES.NONE.key ? finance.description : label}
                  >
                    <DisplayCurrency
                      number={finance.financeType === FINANCE_TYPES['INCOME'].key ? finance.amount : -finance.amount}
                    />
                  </PricingItem>
                )
              })}
              <PricingItem label={t('FinancesTotal')} bold>
                <DisplayCurrency number={priceFromExistingFinancesWithStandard} />
              </PricingItem>
            </Stack>

            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">{t('WhatHappensWithTheFinances')}</FormLabel>
              <RadioGroup
                aria-labelledby="demo-row-radio-buttons-group-label"
                value={action}
                onChange={(e) => setAction(e.target.value)}
              >
                <FormControlLabel value={DO_NOTHING_ACTION} control={<Radio />} label={t('DoNothing')} />
                <FormControlLabel value={REFUND_ACTION} control={<Radio />} label={t('RefundFinance')} />
                {existingFinances.length === 1 && (
                  <FormControlLabel value={DELETE_ACTION} control={<Radio />} label={t('DeleteFinance')} />
                )}
              </RadioGroup>
            </FormControl>

            {action === REFUND_ACTION && (
              <TextField
                sx={{ mt: 1 }}
                label={t('finances:Amount')}
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
                fullWidth
                type="number"
              />
            )}
          </>
        )}
        {existingFinances.length === 1 && existingFinances[0]?.paymentIntentId && action === DELETE_ACTION && (
          <Alert sx={{ mt: 1 }} severity="warning">
            {t('FinanceHasAPaymentIntentId')}
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            handleCloseDialog()
          }}
        >
          {t('translation:Cancel')}
        </Button>
        <Button color="primary" onClick={handleUpdateReservation}>
          {t('translation:Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UnpaidDialog
