import { Palette } from '@mui/icons-material'
import { Box, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { ButtonTriggeredDialog } from 'components'
import ColorPickerButton from 'components/ColorPickerButton/ColorPickerButton'
import { FRAME_FONTS } from 'config'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Layer, Stage } from 'react-konva'
import { DraggableText } from 'views/PhotoFrame/components/Preview/components'

export default function StyleText({ element, handleChange }) {
  const { t } = useTranslation('photos')
  const stageWidth = 320
  const stageHeight = element.fontSize < 100 ? 100 : element.fontSize
  const textRef = useRef(null)

  return (
    <ButtonTriggeredDialog icon={Palette} buttonText={t('StyleText')}>
      <Typography variant="caption">{t('Preview')}</Typography>
      <Box
        sx={(theme) => ({
          border: '2px solid',
          borderColor: theme.palette.mode === 'light' ? '#CCCCCC' : '#161c24',
          width: stageWidth + 4,
          height: stageHeight + 4,
          bgcolor: theme.palette.getContrastText(element.fill),
          mb: 1,
        })}
      >
        <Stage width={stageWidth} height={stageHeight}>
          <Layer>
            <DraggableText
              stageWidth={stageWidth}
              stageHeight={stageHeight}
              element={element}
              handleDragEnd={() => {}}
              listening={false}
              verticalAlign="middle"
              width={stageWidth}
              height={stageHeight}
              x={0}
              y={(stageHeight - textRef.current?.height() || 0) / 2}
              ref={textRef}
            />
          </Layer>
        </Stage>
      </Box>
      <Stack gap={1}>
        <ColorPickerButton
          fullWidth
          label={t('TextColor')}
          value={element.fill}
          onChange={(value) => handleChange(element.id, 'fill', value)}
          size="small"
        />
        <TextField
          select
          label={t('Font')}
          value={element.fontFamily}
          onChange={(e) => handleChange(element.id, 'fontFamily', e.target.value)}
          size="small"
        >
          {FRAME_FONTS.map((font) => (
            <MenuItem key={font} value={font} sx={{ fontFamily: font }}>
              {font}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label={t('FontSize')}
          type="number"
          value={element.fontSize}
          onChange={(e) => handleChange(element.id, 'fontSize', +e.target.value)}
          size="small"
        />
        <TextField
          label={t('FontStyle')}
          select
          value={element.fontStyle}
          onChange={(e) => handleChange(element.id, 'fontStyle', e.target.value)}
          size="small"
        >
          <MenuItem value="normal">{t('Normal')}</MenuItem>
          <MenuItem value="italic">{t('Italic')}</MenuItem>
          <MenuItem value="bold">{t('Bold')}</MenuItem>
          <MenuItem value="italic bold">{t('ItalicBold')}</MenuItem>
        </TextField>
        <TextField
          label={t('Align')}
          select
          value={element.align}
          onChange={(e) => handleChange(element.id, 'align', e.target.value)}
          size="small"
        >
          <MenuItem value="left">{t('Left')}</MenuItem>
          <MenuItem value="center">{t('Center')}</MenuItem>
          <MenuItem value="right">{t('Right')}</MenuItem>
        </TextField>
        <ColorPickerButton
          fullWidth
          label={t('ShadowColor')}
          value={element.shadowColor}
          onChange={(value) => handleChange(element.id, 'shadowColor', value)}
          size="small"
        />
        <TextField
          label={t('ShadowBlur')}
          type="number"
          value={element.shadowBlur}
          onChange={(e) => handleChange(element.id, 'shadowBlur', +e.target.value)}
          size="small"
        />
        <TextField
          label={t('ShadowOpacity')}
          type="number"
          value={element.shadowOpacity}
          onChange={(e) =>
            handleChange(
              element.id,
              'shadowOpacity',
              +e.target.value >= 1 ? 1 : +e.target.value <= 0 ? 0 : +e.target.value
            )
          }
          size="small"
        />
        <ColorPickerButton
          fullWidth
          label={t('StrokeColor')}
          value={element.stroke}
          onChange={(value) => handleChange(element.id, 'stroke', value)}
          size="small"
        />
        <TextField
          label={t('StrokeWidth')}
          type="number"
          value={element.strokeWidth}
          onChange={(e) => handleChange(element.id, 'strokeWidth', +e.target.value)}
          size="small"
        />
        <TextField
          label={t('TextDecoration')}
          select
          value={element.textDecoration}
          onChange={(e) => handleChange(element.id, 'textDecoration', e.target.value)}
          size="small"
        >
          <MenuItem value="">{t('NoDecoration')}</MenuItem>
          <MenuItem value="line-through">{t('LineThrough')}</MenuItem>
          <MenuItem value="underline">{t('Underline')}</MenuItem>
        </TextField>
        <TextField
          label={t('Opacity')}
          type="number"
          value={element.opacity}
          onChange={(e) =>
            handleChange(element.id, 'opacity', +e.target.value >= 1 ? 1 : +e.target.value <= 0 ? 0 : +e.target.value)
          }
          size="small"
        />
      </Stack>
    </ButtonTriggeredDialog>
  )
}
