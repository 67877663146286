import { CircularProgress } from '@mui/material'
import { useTenantInfo } from 'core'
import { transformLanguage } from 'helpers'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

const DisplayCurrency = ({ number, currency, language = undefined }) => {
  const { activeTenantFullInfo, loadingTenants } = useTenantInfo()
  const { i18n } = useTranslation()

  return currency ? (
    new Intl.NumberFormat(language ?? transformLanguage(i18n?.language), {
      style: 'currency',
      currency,
    }).format(number)
  ) : !loadingTenants ? (
    new Intl.NumberFormat(language ?? transformLanguage(i18n?.language), {
      style: 'currency',
      currency: activeTenantFullInfo?.currency,
    }).format(number)
  ) : (
    <CircularProgress />
  )
}

export default memo(DisplayCurrency)
