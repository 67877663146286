import { Link } from '@mui/material'
import { Link as RouterLink, useLocation } from 'react-router-dom'

const LinkValue = ({ isPhone = false, to = null, children: value }) => {
  const { pathname } = useLocation()

  return !isPhone ? (
    <Link component={RouterLink} to={`${pathname}/${to}`}>
      {value}
    </Link>
  ) : (
    <Link href={`tel: ${value}`}>{value}</Link>
  )
}

export default LinkValue
