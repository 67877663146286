import { Box, Button, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Layer, Stage } from 'react-konva'
import { Redo, Undo } from '@mui/icons-material'
import { DraggableImage, DraggableText } from './components'
import { ASPECT_RATIOS } from 'config'

export default function Preview({
  stageDimensions,
  aspectRatio,
  setStageDimensions,
  setElements,
  elements,
  isCreate,
  setDataUrl,
  interactable = true,
  hideButtons = false,
}) {
  const { t } = useTranslation('photos')
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'), { defaultMatches: true })

  const parentRef = useRef(null)
  const stageRef = useRef(null)

  const [selectedElementId, setSelectedElementId] = useState(null)
  const [scale, setScale] = useState(1)
  const [history, setHistory] = useState([])
  const [redoStack, setRedoStack] = useState([])

  const selectedAspectRatioItem = ASPECT_RATIOS.find((_aspectRatio) => _aspectRatio.value === aspectRatio)

  const handleDragEnd = (e, id) => {
    const { x, y } = e.target.position()

    const newX = Math.round(x)
    const newY = Math.round(y)

    setHistory((prevHistory) => [...prevHistory, elements])
    setRedoStack([])

    setElements((prevElements) =>
      prevElements.map((element) => (element.id === id ? { ...element, x: newX, y: newY } : element))
    )
  }

  const handleTransformEnd = (e, id) => {
    const node = e.target
    const newWidth = node.width() * node.scaleX()
    const newHeight = node.height() * node.scaleY()
    const newRotation = node.rotation()

    setHistory((prevHistory) => [...prevHistory, elements])
    setRedoStack([])

    setElements((prevElements) =>
      prevElements.map((element) =>
        element.id === id
          ? {
              ...element,
              width: Math.round(newWidth),
              height: Math.round(newHeight),
              rotation: Math.round(newRotation),
            }
          : element
      )
    )

    node.scaleX(1)
    node.scaleY(1)
  }

  const handleSelect = (id) => {
    if (selectedElementId === id) {
      setSelectedElementId(null)
    } else {
      setSelectedElementId(id)
    }
  }

  const updateStageDimensions = () => {
    const maxDimension = 700
    let stageWidth = maxDimension
    let stageHeight = maxDimension / aspectRatio

    if (stageHeight > maxDimension) {
      stageHeight = maxDimension
      stageWidth = maxDimension * aspectRatio
    }

    setStageDimensions({ width: Math.round(stageWidth), height: Math.round(stageHeight) })
  }

  const handleUndo = () => {
    if (history.length === 0) return

    const lastState = history[history.length - 1]
    setRedoStack([elements, ...redoStack])
    setElements(lastState)
    setHistory(history.slice(0, -1))
  }

  const handleRedo = () => {
    if (redoStack.length === 0) return

    const nextState = redoStack[0]
    setHistory([...history, elements])
    setElements(nextState)
    setRedoStack(redoStack.slice(1))
  }

  useEffect(() => {
    updateStageDimensions() // Set initial dimensions
    window.addEventListener('resize', updateStageDimensions)

    return () => {
      window.removeEventListener('resize', updateStageDimensions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aspectRatio, isSmallScreen, theme])

  useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        const parentWidth = parentRef.current.offsetWidth
        const parentHeight = parentRef.current.offsetHeight
        const widthScale = parentWidth / stageDimensions.width
        const heightScale = parentHeight / stageDimensions.height
        // Choose the smaller scale to ensure the div fits within both dimensions
        const finalScale = Math.min(widthScale, heightScale)

        setScale(finalScale)
      }
    }

    if (!isCreate) {
      handleResize() // Initial call
      window.addEventListener('resize', handleResize) // Recalculate on resize
    }

    return () => window.removeEventListener('resize', handleResize)
  }, [stageDimensions.width, stageDimensions.height, isCreate])

  useEffect(() => {
    if (!!setDataUrl && stageRef?.current) {
      const stage = stageRef.current
      const oldScale = stage.scaleX()
      const oldWidth = stageDimensions.width
      const oldHeight = stageDimensions.height

      // Temporarily scale the stage to the export size

      setTimeout(() => {
        stage.size({
          width: Math.round(selectedAspectRatioItem.width),
          height: Math.round(selectedAspectRatioItem.height),
        })
        const scaleX = selectedAspectRatioItem.width / oldWidth
        const scaleY = selectedAspectRatioItem.height / oldHeight
        stage.scale({ x: scaleX, y: scaleY })
        const dataURL = stage.toDataURL()
        if (dataURL) {
          setDataUrl(dataURL) // Set the dataUrl state
        } else {
          console.error('Failed to generate dataURL')
        }
        stage.size({ width: oldWidth, height: oldHeight })
        stage.scale({ x: oldScale, y: oldScale })
        // Restore the stage to its original size and scale
      }, 1000)
    }
  }, [
    selectedAspectRatioItem.height,
    selectedAspectRatioItem.width,
    setDataUrl,
    elements,
    stageDimensions.height,
    stageDimensions.width,
  ])

  return (
    <>
      <Box
        ref={parentRef}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          position: 'relative',
          height: stageDimensions.height * scale,
        }}
      >
        <Box
          sx={{
            transform: `scale(${scale > 1 ? 1 : scale})`,
            border: '2px solid',
            bgcolor: 'white',
            borderColor: theme.palette.mode === 'light' ? '#CCCCCC' : '#161c24',
            width: stageDimensions.width + 4,
            height: stageDimensions.height + 4,
            backgroundImage: `linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc), linear-gradient(45deg, 
             #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc)`,
            backgroundSize: '16px 16px',
            backgroundPosition: ' 0 0, 8px 8px',
          }}
        >
          <Stage width={stageDimensions.width} height={stageDimensions.height} ref={stageRef} listening={interactable}>
            <Layer>
              {elements.map((element) =>
                element.type === 'image' ? (
                  <DraggableImage
                    key={element.id}
                    image={element}
                    onDragEnd={handleDragEnd}
                    isSelected={interactable ? element.id === selectedElementId : false}
                    onSelect={() => handleSelect(element.id)}
                    onTransformEnd={handleTransformEnd}
                    stageWidth={stageDimensions.width}
                    stageHeight={stageDimensions.height}
                  />
                ) : (
                  <DraggableText key={element.id} element={element} handleDragEnd={handleDragEnd} />
                )
              )}
            </Layer>
          </Stage>
        </Box>
      </Box>
      {!hideButtons && (
        <Box display="flex" justifyContent="center" alignItems="center" gap={1} mt={0.5} mb={0}>
          <Button
            onClick={handleUndo}
            startIcon={<Undo />}
            variant="outlined"
            size="small"
            color="primary"
            disabled={!history.length}
          >
            {t('Undo')}
          </Button>
          <Button
            onClick={handleRedo}
            startIcon={<Redo />}
            variant="outlined"
            size="small"
            color="primary"
            disabled={!redoStack.length}
          >
            {t('Redo')}
          </Button>
        </Box>
      )}
    </>
  )
}

// Download functionality if necessary one day
// const selectedAspectRatioItem = ASPECT_RATIOS.find((_aspectRatio) => _aspectRatio.value === aspectRatio)
// const [triggerDownload, setTriggerDownload] = useState(false)
// useEffect(() => {
//   if (triggerDownload && selectedElementId === null) {
//     // Temporarily scale stage to the full size
//     const stage = stageRef.current
//     const oldScale = stage.scaleX()
//     const oldWidth = stage.width()
//     const oldHeight = stage.height()

//     // Scale the stage up for export
//     stage.size({
//       width: selectedAspectRatioItem.width,
//       height: selectedAspectRatioItem.height,
//     })
//     const scaleX = selectedAspectRatioItem.width / oldWidth
//     const scaleY = selectedAspectRatioItem.height / oldHeight
//     stage.scale({ x: scaleX, y: scaleY })

//     // Export the stage as full-size image
//     const dataURL = stage.toDataURL()

//     // Restore the stage to its original size and scale
//     stage.size({ width: oldWidth, height: oldHeight })
//     stage.scale({ x: oldScale, y: oldScale })

//     // Download the image
//     downloadURI(dataURL, 'image.png')

//     // Reset triggerDownload
//     setTriggerDownload(false)
//   }
// }, [selectedAspectRatioItem.height, selectedAspectRatioItem.width, selectedElementId, triggerDownload])

// const downloadURI = (uri, name) => {
//   const link = document.createElement('a')
//   link.download = name
//   link.href = uri
//   document.body.appendChild(link)
//   link.click()
//   document.body.removeChild(link)
// }
