import { memo, useMemo } from 'react'
import ReactQuill from 'react-quill'

const RichTextEditor = ({ value, onChange, isMultiLanguage = false, ...rest }) => {
  const modules = useMemo(
    () => ({
      toolbar: [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }],
        ['link'],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ color: [] }, { background: [] }],
      ],
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  )

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'link',
    'indent',
    'align',
    'color',
    'background',
  ]

  return (
    <ReactQuill
      content
      theme="snow"
      value={value}
      onChange={onChange}
      modules={modules}
      formats={formats}
      className={isMultiLanguage ? 'quill-custom-border' : ''}
      {...rest}
    />
  )
}

export default memo(RichTextEditor)
