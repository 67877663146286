export const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:8080'
export const SERVICES_URL = import.meta.env.VITE_SERVICES_URL || 'http://localhost:3003'
export const FRONT_END_APP_URL = import.meta.env.VITE_FRONT_END_APP_URL || 'http://localhost:3000'
export const DISCORD_WEBHOOK_URL = import.meta.env.VITE_DISCORD_WEBHOOK_URL || 'http://localhost:3000'
export const UNLEASH_CLIENT_KEY = '06cff523-080b-46c4-8b42-fb8da580a605' // it's the most basic authentication, not that we need it
export const WS_API_URL = API_URL.replace(/(^\w+:|^)\/\//, '')
export const LANGUAGE = 'enGB'
export const DEFAULT_PAGE_SIZE = 10
export const PAGE_SIZES = [10, 25, 50, 100, 150]
export const DEFAULT_DRAWER_OPEN = true
export const defaultPrimaryColors = {
  green: {
    lighter: '#C8FACD',
    light: '#5BE584',
    main: '#00AB55',
    dark: '#007B55',
    darker: '#005249',
    contrastText: '#fff',
  },
  red: {
    lighter: '#FFE3D5',
    light: '#FFC1AC',
    main: '#FF3030',
    dark: '#B71833',
    darker: '#7A0930',
    contrastText: '#fff',
  },
  purple: {
    lighter: '#EBD6FD',
    light: '#B985F4',
    main: '#7635dc',
    dark: '#431A9E',
    darker: '#200A69',
    contrastText: '#fff',
  },
  cyan: {
    lighter: '#D1FFFC',
    light: '#76F2FF',
    main: '#1CCAFF',
    dark: '#0E77B7',
    darker: '#053D7A',
    contrastText: '#212B36',
  },
  blue: {
    lighter: '#CCDFFF',
    light: '#6697FF',
    main: '#0045FF',
    dark: '#0027B7',
    darker: '#00137A',
    contrastText: '#fff',
  },
  orange: {
    lighter: '#FEF4D4',
    light: '#FED680',
    main: '#fda92d',
    dark: '#B66816',
    darker: '#793908',
    contrastText: '#212B36',
  },
}

export const admin = 'ADMIN'
export const client = 'CLIENT'
export const moderator = 'MODERATOR'
export const USER_GROUPS = {
  [admin]: { key: admin, value: 'Administrator' },
  [client]: { key: client, value: 'Client' },
  [moderator]: { key: moderator, value: 'Moderator' },
}
export const SUBSCRIPTION_STATUSES = {
  ACTIVE: {
    value: 'Active',
    key: 'ACTIVE',
  },
  STALLED: {
    value: 'Stalled',
    key: 'STALLED',
  },
  INACTIVE: {
    value: 'Inactive',
    key: 'INACTIVE',
  },
}
export const ACCEPTED_GROUPS = [admin, client, moderator]
export const ACCEPTED_ADMIN_GROUPS = [admin, moderator]
export const RESERVATION_STATUSES = {
  BLOCKED: {
    icon: 'alarm_off',
    value: 'Blocked',
    key: 'BLOCKED',
  },
  UNCONFIRMED: {
    icon: 'access_alarm',
    value: 'Unconfirmed',
    key: 'UNCONFIRMED',
  },
  CONFIRMED: {
    icon: 'alarm_add',
    value: 'Confirmed',
    key: 'CONFIRMED',
  },
  CANCELED: {
    icon: 'alarm_off',
    value: 'Canceled',
    key: 'CANCELED',
  },
  PLAYED: {
    icon: 'alarm_on',
    value: 'Played',
    key: 'PLAYED',
  },
}
export const ROLES = {
  EMPLOYEE: {
    descriptionTranslationKey: 'EmployeeRoleDescription',
    value: 'Employee',
    key: 'EMPLOYEE',
  },
  OWNER: {
    descriptionTranslationKey: 'OwnerRoleDescription',
    value: 'Owner',
    key: 'OWNER',
  },
  READ_ONLY: {
    descriptionTranslationKey: 'ReadOnlyRoleDescription',
    value: 'ReadOnly',
    key: 'READ_ONLY',
  },
}
export const WEEK_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
export const FINANCE_TYPES = {
  EXPENSE: {
    value: 'Expense',
    key: 'EXPENSE',
  },
  SALARY: {
    value: 'Salary',
    key: 'SALARY',
  },
  INCOME: {
    value: 'Income',
    key: 'INCOME',
  },
}
export const EXPENSE_TRANSACTION_TYPES = {
  ADVERTISING: {
    value: 'Advertising',
    key: 'ADVERTISING',
    imagePath: '/images/icons/ad.png',
  },
  INSURANCE: {
    value: 'Insurance',
    key: 'INSURANCE',
    imagePath: '/images/icons/insurance.png',
  },
  MATERIALS: {
    value: 'Materials',
    key: 'MATERIALS',
    imagePath: '/images/icons/materials.png',
  },
  RENT: {
    value: 'Rent',
    key: 'RENT',
    imagePath: '/images/icons/rent.png',
  },
  SALARY: {
    value: 'Salary',
    key: 'SALARY',
    imagePath: '/images/icons/salary.png',
  },
  TAX: {
    value: 'Tax',
    key: 'TAX',
    imagePath: '/images/icons/tax.png',
  },
}
export const INCOME_TRANSACTION_TYPES = {
  REFUND_AT_VENUE: {
    value: 'RefundAtVenue',
    key: 'REFUND_AT_VENUE',
  },
  REFUND_ONLINE: {
    value: 'RefundOnline',
    key: 'REFUND_ONLINE',
  },
  DEPOSIT: {
    value: 'Deposit',
    key: 'DEPOSIT',
  },
  FULL_PAYMENT: {
    value: 'FullPayment',
    key: 'FULL_PAYMENT',
  },
  PRODUCT: {
    value: 'Product',
    key: 'PRODUCT',
  },
  VOUCHER: {
    value: 'GiftCard',
    key: 'VOUCHER',
  },
}
export const TRANSACTION_TYPES = {
  NONE: {
    value: 'Standard',
    key: 'NONE',
  },
  ...EXPENSE_TRANSACTION_TYPES,
  ...INCOME_TRANSACTION_TYPES,
}
export const PAY_TYPES = {
  FIXED_PAY: {
    descriptionTranslationKey: 'FixedPayDescription',
    value: 'FixedPay',
    key: 'FIXED_PAY',
  },
  PERCENTAGE: {
    descriptionTranslationKey: 'PercentageDescription',
    value: 'Percentage',
    key: 'PERCENTAGE',
  },
}
export const USER_STATUSES = {
  ACTIVE: {
    key: 'ACTIVE',
    value: 'Active',
  },
  DISABLED: {
    key: 'DISABLED',
    value: 'Disabled',
  },
  UNVERIFIED: {
    key: 'UNVERIFIED',
    value: 'Unverified',
  },
}
export const ONLINE_PAYMENT_TYPES = {
  FULL: {
    key: 'FULL',
    value: 'Full',
  },
  FULL_AND_DEPOSIT: {
    key: 'FULL_AND_DEPOSIT',
    value: 'FullAndDeposit',
  },
  DEPOSIT_ONLY: {
    key: 'DEPOSIT_ONLY',
    value: 'DepositOnly',
  },
}
export const PAYMENT_STATUSES = {
  ONLINE_PAYMENT_PENDING: {
    key: 'ONLINE_PAYMENT_PENDING',
    value: 'OnlinePaymentPending',
  },
  PARTIALLY_PAID_ONLINE: {
    key: 'PARTIALLY_PAID_ONLINE',
    value: 'PartiallyPaidOnline',
  },
  PAID_ONLINE: {
    key: 'PAID_ONLINE',
    value: 'PaidOnline',
  },
  NOT_PAID: {
    key: 'NOT_PAID',
    value: 'NotPaid',
  },
  PARTIALLY_PAID_AT_VENUE: {
    key: 'PARTIALLY_PAID_AT_VENUE',
    value: 'PartiallyPaidAtVenue',
  },
  PAID_AT_VENUE: {
    key: 'PAID_AT_VENUE',
    value: 'PaidAtVenue',
  },
}
export const ONLINE_PAYMENT_STATUSES = [
  PAYMENT_STATUSES.ONLINE_PAYMENT_PENDING,
  PAYMENT_STATUSES.PARTIALLY_PAID_ONLINE,
  PAYMENT_STATUSES.PAID_ONLINE,
]
export const AT_VENUE_PAYMENT_STATUSES = [
  PAYMENT_STATUSES.NOT_PAID,
  PAYMENT_STATUSES.PARTIALLY_PAID_AT_VENUE,
  PAYMENT_STATUSES.PAID_AT_VENUE,
]
export const GIFTCARD_TYPES = {
  FIXED_AMOUNT: {
    value: 'FixedAmount',
    key: 'FIXED_PAY',
  },
  PERCENTAGE: {
    value: 'Percentage',
    key: 'PERCENTAGE',
  },
}
export const ASPECT_RATIOS = [
  {
    text: '16:9',
    value: 16 / 9,
    width: 1920,
    height: 1080,
  },
  {
    text: '5:4',
    value: 5 / 4,
    width: 1350,
    height: 1080,
  },
  {
    text: '4:3',
    value: 4 / 3,
    width: 1440,
    height: 1080,
  },
  {
    text: '2:1',
    value: 2 / 1,
    width: 1024,
    height: 512,
    name: 'BestForTwitter',
  },
  {
    text: '1.91:1',
    value: 1.91 / 1,
    width: 1200,
    height: 630,
    name: 'BestForFacebookAndInstagram',
  },
  {
    text: '1:1',
    value: 1 / 1,
    width: 1080,
    height: 1080,
    name: 'SuitableForFacebookAndInstagram',
  },
  {
    text: '3:4',
    value: 3 / 4,
    width: 1080,
    height: 1440,
  },
  {
    text: '4:5',
    value: 4 / 5,
    width: 1080,
    height: 1350,
    name: 'SuitableForFacebookAndInstagram',
  },
  {
    text: '9:16',
    value: 9 / 16,
    width: 1920,
    height: 1080,
  },
]
export const FRAME_FONTS = [
  'Source Sans Pro',
  'Roboto',
  'Montserrat',
  'Inter',
  'Oswald',
  'Noto Sans',
  'Raleway',
  'Sofia Sans',
  'Playfair Display',
  'Merriweather',
  'Roboto Slab',
  'Handjet',
  'EB Garamond',
  'Comfortaa',
  'Great Vibes',
  'Amatic SC',
  'Poiret One',
  'Rubik Glitch',
  'Rubik Iso',
  'Rubik Distressed',
  'Kablammo',
  'Lobster',
  'Open Sans',
  'Lora',
]
