import { a11yProps } from 'utils'

const TabPanel = (props) => {
  const { children, value, index, ...rest } = props

  return (
    <div role="tabpanel" hidden={value !== index} {...a11yProps(index)} {...rest}>
      {value === index && <>{children}</>}
    </div>
  )
}

export default TabPanel
