import { Box, Grid, IconButton, List, ListSubheader, Popover, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import SimpleBar from 'simplebar-react'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
import { emojis, emojisCategories } from 'utils/emojis'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

const EmojiPicker = ({ setMessage }) => {
  const { t } = useTranslation('chat')

  const [anchorEl, setAnchorEl] = useState(null)
  const [searchText, setSearchText] = useState('')

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelectEmoji = (emoji) => () => {
    setMessage((message) => `${message}${emoji}`)
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      <IconButton onClick={handleClick} size="small">
        <InsertEmoticonIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{ zIndex: 'zIndex.modal' }}
      >
        <Box sx={{ width: '260px', p: 0.5 }}>
          <TextField
            size="small"
            fullWidth
            placeholder={t('SearchForEmoji')}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
          />
          <SimpleBar style={{ height: '300px' }}>
            {searchText === '' ? (
              emojisCategories.map((category) => (
                <List
                  key={category}
                  aria-labelledby={category}
                  subheader={
                    <ListSubheader component="div" id={category}>
                      {category}
                    </ListSubheader>
                  }
                >
                  <Grid container spacing={1}>
                    {emojis
                      .filter((emoji) => emoji.c === category)
                      .map((item) => (
                        <Grid item xs={2} key={item.e}>
                          <Typography sx={{ fontSize: 24 }} component="div">
                            <IconButton size="small" onClick={handleSelectEmoji(item.e)}>
                              {item.e}
                            </IconButton>
                          </Typography>
                        </Grid>
                      ))}
                  </Grid>
                </List>
              ))
            ) : (
              <Grid container spacing={1}>
                {emojis
                  .filter((emoji) => emoji.d.toLowerCase().includes(searchText.toLowerCase()) || searchText === '')
                  .map((item) => (
                    <Grid item xs={2} key={item.e}>
                      <Typography sx={{ fontSize: 24 }} component="div">
                        <IconButton size="small" onClick={handleSelectEmoji(item.e)}>
                          {item.e}
                        </IconButton>
                      </Typography>
                    </Grid>
                  ))}
              </Grid>
            )}
          </SimpleBar>
        </Box>
      </Popover>
    </div>
  )
}

export default memo(EmojiPicker)
