/* eslint-disable import/no-anonymous-default-export */
export default (theme) => ({
  MuiListItemText: {
    styleOverrides: {
      root: {
        fontSize: '12px !important',
      },
    },
    defaultProps: {
      primaryTypographyProps: {
        variant: 'body2',
      },
      secondaryTypographyProps: {
        variant: 'caption',
      },
    },
  },
})
