import { createContext, useReducer, useContext, useEffect } from 'react'
import { DEFAULT_PAGE_SIZE, LANGUAGE, DEFAULT_DRAWER_OPEN } from 'config'
import { useMediaQuery, useTheme } from '@mui/material'

const userPreferencesReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'CHANGE_LANGUAGE':
      localStorage.setItem('language', payload)
      return {
        ...state,
        language: payload,
      }
    case 'CHANGE_DEFAULT_PAGE_SIZE':
      localStorage.setItem('pageSize', payload)
      return {
        ...state,
        pageSize: payload,
      }
    case 'CHANGE_IS_DRAWER_OPEN':
      localStorage.setItem('isDrawerOpen', payload)
      return {
        ...state,
        isDrawerOpen: payload,
      }
    case 'CHANGE_DRAWER_TO_OPEN':
      localStorage.setItem('isDrawerOpen', true)
      return {
        ...state,
        isDrawerOpen: true,
      }
    default:
      return state
  }
}

const initialState = {
  language: localStorage.getItem('language') || LANGUAGE,
  pageSize: +localStorage.getItem('pageSize') || +DEFAULT_PAGE_SIZE,
  isDrawerOpen: localStorage.getItem('isDrawerOpen') || DEFAULT_DRAWER_OPEN,
}

const UserPreferencesContext = createContext(initialState)

const UserPreferencesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userPreferencesReducer, initialState)
  const theme = useTheme()

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })

  useEffect(() => {
    localStorage.getItem('language') === null && localStorage.setItem('language', LANGUAGE)
  }, [])

  useEffect(() => {
    if (!isDesktop) {
      dispatch({
        type: 'CHANGE_DRAWER_TO_OPEN',
      })
    }
  }, [isDesktop])

  const changeLanguage = (language) => {
    dispatch({
      type: 'CHANGE_LANGUAGE',
      payload: language,
    })
  }

  const changePageSize = (pageSize) => {
    dispatch({
      type: 'CHANGE_DEFAULT_PAGE_SIZE',
      payload: pageSize,
    })
  }

  const changeDrawerSize = () => {
    dispatch({
      type: 'CHANGE_IS_DRAWER_OPEN',
      payload: !JSON.parse(state.isDrawerOpen),
    })
  }

  return (
    <UserPreferencesContext.Provider
      value={{
        language: state.language,
        pageSize: state.pageSize,
        isDrawerOpen: JSON.parse(state.isDrawerOpen),
        isDesktop,
        changeDrawerSize,
        changeLanguage,
        changePageSize,
      }}
      displayName="User Preferences"
    >
      {children}
    </UserPreferencesContext.Provider>
  )
}

const useUserPreferences = () => {
  const context = useContext(UserPreferencesContext)

  if (context === undefined) {
    throw new Error('useUserPreferences can only be used inside UserPreferencesProvider')
  }

  return context
}

export { UserPreferencesProvider, useUserPreferences }
