import { Box, CircularProgress, Typography } from '@mui/material'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

const Loading = ({ height = '100vh' }) => {
  const { t } = useTranslation()

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress sx={{ marginBottom: 2 }} />
        <Typography variant="body2" color="textSecondary">
          {t('JustASec')}
        </Typography>
      </Box>
    </Box>
  )
}

export default memo(Loading)
