/* 
 * VERSION - should be x.y.z where:
 * x - for major changes
 * y - for new features
 * z - for bug fixes and small adjustments

 * DATE - written in YYYY-MM-DD format

 * DESCRIPTION - should be used in a case of a message towards the clients regarding a major update for example

 * template:

{
  "version": "VERSION",
  "date": "DATE",
  "description": "DESCRIPTION",
  "changes": {
    "added": [],
    "changed": [],
    "deprecated": [],
    "removed": [],
    "fixed": [],
    "security": []
  }
}

*/
const changelog = [
  {
    version: '4.0.6',
    date: '2024-10-28',
    changes: {
      added: [
        'Width and align to text elements in the Photo Builder so you can easily align the text the way you need it to be (very useful for dynamic values)',
        'Hover effect over the text in preview in Photo Builder for simple orientation',
        'FAQ sections in a few pages for our newcomers. This is about be an ongoing process - it will take us some time before we can write documentation for each feature we provide so please be patient. In the meantime you can always ask us on our Discord if something is unclear',
        "A configuration item to scroll automatically to today's date if on monthly calendar",
      ],
      changed: [],
      deprecated: [],
      removed: [
        'Ability to add alpha (opacity) to the color picker color of your choice as it was never used anywhere',
      ],
      fixed: [
        'A bug where searching a reservation in the reservation picker field would sometimes show wrong results',
        'Pocket not being able to scroll if you have many items that cannot fit on your screen',
      ],
      security: [],
    },
  },
  {
    version: '4.0.5',
    date: '2024-10-18',
    changes: {
      added: [],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: ['A bug where searching a reservation in the reservation picker field would sometimes show wrong results'],
      security: [],
    },
  },
  {
    version: '4.0.4',
    date: '2024-10-15',
    changes: {
      added: [
        'A functionality to our mobile version to change your language and logout easier when you open the burger menu',
        'A faster deployment of the app whenever we deploy a new version. Previously we had a 3 minute downtime while now it should be less than a second!',
      ],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: [
        "Sign up flow - we haven't updated it for a while as we still don't accept customers out of the invited ones",
      ],
      security: [],
    },
  },
  {
    version: '4.0.3',
    date: '2024-10-14',
    changes: {
      added: ['A functionality to the Photo Builder enabling you to select images from Pocket directly'],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: [
        "A bug where an image in the Photo Builder wouldn't load because of a CORS bug",
        "Reservation hour being available even if it's already booked when moving a reservation date",
        'History timeline connector showing only on one item',
        'Reservation page navigating back to Calendar even if there was an error saving the reservation',
      ],
      security: [],
    },
  },
  {
    version: '4.0.2',
    date: '2024-10-10',
    changes: {
      added: [],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: [
        'Reservation status changed to paid at venue if the reservation was previously paid online',
        'Chat attempt to ping if not authenticated yet',
        'Media not being accepted',
      ],
      security: [],
    },
  },
  {
    version: '4.0.1',
    date: '2024-10-08',
    changes: {
      added: [],
      changed: [
        'Calendar is now 3x faster depending on your location (from ~350ms for around 200 reservations to ~120ms for the same amount of reservations) as all API calls are happening asynchronously',
      ],
      deprecated: [],
      removed: [],
      fixed: [
        'Calendar was refetching endpoints twice',
        "User configuration now extends the default configuration set by us - if you didn't have some newly added features, they will appear even if you don't have them in your config",
        'Pocket opening twitch',
      ],
      security: [],
    },
  },
  {
    version: '4.0.0',
    description:
      "Fun fact about this update: It has more code than what we had until now in both our front-end and back-end! Online Payments are finally here! This is one of the bigger steps for us as we are getting closer and closer to finalizing our closed beta and nearing the first official release of Keyhowl to the public! We made an integration with Stripe Connect, so you can finally accept payments from your customers for all your reservations. We've added an additional step in the reservation form where your client selects how they are going to pay. In case you have only one payment method, this step is skipped. Since there are a lot of updates all around the app, please read the changes below.",
    date: '2024-10-07',
    changes: {
      added: [
        'Payment methods in Escape room page',
        'Products - this is a new and very big feature. You can use it if you offer merchandise or stuff like that',
        'Game modes - you can now add game modes to your escape rooms',
        "Photo Builder - it's time to ditch Photoshop and simply use our Photo Builder functionality. With a powerful drag n drop you can easily add, edit or remove elements on the canvas. You can add images and text in a layer based system to control the index of visibility for each element. We have a ton of styling opportunities for the text as well! Go on - try it!",
        "Photos - we decided that it's time that all the photos you take for your teams are going to be kept with us. That's right! All those images are going to be stored on a server so you can easily remember who were those pretty faces that played your room when they come back to play again. There is also a functionality to share those photos after you've closed the reservation if you forgot or just wanted to deal with the photos later",
        "Pocket - you'll love this one. Pocket is used for example when you're taking a photo of the team with your phone and don't have time to send it to them immediately because the next team is at the door. Pocket will save all your photos so you can later get back to them and send them to the teams. Plus you can directly open the Photo Builder with the preloaded photo you selected",
        'A Discord server - Yay, we are starting to build our community, hop on the server already, will ya?',
        'Adding an attached income to a reservation in Finances',
        'Refund option in finances',
        'Filters in the gift cards page',
        'A button in the top bar if the page is longer than expected and there is an action, like save or create, that needs to be done at the end of your changes. This is to prevent any data loss and to make sure you see the save button at all times',
        "Default language in the escape room page. This is useful for your booking form if you don't specifically set a default language to load",
        'A warning message in Escape room schedules if you have overlapping hours',
        "A unified pricing summary component for where it's applicable",
        'Drag and drop for the escape room media gallery',
        'Automatic removal of all future assignments of a game master if you remove him from the company (not the escape room)',
        'Income types in the incomes table in Finances',
        'Nested incomes in the incomes table in Finances - currently supported only for reservations. When you have multiple incomes they nest and you can expand the whole row to see all descendent rows',
        'Game mode in calendar preferences (in each reservation)',
        "Ability to hide payment status if for example you don't care about it because you accept only cash",
        'Set amount dialog when changing the reservation payment status',
        'Highlighted the current day in the Calendar for more visibility',
        'Price amount text fields now have your preselected currency for more clarification',
        'Language selector for Invoice PDF export',
        'Language selector for Gift card PDF export',
        'Refresh button in the Calendar to refetch it. We also added an automatic refresh each time you focus on the tab again.',
        'Reservation picker field in Add income dialog in Finances if a finance has to be attached to a reservation',
      ],
      changed: [
        'Played reservation dialog is now fully redesigned and is using a stepper for a navigation. As we added products, we had to overhaul this component, since it was getting really hard to navigate',
        'Escape room page now has tabs for easier navigation',
        'The remove button on a media is now easier to see',
        'Gift cards now show the reason why it is invalid (e.g. expired or used)',
        'Gift card status is more visible and with an eye pleasing colors',
        'All color picker text fields are now buttons with a background color of the selected color of choice',
        'Tenant settings page design is now more like the other parts of the app',
        'Some of our main dependencies got upgraded, which should speed up the app even more',
        'Invoice default number now contains the date of the invoice creation',
        'Name column in finances is now going to take most of the space on the screen to format it better',
        'Default behavior of avatar is now showing a tooltip of the full user name',
        'Receipt is now showing sums taking into account if there are finances for the current reservation',
        "Create income finance is now the default behavior when creating a gift card from the Gift card's page",
        'Improve multiple translations',
        "Rearranged the menu as per our analytics for our users. We've put the most important navigation items on top",
        'Default theme is now dark',
        'Reservation status menu in Calendar and in Reservations are now completely identical',
        'Create reservation button in Calendar is now a bigger size',
        'Chat is now unpinned by default off your screen in favor of Pocket. You can pin it back when you go to the Chat page',
        'We opened some of the customer fields in the reservation dialog after the game has been played. You still cannot change the date of the game though, so please be careful when you\'re putting a reservation in a "played" status',
        'Receipt is now called Payment summary',
        "Payment summary (previously known as Receipt) is now moved from the reservation edit dialog to it's own dialog",
        'Icons on the reservation menu in the Calendar',
        'Custom fields are not in an accordion anymore, they are visible at all times. We decided that if you want custom fields then you would probably want to see them at all times',
      ],
      deprecated: [],
      removed: [
        'Custom fields cannot have price anymore, you can use products instead',
        "Receipt in reservation dialog - it's now moved in the reservation menu in the Calendar",
      ],
      fixed: [
        'Add game master button in Escape room page now disables itself if there are no game masters to add',
        'Chat widget is now always being fixed to the bottom of the screen on mobile devices',
        'Gift card date is now red if the date has passed',
        'Uploaded pictures are now completely covering their container box',
        'A bug not being able to search for a reservation by email',
        'Invoice creation process now works as expected',
        'A bug where the weekly calendar would show duplicated dates on the day of the daytime saving switch',
        'Chat widget seen functionality wrong height for avatars',
        'A timezone bug in the finances, showing a duplicated month',
        "Showing NaN (not a number) if any of the room prices weren't entered",
        "Dates on tables aren't wrapping anymore if the screen isn't large enough",
        'Search in reservations breaking the app if less than 3 characters were entered',
        'Hour field in reservation dialog is disabled if the reservation is marked as played',
        'Hid logo text if no company logo set up in Invoice',
        'Invoice footer is now showing provider and not recipient',
        'Creating a gift card with a valid date will now make it valid till the rest of the selected day',
        'A bug preventing reservations from being made for time slots previously canceled.',
        'Played reservation dialog title if no team name was saved for the selected reservation',
        "...a lot more minor bugs that you might've noticed or not",
      ],
      security: [],
    },
  },
  {
    version: '3.1.5',
    date: '2023-11-14',
    changes: {
      added: ['A successful message on reservation update and create'],
      changed: [
        "Color of note's snackbar on save",
        'Escape room is now impossible to edit if there are no languages selected',
      ],
      deprecated: [],
      removed: [],
      fixed: [
        "Localized values in the escape room fields with rich text weren't saving their state if you add a language and switch to it",
        'An issue where reservation would throw an error when changing its date and saving it',
        'Values returning to initial values when selecting a new date',
        'Note create not working',
        'Border radius of rich text field not being rounded',
      ],
      security: [],
    },
  },
  {
    version: '3.1.4',
    date: '2023-11-12',
    changes: {
      added: ['Title for each page for better analytics'],
      changed: ['Padding for Calendar page', 'Some misleading translations'],
      deprecated: [],
      removed: [],
      fixed: ['A rare bug where viewing a reservation would return an error'],
      security: [],
    },
  },
  {
    version: '3.1.3',
    date: '2023-11-09',
    changes: {
      added: [
        'Description text field in error screen, so we could easily understand how you got the issue',
        "Default booking form at /book/YOUR_COMPANY_ID/YOUR_ESCAPE_ROOM_ID. Currently it's here only for testing purposes, but you're free to look around. FYI, you can book your free hours from this form too",
      ],
      changed: ['Favicon size to a smaller one'],
      deprecated: [],
      removed: [],
      fixed: [
        'Wrong visual representation of booked hour for hours before (or after) the DST switch in the Calendar and in the confirmation email',
      ],
      security: [],
    },
  },
  {
    version: '3.1.2',
    date: '2023-10-17',
    changes: {
      added: [],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: [
        'Receipt in reservation dialog showing wrong discount if percentage gift card was added',
        'Linear progress component on the Dashboard showing wrong values',
        'Wrong hour showed on reservation page only on the day of daytime saving switch',
      ],
      security: [],
    },
  },
  {
    version: '3.1.1',
    date: '2023-09-30',
    changes: {
      added: [],
      changed: [
        'Testing how the pinned Chat will work with new CSS properties, since the old implementation was jumping on mobile',
        'Dark mode on waivers (depending on your system mode)',
      ],
      deprecated: [],
      removed: [],
      fixed: [],
      security: [],
    },
  },
  {
    version: '3.1.0',
    date: '2023-09-28',
    description:
      'As time passes by, we are getting closer to releasing our version 4, which will include a handful of updates including Schedules and Online payments. This will be the last update before we officially release Keyhowl to the public. In the mean time we decided to refactor some of our pages, that needed a refreshment and will boost your productivity on mobile.',
    changes: {
      added: [],
      changed: [
        'Reservation cannot be marked as played if there are no added game masters to it',
        'Finances to be more mobile friendly',
        'Waivers to be more mobile friendly',
      ],
      deprecated: [],
      removed: [],
      fixed: [
        'Non-existing reservation always failing with the Error boundary, rather than a 404 not found message',
        'Missing translation on an error page',
        'Percentage gift cards not calculating the total sum correctly (oh, math, how I love you)',
      ],
      security: [],
    },
  },
  {
    version: '3.0.4',
    date: '2023-09-14',
    changes: {
      added: [],
      changed: ['Action buttons in reservation to be more mobile friendly'],
      deprecated: [],
      removed: [],
      fixed: [],
      security: [],
    },
  },
  {
    version: '3.0.3',
    date: '2023-09-14',
    changes: {
      added: [],
      changed: ["Next reservations in Dashboard don't show blocked reservations anymore"],
      deprecated: [],
      removed: [],
      fixed: [],
      security: [],
    },
  },
  {
    version: '3.0.2',
    date: '2023-09-05',
    changes: {
      added: [
        'In Preferences in "Default company" a new option "Last selected company"',
        'Calendar preferences cog icon in the calendar for easier customization of the calendar',
      ],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: ['Gift card logo not showing with correct dimensions if the logo is not a square'],
      security: [],
    },
  },
  {
    version: '3.0.1',
    date: '2023-09-01',
    changes: {
      added: [
        'A "removed user" tooltip on avatars of users that were removed from the employees. If the removed user was a game master for a reservation, or made a finance, it will show him as a removed user',
      ],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: [
        'A bug where played reservation in finances would show multiple game masters and duplicated team name if you have more than one escape room with the same hour and another game master is assigned to the other reservation from the other escape room with the same hour',
      ],
      security: [],
    },
  },
  {
    version: '3.0.0',
    date: '2023-08-19',
    changes: {
      added: [
        'A Dashboard page! We know you were waiting quite a while for this one, but we had to do it right, so we needed some time. We hope you like it!',
        '"UK English" language, which is basically the same as "USA English", but with a different date format',
        "Language selector in profile preferences, since you couldn't change the language from a mobile device",
        'User avatar and date in expenses in Finances',
        'Filters in expenses in Finances, so you can filter by employee and by expense type',
        'Show receipt button in reservation dialog in the Calendar and in the Reservation page',
        'An informational description for adding game masters for the day in the Calendar',
      ],
      changed: [
        "The overall look and feel of the app. We've added more contrasty colors to the main design layout (mainly on the light version), we also face lifted some of the main components. We won't go into details, but your eyes are going to thank you for those changes",
        "The way avatars are fetched. We now cache avatars, so you won't refetch them every time you switch to a new page",
        'Expenses sorting in Finances is now from newest to oldest, instead of oldest to newest',
        'Dialog windows have less padding on mobile devices, so you can see more content on the screen',
      ],
      deprecated: [],
      removed: [],
      fixed: [
        'A bug where played reservation dialog would show multiple game masters if you have more than one escape room with the same hour and another game master is assigned to the other reservation from the other escape room with the same hour',
        "A bug where adding additional cost from a product to a reservation wouldn't show it in the final price field in the played reservation dialog",
        "A bug where changing reservation hour and then trying to change it back to its initial value wouldn't work in the calendar and in reservation pages",
        "A bug where updating a waiver would update all existing waivers in the database (Don't worry, we had the data backed up and we restored it)",
        'A bug where calculating percentages in some places would show a wrong value',
      ],
      security: [],
    },
  },
  {
    version: '2.8.1',
    date: '2023-07-14',
    changes: {
      added: [
        'Emails from the waiver in the Played reservation dialog. If your clients signed the waiver and put their email addresses in, they will be automatically entered there',
        'A way to see only the reservations that you are a game master of or don\'t have an assigned one. To control this you can go to your profile preferences and check the "Show only reservations where I am a game master" checkbox or go to the bottom of the calendar page (only desktop)',
      ],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: [],
      security: [],
    },
  },
  {
    version: '2.8.0',
    date: '2023-07-14',
    changes: {
      added: [
        'Sending email to custom email addresses after changing a reservation status to "Played"',
        'A description as a tooltip icon next to the sending email functionality',
        'A checkbox to select whether to send an email to the customer or not when changing a reservation status to "Played"',
      ],
      changed: ['Max photos to upload are now 7 instead of 3 (by request of one of our customers)'],
      deprecated: [],
      removed: [],
      fixed: ['Wrong border radius on the multi language rich text editor'],
      security: [],
    },
  },
  {
    version: '2.7.3',
    date: '2023-07-02',
    changes: {
      added: [],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: ['Chat bug where the chat would drop connection after 90 seconds'],
      security: [],
    },
  },
  {
    version: '2.7.2',
    date: '2023-07-02',
    changes: {
      added: [],
      changed: [
        'The rich text editor now supports adding links. You can mark a text and then click on the link icon, then a popup should appear',
        'The edit button in Escape rooms page is now a bit bigger and easier to click on',
        'We did a code refactor on the escape room page. It should feel slightly faster now',
      ],
      deprecated: [],
      removed: [],
      fixed: [],
      security: [],
    },
  },
  {
    version: '2.7.1',
    date: '2023-06-25',
    changes: {
      added: [],
      changed: [
        'The description field in the escape room page is now a rich text field instead of a plain textarea field',
        "The design of the rich text editor is now more user friendly and it's easier to use",
        'The rich text editor now supports adding colored text with a background color',
      ],
      deprecated: [],
      removed: [],
      fixed: [],
      security: [],
    },
  },
  {
    version: '2.7.0',
    date: '2023-06-12',
    description:
      'Another cool update from us. You can now add pricing to the checkbox and select custom fields you have in your reservation form. The custom fields with pricing are also added to the reservation dialog when marking a reservation as played',
    changes: {
      added: ['Pricing to checkbox custom field', 'Pricing to select custom field'],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: ['Bug where uploading avatar wont work'],
      security: [],
    },
  },
  {
    version: '2.6.1',
    date: '2023-04-15',
    description:
      'This is a bigger one. Your customers can now sign waivers before entering your escape room. Important note - we save those waivers for 2 years (as required by GDPR) and you can download them at any time. We also added a new template for post-game emails. You can now send a custom email to your customers after they finish the game',
    changes: {
      added: ['Template for post-game emails', 'Waivers'],
      changed: [
        "Warning in the played reservation status dialog if the user didn't select a game master and/or didn't upload a team photo",
      ],
      deprecated: [],
      removed: [],
      fixed: ['Bug where uploading avatar wont work'],
      security: [],
    },
  },
  {
    version: '2.5.3',
    date: '2023-04-12',
    changes: {
      added: [],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: ['Company settings not loading if the user account is freshly created'],
      security: [],
    },
  },
  {
    version: '2.5.2',
    date: '2023-04-05',
    changes: {
      added: [],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: ['Register page for owners not working since Vite migration'],
      security: [],
    },
  },
  {
    version: '2.5.1',
    date: '2023-03-16',
    changes: {
      added: [],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: ['Recipient fields in Invoice PDF showing provider values'],
      security: [],
    },
  },
  {
    version: '2.5.0',
    date: '2023-02-24',
    changes: {
      added: ['Total expenses and total salaries in finances as a tooltip'],
      changed: ['Total expenses (and newly added total salaries) now show total for each active escape room'],
      deprecated: [],
      removed: [],
      fixed: [],
      security: [],
    },
  },
  {
    version: '2.4.2',
    date: '2023-02-13',
    changes: {
      added: [
        'Slight performance improvements all over the app',
        'Quick view button in the reservation dialog in the calendar on all played reservations',
      ],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: [
        'Bug where a missing reservation will throw a "bug view" to the user - it will now throw a 404 Not found',
      ],
      security: [],
    },
  },
  {
    version: '2.4.1',
    date: '2023-02-13',
    changes: {
      added: [],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: [
        'Bug that was breaking the app if Min/Max Players were changed from an Android phone in the Escape Room page',
      ],
      security: [],
    },
  },
  {
    version: '2.4.0',
    date: '2023-01-29',
    changes: {
      added: [
        'Total tooltip for expenses to tell you the amount of expenses without including the salaries',
        'Google Analytics for better understanding of how our clients (you) are using the app. This way we will know how you interact with your app and see what we can improve',
      ],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: [],
      security: [
        'We now use Cloudflare as a primary security measure. This will mean that if our system is under DDoS attack, Cloudflare should protect the app and no downtime will occur. Other security features like firewalls and bot protections are also included.<br /> Another great feature is that the system is now using a CDN and the app will load faster not depending on your region, because it will provide host that is closer to you',
      ],
    },
  },
  {
    version: '2.3.2',
    date: '2023-01-25',
    description: '',
    changes: {
      added: [],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: [
        'Bug where editing your company settings will set the activity to false (currently is not being used in the BETA)',
        "Bug when editing company info, default tenant will get selected even if you didn't edit it",
        'Bug where cache of the whole website required clean reload after new changes were moved to production for the users to see them',
      ],
      security: ['Header to forbid the website to be shown as a frame (iframe) to prevent clickJacking'],
    },
  },
  {
    version: '2.3.1',
    date: '2023-01-19',
    description: '',
    changes: {
      added: ['Ability to logout from all sessions'],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: [
        'Bug when selecting custom period on finances',
        'Bug where page would enter in an infinite loading state if a token has expired and the user came back to the app',
        'Bug where employees were led to Register page even if they are part of a company',
        'A few useless endpoint calls for faster load',
        'A few flickering screens in between logins and logouts',
      ],
      security: [],
    },
  },
  {
    version: '2.2.0',
    date: '2023-01-13',
    description: '',
    changes: {
      added: [
        "Token refresh functionality - we now use cookies instead of local storage for a more secure experience. Previously the app would automatically logout you from your profile after 24h without refreshing your session. <br /> Now you have an authorization token (secret key which gives you access to our servers) which is valid for 30 minutes and a refresh token (another secret key used to get you an authorization token) with a 2 day validity. If you don't log in Keyhowl for more than 48 hours, you will still be logged out automatically. In case you get in less than 48 hours you won't need to reauthenticate.",
        "Sessions - now you can see which accounts you are logged in from with their IP addresses and ability to log out from all accounts if there is something suspicious or you don't have access to a certain device",
        "Ability to show or hide price in gift card's PDF rendered document",
        "Ability to change currency in gift card's PDF rendered document",
      ],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: [
        'Bug when user was trying to register on the website',
        'Bug where the page would flicker between loading screen and dashboard on initial login',
      ],
      security: ['Added more secure method of communicating in the Chat that prevents session stealing'],
    },
  },
  {
    version: '2.1.2',
    date: '2022-10-21',
    description: '',
    changes: {
      added: [],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: [
        'Bug when user email was set with a different capitalization (ex. "Myemail@email.com" and not "myemail@email.com") would break the page',
      ],
      security: [],
    },
  },
  {
    version: '2.1.1',
    date: '2022-07-01',
    description: '',
    changes: {
      added: [],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: ['Bug when even owners wont be able to see Finances components if the permissions were set this way'],
      security: [],
    },
  },
  {
    version: '2.1.0',
    date: '2022-07-01',
    changes: {
      added: ['Game Masters viewing permissions'],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: [],
      security: [],
    },
  },
  {
    version: '2.0.1',
    date: '2022-06-21',
    description:
      "There we go! V2 is out! At first sight it doesn't have much things changed. The only big change is that you can now select multiple escape rooms and not just one as your selection. Trust us, this was a huge change in the code and it was a lot of work to get to this point. <br /> Now you can check reservations for all rooms in Reservation, you also have multiple rooms in the calendar, so when you are in a call with a customer, you can easily tell him what rooms are available for today and when. Last, but not least, you have Finances for all your rooms simultaneously, how cool is that? <br /> Expect some more cool features in the very near future! ❤️",
    changes: {
      added: [
        'Multiple escape rooms selection and tracking data of them',
        'Escape room field in tables - Finances and Reservations',
        'Game masters permissions',
      ],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: [
        '"To" date in custom period (was beginning of the day, not the end of it)',
        'Bug that made changing reservation status to Played from Reservations impossible',
      ],
      security: [],
    },
  },
  {
    version: '1.2.2',
    date: '2022-05-04',
    description: '',
    changes: {
      added: ['Team name column in Reservations'],
      changed: ['Column order in Reservations', 'Visible "generate PDF" icon button on each gift card in Gift cards'],
      deprecated: [],
      removed: [],
      fixed: [
        'State of people keeping the old state in Played dialog after changing it from the Reservation Dialog',
        'Wrongfully used colors on tabs in Reservations',
      ],
      security: [],
    },
  },
  {
    version: '1.2.1',
    date: '2022-04-05',
    description: '',
    changes: {
      added: ['Search in reservations', 'Online users menu in top bar'],
      changed: [
        'Dummy notifications icon button is now hidden, until we implement this feature',
        'Height of top bar from 86 pixels to 80 pixels',
        'Backgrounds of all menus are now lighter and more visible in dark mode',
      ],
      deprecated: [],
      removed: [],
      fixed: [
        'Wrong action and secondary button texts of some finance dialogs',
        'Translation regarding incomes and expenses, considered only for expense values',
        'Unpassing page break after logging out and logging in again',
      ],
      security: [],
    },
  },
  {
    version: '1.1.2',
    date: '2022-03-22',
    description: '',
    changes: {
      added: [],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: [
        'Bug when reservations after DST change wont appear on free reservations',
        'Wrong link copy when clicking "Copy reservation link"',
        'Checkbox in custom fields not being disabled if the reservation has been set as played',
      ],
      security: [],
    },
  },
  {
    version: '1.1.1',
    date: '2022-03-14',
    description: '',
    changes: {
      added: [],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: ['Bug when reservations after DST change wont appear'],
      security: [],
    },
  },
  {
    version: '1.1.0',
    date: '2022-03-09',
    description: '',
    changes: {
      added: ['Reservation status picker in "Reservations"'],
      changed: [],
      deprecated: [],
      removed: [],
      fixed: [],
      security: [],
    },
  },
  {
    version: '1.0.0',
    date: '2022-03-04',
    description:
      'We are very happy to announce our first big version - 1.0.0! All of you, our beta testers, will have a bunch of new features to use, that will cancel the use of Google Docs (because of Notes) and all types of messenger apps (because of Chat). Soon we will start implementing push notifications in our app, so your phone will vibrate and create noise if a new message has been received, or a new reservation has been made. Same for your computers - direct notification via your browser, even if the Keyhowl app is not opened',
    changes: {
      added: ['Chat', 'Notes', 'Gift card PDF render', 'Website field in company settings and registration'],
      changed: [
        'Avatar in navigation bar and top bar to show initials if no avatar image is uploaded',
        'Task background color',
      ],
      deprecated: [],
      removed: [],
      fixed: [],
      security: [],
    },
  },
  {
    version: '0.3.0',
    date: '2022-02-14',
    description: '',
    changes: {
      added: ["Game masters column in finances' incomes", 'Performance optimizations in calendar'],
      changed: ['Design of changelog'],
      deprecated: [],
      removed: ["Ability to edit text if it was a group in finances' incomes for security"],
      fixed: [],
      security: [],
    },
  },
  {
    version: '0.2.0',
    date: '2022-02-12',
    description: '',
    changes: {
      added: [
        'Number of players text field in played dialog',
        'Disclaimer in played dialog if no game masters were added',
        'Link to the reservation in the Keyhowl app',
        'Popup with disclaimers if user attempts to change reservation status to "Blocked"',
      ],
      changed: ['Design of played dialog'],
      deprecated: [],
      removed: [],
      fixed: ['Not sending email if it was capitalized'],
      security: [],
    },
  },
  {
    version: '0.1.0',
    date: '2022-02-12',
    description: '',
    changes: {
      added: ['Changelog page'],
      changed: ['Link to this changelog in the navigation bar'],
      deprecated: [],
      removed: [],
      fixed: [],
      security: [],
    },
  },
]

export default changelog
