import { useState } from 'react'
import { Rect, Text } from 'react-konva'

export default function DraggableText({ element, handleDragEnd, ...rest }) {
  const [isHovered, setIsHovered] = useState(false)
  const [position, setPosition] = useState({ x: element.x, y: element.y })

  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)

  const handleDragMove = (e) => {
    setPosition({
      x: e.target.x(),
      y: e.target.y(),
    })
  }

  return (
    <>
      {isHovered && (
        <Rect
          x={position.x}
          y={position.y}
          width={element.width}
          height={element.fontSize}
          stroke={element.fill}
          strokeWidth={1}
        />
      )}

      <Text
        {...element}
        draggable
        onDragEnd={(e) => handleDragEnd(e, element.id)}
        fillAfterStrokeEnabled={true}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onDragMove={handleDragMove}
        {...rest}
      />
    </>
  )
}
