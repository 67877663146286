import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function DeleteAfterEditDialog({ open, setOpen, handleDelete }) {
  const { t } = useTranslation('pocket')

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('DeletePhotoAfterEdit')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('ShouldDeletePhotoText')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('translation:Cancel')}</Button>
        <Button
          onClick={async () => {
            await handleDelete()
            handleClose()
          }}
          color="primary"
          variant="contained"
        >
          {t('translation:Delete')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
