import { Checkbox, Divider, FormControlLabel, Grid, TextField, Typography } from '@mui/material'
import { CustomPaperComponent, ReservationPickerField } from 'components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CompletionTimeField from 'views/Calendar/components/ReservationDialog/components/ReservationDetails/components/CompletionTimeField'
import { Preview } from 'views/PhotoFrame/components'
import {
  DYNAMIC_VALUES_FOR_PHOTO_FRAME_TEXTS,
  TEAM_NAME,
  TOTAL_TIME,
} from 'views/PhotoFrame/components/Element/components/TextDynamicValues/TextDynamicValues'
import { dynamicValueSetter } from './utils'
import { useEscapeRooms } from 'core'

export default function Review({
  stageDimensions,
  aspectRatio,
  elements,
  reservation,
  completionTime,
  teamName,
  setReservation,
  setCompletionTime,
  setTeamName,
  setDataUrl,
}) {
  const { t } = useTranslation('photos')
  const { escapeRooms } = useEscapeRooms()

  const isRequired = (text) => elements.map((element) => element?.text).some((elementText) => elementText === text)

  const [showOnlyMinutesAndSeconds, setShowOnlyMinutesAndSeconds] = useState(
    localStorage.getItem('showOnlyMinutesAndSecondsInPhotoFrame')
      ? JSON.parse(localStorage.getItem('showOnlyMinutesAndSecondsInPhotoFrame'))
      : false
  )

  const elementsWithDynamicValues = reservation
    ? elements.map((element) => {
        if (element.type === 'text' && DYNAMIC_VALUES_FOR_PHOTO_FRAME_TEXTS.includes(element.text)) {
          return {
            ...element,
            text: dynamicValueSetter(
              element.text,
              teamName,
              completionTime,
              showOnlyMinutesAndSeconds,
              reservation,
              escapeRooms
            ),
          }
        }
        return element
      })
    : elements

  // we are updating them here and not with setElements because the user might go Back
  // and we would have to revert the changes we made to show the correct element names
  const [updatedElements, setUpdatedElements] = useState(elementsWithDynamicValues)

  useEffect(() => {
    if (reservation) {
      setUpdatedElements(
        elements.map((element) => {
          if (element.type === 'text' && DYNAMIC_VALUES_FOR_PHOTO_FRAME_TEXTS.includes(element.text)) {
            return {
              ...element,
              text: dynamicValueSetter(
                element.text,
                teamName,
                completionTime,
                showOnlyMinutesAndSeconds,
                reservation,
                escapeRooms
              ),
            }
          }
          return element
        })
      )
    } else {
      setUpdatedElements(elements)
    }
  }, [reservation, completionTime, teamName, elements, escapeRooms, showOnlyMinutesAndSeconds])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={6}>
        <CustomPaperComponent sx={{ p: 3 }}>
          <Preview
            hideButtons
            isCreate={false}
            aspectRatio={aspectRatio}
            stageDimensions={stageDimensions}
            setStageDimensions={() => {}}
            elements={updatedElements}
            setElements={setUpdatedElements}
            setDataUrl={setDataUrl}
            interactable={false}
          />
        </CustomPaperComponent>
      </Grid>
      <Grid item xs={12} lg={6}>
        <CustomPaperComponent sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            {t('ReservationDetails')}
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ReservationPickerField
                textFieldProps={{ required: true }}
                reservation={reservation}
                setReservation={(selectedReservation) => {
                  setReservation(selectedReservation)
                  setCompletionTime(selectedReservation?.completionTime ?? 0)
                  setTeamName(selectedReservation?.teamName ?? '')
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CompletionTimeField
                value={completionTime}
                onChange={(value) => setCompletionTime(value)}
                textFieldProps={{ required: isRequired(TOTAL_TIME) }}
              />
              {isRequired(TOTAL_TIME) && (
                <FormControlLabel
                  sx={{ ml: 0 }}
                  label={<Typography variant="caption">{t('ShowOnlyMinutesAndSeconds')}</Typography>}
                  control={
                    <Checkbox
                      size="small"
                      checked={showOnlyMinutesAndSeconds}
                      onChange={(e) => {
                        localStorage.setItem('showOnlyMinutesAndSecondsInPhotoFrame', e.target.checked)
                        setShowOnlyMinutesAndSeconds(e.target.checked)
                      }}
                    />
                  }
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                required={isRequired(TEAM_NAME)}
                fullWidth
                label={t('reservations:TeamName')}
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
              />
            </Grid>
          </Grid>
        </CustomPaperComponent>
      </Grid>
    </Grid>
  )
}
