import { FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, TextField } from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers-pro'
import { makeStyles } from '@mui/styles'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { useDate } from 'core'

const useStyles = makeStyles((theme) => ({
  field: {
    margin: theme.spacing(1, 0),
  },
}))

/*  
    HOW TO USE:
    1. Pass formik to the component 
    2. Add to initialValues: { frequency, minutes, hour, weekDay, day, month } (Copy from below)
    ------------------------
    dayValue: 'specificDate',
    frequency: 'everyMonth',
    month: 'JAN',
    day: 1,
    weekDay: '',
    hour: 0,
    minutes: 0,
    ------------------------
*/

const CronExpressionFields = ({ formik, ...rest }) => {
  const classes = useStyles()
  const { t } = useTranslation(['finances', 'translation'])
  const { todaysDateInMillis } = useDate()

  const [time, setTime] = useState(todaysDateInMillis)

  return (
    <>
      <TextField
        className={classes.field}
        select
        fullWidth
        variant="outlined"
        name="frequency"
        label={t('Frequency')}
        value={formik.values.frequency}
        onChange={formik.handleChange}
        error={formik.touched.frequency && Boolean(formik.errors.frequency)}
        helperText={formik.touched.frequency && formik.errors.frequency}
        inputProps={{
          'aria-label': 'frequency',
        }}
        {...rest}
      >
        <MenuItem value="everyDay">{t('EveryDay')}</MenuItem>
        <MenuItem value="everyWeek">{t('EveryWeek')}</MenuItem>
        <MenuItem value="everyMonth">{t('EveryMonth')}</MenuItem>
        <MenuItem value="everyYear">{t('EveryYear')}</MenuItem>
      </TextField>
      <div className={classes.field}>
        <TimePicker
          ampm={false}
          name="hour"
          value={DateTime.fromMillis(time)}
          onChange={(newValue) => {
            setTime(newValue?.valueOf())
            formik.setFieldValue('hour', newValue?.c?.hour || 0)
            formik.setFieldValue('minutes', newValue?.c?.minute || 0)
          }}
          renderInput={(params) => <TextField fullWidth {...params} label={t('SelectHour')} {...rest} />}
        />
      </div>
      {formik.values.frequency === 'everyWeek' && (
        <TextField
          className={classes.field}
          select
          fullWidth
          variant="outlined"
          name="weekDay"
          label={t('SelectWeekDay')}
          value={formik.values.weekDay}
          onChange={formik.handleChange}
          error={formik.touched.weekDay && Boolean(formik.errors.weekDay)}
          helperText={formik.touched.weekDay && formik.errors.weekDay}
          inputProps={{
            'aria-label': 'weekDay',
          }}
          {...rest}
        >
          <MenuItem value="MON">{t('translation:Monday')}</MenuItem>
          <MenuItem value="TUE">{t('translation:Tuesday')}</MenuItem>
          <MenuItem value="WED">{t('translation:Wednesday')}</MenuItem>
          <MenuItem value="THU">{t('translation:Thursday')}</MenuItem>
          <MenuItem value="FRI">{t('translation:Friday')}</MenuItem>
          <MenuItem value="SAT">{t('translation:Saturday')}</MenuItem>
          <MenuItem value="SUN">{t('translation:Sunday')}</MenuItem>
        </TextField>
      )}
      {(formik.values.frequency === 'everyMonth' || formik.values.frequency === 'everyYear') && (
        <FormControl component="fieldset" sx={{ width: '100%' }} className={classes.field}>
          <FormLabel component="legend">{t('SelectDay')}</FormLabel>
          <RadioGroup
            aria-label="select-day"
            value={formik.values.dayValue}
            onChange={formik.handleChange}
            name="dayValue"
          >
            <FormControlLabel value="specificDate" control={<Radio />} label={t('SpecificDateOfMonth')} />
            {formik.values.dayValue === 'specificDate' && (
              <TextField
                className={classes.field}
                select
                fullWidth
                variant="outlined"
                name="day"
                label={t('SelectDay')}
                value={formik.values.day}
                onChange={formik.handleChange}
                error={formik.touched.day && Boolean(formik.errors.day)}
                helperText={formik.touched.day ? formik.errors.day : t('SpecificDateHelperText')}
                inputProps={{
                  'aria-label': 'day',
                }}
                {...rest}
              >
                {[...Array(31)].map((v, index) => (
                  <MenuItem value={index + 1} key={index}>
                    {index + 1}
                  </MenuItem>
                ))}
              </TextField>
            )}
            <FormControlLabel value="lastDayOfMonth" control={<Radio />} label={t('LastDayOfMonth')} />
          </RadioGroup>
        </FormControl>
      )}
      {formik.values.frequency === 'everyYear' && (
        <TextField
          className={classes.field}
          select
          fullWidth
          variant="outlined"
          name="month"
          label={t('SelectMonth')}
          value={formik.values.month}
          onChange={formik.handleChange}
          error={formik.touched.month && Boolean(formik.errors.month)}
          helperText={formik.touched.month && formik.errors.month}
          inputProps={{
            'aria-label': 'month',
          }}
          {...rest}
        >
          {[
            { value: 'JAN', key: t('translation:January') },
            { value: 'FEB', key: t('translation:February') },
            { value: 'MAR', key: t('translation:March') },
            { value: 'APR', key: t('translation:April') },
            { value: 'MAY', key: t('translation:May') },
            { value: 'JUN', key: t('translation:June') },
            { value: 'JUL', key: t('translation:July') },
            { value: 'AUG', key: t('translation:August') },
            { value: 'SEP', key: t('translation:September') },
            { value: 'OCT', key: t('translation:October') },
            { value: 'NOV', key: t('translation:November') },
            { value: 'DEC', key: t('translation:December') },
          ].map((month) => (
            <MenuItem value={month.value} key={month.value}>
              {month.key}
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  )
}

export default CronExpressionFields
