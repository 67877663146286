import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, TextField, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import BusinessIcon from '@mui/icons-material/Business'
import { useState } from 'react'
import { useEscapeRooms, useTenantInfo } from 'core/contexts'
import { useAuth } from 'core'
import { ACCEPTED_ADMIN_GROUPS } from 'config'

const SelectOtherCompany = () => {
  const { t } = useTranslation()
  const { tenants, setActiveTenant, activeTenant } = useTenantInfo()
  const { setLoadingEscapeRooms } = useEscapeRooms()
  const {
    user: { userGroup },
  } = useAuth()

  const [open, setOpen] = useState(false)
  const [searchText, setSearchText] = useState('')

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSelectTenant = (tenant) => {
    localStorage.setItem('last-selected-company', tenant)
    setLoadingEscapeRooms(true)
    setActiveTenant(tenant)
    handleClose()
  }

  return (
    <>
      <Tooltip title={t('SelectDifferentCompany')}>
        <IconButton size="small" aria-label="select different company" onClick={handleClickOpen}>
          <BusinessIcon />
        </IconButton>
      </Tooltip>

      <Dialog onClose={handleClose} aria-labelledby="select different company" open={open} maxWidth="xs">
        <DialogTitle id="select different company">{t('SelectDifferentCompany')}</DialogTitle>
        <DialogContent>
          {ACCEPTED_ADMIN_GROUPS.includes(userGroup) && (
            <TextField
              size="small"
              type="search"
              sx={{ mb: 2, mt: 1 }}
              label={t('Search')}
              fullWidth
              value={searchText}
              helperText="By name, ID or owner"
              onChange={(e) => setSearchText(e.target.value)}
            />
          )}
          {ACCEPTED_ADMIN_GROUPS.includes(userGroup)
            ? tenants
                .filter(
                  (tenant) =>
                    tenant.name.toLowerCase().includes(searchText.toLowerCase()) ||
                    tenant.owner.toLowerCase().includes(searchText.toLowerCase()) ||
                    tenant.id.toLowerCase().includes(searchText.toLowerCase()) ||
                    searchText === ''
                )
                .map((tenant) => (
                  <Box key={tenant.id} display="flex" justifyContent="space-between" alignItems="center" mb={1.5}>
                    {tenant.name}
                    <Button
                      size="small"
                      disabled={activeTenant === tenant.id}
                      variant="outlined"
                      color="primary"
                      onClick={() => handleSelectTenant(tenant.id)}
                    >
                      {activeTenant === tenant.id ? t('Selected') : t('Select')}
                    </Button>
                  </Box>
                ))
            : tenants.map((tenant) => (
                <Box key={tenant.id} display="flex" justifyContent="space-between" alignItems="center" mb={1.5}>
                  {tenant.name}
                  <Button
                    size="small"
                    disabled={activeTenant === tenant.id}
                    variant="outlined"
                    color="primary"
                    onClick={() => handleSelectTenant(tenant.id)}
                  >
                    {activeTenant === tenant.id ? t('Selected') : t('Select')}
                  </Button>
                </Box>
              ))}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SelectOtherCompany
