import {
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Popover,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import NotificationsIcon from '@mui/icons-material/Notifications'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import ImageIcon from '@mui/icons-material/Image'
import SimpleBar from 'simplebar-react'
import { transformLanguage } from 'helpers'
import { DateTime } from 'luxon'

const useStyles = makeStyles((theme) => ({
  topBar: {
    width: 360,
  },
}))

const dummyNotifications = [
  {
    id: 1,
    text: 'Hello, this is notification 1',
    link: '/',
    created: new Date(2022, 2, 14).valueOf(),
    seen: false,
    avatar: null,
  },
  {
    id: 2,
    text: 'Hello, this is notification 2',
    link: '/',
    created: new Date(2021, 4, 8).valueOf(),
    seen: true,
    avatar: null,
  },
  {
    id: 3,
    text: 'Hello, this is notification 3',
    link: '/',
    created: new Date(2021, 5, 8).valueOf(),
    seen: true,
    avatar: null,
  },
  {
    id: 4,
    text: 'Hello, this is notification 4',
    link: '/',
    created: new Date(2021, 5, 1).valueOf(),
    seen: false,
    avatar: null,
  },
  {
    id: 5,
    text: 'Hello, this is notification 5',
    link: '/',
    created: new Date(2021, 5, 1).valueOf(),
    seen: true,
    avatar: null,
  },
  {
    id: 6,
    text: 'Hello, this is notification 6',
    link: '/',
    created: new Date(2021, 5, 1).valueOf(),
    seen: false,
    avatar: null,
  },
  {
    id: 7,
    text: 'Hello, this is notification 7',
    link: '/',
    created: new Date(2021, 5, 1).valueOf(),
    seen: true,
    avatar: null,
  },
  {
    id: 8,
    text: 'Hello, this is notification 8',
    link: '/',
    created: new Date(2021, 5, 1).valueOf(),
    seen: true,
    avatar: null,
  },
]

const Notifications = ({ ...rest }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t, i18n } = useTranslation()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const unreadNotifications = dummyNotifications.filter((notification) => notification.seen === false)
  const readNotifications = dummyNotifications.filter((notification) => notification.seen === true)

  return (
    readNotifications.length && (
      <div className={classes.root} {...rest}>
        <Tooltip title={t('Notifications')} placement="bottom" style={{ marginRight: 8 }}>
          <IconButton onClick={handleClick}>
            <Badge badgeContent={4} variant="dot" overlap="circular" color="primary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          PaperProps={{
            sx: {
              borderRadius: 2,
              boxShadow: theme.customShadows.z16,
              backgroundColor: theme.palette.background.default,
              color: theme.palette.text.primary,
              minWidth: 220,
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box className={classes.topBar}>
            <Box p={2} display="flex" alignItems="center" justifyContent="space-between">
              <Box>
                <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 500 }}>
                  {t('Notifications')}
                </Typography>
                <Typography variant="body2" color="textSecondary" style={{ fontWeight: 'normal' }}>
                  {t('XUnreadNotifications', { x: unreadNotifications.length })}
                </Typography>
              </Box>
              <Box>
                <Tooltip title={t('MarkAsRead')} placement="bottom" style={{ marginRight: 8 }}>
                  <IconButton color="primary">
                    <DoneAllIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Divider />
            <SimpleBar style={{ maxHeight: '500px' }}>
              {unreadNotifications.length && (
                <List
                  aria-labelledby="unread-notifications"
                  subheader={
                    <ListSubheader component="div" id="unread-notifications">
                      {t('New')}
                    </ListSubheader>
                  }
                >
                  {unreadNotifications.map((notification) => (
                    <ListItem key={notification.id} button>
                      <ListItemIcon>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
                            {notification.text}
                          </Typography>
                        }
                        secondary={
                          <Typography variant="caption" color="textSecondary">
                            {DateTime.fromMillis(notification.created)
                              .setLocale(transformLanguage(i18n.language))
                              .toRelative()}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              )}

              {readNotifications.length && (
                <List
                  aria-labelledby="read-notifications"
                  subheader={
                    <ListSubheader component="div" id="read-notifications">
                      {t('BeforeThat')}
                    </ListSubheader>
                  }
                >
                  {readNotifications.map((notification) => (
                    <ListItem key={notification.id} button>
                      <ListItemIcon>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="subtitle2" style={{ fontWeight: 400 }}>
                            {notification.text}
                          </Typography>
                        }
                        secondary={
                          <Typography variant="caption" color="textSecondary">
                            {DateTime.fromMillis(notification.created)
                              .setLocale(transformLanguage(i18n.language))
                              .toRelative()}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              )}
            </SimpleBar>
          </Box>
        </Popover>
      </div>
    )
  )
}

export default Notifications
