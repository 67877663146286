import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  typography: {
    fontSize: 14,
    fontWeight: 400,
    width: '100%',
  },
}))

const String = ({ children: value }) => {
  const classes = useStyles()

  return (
    <Typography component="div" className={classes.typography} noWrap>
      {value}
    </Typography>
  )
}

export default String
