import { Box, Button, Card, CardHeader, Divider, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ProfileAvatar } from 'components'
import { API_URL } from 'config'
import { useAuth, useTenantInfo, useUserPreferences } from 'core'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { PageURLs } from 'Routes'
import { Language } from './components'
import LogoutIcon from '@mui/icons-material/Logout'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 2.5, 0),
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40,
  },
  card: {
    background: theme.palette.background.default,
    borderRadius: 12,
  },
}))

const UserInformation = () => {
  const classes = useStyles()
  const { user, logout } = useAuth()
  const { activeTenantFullInfo } = useTenantInfo()
  const { isDrawerOpen, isDesktop } = useUserPreferences()
  const { t } = useTranslation()

  return (
    <Box className={classes.root}>
      <Card component={RouterLink} to={PageURLs.Profile} sx={(theme) => ({ boxShadow: theme.customShadows.z4 })}>
        <CardHeader
          className={classes.card}
          sx={!isDrawerOpen ? { background: 'transparent !important', px: 0.5 } : {}}
          avatar={
            <ProfileAvatar
              firstName={user?.firstName}
              lastName={user?.lastName}
              className={classes.avatar}
              src={user?.avatar && `${API_URL}/media/${user?.avatar}`}
            />
          }
          title={
            isDrawerOpen && (
              <Typography variant="subtitle2">
                {user?.firstName} {user?.lastName}
              </Typography>
            )
          }
          subheader={isDrawerOpen && t(activeTenantFullInfo?.role)}
        />
      </Card>
      {!isDesktop && (
        <Stack
          sx={{
            bgcolor: 'background.default',
            mt: '-8px',
            pt: '8px',
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
          }}
        >
          <Divider />
          <Box display="flex" justifyContent="space-around">
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ maxWidth: 120, px: 1, py: 0.5 }}>
              <Typography noWrap color="textPrimary">
                <Language />
              </Typography>
            </Box>
            <Divider flexItem orientation="vertical" />
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ maxWidth: 120, px: 1 }}>
              <Typography variant="subtitle2" noWrap>
                <Button
                  fullWidth
                  size="small"
                  variant="text"
                  startIcon={<LogoutIcon />}
                  onClick={() => logout({ sessionExpired: false })}
                  sx={{ color: 'text.primary' }}
                >
                  {t('Logout')}
                </Button>
              </Typography>
            </Box>
          </Box>
        </Stack>
      )}
    </Box>
  )
}

export default UserInformation
