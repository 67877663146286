import { Box, CardMedia, IconButton, Stack, Typography } from '@mui/material'
import { API_URL } from 'config'
import { useTenantInfo } from 'core'
import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined'
import { Add, Remove } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { DisplayCurrency } from 'components'

export default function AddRemoveProductsFromCart({
  roomId,
  cart,
  setCart,
  disableRemoveButton = false,
  disableAddButton = false,
  initialCart = {},
}) {
  const { t } = useTranslation()
  const { getProductsForRoom, activeTenantFullInfo } = useTenantInfo()
  const products = getProductsForRoom(roomId)

  const addToCart = (productId) => {
    setCart((products) => ({ ...products, [productId]: productId in products ? products[productId] + 1 : 1 }))
  }

  const removeFromCart = (productId) => {
    setCart((products) => ({
      ...products,
      [productId]: !(productId in products) || products[productId] === 0 ? 0 : products[productId] - 1,
    }))
  }

  const isAddButtonDisabled = (productId) => {
    if (!(productId in initialCart)) {
      return true
    }

    if (initialCart[productId] <= cart[productId]) {
      return true
    }

    return false
  }

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {products.map((product) => (
        <Box
          key={product.id}
          sx={{
            borderRadius: 2,
            border: '1px solid',
            borderColor: 'divider',
            p: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            {!!product.images.length ? (
              <CardMedia
                component="img"
                height="50"
                alt={product.name}
                sx={{ borderRadius: 1, overflow: 'hidden', maxWidth: 50 }}
                image={`${API_URL}/media/${product.images[0]}`}
              />
            ) : (
              <Box sx={{ height: 50, width: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <ImageNotSupportedOutlinedIcon fontSize="large" />
              </Box>
            )}
            <Box display="flex" flexDirection="column">
              <Typography
                noWrap
                sx={{ maxWidth: 200 }}
                title={`${product.name} (${product.price} ${activeTenantFullInfo.currency})`}
              >
                {product.name} (<DisplayCurrency number={product.price} />)
              </Typography>

              <Box display="flex" sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}>
                {product.config.quantityEnabled && (
                  <Typography
                    variant="caption"
                    color={product.quantity <= 3 ? 'error' : product.quantity <= 10 ? 'warning.main' : 'textSecondary'}
                    sx={{ mr: 1 }}
                  >
                    {t('products:Quantity')}: {product.quantity}
                  </Typography>
                )}
                <Stack>
                  {product.config.quantityEnabled && cart?.[product.id] >= product.quantity && (
                    <Typography variant="caption" sx={{ color: 'warning.main' }}>
                      {t('products:ReachedAvailableQuantity')}
                    </Typography>
                  )}
                  {product.config.maxOrderQuantityEnabled && cart[product.id] >= product.maxOrderQuantity && (
                    <Typography variant="caption" sx={{ color: 'warning.main' }}>
                      {t('products:ReachedMaxOrderQuantity')}
                    </Typography>
                  )}
                </Stack>
              </Box>
            </Box>
          </Box>

          <Box display="flex" alignItems="center">
            <IconButton
              size="small"
              disabled={disableRemoveButton || !(product.id in cart) || cart?.[product.id] === 0}
              onClick={() => removeFromCart(product.id)}
            >
              <Remove />
            </IconButton>
            <Typography align="center" sx={{ mx: 1, cursor: 'default', width: 16 }}>
              {product.id in cart ? cart[product.id] : 0}
            </Typography>
            <IconButton
              size="small"
              disabled={
                disableAddButton
                  ? isAddButtonDisabled(product.id)
                  : false ||
                    (product.config.quantityEnabled && product.quantity === 0) ||
                    (product.config.quantityEnabled && cart[product.id] >= product.quantity) ||
                    (product.config.maxOrderQuantityEnabled && cart[product.id] >= product.maxOrderQuantity)
              }
              onClick={() => addToCart(product.id)}
            >
              <Add />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Box>
  )
}
