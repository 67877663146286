import { Paper, useTheme } from '@mui/material'
import { memo } from 'react'

const CustomPaperComponent = ({ children, backgroundColor, color, boxShadow, ...rest }) => {
  const theme = useTheme()

  return (
    <Paper
      style={{
        borderRadius: 16,
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        boxShadow: theme.customShadows.z4,
      }}
      {...rest}
    >
      {children}
    </Paper>
  )
}

export default memo(CustomPaperComponent)
