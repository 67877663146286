import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FieldType } from '../../DisplayValue'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: 400,
  },
}))

const AdditionalField = ({ row, column, value, additionalFieldValue, setIsUpdated, t }) => {
  const classes = useStyles()

  return (
    <Box>
      <Typography component="div" className={classes.root}>
        {FieldType({ column, value, row, setIsUpdated, t })}
      </Typography>
      <Typography component="div" className={classes.root} color="textSecondary">
        {FieldType({ column, value: additionalFieldValue, row, isAdditionalField: true, setIsUpdated, t })}
      </Typography>
    </Box>
  )
}

export default AdditionalField
