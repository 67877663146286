import { createContext, useContext, useState } from 'react'

const initialState = {
  topBarComponent: null,
}

const MiscellaneousContext = createContext(initialState)

const MiscellaneousProvider = ({ children }) => {
  const [topBarComponent, setTopBarComponent] = useState(initialState.topBarComponent)

  return (
    <MiscellaneousContext.Provider
      value={{
        topBarComponent,
        setTopBarComponent,
      }}
      displayName="Miscellaneous"
    >
      {children}
    </MiscellaneousContext.Provider>
  )
}

const useMisc = () => {
  const context = useContext(MiscellaneousContext)

  if (context === undefined) {
    throw new Error('useMisc can only be used inside MiscellaneousProvider')
  }

  return context
}

export { MiscellaneousProvider, useMisc }
