/* eslint-disable import/no-anonymous-default-export */
export default (theme) => ({
  MuiTabs: {
    styleOverrides: {
      root: {
        alignItems: 'center',
      },
      scroller: {
        height: 48,
      },
      flexContainer: {
        height: '100%',
      },
    },
  },
})
