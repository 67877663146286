import { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { API_URL } from 'config'

const useError = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t, i18n } = useTranslation()
  const [error, setError] = useState(null)
  const [errorCode, setErrorCode] = useState(0)

  const sendError = async (error) => {
    try {
      await axios.post(`${API_URL}/error-report`, { data: error })
    } catch (err) {
      console.error('Could not send error:', err)
    }
  }

  useEffect(() => {
    if (error) {
      setErrorCode(error?.response?.data?.errorCode)
      const errorMessage = error?.response?.data?.message

      !!errorMessage &&
        enqueueSnackbar(
          i18n.exists(`errors:Error${error.response.data.errorCode}`)
            ? t(`errors:Error${error.response.data.errorCode}`)
            : errorMessage,
          {
            variant: 'error',
          }
        )
    }
    // eslint-disable-next-line
  }, [error])

  return { errorMessage: t(`errors:Error${errorCode}`), setError, sendError }
}

export default useError
