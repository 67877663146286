import { convertMillisecondsToHumanReadableFormat } from 'views/Calendar/components/ReservationDialog/components/ReservationDetails/components/CompletionTimeField/helpers'
import {
  ROOM_NAME,
  TEAM_NAME,
  TOTAL_TIME,
} from 'views/PhotoFrame/components/Element/components/TextDynamicValues/TextDynamicValues'

export const dynamicValueSetter = (
  text,
  teamName,
  completionTime,
  showOnlyMinutesAndSeconds,
  reservation,
  escapeRooms
) => {
  const escapeRoom = escapeRooms.find((room) => room.id === reservation.key.roomId)

  switch (text) {
    case ROOM_NAME:
      return escapeRoom?.localizedName[reservation.language] ?? escapeRoom?.name
    case TEAM_NAME:
      return teamName
    case TOTAL_TIME:
      return convertMillisecondsToHumanReadableFormat(completionTime, showOnlyMinutesAndSeconds)
    default:
      return 'Dynamic value unknown; please change it or remove it'
  }
}
