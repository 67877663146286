import { AppBar, Box, IconButton, Toolbar, Tooltip } from '@mui/material'
import { useTheme, makeStyles } from '@mui/styles'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { alpha } from 'utils'
import { useAuth, useThemeEdit } from 'core'
import DarkThemeIcon from '@mui/icons-material/Brightness4'
import LightThemeIcon from '@mui/icons-material/Brightness7'
import { useTranslation } from 'react-i18next'
import { Language } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    background: alpha(theme.palette.background.default, 0.1),
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(4),
  },
}))

const TopBar = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { changeTheme } = useThemeEdit()
  const { logout } = useAuth()
  const { t } = useTranslation()

  return (
    <AppBar className={classes.root} elevation={0} position="static">
      <Toolbar>
        <Box flexGrow={1}>Logo</Box>
        <Box display="flex">
          <Language />
          <Tooltip title={t('ChangeTheme')} placement="bottom" style={{ marginRight: 8 }}>
            {theme.palette.mode === 'light' ? (
              <IconButton color="primary" onClick={changeTheme}>
                <LightThemeIcon />
              </IconButton>
            ) : (
              <IconButton color="primary" onClick={changeTheme}>
                <DarkThemeIcon />
              </IconButton>
            )}
          </Tooltip>
          <Tooltip title={t('Logout')} placement="bottom">
            <IconButton color="primary" onClick={() => logout({ sessionExpired: false })}>
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
